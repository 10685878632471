// import { Directive, ElementRef, HostListener, Renderer2 } from "@angular/core";

// @Directive({
//   selector: "img[openImageViewer], video[openImageViewer]",
// })
// export class OpenImageViewerDirective {
//   private images: string[] = [];
//   private currentIndex = 0;

//   constructor(private elementRef: ElementRef, private renderer: Renderer2) {
//     this.addStyles();
//   }

//   @HostListener("click")
//   onClick() {
//     this.images = this.getImagesUrls();
//     if (this.images.length === 0) {
//       return;
//     }

//     const imageUrl = this.elementRef.nativeElement.src;
//     this.currentIndex = this.images.indexOf(imageUrl);

//     const viewer = this.createImageViewer();
//     this.openImageViewer(viewer, imageUrl);
//   }

//   private addStyles(): void {
//     const styles = `
//     .image-viewer {
//       position: fixed;
//       top: 0;
//       left: 0;
//       width: 100%;
//       height: 100%;
//       z-index: 9999;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//     }

//     .image-viewer-overlay {
//       position: absolute;
//       top: 0;
//       left: 0;
//       width: 100%;
//       height: 100%;
//       background-color: rgba(0, 0, 0, 0.8);
//     }

//     .image-viewer-image-container {
//       position: relative;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       width: 100%;
//       height: 100%;
//     }

//     .image-viewer-image {
//       max-width: 90%;
//       max-height: 90%;
//       z-index: 1;
//     }

//     .image-viewer-navigation {
//       position: absolute;
//       top: 0;
//       height: 100%;
//       display: flex;
//       align-items: center;
//     }

//     .image-viewer-navigation-button {
//       color: white;
//       font-size: 50px;
//       background: none;
//       border: none;
//       cursor: pointer;
//       outline: none;
//       margin: 0 8px; /* Adjust the margin as needed */
//     }

//     .image-viewer-navigation-button.prev-button {
//       margin-right: 16px;
//     }

//     .image-viewer-navigation-button.next-button {
//       margin-left: 16px;
//     }
//   `;

//     const styleElement = this.renderer.createElement("style");
//     this.renderer.appendChild(styleElement, this.renderer.createText(styles));
//     this.renderer.appendChild(document.head, styleElement);
//   }

//   private createImageViewer(): HTMLDivElement {
//     const viewer = this.renderer.createElement("div");
//     this.renderer.addClass(viewer, "image-viewer");
//     return viewer;
//   }

//   private openImageViewer(viewer: HTMLDivElement, imageUrl: string): void {
//     const body = document.body;
//     this.renderer.appendChild(body, viewer);
//     viewer.innerHTML = `
//       <div class="image-viewer-overlay"></div>
//       <div class="image-viewer-image-container">
//         <button class="image-viewer-navigation-button prev-button">&lt;</button>
//         <img class="image-viewer-image" src="${imageUrl}" alt="Image">
//         <button class="image-viewer-navigation-button next-button">&gt;</button>
//       </div>
//     `;

//     const prevButton = viewer.querySelector(".prev-button");
//     const nextButton = viewer.querySelector(".next-button");

//     // Add event listeners to handle navigation
//     this.renderer.listen(prevButton, "click", () => this.showPreviousImage(viewer));
//     this.renderer.listen(nextButton, "click", () => this.showNextImage(viewer));
//   }

//   private closeImageViewer(viewer: HTMLDivElement): void {
//     const body = document.body;
//     this.renderer.removeChild(body, viewer);
//   }

//   private showPreviousImage(viewer: HTMLDivElement): void {
//     if (this.currentIndex > 0) {
//       this.currentIndex--;
//       const imageUrl = this.images[this.currentIndex];
//       this.updateImageViewerImage(viewer, imageUrl);
//     }
//   }

//   private showNextImage(viewer: HTMLDivElement): void {
//     if (this.currentIndex < this.images.length - 1) {
//       this.currentIndex++;
//       const imageUrl = this.images[this.currentIndex];
//       this.updateImageViewerImage(viewer, imageUrl);
//     }
//   }

//   private updateImageViewerImage(viewer: HTMLDivElement, imageUrl: string): void {
//     const imageElement = viewer.querySelector(".image-viewer-image");
//     imageElement.setAttribute("src", imageUrl);
//   }

//   private getImagesUrls(): string[] {
//     const images: HTMLImageElement[] = Array.from(document.querySelectorAll("img[openImageViewer]"));
//     return images.map((image) => image.src);
//   }
// }
import { Directive, ElementRef, HostListener, Renderer2 } from "@angular/core";

@Directive({
	selector: "img[openImageViewer], video[openImageViewer]",
})
export class OpenImageViewerDirective {
	private mediaElement: HTMLImageElement | HTMLVideoElement;
	private images: string[] = [];
	private currentIndex = 0;

	constructor(private elementRef: ElementRef, private renderer: Renderer2) {
		this.addStyles();
	}

	@HostListener("click")
	onClick() {
		this.images = this.getImagesUrls();
		if (this.images.length === 0) {
			return;
		}

		const mediaUrl = this.elementRef.nativeElement.src;
		this.currentIndex = this.images.indexOf(mediaUrl);

		const viewer = this.createImageViewer();
		this.openImageViewer(viewer, mediaUrl);
	}

	private addStyles(): void {
    const styles = `
    .image-viewer {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 9999;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .image-viewer-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.8);
    }
    
    .image-viewer-media-container {
      z-index: 1;
    }

    .image-viewer-media {
      max-width: 90%;
      max-height: 90%;
    }

    .image-viewer-navigation {
      position: absolute;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
    }

    .image-viewer-navigation-button {
      color: white;
      font-size: 50px;
      background: none;
      border: none;
      cursor: pointer;
      outline: none;
      margin: 0 8px; /* Adjust the margin as needed */
    }

    .image-viewer-navigation-button.prev-button {
      margin-right: 16px;
    }

    .image-viewer-navigation-button.next-button {
      margin-left: 16px;
    }
  `;

  const styleElement = this.renderer.createElement("style");
  this.renderer.appendChild(styleElement, this.renderer.createText(styles));
  this.renderer.appendChild(document.head, styleElement);
	}

	private createImageViewer(): HTMLDivElement {
		const viewer = this.renderer.createElement("div");
		this.renderer.addClass(viewer, "image-viewer");
		return viewer;
	}

	private openImageViewer(viewer: HTMLDivElement, mediaUrl: string): void {
		const body = document.body;
		this.renderer.appendChild(body, viewer);
		viewer.innerHTML = `
      <div class="image-viewer-overlay"></div>
      <div class="image-viewer-media-container">
        <button class="image-viewer-navigation-button prev-button">&lt;</button>
        ${
					this.isImage(mediaUrl)
						? `<img class="image-viewer-media" src="${mediaUrl}" alt="Image">`
						: `<video class="image-viewer-media" src="${mediaUrl}" controls></video>`
				}
        <button class="image-viewer-navigation-button next-button">&gt;</button>
      </div>
    `;

		const prevButton = viewer.querySelector(".prev-button");
		const nextButton = viewer.querySelector(".next-button");

		// Add event listeners to handle navigation
		this.renderer.listen(prevButton, "click", () =>
			this.showPreviousMedia(viewer),
		);
		this.renderer.listen(nextButton, "click", () => this.showNextMedia(viewer));

		this.mediaElement = viewer.querySelector(".image-viewer-media") as
			| HTMLImageElement
			| HTMLVideoElement;
		this.renderer.listen(this.mediaElement, "click", () =>
			this.toggleFullScreen(),
		);
	}

	private closeImageViewer(viewer: HTMLDivElement): void {
		const body = document.body;
		this.renderer.removeChild(body, viewer);
	}

	private showPreviousMedia(viewer: HTMLDivElement): void {
		if (this.currentIndex > 0) {
			this.currentIndex--;
			const mediaUrl = this.images[this.currentIndex];
			this.updateImageViewerMedia(viewer, mediaUrl);
		}
	}

	private showNextMedia(viewer: HTMLDivElement): void {
		if (this.currentIndex < this.images.length - 1) {
			this.currentIndex++;
			const mediaUrl = this.images[this.currentIndex];
			this.updateImageViewerMedia(viewer, mediaUrl);
		}
	}

	private updateImageViewerMedia(viewer: HTMLDivElement, mediaUrl: string): void {
    const mediaContainer = viewer.querySelector(".image-viewer-media-container");
  
    // Check if the previous media was a video
    const previousMediaType = this.isImage(mediaUrl) ? 'image' : 'video';
    const previousMediaElement = mediaContainer.querySelector(
      previousMediaType === 'image' ? 'img' : 'video'
    );
  
    if (previousMediaType === 'video') {
      // Remove the previous video element
      this.renderer.removeChild(mediaContainer, previousMediaElement);
    }
  
    if (this.isImage(mediaUrl)) {
      // Create a new image element
      const imageElement = this.renderer.createElement('img');
      this.renderer.addClass(imageElement, 'image-viewer-media');
      this.renderer.setAttribute(imageElement, 'src', mediaUrl);
      this.renderer.setAttribute(imageElement, 'alt', 'Image');
  
      // Append the new image element
      this.renderer.appendChild(mediaContainer, imageElement);
      this.mediaElement = imageElement;
    } else {
      // Create a new video element
      const videoElement = this.renderer.createElement('video');
      this.renderer.addClass(videoElement, 'image-viewer-media');
      this.renderer.setAttribute(videoElement, 'src', mediaUrl);
      this.renderer.setAttribute(videoElement, 'controls', '');
  
      // Append the new video element
      this.renderer.appendChild(mediaContainer, videoElement);
      this.mediaElement = videoElement;
    }
  }

	private toggleFullScreen(): void {
		if (this.mediaElement.requestFullscreen) {
			this.mediaElement.requestFullscreen();
		} else if (this.mediaElement.requestFullscreen) {
			this.mediaElement.requestFullscreen();
		} else if (this.mediaElement.requestFullscreen) {
			this.mediaElement.requestFullscreen();
		} else if (this.mediaElement.requestFullscreen) {
			this.mediaElement.requestFullscreen();
		}
	}

	private getImagesUrls(): string[] {
		const elements: (HTMLImageElement | HTMLVideoElement)[] = Array.from(
			document.querySelectorAll("img[openImageViewer], video[openImageViewer]"),
		);
		return elements.map((element) => element.src);
	}

	private isImage(mediaUrl: string): boolean {
		return (
			mediaUrl.endsWith(".jpg") ||
			mediaUrl.endsWith(".jpeg") ||
			mediaUrl.endsWith(".png")
		);
	}
}
