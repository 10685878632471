<mat-expansion-panel
  class="expandable-card-container"
  [expanded]="true"
  #tab
  hideToggle
>
  <mat-expansion-panel-header class="header-container">
    <div class="header">
      <div style="display: flex">
        <!-- <img src="https://i.ibb.co/CHn3H6c/Group.png" alt="" class="header__img" *ngIf="forCarDetails"> -->
        <div class="header__title">{{ title }}</div>
      </div>
      <div style="display: inline-block">
        <button
          mat-stroked-button
          class="header__edit-btn"
          *ngIf="forCarDetails"
          (click)="edit($event)"
          [disabled]="disableBtn"
        >
          {{ "common.edit" | translate }}
        </button>
        <button mat-icon-button class="icon-button" [disableRipple]="true">
          <mat-icon *ngIf="tab.expanded"> keyboard_arrow_up </mat-icon>
          <mat-icon *ngIf="!tab.expanded"> keyboard_arrow_down </mat-icon>
        </button>
      </div>
    </div>
    <div class="snippet">
      <ng-content select="[snippet]" *ngIf="!tab.expanded"></ng-content>
      <ng-content select="[hidden]" *ngIf="tab.expanded"></ng-content>
      <ng-content select="[share]"></ng-content>
    </div>
  </mat-expansion-panel-header>
</mat-expansion-panel>
