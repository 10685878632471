<div class="dialog-wrapper">
    <form class="forms" [formGroup]="vehicleFormGroup" #vehicleForm="ngForm">

        <!-- <div class="forms__bar-container">
            <div></div>
        </div> -->
        <div class="forms__header">
            <div class="forms__header__title">
                {{ "renewQuotation.pleaseCheck" | translate }}
            </div>
            <div class="forms__header__close-button">
                <button mat-icon-button (click)="bottomSheetRef.dismiss();">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>

        <div class="forms__type-container">
            <!-- <mat-form-field class="forms__type-container__form" appearance="outline">
                <mat-label>{{ "common.typeOfInsurance" | translate }}</mat-label>
                <mat-select disableOptionCentering formControlName="insuranceType" required>
                    <mat-option *ngFor="let item of typeOfInsuranceList" [value]="item.id">
                        {{ item.name | translate }}
                    </mat-option>
                </mat-select>
                <mat-error>{{ "common.typeOfInsurance" | translate }}
                    {{
                    vehicleFormGroup.controls.insuranceType.errors
                    | inputError
                    | translate
                    }}</mat-error>
            </mat-form-field> -->

            <!-- <div class="forms__type-container__icon-container">
                <mat-icon [matMenuTriggerFor]="typeTooltip" #typeTrigger="matMenuTrigger"
                    (mouseenter)="typeTrigger.openMenu()">
                    info
                </mat-icon>
                <mat-menu #typeTooltip="matMenu" [overlapTrigger]="false" class="tooltip-popover dashboard">
                    <span (mouseleave)="typeTrigger.closeMenu()">
                        <div class="title">
                            {{ "common.COM" | translate }}
                        </div>
                        {{ "common.comprehensiveInsDesc" | translate }}
                        <br />
                        <br />
                        <div class="title">
                            {{ "common.TPFT" | translate }}
                        </div>
                        {{ "common.fireTheftInsDesc" | translate }}
                    </span>
                </mat-menu>
            </div> -->
        </div>
        <mat-form-field appearance="outline" class="upperc-input">
            <mat-label>{{ "common.carPlateNo" | translate }}</mat-label>
            <input matInput required formControlName="carPlate" mask="A{255}" [validation]="false" autocomplete="off" />
        </mat-form-field>

        <ng-container>
            <div class="forms__identity">
                <div class="forms__identity__dropdown">
                    <mat-form-field appearance="outline">
                        <mat-select formControlName="psIdentityType">
                            <mat-option value="Malaysian">{{
                                "common.Malaysian" | translate
                                }}</mat-option>
                            <mat-option value="Company">{{
                                "common.Company" | translate
                                }}</mat-option>
                            <mat-option value="Foreigner">{{
                                "common.Foreigner" | translate
                                }}</mat-option>
                            <mat-option value="Police/Army">{{
                                "common.Police/Army" | translate
                                }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="forms__identity__input">
                    <ng-container [ngSwitch]="vehicleFormGroup.value.psIdentityType">
                        <ng-container *ngSwitchCase="'Malaysian'">
                            <mat-form-field appearance="outline">
                                <mat-label>
                                    {{ "common.icNoLabel" | translate }}
                                </mat-label>
                                <input matInput formControlName="psIdentityNo" [mask]="getIdentityNoMasking()"
                                    autocomplete="off" [maxLength]="12" />
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngSwitchCase="'Company'">
                            <mat-form-field appearance="outline">
                                <mat-label>{{
                                    "common.businessRegistrationNo" | translate
                                    }}</mat-label>
                                <input matInput formControlName="psIdentityNo" autocomplete="off" />
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngSwitchCase="'Foreigner'">
                            <mat-form-field appearance="outline">
                                <mat-label>{{
                                    "common.passportLabel" | translate
                                    }}</mat-label>
                                <input matInput formControlName="psIdentityNo" autocomplete="off" />
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngSwitchCase="'Police/Army'">
                            <mat-form-field appearance="outline">
                                <mat-label>{{
                                    "common.policeOrArmyIcNo" | translate
                                    }}</mat-label>
                                <input matInput formControlName="psPolicIdentityNo" />
                            </mat-form-field>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
            <ng-container *ngIf="vehicleFormGroup.value.psIdentityType === 'Police/Army'">
                <mat-form-field appearance="outline">
                    <mat-label>{{ "common.icNoLabel" | translate }}</mat-label>
                    <input matInput formControlName="psIdentityNo" [mask]="getIdentityNoMasking()" autocomplete="off"
                        [maxLength]="12" />
                </mat-form-field>
            </ng-container>
        </ng-container>

    </form>
    <form class="forms" #custForm="ngForm" [formGroup]="custFormGroup">
        <div *ngIf="!isMalaysian">
            <mat-form-field appearance="outline">
                <mat-label>{{ "common.nationality" | translate }}</mat-label>
                <mat-select formControlName="nationality">
                    <mat-option>
                        <ngx-mat-select-search [placeholderLabel]="'common.nationalityPH' | translate"
                            [ngModelOptions]="{ standalone: true }" [searching]="!countryList.length"
                            noEntriesFoundLabel="{{
                                'common.nationalityXrecord' | translate
                            }}" (ngModelChange)="filterCountry($event)" ngModel ngDefaultControl>
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let item of filterCountryList" [value]="item">
                        {{ item }}</mat-option>
                </mat-select>
                <mat-error>{{ "common.nationality" | translate }}
                    {{
                    custFormGroup.controls.nationality.errors
                    | inputError
                    | translate
                    }}</mat-error>
            </mat-form-field>
        </div>

        <mat-form-field appearance="outline">
            <mat-label>{{ getNameLabel() | translate }}</mat-label>
            <input matInput autocomplete="name" formControlName="name" />
        </mat-form-field>

        <!-- ## D2C Exclusive (PostCode in step 2) -->
        <ng-container>
            <mat-form-field appearance="outline">
                <mat-label>{{
                    "common.residentialPostcode" | translate
                    }}</mat-label>
                <input matInput required formControlName="psPostCode" mask="00000" autocomplete="postal-code" />
            </mat-form-field>

            <ng-container *ngIf="!isMalaysian">
                <mat-form-field appearance="outline">
                    <mat-label>{{ "common.dob" | translate }}</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="dob" readonly (click)="picker.open()"
                        [max]="maxDob" />
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error>{{ "common.dob" | translate }}
                        {{
                        custFormGroup.controls.dob.errors | inputError | translate
                        }}</mat-error>
                </mat-form-field>
            </ng-container>
        </ng-container>
        <!-- ## End D2C Exclusive (PostCode in step 2) -->

        <mat-form-field appearance="outline">
            <mat-label>{{ "common.mobileNo" | translate }}</mat-label>
            <ngx-mat-intl-tel-input [enableSearch]="true" [preferredCountries]="['my']" formControlName="mobileNo">
            </ngx-mat-intl-tel-input>
            <mat-error>{{ "common.mobileNo" | translate }}
                {{
                custFormGroup.controls.mobileNo.errors
                | inputError
                | translate
                }}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="pb-sm">
            <mat-label>{{ "common.emailAddress" | translate }}</mat-label>
            <input matInput formControlName="email" autocomplete="email" />
            <mat-error style="display: block !important" [hidden]="!custFormGroup.controls.email?.errors?.email">
                {{ "error.invalidEmail" | translate }}</mat-error>
            <mat-error>{{ "common.emailAddress" | translate }}
                {{
                custFormGroup.controls.email.errors | inputError | translate
                }}</mat-error>
        </mat-form-field>

        <!-- ## D2C Exclusive -->
        <section class="box-selection" *ngIf="!isMalaysian">
            <mat-label>{{ "common.gender" | translate }}</mat-label>
            <mat-radio-group class="b2c-radio" aria-label="Select an option" formControlName="gender">
                <mat-radio-button value="M">{{ "common.M" | translate }}
                </mat-radio-button>
                <mat-radio-button value="F">{{ "common.F" | translate }}
                </mat-radio-button>
            </mat-radio-group>
            <mat-error>{{ "common.gender" | translate }}
                {{
                custFormGroup.controls.gender.errors | inputError | translate
                }}</mat-error>
        </section>
        <!-- ## End D2C Exclusive -->

        <section class="box-selection" *ngIf="!isCompany">
            <mat-label>{{ "common.maritalStatus" | translate }}:</mat-label>
            <mat-radio-group class="b2c-radio" aria-label="Select an option" formControlName="maritalStatus">
                <mat-radio-button value="Single">{{ "common.Single" | translate }}
                </mat-radio-button>
                <mat-radio-button value="Married">{{ "common.Married" | translate }}
                </mat-radio-button>
            </mat-radio-group>
        </section>

        <!-- ## D2C Exclusive (Preferred Channel) -->
        <section class="box-selection mt-sm">
            <mat-label>{{ "common.preferredContact" | translate }}:</mat-label>
            <mat-radio-group class="b2c-radio" aria-label="Select an option" formControlName="contactMethod">
                <mat-radio-button value="Email">{{ "common.email" | translate }}
                </mat-radio-button>
                <mat-radio-button value="Mobile">{{ "common.WhatsApp" | translate }}
                </mat-radio-button>
            </mat-radio-group>
        </section>
        <div class="forms__checkbox-container" *ngIf="this.vehicleFormGroup.value.insuranceType !== 'TPFT'">
            <mat-checkbox (change)="eHailingUsedChange($event.checked)" formControlName="eHailingUsed"
                [checked]="eHailingChecked" class="forms__checkbox">
                <div class="forms__checkbox-container__label" [ngStyle]="{ color: eHailingChecked ? '#132E5B' : '' }">
                    {{ "common.carUsedForEHailing" | translate }}
                </div>
            </mat-checkbox>
        </div>
        <!-- ## End D2C Exclusive (Preferred Channel) -->

        <div class="forms__submit-container">
            <button class="forms__submit-container__btn btn--yellow" (click)="onUpdateDetails()" mat-flat-button
                [disabled]="
                    !vehicleFormGroup.valid ||
                    !custFormGroup.valid
                ">
                <div class="">{{ "common.updateDetails" | translate }}</div>
                <mat-icon *ngIf="isLoading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
            </button>
        </div>
    </form>
</div>