export const CAMPAIGN_TYPE = {
  freeRoadTaxFS: "FREE_ROAD_TAX_FLASH_SALES_CAMPAIGN", // discount 100% on road tax price, 50k cap, 28 aug - 30 aug
  roadtaxFS: "ROAD_TAX_FLASH_SALES_CAMPAIGN", // discount 50% on road tax price, no cap, 50k cap - 30 aug
  roadtaxPromo: "ROAD_TAX_PROMO_CAMPAIGN", // discount 50% on road tax price, cap RM 400 / transasction / 9 sep - forever

  roadtaxMalaysianCar: "ROAD_TAX_DISCOUNT_MY_CAR", // campaign for only malaysian made cars
  roadtaxSept14: "ROAD_TAX_DISCOUNT_CAMPAIGN_SEP_2024", // roadtax campaign for sept 14 onwards (until further notice)
  roadtaxSept19: "EPP_0_SERVICE_FEE_2",
};

export const FULLY_REDEEMED_ERR_MSG =
  "Free Road Tax Flash Sales Campaign has been fully redeemed.";

export const FULLY_REDEEMED_MSG = "fully redeemed";
export const HAS_ENDED_MSG = "has ended";
