<div class="customer-policy">
	<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
		<div class="content-container small">
			<p class="main-title mt-md">
				{{ "customerPolicy.title" | translate }}
			</p>
			<p class="secondary-title mt-lg">
				{{ "common.personalDetails" | translate }}
			</p>
			<mat-accordion>
				<mat-expansion-panel class="mat-elevation-z0 expandable-card">
					<mat-expansion-panel-header>
						<mat-panel-title>
							{{ "customerPolicy.ownerName" | translate }}</mat-panel-title
						>
					</mat-expansion-panel-header>
					<mat-form-field appearance="outline" class="input-field">
						<input matInput formControlName="name" />
					</mat-form-field>
				</mat-expansion-panel>

				<mat-expansion-panel class="mat-elevation-z0 expandable-card">
					<mat-expansion-panel-header>
						<mat-panel-title>
							{{ "customerPolicy.idNumber" | translate }}
						</mat-panel-title>
					</mat-expansion-panel-header>
					<mat-form-field appearance="outline" class="input-field">
						<input matInput formControlName="idNumber" />
					</mat-form-field>
				</mat-expansion-panel>

				<mat-expansion-panel class="mat-elevation-z0 expandable-card">
					<mat-expansion-panel-header>
						<mat-panel-title> {{ "common.dob" | translate }} </mat-panel-title>
					</mat-expansion-panel-header>
					<mat-form-field appearance="outline" class="input-field">
						<mat-label>Choose a date</mat-label>
						<input matInput [matDatepicker]="picker" formControlName="dob" />
						<mat-datepicker-toggle
							matSuffix
							[for]="picker"
						></mat-datepicker-toggle>
						<mat-datepicker #picker></mat-datepicker>
					</mat-form-field>
				</mat-expansion-panel>

				<mat-expansion-panel class="mat-elevation-z0 expandable-card">
					<mat-expansion-panel-header>
						<mat-panel-title>
							{{ "common.gender" | translate }}
						</mat-panel-title>
					</mat-expansion-panel-header>
					<div fxFlex>
						<button
							class="mat-flat-button mat-secondary gender-btn mr-sm"
							[ngClass]="{
								muted: formGroup.controls.gender.value === 'female'
							}"
							(click)="handleGenderBtn('male')"
						>
							{{ "common.M" | translate }}
						</button>
						<button
							class="mat-flat-button mat-secondary gender-btn mr-sm"
							[ngClass]="{ muted: formGroup.controls.gender.value === 'male' }"
							(click)="handleGenderBtn('female')"
						>
							{{ "common.F" | translate }}
						</button>
					</div>
				</mat-expansion-panel>

				<mat-expansion-panel class="mat-elevation-z0 expandable-card">
					<mat-expansion-panel-header>
						<mat-panel-title>
							{{ "common.maritalStatus" | translate }}
						</mat-panel-title>
					</mat-expansion-panel-header>
					<div fxFlex>
						<button
							class="mat-flat-button mat-secondary gender-btn mr-sm"
							[ngClass]="{
								muted: formGroup.controls.maritalStatus.value === 'single'
							}"
							(click)="handleMaritalStatus('single')"
						>
							{{ "common.Single" | translate }}
						</button>
						<button
							class="mat-flat-button mat-secondary gender-btn mr-sm"
							[ngClass]="{
								muted: formGroup.controls.maritalStatus.value === 'married'
							}"
							(click)="handleMaritalStatus('married')"
						>
							{{ "common.Married" | translate }}
						</button>
					</div>
				</mat-expansion-panel>

				<mat-expansion-panel class="mat-elevation-z0 expandable-card">
					<mat-expansion-panel-header>
						<mat-panel-title>
							{{ "common.residentialAddress" | translate }}
						</mat-panel-title>
					</mat-expansion-panel-header>
					<div class="section-container">
						<div
							class="form-field input-field"
							[formGroup]="residentialAddress"
						>
							<mat-form-field appearance="outline">
								<mat-label>{{ "common.addressLineOne" | translate }}</mat-label>
								<input matInput formControlName="address1" />
							</mat-form-field>
							<mat-form-field appearance="outline">
								<mat-label>{{ "common.addressLineTwo" | translate }}</mat-label>
								<input matInput formControlName="address2" />
							</mat-form-field>
							<mat-form-field appearance="outline">
								<mat-label>{{ "common.city" | translate }}</mat-label>
								<input matInput formControlName="city" />
							</mat-form-field>
							<mat-form-field appearance="outline" class="left">
								<mat-label>{{ "common.postcode" | translate }}</mat-label>
								<input matInput formControlName="postCode" />
							</mat-form-field>
							<mat-form-field appearance="outline" class="right">
								<mat-label>{{ "common.state" | translate }}</mat-label>
								<mat-select formControlName="state">
									<mat-option *ngFor="let item of stateList" [value]="item">{{
										item
									}}</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>
				</mat-expansion-panel>

				<mat-expansion-panel class="mat-elevation-z0 expandable-card">
					<mat-expansion-panel-header>
						<mat-panel-title>
							{{ "common.mobileNo" | translate }}
						</mat-panel-title>
					</mat-expansion-panel-header>
					<mat-form-field appearance="outline" class="input-field">
						<mat-label>{{ "common.mobileNo" | translate }}</mat-label>
						<ngx-mat-intl-tel-input
							[enableSearch]="true"
							[preferredCountries]="['my']"
							formControlName="mobileNo"
						>
						</ngx-mat-intl-tel-input>
						<mat-error
							>{{ "common.mobileNo" | translate }}
							{{
								formGroup.controls.mobileNo.errors | inputError | translate
							}}</mat-error
						>
					</mat-form-field>
				</mat-expansion-panel>
			</mat-accordion>

			<p class="secondary-title mt-lg">
				{{ "customerPolicy.vehicleDetails" | translate }}
			</p>
			<mat-accordion>
				<mat-expansion-panel class="mat-elevation-z0 expandable-card">
					<mat-expansion-panel-header>
						<mat-panel-title>
							{{ "common.carPlateNo" | translate }}
						</mat-panel-title>
					</mat-expansion-panel-header>
					<mat-form-field appearance="outline" class="input-field">
						<input matInput formControlName="carPlateNo" />
					</mat-form-field>
				</mat-expansion-panel>
				<mat-expansion-panel class="mat-elevation-z0 expandable-card">
					<mat-expansion-panel-header>
						<mat-panel-title>
							{{ "customerPolicy.vehicleModel" | translate }}
						</mat-panel-title>
					</mat-expansion-panel-header>
					<mat-form-field appearance="outline" class="input-field">
						<input matInput formControlName="vehicleModel" />
					</mat-form-field>
				</mat-expansion-panel>
			</mat-accordion>

			<p class="secondary-title mt-lg">
				{{ "customerPolicy.protectionPlan" | translate }}
			</p>
			<mat-accordion>
				<mat-expansion-panel class="mat-elevation-z0 expandable-card">
					<mat-expansion-panel-header>
						<mat-panel-title>
							{{ "customerPolicy.vehicleInsuranceType" | translate }}
						</mat-panel-title>
					</mat-expansion-panel-header>
					<mat-form-field appearance="outline" class="input-field">
						<mat-select
							disableOptionCentering
							formControlName="typeOfVehicleInsurance"
						>
							<mat-option
								*ngFor="let item of typeOfInsuranceList"
								[value]="item.id"
							>
								{{ item.name | translate }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</mat-expansion-panel>
				<mat-expansion-panel class="mat-elevation-z0 expandable-card">
					<mat-expansion-panel-header>
						<mat-panel-title>
							{{ "common.sumInsured" | translate }}
						</mat-panel-title>
					</mat-expansion-panel-header>
					<div>
						<div fxFlex>
							<button
								class="mat-flat-button mat-secondary gender-btn mr-sm"
								[ngClass]="{
									muted:
										formGroup.controls.sumInsured.value.type === 'Agreed Value'
								}"
								(click)="handleMvAv('Market Value')"
							>
								{{ "common.MV" | translate }}
							</button>
							<button
								class="mat-flat-button mat-secondary gender-btn mr-sm"
								[ngClass]="{
									muted:
										formGroup.controls.sumInsured.value.type === 'Market Value'
								}"
								(click)="handleMvAv('Agreed Value')"
							>
								{{ "common.AV" | translate }}
							</button>
						</div>
					</div>
					<div class="addon-card mt-md ml-md slider" [formGroup]="sumInsured">
						<mat-slider
							[displayWith]="addonLabel"
							thumbLabel
							formControlName="value"
							[min]="sumInsuredValue.min"
							step="500"
							[max]="sumInsuredValue.max"
						></mat-slider>
						<div fxLayout="row" class="slider-value">
							<div fxFlex>{{ sumInsuredValue.min }}</div>
							<div>{{ sumInsuredValue.max }}</div>
						</div>
					</div>
					<div class="estimate-price mt-md">
						<div class="label-desc">
							{{ "common.estimatedMarketVal" | translate }} :
						</div>
						<div class="label-value">
							RM {{ sumInsuredValue.min }} – RM {{ sumInsuredValue.max }}
						</div>
					</div>
				</mat-expansion-panel>

				<mat-expansion-panel class="mat-elevation-z0 expandable-card">
					<mat-expansion-panel-header>
						<mat-panel-title>
							{{ "customerPolicy.insurancePeriod" | translate }}
						</mat-panel-title>
					</mat-expansion-panel-header>
					<mat-form-field appearance="outline" class="input-field">
						<mat-label> {{ "customerPolicy.years" | translate }} </mat-label>
						<input matInput formControlName="insurancePeriod" />
					</mat-form-field>
				</mat-expansion-panel>
				<mat-expansion-panel class="mat-elevation-z0 expandable-card">
					<mat-expansion-panel-header>
						<mat-panel-title>
							{{ "addOn.addOns" | translate }}
						</mat-panel-title>
					</mat-expansion-panel-header>
					<!-- Road Tax Add Ons -->
					<mat-card class="addon-card cover-note">
						<mat-checkbox formControlName="isIncludeRoadtax" #roadtax>
							<div fxLayout="row">
								<div fxFlex class="title">
									{{ "addOn.roadTax" | translate }}
								</div>
								<div class="value">
									<span>RM</span>
									90
									<!-- REQUIRED DATA -->
								</div>
								<mat-icon
									class="tooltip-icon"
									[matMenuTriggerFor]="roadTaxTooltip"
									#roadTaxTrigger="matMenuTrigger"
									(mouseenter)="roadTaxTrigger.openMenu()"
								>
									info
								</mat-icon>
								<mat-menu
									#roadTaxTooltip="matMenu"
									[overlapTrigger]="false"
									class="tooltip-popover"
								>
									<span (mouseleave)="roadTaxTrigger.closeMenu()">
										{{ "addOn.roadTaxTooltip" | translate }}
									</span>
								</mat-menu>
							</div>
						</mat-checkbox>
						<ng-container *ngIf="roadtax.checked">
							<div fxLayout="row">
								<mat-checkbox
									fxFlex
									color="warn"
									formControlName="isNotBlacklisted"
								>
									{{ "addOn.isNotBlacklisted" | translate }}
								</mat-checkbox>
								<div class="desc sub-right">
									{{ "addOn.deliveryCharge" | translate }} <br />
									<b>
										RM 15.00
										<!-- REQUIRED DATA {{ requestData.roadTaxDeliveryPrice | number: "1.2-2" }} -->
									</b>
								</div>
							</div>
						</ng-container>
					</mat-card>

					<!-- Strike Riot Add Ons -->
					<mat-card class="addon-card cover-note">
						<mat-checkbox #strikeRiot>
							<div fxLayout="row">
								<div class="title" fxFlex>
									{{ "addOn.SRCC" | translate }}
								</div>
								<div class="value">
									<span>RM</span> 24.00
									<!-- REQUIRE DATA {{ getPrice(addOn) | number: "1.2-2" }} -->
								</div>
								<mat-icon
									class="tooltip-icon"
									[matMenuTriggerFor]="SRCCTooltip"
									#SRCCTrigger="matMenuTrigger"
									(mouseenter)="SRCCTrigger.openMenu()"
								>
									info
								</mat-icon>
								<mat-menu
									#SRCCTooltip="matMenu"
									[overlapTrigger]="false"
									class="tooltip-popover"
								>
									<span (mouseleave)="SRCCTrigger.closeMenu()">
										{{ "addOn.SRCCTooltip" | translate }}
									</span>
								</mat-menu>
							</div>
							<div class="coverage-desc" *ngIf="strikeRiot.checked">
								{{ "addOn.coverage" | translate }}:
								<ul>
									<li>{{ "addOn.SRCCDesc" | translate }}</li>
								</ul>
							</div>
						</mat-checkbox>
					</mat-card>

					<!-- Windshield Add Ons -->
					<mat-card class="addon-card cover-note">
						<mat-checkbox #windshield>
							<div fxLayout="row">
								<div fxFlex class="title">
									{{ "addOn.Windshield" | translate }}
								</div>
								<div class="value">
									<span>RM</span>
									500
									<!-- {{
                  getPrice(addOn, addOnFrmGroup.value.windshield)
                    | number: "1.2-2"
                }} -->
								</div>
								<mat-icon
									class="tooltip-icon"
									[matMenuTriggerFor]="windshieldTooltip"
									#windshieldTrigger="matMenuTrigger"
									(mouseenter)="windshieldTrigger.openMenu()"
								>
									info</mat-icon
								>
								<mat-menu
									#windshieldTooltip="matMenu"
									[overlapTrigger]="false"
									class="tooltip-popover"
								>
									<span (mouseleave)="windshieldTrigger.closeMenu()">
										{{ "addOn.WindshieldTooltip" | translate }}
									</span>
								</mat-menu>
							</div>
						</mat-checkbox>

						<ng-container *ngIf="windshield.checked">
							<div class="slider-desc">
								{{ "addOn.coverage" | translate }} :
							</div>
							<mat-slider
								[displayWith]="addonLabel"
								thumbLabel
								formControlName="windshield"
								[min]="35000"
								step="500"
								[max]="50000"
							></mat-slider>
							<div fxLayout="row" class="slider-value">
								<div fxFlex>RM 35000</div>
								<div>RM 50000</div>
							</div>
						</ng-container>
					</mat-card>

					<!-- Flood Coverage Add Ons -->
					<mat-card class="addon-card cover-note">
						<!-- <ng-container *ngIf="addOnList.length == 1">
          <ng-template
            #flood
            [ngTemplateOutlet]="flood"
            >
            <mat-checkbox
              >
              <div fxLayout="row">
                <div fxFlex class="title">
                  {{ "addOn.Flood" + | translate }}
                </div>
                <div class="value">
                  <span>RM</span>
                  {{ getPrice(addOn) | number: "1.2-2" }}
                </div>
                <mat-icon
                  class="tooltip-icon"
                  [matMenuTriggerFor]="FloodTooltip"
                  #FloodTrigger="matMenuTrigger"
                  (mouseenter)="FloodTrigger.openMenu()"
                >
                  info</mat-icon
                >
                <mat-menu
                  #FloodTooltip="matMenu"
                  [overlapTrigger]="false"
                  class="tooltip-popover"
                >
                  <span (mouseleave)="FloodTrigger.closeMenu()">
                    {{ "addOn.FloodTooltip" | translate }}
                  </span>
                </mat-menu>
              </div>

              <div class="coverage-desc" *ngIf="isAddOnSelected(addOn)">
                {{ "addOn.coverage" | translate }}:
                <ul>
                  <li>{{ "addOn.fullFloodDesc" | translate }}</li>
                </ul>
              </div>
            </mat-checkbox>
          </ng-template>
        </ng-container> -->

						<ng-container>
							<mat-checkbox #flood>
								<div fxLayout="row">
									<div fxFlex class="title">
										{{ "addOn.Flood" | translate }}
									</div>
									<mat-icon
										class="tooltip-icon"
										[matMenuTriggerFor]="FloodTooltip"
										#FloodTrigger="matMenuTrigger"
										(mouseenter)="FloodTrigger.openMenu()"
									>
										info
									</mat-icon>
									<mat-menu
										#FloodTooltip="matMenu"
										[overlapTrigger]="false"
										class="tooltip-popover"
									>
										<span (mouseleave)="FloodTrigger.closeMenu()">
											{{ "addOn.FloodTooltip" | translate }}
										</span>
									</mat-menu>
								</div>
							</mat-checkbox>

							<mat-radio-group *ngIf="flood.checked">
								<mat-radio-button>
									<div fxLayout="row">
										<div class="title" fxFlex>
											{{ "addOn.FullFloodPeril" | translate }}
										</div>
										<div class="value">
											<span>RM</span>
											<!-- {{ getPrice(item) | number: "1.2-2" }} -->
											5.00
										</div>
									</div>
									<div class="coverage-desc">
										{{ "addOn.coverage" | translate }}:
										<ul>
											<li>
												{{ "addOn.fullFloodDesc" | translate }}
											</li>
											<!-- <li>
                    {{ "addOn.limitedFloodDesc" | translate }}
                  </li> -->
										</ul>
									</div>
								</mat-radio-button>
							</mat-radio-group>
						</ng-container>
					</mat-card>
					<!-- END Flood Coverage Add Ons -->

					<!-- LLTP Add Ons -->
					<mat-card class="addon-card cover-note">
						<mat-checkbox #lltp>
							<div fxLayout="row">
								<div class="title" fxFlex>
									{{ "addOn.LLTP" | translate }}
								</div>
								<div class="value">
									<span>RM</span>
									<!-- {{ getPrice(addOn) | number: "1.2-2" }} -->30
								</div>
								<mat-icon
									class="tooltip-icon"
									[matMenuTriggerFor]="LLTPTooltip"
									#LLTPTrigger="matMenuTrigger"
									(mouseenter)="LLTPTrigger.openMenu()"
								>
									info
								</mat-icon>
								<mat-menu
									#LLTPTooltip="matMenu"
									[overlapTrigger]="false"
									class="tooltip-popover"
								>
									<span (mouseleave)="LLTPTrigger.closeMenu()">
										{{ "addOn.LLTPTooltip" | translate }}
									</span>
								</mat-menu>
							</div>
							<div class="coverage-desc" *ngIf="lltp.checked">
								{{ "addOn.coverage" | translate }}:
								<ul>
									<li>{{ "addOn.LLDesc1" | translate }}</li>
									<li>{{ "addOn.LLDesc2" | translate }}</li>
								</ul>
							</div>
						</mat-checkbox>
					</mat-card>
					<!-- END LLTP Add Ons -->

					<!-- LLOP Add Ons -->
					<mat-card class="addon-card cover-note">
						<mat-checkbox #llop>
							<div fxLayout="row wrap">
								<div class="title" fxFlex="70">
									{{ "addOn.LLOPNA" | translate }}
								</div>
								<span fxFlex></span>
								<div class="value">
									<span>RM</span>
									<!-- {{ getPrice(addOn) | number: "1.2-2" }} -->20
								</div>
								<mat-icon
									class="tooltip-icon"
									[matMenuTriggerFor]="LLOPNATooltip"
									#LLOPNATrigger="matMenuTrigger"
									(mouseenter)="LLOPNATrigger.openMenu()"
								>
									info</mat-icon
								>
								<mat-menu
									#LLOPNATooltip="matMenu"
									[overlapTrigger]="false"
									class="tooltip-popover"
								>
									<span (mouseleave)="LLOPNATrigger.closeMenu()">
										{{ "addOn.LLOPNATooltip" | translate }}
									</span>
								</mat-menu>
							</div>
							<div class="coverage-desc" *ngIf="llop.checked">
								{{ "addOn.coverage" | translate }}:
								<ul>
									<li>{{ "addOn.LLDesc1" | translate }}</li>
									<li>{{ "addOn.LLDesc2" | translate }}</li>
								</ul>
							</div>
						</mat-checkbox>
					</mat-card>
					<!-- END LLOP Add Ons -->
				</mat-expansion-panel>

				<!-- Additional Driver Add Ons -->
				<mat-expansion-panel class="mat-elevation-z0 expandable-card">
					<mat-expansion-panel-header>
						<mat-panel-title>
							{{ "addOn.AutomaticallyCovered" | translate }}
						</mat-panel-title>
					</mat-expansion-panel-header>

					<mat-card class="addon-card borderless">
						<ng-container>
							<div>
								<br />
								<ng-container>
									<div fxLayout="row" fxLayoutAlign=" center">
										<div class="driver-count">
											{{ "addOn.additionalDriver" | translate }} #1
										</div>
										<div class="driver-divider" fxFlex></div>
										<button class="driver-delete" mat-icon-button type="button">
											<mat-icon>delete_outline</mat-icon>
										</button>
									</div>

									<br />

									<div class="form-field">
										<mat-form-field floatLabel="always" class="left">
											<mat-label>{{ "common.name" | translate }} : </mat-label>
											<input
												matInput
												autocomplete="off"
												formControlName="name"
											/>
											<!-- <mat-error>
                      {{ "common.name" | translate }}
                      {{
                        getFormArrayControlError(i, "name")
                          | inputError
                          | translate
                      }}
                    </mat-error> -->
										</mat-form-field>
										<mat-form-field floatLabel="always" class="right">
											<mat-label>{{ "common.icNo" | translate }} : </mat-label>
											<input matInput autocomplete="off" mask="000000000000" />
											<!-- (input)="additionalDriverIdentityNoChange(i)"
                      formControlName="identityNo" -->
											<!-- <mat-error>
                      {{ "common.icNo" | translate }}
                      {{
                        getFormArrayControlError(i, "identityNo")
                          | inputError
                          | translate
                      }}
                    </mat-error> -->
										</mat-form-field>

										<mat-form-field floatLabel="always" class="left">
											<mat-label>{{ "common.dob" | translate }} : </mat-label>
											<input matInput [matDatepicker]="picker" readonly />
											<!-- formControlName="dob" -->
											<mat-datepicker #picker></mat-datepicker>
											<!-- <mat-error>
                      {{ "common.dob" | translate }}
                      {{
                        getFormArrayControlError(i, "dob")
                          | inputError
                          | translate
                      }}
                    </mat-error> -->
										</mat-form-field>

										<section class="right additional-radio-btn">
											<mat-label
												>{{ "common.gender" | translate }} :
											</mat-label>
											<mat-radio-group aria-label="Select an option">
												<!-- formControlName="gender" -->
												<mat-radio-button value="M">
													{{ "common.M" | translate }}
												</mat-radio-button>
												<mat-radio-button value="F">
													{{ "common.F" | translate }}
												</mat-radio-button>
											</mat-radio-group>
											<!-- <mat-error>
                      {{ "common.gender" | translate }}
                      {{
                        getFormArrayControlError(i, "gender")
                          | inputError
                          | translate
                      }}
                    </mat-error> -->
										</section>
										<section class="additional-radio-btn full">
											<mat-label
												>{{ "common.maritalStatus" | translate }} :
											</mat-label>
											<mat-radio-group aria-label="Select an option">
												<!-- formControlName="maritalStatus" -->
												<mat-radio-button value="Single">
													{{ "common.Single" | translate }}
												</mat-radio-button>
												<mat-radio-button value="Married">
													{{ "common.Married" | translate }}
												</mat-radio-button>
											</mat-radio-group>
										</section>

										<div>
											<div class="f-w-500">
												{{ "common.relationship" | translate }} :
											</div>

											<mat-form-field appearance="outline" floatLabel="never">
												<mat-select placeholder="Please select">
													<!-- formControlName="relationship" -->
													<mat-option *ngFor="let item of relationshipList">
														{{ item.name | translate }}
													</mat-option>
												</mat-select>
												<!-- <mat-error>
                        {{ "common.relationship" | translate }}
                        {{
                          getFormArrayControlError(i, "relationship")
                            | inputError
                            | translate
                        }}
                      </mat-error> -->
											</mat-form-field>
										</div>
									</div>
								</ng-container>
							</div>
							<button type="button" mat-stroked-button class="add-driver-btn">
								<mat-icon>add_circle</mat-icon>
								{{ "addOn.addDriver" | translate }}
							</button>
						</ng-container>
					</mat-card>
				</mat-expansion-panel>
				<!-- END Additional Drivers Add Ons -->
			</mat-accordion>

			<p class="secondary-title mt-lg">
				{{ "customerPolicy.others" | translate }}
			</p>
			<mat-accordion>
				<mat-expansion-panel class="mat-elevation-z0 expandable-card">
					<mat-expansion-panel-header>
						<mat-panel-title>
							{{ "customerPolicy.withdrawNCD" | translate }}
						</mat-panel-title>
					</mat-expansion-panel-header>
					<mat-form-field appearance="outline" class="input-field">
						<mat-label>{{ "customerPolicy.reasons" | translate }} </mat-label>
						<input
							matInput
							formControlName="withdrawNcd"
							placeholder="Please describe your reason"
						/>
					</mat-form-field>
				</mat-expansion-panel>
				<mat-expansion-panel class="mat-elevation-z0 expandable-card">
					<mat-expansion-panel-header>
						<mat-panel-title>
							{{ "customerPolicy.hirePurchaseDetails" | translate }}
						</mat-panel-title>
					</mat-expansion-panel-header>
					<mat-form-field appearance="outline" class="input-field">
						<mat-label
							>{{ "customerPolicy.description" | translate }}
						</mat-label>
						<input
							matInput
							formControlName="hirePurchaseDetails"
							placeholder="Please provide a description"
						/>
					</mat-form-field>
				</mat-expansion-panel>
				<mat-expansion-panel class="mat-elevation-z0 expandable-card">
					<mat-expansion-panel-header>
						<mat-panel-title>
							{{ "customerPolicy.cancelInsurance" | translate }}
						</mat-panel-title>
					</mat-expansion-panel-header>
					<mat-form-field appearance="outline" class="input-field">
						<mat-select
							[placeholder]="'customerPolicy.provideReasons' | translate"
							formControlName="cancelInsuranceReasons"
						>
							<mat-option
								*ngFor="let item of cancelInsuranceReasons"
								[value]="item"
								>{{ item }}</mat-option
							>
						</mat-select>
					</mat-form-field>
				</mat-expansion-panel>
			</mat-accordion>

			<div class="upload-container mt-md">
				<div class="title">
					{{ "customerPolicy.provideDocuments" | translate }}
				</div>
				<mat-card class="upload-card">
					<div fxLayout="row">
						<div>
							<img src="assets/image/photo-id.png" alt="" />
						</div>
						<div>
							<div class="title">
								{{ "customerPolicy.uploadId" | translate }}
							</div>
							<div class="desc">
								{{ "customerPolicy.uploadIdDesc" | translate }}
							</div>

							<button mat-stroked-button>
								<mat-icon>add_circle</mat-icon>{{ "common.upload" | translate }}
							</button>
						</div>
					</div>
				</mat-card>

				<mat-card class="upload-card">
					<div fxLayout="row">
						<div>
							<img src="assets/image/grant.png" alt="" />
						</div>
						<div>
							<div class="title">
								{{ "customerPolicy.vehicleRegis" | translate }}
							</div>
							<div class="desc">
								{{ "customerPolicy.vehicleRegisDesc" | translate }}
							</div>

							<button mat-stroked-button>
								<mat-icon>add_circle</mat-icon>{{ "common.upload" | translate }}
							</button>
						</div>
					</div>
				</mat-card>

				<mat-card class="upload-card">
					<div fxLayout="row">
						<div>
							<img src="assets/image/car-icon.png" alt="" />
						</div>
						<div>
							<div class="title">
								{{ "customerPolicy.carPhotos" | translate }}
							</div>
							<div class="desc">
								{{ "customerPolicy.carPhotosDesc" | translate }}
							</div>

							<div class="uploaded-list">
								<div class="desc" fxFlex>
									filename.png
									<div class="upload-progress">
										<mat-slider aria-label="unit(s)" [value]="50"></mat-slider
										><span>99%</span>
									</div>
								</div>
								<button mat-icon-button class="stop-upload-btn">
									<mat-icon> cancel </mat-icon>
								</button>
							</div>

							<div class="uploaded-list">
								<mat-icon class="uploaded-icon"> check_circle </mat-icon>
								<div class="desc" fxFlex>filename.png</div>
								<button mat-icon-button>
									<mat-icon> delete </mat-icon>
								</button>
							</div>

							<button mat-stroked-button>
								<mat-icon>add_circle</mat-icon>{{ "common.upload" | translate }}
							</button>
						</div>
					</div>
				</mat-card>
			</div>
			<div class="mt-md mb-lg">
				<button
					class="full-btn third"
					type="submit"
					[disabled]="!formGroup.valid || !formGroup.touched"
					mat-flat-button
				>
					{{ "common.submit" | translate }}
				</button>
			</div>
			<div class="sidefix-menu" style="right: 16px">
				<button mat-raised-button (click)="faqDialog()">
					<img src="assets/image/faq.png" alt="" />
					{{ "floatingMenu.faq" | translate }}
				</button>
				<button mat-raised-button (click)="guideDialog()">
					<img src="assets/image/guides.png" alt="" />
					{{ "floatingMenu.guide" | translate }}
				</button>
				<button mat-raised-button [matMenuTriggerFor]="language">
					<img src="assets/image/language.png" alt="" />
					{{ "language." + translate.currentLang | translate }}
				</button>
				<mat-menu #language="matMenu">
					<button mat-menu-item (click)="switch('en')">
						<span>{{ "language.en" | translate }}</span>
					</button>
					<button mat-menu-item (click)="switch('bm')">
						<span>{{ "language.bm" | translate }}</span>
					</button>
				</mat-menu>
			</div>
		</div>
	</form>
</div>
