<div>
	<div class="success-graphic">
		<img src="assets/image/submit-success-dialog.png" alt="" />
		<div>
			{{ "success.thankYou" | translate }}
		</div>
	</div>
	<div class="success-content">
		<!-- <ng-container *ngIf="!editQuotations">
			<div>
				{{ data?.content | translate }}
				<span class="bold">{{ data?.boldContent }}</span>
				<span> {{ "dashboard.preferredChannelDuration" | translate }}</span>
			</div>
		</ng-container> -->
		<!-- <ng-container *ngIf="editQuotations"> -->

			<!-- <span *ngIf="onOperation">
				{{
					"success.quotationsProcessed"
						| translate : { responseTime: responseTime }
				}}
				{{ "success.emailUs1" | translate }}
            	<a href="mailto:customerservice@policystreet.com">customerservice@policystreet.com</a>
				{{ "success.emailUs2" | translate }}
				{{ "success.dropEmail" | translate }}
				<a href="mailto:info@policystreet.com">info@policystreet.com</a>.
			</span> -->

				{{ "success.selected1" | translate }} 
				<span [style.color]="'#49b0ff'"><b>{{ "common.AV" | translate }}</b></span>
				{{ "success.selected2" | translate }} 
				<span [style.color]="'#49b0ff'"><b>{{ data?.boldContent }}</b></span>

				<span *ngIf="onOperation">
					{{ "success.selectedOpenHours" | translate }}
				</span>

				<span *ngIf="!onOperation">
				{{ "success.selectedCloseHours" | translate }}
				</span>
		<!-- </ng-container> -->
		<button mat-flat-button mat-primary mat-dialog-close>
			{{ "success.close" | translate }}
		</button>
	</div>
</div>
