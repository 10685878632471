import { HostListener, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScreenSizeService {
  public  isMobileSubject = new BehaviorSubject<boolean>(false);

  isMobile$ = this.isMobileSubject.asObservable();

  constructor() {
    this.checkIsMobile(); // Initial check
  }

  private checkIsMobile(): void {
    this.isMobileSubject.next(window.innerWidth < 960); // Adjust the threshold as needed
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.checkIsMobile();
  }
  notifyResize(): void {
    this.checkIsMobile();
  }
}