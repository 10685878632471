<div class="page">
	<div class="page-content">
		<div class="card1 card-background">
			<h1 class="card1__header">
				{{ "endorsement.whatWouldYouLIke" | translate }}
			</h1>
			<div class="card1__subheading">
				<h3 *ngIf="false">
					{{ "endorsement.enterDetails" | translate }}
				</h3>
			</div>
			<div [formGroup]="card1Group" class="card1__forms forms">
				<mat-form-field appearance="outline" class="upperc-input">
					<mat-label class="label">{{
						"common.carPlateNo" | translate
					}}</mat-label>
					<input
						matInput
						required
						formControlName="vehicleNo"
						mask="A{255}"
						[validation]="false"
						autocomplete="off"
					/>
				</mat-form-field>
				<ng-container>
					<div class="forms__identity mt-xs">
						<div class="forms__identity__dropdown">
							<mat-form-field appearance="outline">
								<mat-select formControlName="identityType">
									<mat-option [value]="idTypeOption.malaysian">{{
										"common.Malaysian" | translate
									}}</mat-option>
									<mat-option [value]="idTypeOption.company">{{
										"common.Company" | translate
									}}</mat-option>
									<mat-option [value]="idTypeOption.foreigner">{{
										"common.Foreigner" | translate
									}}</mat-option>
									<mat-option [value]="idTypeOption.police">{{
										"common.Police/Army" | translate
									}}</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div class="forms__identity__input">
							<ng-container [ngSwitch]="idType">
								<ng-container *ngSwitchCase="idTypeOption.malaysian">
									<mat-form-field appearance="outline">
										<mat-label class="label">
											{{ "common.icNoLabel" | translate }}
										</mat-label>
										<input
											matInput
											required
											formControlName="identityNo"
											[mask]="getIdentityNoMasking()"
											autocomplete="off"
											[maxLength]="12"
										/>
									</mat-form-field>
								</ng-container>
								<ng-container *ngSwitchCase="idTypeOption.company">
									<mat-form-field appearance="outline">
										<mat-label class="label">{{
											"common.businessRegistrationNo" | translate
										}}</mat-label>
										<input
											matInput
											required
											formControlName="identityNo"
											autocomplete="off"
										/>
									</mat-form-field>
								</ng-container>
								<ng-container *ngSwitchCase="idTypeOption.foreigner">
									<mat-form-field appearance="outline">
										<mat-label class="label">{{
											"common.passportLabel" | translate
										}}</mat-label>
										<input
											matInput
											required
											formControlName="identityNo"
											autocomplete="off"
										/>
									</mat-form-field>
								</ng-container>
								<ng-container *ngSwitchCase="idTypeOption.police">
									<mat-form-field appearance="outline">
										<mat-label class="label">
											{{ "common.icNoLabel" | translate }}
										</mat-label>
										<input
											matInput
											required
											formControlName="identityNo"
											[mask]="getIdentityNoMasking()"
											autocomplete="off"
											[maxLength]="12"
										/>
									</mat-form-field>
								</ng-container>
							</ng-container>
						</div>
					</div>
				</ng-container>
				<button
					*ngIf="false"
					class="forms__next-btn btn--blue"
					mat-flat-button
					[disabled]="false"
				>
					{{ "common.next" | translate }}
				</button>
			</div>
		</div>
		<div class="card2 card-background">
			<div class="card2__header">
				<h2 class="header-font">
					{{ "endorsement.policyHolder" | translate }}
				</h2>
			</div>
			<div [formGroup]="card2Card3Group" class="card2__forms forms">
				<mat-form-field appearance="outline">
					<mat-label class="label">{{
						"common.namePerIc" | translate
					}}</mat-label>
					<input matInput autocomplete="name" formControlName="name" />
				</mat-form-field>
				<div class="forms__email-mobile form-flex mt-xs">
					<mat-form-field appearance="outline" class="">
						<mat-label class="label">{{
							"common.emailAddress" | translate
						}}</mat-label>
						<input matInput formControlName="email" autocomplete="email" />
						<mat-error
							style="display: block !important"
							[hidden]="!card2Card3Group.controls.email?.errors?.email"
						>
							{{ "error.invalidEmail" | translate }}</mat-error
						>
						<mat-error
							>{{ "common.emailAddress" | translate }}
							{{
								card2Card3Group.controls.email.errors | inputError | translate
							}}</mat-error
						>
					</mat-form-field>
					<mat-form-field appearance="outline">
						<mat-label class="label">{{
							"common.mobileNo" | translate
						}}</mat-label>
						<input matInput formControlName="mobileNo" />
						<mat-error
							>{{ "common.mobileNo" | translate }}
							{{
								card2Card3Group.controls.mobileNo.errors
									| inputError
									| translate
							}}</mat-error
						>
					</mat-form-field>
				</div>
				<div class="forms__roadtax-endorsement form-flex mt-xs">
					<mat-form-field appearance="outline">
						<mat-label class="label">{{
							"endorsement.roadtaxStatus" | translate
						}}</mat-label>
						<mat-select
							formControlName="isRenewRoadTax"
						>
							<mat-option [value]="true">{{
								"endorsement.renewed" | translate
							}}</mat-option>
							<mat-option [value]="false">{{
								"endorsement.notRenewed" | translate
							}}</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-form-field appearance="outline">
						<mat-label class="label">{{
							"endorsement.endorsementType" | translate
						}}</mat-label>
						<mat-select
							formControlName="endorsementType"
							panelClass="endorsement-type-select"
						>
							<mat-option
								*ngFor="let endorsement of endorsementTypes"
								[value]="endorsement.id"
								>{{ endorsement.endorsementTypeName.toUpperCase() }}</mat-option
							>
						</mat-select>
					</mat-form-field>
				</div>
				<mat-form-field
					class="card2__forms__textarea-wrapper"
					appearance="outline"
					floatLabel="always"
				>
					<mat-label class="endorsement-type-label">
						{{
							selectedEndorsement?.endorsementTypeDescriptionLabel
								? selectedEndorsement?.endorsementTypeDescriptionLabel
								: "Note"
						}}
					</mat-label>
					<textarea
						class="card2__forms__textarea mt-sm"
						matInput
						required
						rows="8"
						formControlName="description"
					></textarea>
				</mat-form-field>
			</div>
		</div>
		<div class="card3 card-background" *ngIf="documentRequired">
			<div class="card3__header">
				<h2 class="header-font">
					{{ "endorsement.requiredDocument" | translate }}
					{{ selectedEndorsement.endorsementTypeName }}
				</h2>
			</div>

			<div class="card3__required-doc">
				<h3>{{ "endorsement.pleaseUpload" | translate }}</h3>
				<div class="card3__required-doc__list">
					<div
						*ngFor="
							let documentType of selectedEndorsement.documentType;
							let i = index
						"
					>
						{{ i + 1 }}.&nbsp;{{ documentType.endorsementDocumentTypeName }}
					</div>
				</div>
			</div>
			<div class="card3__upload" [formGroup]="card2Card3Group">
				<label class="card3__upload__label">
					<input
						type="file"
						(change)="uploadFiles($event)"
						(click)="onFileInputClick($event)"
						accept="image/jpeg,image/png,application/pdf,video/mp4,video/mpeg"
						multiple
						style="display: none"
						formArrayName="files"
					/>
					<div class="card3__upload__label__button">
						<img
							src="../../../../assets/image/endorsement-upload-button.svg"
							width="25px"
							alt=""
						/>
						<span>{{ "endorsement.uploadDocuments" | translate }}</span>
					</div>
				</label>
				<div
					class="card3__upload__box"
					(mouseenter)="mouseEnter()"
					(mouseleave)="mouseleave()"
				>
					<img
						class="carousel-nav"
						src="../../../../assets/image/endorsement-left-arrow.svg"
						width=""
						alt=""
						(click)="slidePrev()"
					/>
					<div
						class="preview"
						[ngStyle]="{ 'background-color': fileHovering ? 'grey' : '' }"
					>
						<swiper class="endorsement-swiper" #swiper [config]="swiperConfig">
							<ng-container
								*ngFor="let previewFile of previewFiles; let i = index"
							>
								<ng-template swiperSlide>
									<div class="preview-tile">
										<app-upload-carousel-preview-card
											[previewFile]="previewFile"
											(onDeletePreview)="onDeletePreview(i)"
											(onDownloadPreview)="onDownloadPreview(i)"
										></app-upload-carousel-preview-card>
									</div>
								</ng-template>
							</ng-container>
						</swiper>
						<div
							class="preview__upload-text"
							*ngIf="!(previewFiles.length > 0)"
						>
							{{ "endorsement.uploadFilesHere" | translate }}
						</div>
					</div>
					<img
						class="carousel-nav"
						src="../../../../assets/image/endorsement-right-arrow.svg"
						width=""
						alt=""
						(click)="slideNext()"
					/>
					<ngx-file-drop
						[ngClass]="{ hovered: mouseHovering }"
						class="file-drop-container"
						(onFileDrop)="handleFiles($event)"
						(onFileOver)="fileOver()"
						(onFileLeave)="fileLeave()"
					>
					</ngx-file-drop>
				</div>

				<div class="card3__upload__requirements">
					<h5>
						{{ "endorsement.supportedFormat" | translate }} JPG, JPEG, PDF, PNG,
						MP4, MPEG
					</h5>
					<h5>{{ "endorsement.maxFileSize" | translate }} 50MB</h5>
				</div>
			</div>
		</div>
		<button
			class="btn--blue"
			mat-flat-button
			[disabled]="!card2Card3Group.valid"
			(click)="submitEndorsement()"
		>
			{{ "common.submit" | translate }}
		</button>
	</div>
</div>
