import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
// Services
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-error-dialog",
	templateUrl: "./error-dialog.component.html",
	styleUrls: ["./error-dialog.component.scss"],
})
export class ErrorDialogComponent {
	contentType: string;

	constructor(
		public dialogRef: MatDialogRef<ErrorDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: { contentType: string },
		public translate: TranslateService,
	) {
		this.contentType = data.contentType;
	}

	closeDialog(): void {
		this.dialogRef.close();
	}
}
