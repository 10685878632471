<div class="main-container">
  <div class="loading-container" *ngIf="!finishedTimerState && !stopTimerState">
    <div class="loading-container__title">
      {{ firstWording | translate }} <span>{{ keyword | translate }}</span>
      {{ secondWording | translate }}
      <span
        >{{ timeLeftState }} {{ "plans.loadingPage.seconds" | translate }}</span
      >...
    </div>
    <div class="img-container">
      <img src="/assets/gif/Instant-Quotation-05-Looking.gif" alt="" />
    </div>
    <div class="loading-container__disclaimer">
      {{ "plans.loadingPage.disclaimerText" | translate }}
    </div>
  </div>
  <ng-container *ngIf="finishedTimerState">
    <div class="finished-state-container">
      <div class="finished-state-container__title">
        {{ "plans.loadingPage.lapseTitle" | translate }}
      </div>
      <div class="img-container">
        <img src="/assets/gif/Instant-Quotation-05_Unsuccessful.gif" alt="" />
      </div>
      <div class="finished-state-container__desc">
        {{ "plans.loadingPage.lapseDesc1" | translate }}
        <span>{{ this.inputData?.email || "-" }}</span>
        {{ "plans.loadingPage.lapseDesc2" | translate }}
      </div>
      <button
        mat-flat-button
        class="finished-state-container__back-btn"
        (click)="backToHome()"
      >
        {{ "plans.loadingPage.back" | translate }}
      </button>
    </div>
  </ng-container>
</div>
