<div class="loading-card">
  <div class="loading-card__header">
    <div class="loading-card__header-logo">
      <img [src]="insurer?.image" alt="" style="width: 100px" />
    </div>
    <div class="loading-card__header-text" *ngIf="insurer?.status == 'New'">
      <img src="/assets/icons/hourglass.png" alt="" />
      <div class="main">{{ "comparison.waitingInsurer" | translate }}</div>
      <div class="desc">{{ "comparison.waitingInsurerDesc" | translate }}</div>
    </div>
    <div class="loading-card__header-text" *ngIf="insurer?.status == 'Rejected'">
      <img src="/assets/icons/noprice.png" alt="" />
      <div class="main highlight">{{ "comparison.priceUnavail" | translate }}</div>
    </div>
  </div>
</div>
