import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {KtmbFooterComponent} from "./layouts/footer/ktmb-footer/ktmb-footer.component";
import { MaterialModule } from "./material-module";
import { DashboardModule } from "./dashboard/dashboard.module";
import { AppFullComponent } from "./layouts/full/full.component";
import { AppHeaderComponent } from "./layouts/header/header.component";
import {
	HttpClient,
	HttpClientModule,
	HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { MAT_DATE_LOCALE } from "@angular/material/core";

import { HttpRequestInterceptor } from "./share/service/http.service";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { NgxSpinnerModule } from "ngx-spinner";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { CustomPipeModule } from "./share/pipe/custom-pipe.module";
import { FooterComponent } from "./layouts/footer/footer.component";
import { NgxMaskModule } from "ngx-mask";
import { MenuComponent } from "./layouts/menu/menu.component";
import { AppBlankComponent } from "./layouts/Blank/blank.component";
import { GoogleTagManagerModule } from "angular-google-tag-manager";
import { MenuMobileComponent } from "./layouts/menu/menu-mobile/menu-mobile.component";
import { CustomLoader } from "./share/service/custom-loader.service";
import { PartnerService } from "./share/service/partner.service";
import { ActivatedRoute } from "@angular/router";
import { AffiliatesModule } from "./affiliates/affiliates.module";
import { SharedComponentModule } from "./share/component/shared-component.module";
import { LoadingOverlayComponent } from "./share/component/loading-overlay/loading-overlay.component";
// Directives
import { OpenImageViewerDirective } from "./share/directive/open-image-viewer.directive";

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, "./assets/i18n", ".json");
}

@NgModule({
	declarations: [
		AppComponent,
		AppFullComponent,
		AppBlankComponent,
		AppHeaderComponent,
		FooterComponent,
		MenuComponent,
		MenuMobileComponent,
    KtmbFooterComponent,
		// OpenImageViewerDirective
	],
	imports: [
		SharedComponentModule,
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		MaterialModule,
		FlexLayoutModule,
		DashboardModule,
		AffiliatesModule,
		HttpClientModule,
		NgxSpinnerModule,
		FormsModule,
		ReactiveFormsModule,
		GoogleTagManagerModule.forRoot({
			id: environment.googleTagId,
		}),
		TranslateModule.forRoot({
			defaultLanguage: "en",
			loader: {
				provide: TranslateLoader,
				useClass: CustomLoader,
				deps: [HttpClient, PartnerService],
			},
		}),
		ServiceWorkerModule.register("/ngsw-worker.js", {
			enabled: environment.production,
		}),
		CustomPipeModule,
		NgxMaskModule.forRoot(),
	],
	exports: [
		// OpenImageViewerDirective
	],
	providers: [
		{ provide: MAT_DATE_LOCALE, useValue: "en-MY" },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpRequestInterceptor,
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
