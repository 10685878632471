<div class="benefits" [class.benefits--first]="isFirst">
  <div class="benefits__list">
    <div class="benefits__item">
      <img alt="Car Icon" src="/assets/image/ktmb/{{ isFirst ? 'car-gold' : 'car'}}.svg">
      <div>{{ "benefits.additionalNamedDriver" | translate }}: <span
        class="benefits__highlight">{{ "benefits.inclusiveForAllDrivers" | translate }}</span></div>
    </div>
    <div class="benefits__item">
      <img alt="Towing Icon" src="/assets/image/ktmb/{{ isFirst ? 'towing-gold' : 'towing'}}.svg">
      <div *ngIf="isLangEn()"><span class="benefits__highlight">{{ "benefits.inclusiveUnlimited" | translate }}</span>
        {{ "benefits.towing" | translate }}
      </div>
      <div *ngIf="!isLangEn()">
        <span class="benefits__highlight">{{ "benefits.inclusive"| translate }}</span>
        {{ "benefits.towing" | translate }}
        <span class="benefits__highlight">{{ "benefits.unlimited" | translate }}</span>
      </div>
    </div>
    <div class="benefits__item">
      <img alt="Check Icon" src="/assets/image/ktmb/{{ isFirst ? 'check-gold' : 'check'}}.svg">
      <div><span
        class="benefits__highlight">{{ "benefits.inclusive" | translate }}</span> {{ "benefits.specialPerils" | translate }}
      </div>
    </div>
    <div class="benefits__item">
      <img alt="Check Icon" src="/assets/image/ktmb/{{ isFirst ? 'check-gold' : 'check'}}.svg">
      <div><span
        class="benefits__highlight">{{ "benefits.inclusive" | translate }}</span> {{ "benefits.warranty" | translate }}
      </div>
    </div>
  </div>
</div>
