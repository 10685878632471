import {Component, ElementRef, Input, OnDestroy, ViewChild} from '@angular/core';
import {ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {filter, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {AlertService} from "../../../share/service/alert.service";
import {
  UploadedImageDialogComponent,
  UploadedImageDialogData
} from "./uploaded-image-dialog/uploaded-image-dialog.component";

@Component({
  selector: 'app-upload-mileage',
  templateUrl: './upload-mileage.component.html',
  styleUrls: ['./upload-mileage.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: UploadMileageComponent,
      multi: true,
    },
  ]
})
export class UploadMileageComponent implements ControlValueAccessor, OnDestroy {
  @Input() summaryMode = false;
  @ViewChild('fileInput') fileInputRef: ElementRef;

  VALID_FILE_EXTENSIONS = ["jpg", "jpeg", "pdf", "png"];
  MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB

  fileControl = this.fb.control([] as File[]);
  reuploadIndex = -1;

  private destroy$ = new Subject<void>();

  constructor(
    private dialog: MatDialog,
    private alertService: AlertService,
    private fb: FormBuilder) {
  }

  onTouched = () => {
  };

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  writeValue(value: File[]): void {
    this.fileControl.patchValue(value);
  }

  registerOnChange(onChange: (files: File[]) => void): void {
    this.fileControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(onChange);
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.fileControl.disable() : this.fileControl.enable();
  }

  onFileChange(event: Event) {
    if (this.fileControl.value.length >= 2 && this.reuploadIndex === -1) {
      this.alertService.openSnackBar(`Total Files exceeded 2`);
      return;
    }

    const fileList = (event.target as HTMLInputElement).files;
    if (!fileList || fileList.length === 0) {
      this.reuploadIndex = -1;
      return;
    }

    let condition = this.reuploadIndex !== -1 ? 1 : 2;
    for (let i = 0; i < condition; i++) {
      if (fileList[i]) {
        this.validateEnteredFiles(fileList[i]);
      }
    }
  }

  removeImage(index: number): void {
    const updatedFiles = this.fileControl.value.filter((_, i: number) => i !== index);
    this.fileControl.patchValue(updatedFiles);
  }

  viewFile(index: number): void {
    const data: UploadedImageDialogData = {
      file: this.fileControl.value[index],
    }

    const dialogRef = this.dialog.open(UploadedImageDialogComponent, {data});
    dialogRef.afterClosed().pipe(filter((resp) => resp)).subscribe(() => {
      this.onReupload(index);
    });
  }

  onUpload() {
    this.fileInputRef.nativeElement.click()
  }

  isUploadDisabled(): boolean {
    return this.fileControl.value.length >= 2 || this.fileControl.disabled;
  }

  onReupload(index: number): void {
    this.reuploadIndex = index;
    this.onUpload();
  }

  private validateEnteredFiles(file: File): void {
    const ext = file.name.split(".").pop().toLowerCase();
    if (!this.VALID_FILE_EXTENSIONS.includes(ext)) {
      this.alertService.openSnackBar("Only support JPG, JPEG, PDF, PNG format");
      this.reuploadIndex = -1;
      return;
    }

    const currentTotalFileSize = this.fileControl.value.reduce((acc, file, index) => {
      return index === this.reuploadIndex ? acc : acc + file.size
    }, 0);
    if (currentTotalFileSize + file.size > this.MAX_FILE_SIZE) {
      this.alertService.openSnackBar("Total File Size exceeded 5MB");
      this.reuploadIndex = -1;
      return;
    }

    if (this.reuploadIndex === -1) {
      const updatedFiles = [...this.fileControl.value, file];
      this.fileControl.patchValue(updatedFiles);
    } else {
      const updatedFiles = this.fileControl.value.map((_, index) => index === this.reuploadIndex ? file : _);
      this.fileControl.patchValue(updatedFiles);
      this.reuploadIndex = -1;
    }
  }
}
