<div class="main-container">
	<div class="renew-container">
		<div class="img-section" *ngIf="!reSubmitted">
			<div class="img-section__error-img">
				<img src="..\assets\image\renew-quotation-misc-error.png" alt="" />
			</div>
			<div class="img-section__title">
				{{ "renewQuotation.oops1" | translate }}
				{{ renewPage.vehicleNo }}
				{{ "renewQuotation.oops2" | translate }}
			</div>
			<div class="img-section__body">
				{{ "renewQuotation.toRenew" | translate }}
			</div>
		</div>
		<div class="img-section" *ngIf="reSubmitted">
			<div class="img-section__error-img">
				<img src="..\assets\image\renew-quotation-submitted.png" alt="" />
			</div>
			<div class="img-section__title" style="max-width: unset">
				{{ "renewQuotation.reSubmitted" | translate }}
			</div>
		</div>
		<app-customer-details-card
			[renewPage]="renewPage"
			[reSubmitted]="reSubmitted"
			[idType]="idType"
			(editDetailsClicked)="editDetails()"
		>
		</app-customer-details-card>
		<div class="renew-btn-container">
			<button
				mat-button
				class="btn--yellow"
				(click)="submit()"
				[disabled]="reSubmitted"
			>
				<span *ngIf="!reSubmitted">{{
					"renewQuotation.renewQuotation" | translate
				}}</span>
				<span *ngIf="reSubmitted">{{
					"renewQuotation.reSubmitted" | translate
				}}</span>
			</button>
		</div>

		<div class="end-note-container">
			<div class="wish-to-submit" *ngIf="!reSubmitted">
				<span>{{ "renewQuotation.wishToSubmit" | translate }}</span>
				<a href="/">
					{{ "renewQuotation.clickHere" | translate }}
				</a>
			</div>
			<div class="submitted-message" *ngIf="reSubmitted">
				{{ "renewQuotation.reSubmittedEndNote1" | translate }}
				<span class="submitted-message__number">
					{{ "renewQuotation.reSubmittedEndNote2" | translate }}
				</span>
				{{ "renewQuotation.reSubmittedEndNote3" | translate }}
				<a
					class="submitted-message__email"
					(click)="
						copyToClipboardAndShowSnackbar('customerservice@policystreet.com')
					"
				>
					customerservice@policystreet.com
				</a>
			</div>
		</div>
	</div>
</div>
