<div class="title-head" [ngClass]="data?.cssClass ?? ''" fxLayout="column">
	<div class="mdTitle-f title-wrapper">
		<ng-container *ngIf="confirmChanges; else modelConfirmation">
			{{ "carVariant.changesReceived" | translate }}
		</ng-container>
		<ng-template #modelConfirmation>
			{{ "carVariant.carModelConfirmation" | translate }}
		</ng-template>

		<button mat-icon-button mat-dialog-close>
			<mat-icon>close</mat-icon>
		</button>
	</div>
</div>
<div class="content-wrapper">
	<div *ngIf="confirmChanges; else showChangesNotConfirmed">
		<div class="content-block">
			<div>
				<mat-icon class="icon-tick">check_circle_outline</mat-icon>
			</div>
			<div>
				{{ "carVariant.revisedQuoteWillBeSent" | translate }}
			</div>
		</div>
		<div class="model-block">
			{{ "carVariant.selectedCarModel" | translate }}
			<br />
			<b>{{ selectedCar.vehicle }}</b>
		</div>
		<div class="close-button-wrapper">
			<button
				mat-flat-button
				mat-dialog-close
				color="primary"
				class="full-width"
			>
				Close
			</button>
		</div>
	</div>
	<ng-template #showChangesNotConfirmed>
		<div>
			<div class="label-desc">
				{{ "carVariant.pleaseConfirmCarModelVariant" | translate }}
			</div>
			<div class="label-desc model-text-wrapper">
				{{ "carVariant.currentCarModel" | translate }}
			</div>

			<div class="label-value">
				{{ carData.vehicle }}
			</div>

			<div *ngIf="selectedCar" class="label-desc block2">
				<div>{{ "carVariant.selectedCarModel" | translate }}</div>
				<b>{{ selectedCar.vehicle }}</b>
			</div>

			<div *ngIf="carVars; else showButtons">
				<div class="dropdown-wrapper">
					<mat-form-field appearance="outline" class="full-width drop-form">
						<mat-label>{{ "carVariant.selectCarModel" | translate }}</mat-label>
						<mat-select
							#carSelect
							(selectionChange)="onCarSelectChange($event)"
						>
							<mat-option *ngFor="let carVar of carVars" [value]="carVar">
								{{ carVar.vehicle }}
							</mat-option>
						</mat-select>
					</mat-form-field>

					<!-- <div *ngIf="selectedCar" class="label-desc" style="margin-top: 10px">
						<div>Your selected car model</div>
						<b>{{ selectedCar.vehicle }}</b>
					</div> -->

					<div class="confirm-button-wrapper" *ngIf="selectedCar">
						<button
							mat-flat-button
							color="primary"
							class="full-width"
							(click)="saveChanges(selectedCar)"
						>
							{{ "carVariant.confirmChange" | translate }}
						</button>

					</div>
				</div>
			</div>
			<ng-template #showButtons>
				<div class="group-button-wrapper">
					<button
						mat-stroked-button
						color="accent"
						class="edit-button-wrapper"
						(click)="editCarVariant()"
						[disabled]="!editCarModel"
					>
						<b>{{ "carVariant.editCarModel" | translate }}</b>
					</button>
					<button
						mat-flat-button
						mat-dialog-close
						color="primary"
						class="confirm-button-wrapper"
						(click)="proceedQuotation()"
					>
						{{ "carVariant.correct&proceed" | translate }}
					</button>
				</div>
			</ng-template>
		</div>
	</ng-template>
</div>
