import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { OtherInsurerInterface } from "src/app/share/interface/motor.interface";

@Component({
  selector: "app-other-insurer-card",
  templateUrl: "./other-insurer-card.component.html",
  styleUrls: ["./other-insurer-card.component.scss"],
})
export class OtherInsurerCardComponent implements OnInit {
  selected: boolean = false;

  @Input() insurer: OtherInsurerInterface;
  @Input() selectedInsurers: string[] = [];
  @Output() clickEvent = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges() {
    this.selected = this.selectedInsurers.includes(this.insurer.id);
  }

  toggleSelection() {
    this.selected = !this.selected;
    this.clickEvent.emit(this.insurer.id);
  }
}
