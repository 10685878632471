<div>
  <div class="success-graphic">
    <img src="assets/image/submit-success-dialog.png" alt="" />
    <div *ngIf="onOperation">
      {{ "success.bestPrice" | translate }}
    </div>
    <div *ngIf="!onOperation">
      {{ "dashboard.outOfOfficeTitle" | translate }}
    </div>
  </div>
  <div class="success-content">
    <ng-container *ngIf="!editQuotations">
      <div *ngIf="onOperation">
        <div>
          {{ "dashboard.expectations" | translate }}
          <span class="bold">
            {{
              channel === "Email"
                ? data.customerInput.email
                : data.customerInput.mobileNo
            }}
          </span>
        </div>

        <ul>
          <li class="mt-xs">
            {{ "dashboard.workingHours" | translate
            }}<span class="bold">{{
              "dashboard.workingHours2" | translate
            }}</span>
          </li>
          <li
            class="mt-xs"
            [innerHTML]="'dashboard.outsideWorkingHours' | translate"
          ></li>
          <li class="mt-xs">
            {{ "dashboard.urgent1" | translate }}
            <span class="bold">{{ "dashboard.urgent2" | translate }}</span>
            <span>{{ "dashboard.urgent3" | translate }}</span>
          </li>
        </ul>
      </div>
      <div *ngIf="!onOperation">
        <div>
          {{ "dashboard.expectations" | translate }}
          <span class="bold">
            {{
              channel === "Email"
                ? data.customerInput.email
                : data.customerInput.mobileNo
            }}
          </span>
        </div>
        <br />
        <div [innerHTML]="'dashboard.getBack' | translate"></div>
      </div>
    </ng-container>
    <ng-container *ngIf="editQuotations">
      <span *ngIf="onOperation">
        {{
          "success.quotationsProcessed"
            | translate : { responseTime: responseTime }
        }}
      </span>
      <span *ngIf="!onOperation">
        {{ "success.getBack" | translate }}
        {{ "success.dropEmail" | translate }}
        <a href="mailto:info@policystreet.com">info@policystreet.com</a>.
      </span>
    </ng-container>
    <button mat-flat-button mat-primary mat-dialog-close>
      {{ "success.close" | translate }}
    </button>
  </div>
</div>
