<div class="endorsement-payment">
    <div class="endorsement-payment__left">
        <div class="endorsement-payment__left__title">
            {{ "common.orderSummary" | translate }} {{ "topup.revised" | translate }}
        </div>
        <div class="endorsement-payment__left__desc">{{ "common.orderSummaryDesc" | translate }}</div>
        <!-- Car Protection Plan -->
        <div class="mt-lg mr-lg">
            <app-section-title
                title="common.carProtectionPlan"
            ></app-section-title>
        </div>
        <div class="endorsement-payment__left__cpp">
            <div class="endorsement-payment__left__cpp__img">
                <img [src]="(endorsementData$ | async)?.image" alt="">
            </div>
            <div>
                <div>
                    <div class="endorsement-payment__left__cpp__title">{{ "common.insuranceProvider" | translate }}:</div>
                    <div class="endorsement-payment__left__cpp__value">{{ (endorsementData$ | async)?.productName  || '-'}}</div>
                </div>
                <div class="pt-sm">
                    <div class="endorsement-payment__left__cpp__title">{{ "common.typeOfInsurance" | translate }}:</div>
                    <div class="endorsement-payment__left__cpp__value">{{ (endorsementData$ | async)?.insuranceType || '-' }}</div>
                </div>
                <div class="pt-sm">
                    <div class="endorsement-payment__left__cpp__title">{{ "common.sumInsured" | translate }}:</div>
                    <div class="endorsement-payment__left__cpp__value">{{ (endorsementData$ | async)?.sumInsured | number: '1.2-2' }}</div>
                </div>
                <div class="pt-sm">
                    <div class="endorsement-payment__left__cpp__title">{{ "common.effectivePeriod" | translate }}:</div>
                    <div class="endorsement-payment__left__cpp__value">{{ (endorsementData$ | async)?.effectiveDate | date:  "dd MMM yyyy" }}</div>
                </div>
            </div>
        </div>
        <!-- Add ons -->
        <!-- <div class="mt-lg mr-lg">
            <app-section-title
                title="addOn.addOns"
            ></app-section-title>
        </div>
        <div class="endorsement-payment__left__addon">
            <div class="chip">Roadtax</div>
            <div class="chip">Roadtax</div>
            <div class="chip">Roadtax</div>
        </div> -->
        <!-- Car details -->
        <div class="mt-lg mr-lg">
            <app-section-title
                title="common.carDetails"
            ></app-section-title>
        </div>
        <app-flex-container
            [title]="'common.carNumber' | translate"
            [value]="(endorsementData$ | async)?.vehicleNo || '-'"
        ></app-flex-container>
        <app-flex-container
            [title]="'common.carModel' | translate"
            [value]="(endorsementData$ | async)?.make + ' ' + (endorsementData$ | async)?.model + ' ' +
            (endorsementData$ | async)?.transmission"
        ></app-flex-container>
        <!-- Personal details -->
        <div class="mt-lg mr-lg">
            <app-section-title
                title="common.personalDetails"
            ></app-section-title>
        </div>
        <app-flex-container
            [title]="getNameLabel() | translate"
            [value]="(endorsementData$ | async)?.name || '-' "
        ></app-flex-container>
        <app-flex-container
            [title]="getIdentityNoLabel() | translate"
            [value]="(endorsementData$ | async)?.identityNo || '-'"
        ></app-flex-container>
        <app-flex-container
            [title]="'common.gender' | translate"
            [value]="'common.' + (endorsementData$ | async)?.gender | translate"
        ></app-flex-container>
        <app-flex-container
            [title]="'common.maritalStatus' | translate"
            [value]="(endorsementData$ | async)?.maritalStatus || '-'"
        ></app-flex-container>
        <app-flex-container
            [title]="'common.dob' | translate"
            [value]="(endorsementData$ | async)?.dob | date: 'dd MMM yyyy'"
        ></app-flex-container>
        <app-flex-container
            [title]="'common.occupation' | translate"
            [value]="(endorsementData$ | async)?.occupation || '-'"
        ></app-flex-container>
        <app-flex-container
            [title]="'common.mobileNo' | translate"
            [value]="(endorsementData$ | async)?.mobileNo || '-'"
        ></app-flex-container>
        <app-flex-container
            [title]="'common.email' | translate"
            [value]="(endorsementData$ | async)?.email || '-'"
        ></app-flex-container>
        <!-- Residential address -->
        <div class="mt-lg mr-lg">
            <app-section-title
                title="common.residentialAddress"
            ></app-section-title>
        </div>
        <app-flex-container
            [title]="'common.addressLineOne' | translate"
            [value]="(endorsementData$ | async)?.address || '-'"
        ></app-flex-container>
        <app-flex-container
            [title]="'common.addressLineTwo' | translate"
            [value]="(endorsementData$ | async)?.address2 || '-'"
        ></app-flex-container>
        <app-flex-container
            [title]="'common.city' | translate"
            [value]="(endorsementData$ | async)?.city || '-'"
        ></app-flex-container>
        <app-flex-container
            [title]="'common.postcode' | translate"
            [value]="(endorsementData$ | async)?.postcode || '-'"
        ></app-flex-container>
        <app-flex-container
            [title]="'common.state' | translate"
            [value]="(endorsementData$ | async)?.state || '-'"
        ></app-flex-container>
        <!-- Delivery address -->
        <div class="mt-lg mb-lg" *ngIf="(endorsementData$ | async)?.deliveryAddress">
            <app-section-title
                title="common.deliveryAddress"
            ></app-section-title>
            <app-flex-container
                [title]="'common.addressLineOne' | translate"
                [value]="(endorsementData$ | async)?.deliveryAddress || '-'"
            ></app-flex-container>
            <app-flex-container
                [title]="'common.addressLineTwo' | translate"
                [value]="(endorsementData$ | async)?.deliveryAddress2 || '-'"
            ></app-flex-container>
            <app-flex-container
                [title]="'common.city' | translate"
                [value]="(endorsementData$ | async)?.deliveryCity || '-'"
            ></app-flex-container>
            <app-flex-container
                [title]="'common.postcode' | translate"
                [value]="(endorsementData$ | async)?.deliveryPostcode || '-'"
            ></app-flex-container>
            <app-flex-container
                [title]="'common.state' | translate"
                [value]="(endorsementData$ | async)?.deliveryState || '-'"
            ></app-flex-container>
        </div>

        <div class="endorsement-content-mobile" *ngIf="(screenSizeService.isMobile$ | async)">
            <div class="pb-sm">
                <app-section-title
                    title="plans.step3Content.paymentOption"
                ></app-section-title>
                <ng-container *ngTemplateOutlet="paymentOption"> </ng-container>
            </div>
            <div class="mt-lg">
                <app-section-title
                    title="common.orderSummary"
                ></app-section-title>
            </div>
            <ng-container *ngTemplateOutlet="orderSummaryTemplate"></ng-container>
            <ng-container *ngTemplateOutlet="payBtnTemplate"> </ng-container>
        </div>
    </div>
    <div class="endorsement-payment__right" *ngIf="!(screenSizeService.isMobile$ | async)">
        <mat-card class="order-summary-card">
            <div class="header">
                {{ 'common.orderSummary' | translate | translate }}
            </div>
            <div class="ph-sm pv-md">
                <ng-container *ngTemplateOutlet="orderSummaryTemplate"></ng-container>
                <ng-container *ngTemplateOutlet="paymentOption"> </ng-container>
                <ng-container *ngTemplateOutlet="payBtnTemplate"> </ng-container>
            </div>
        </mat-card>
    </div>
</div>

<ng-template #orderSummaryTemplate>
    <div class="order-summary-card__content">
        <div class="order-summary-card__content__endorsement">
            <div class="order-summary-card__content__endorsement__title">{{ "complain.endorsement" | translate }}</div>
            <ul *ngIf="(endorsementData$ | async)?.endorsementType">
                <li class="order-summary-card__content__endorsement__list">{{ (endorsementData$ | async)?.endorsementType  }}</li>
            </ul>
        </div>
        <app-flex-container
            [title]="'topup.billAmount' | translate"
            [value]="(endorsementData$ | async)?.total | number: '1.2-2'"
            [currency]="true"
        ></app-flex-container>
        <app-flex-container
            [title]="'topup.serviceCharge' | translate"
            [value]="getTax(endorsementData$ | async) | number: '1.2-2'"
            [currency]="true"
        ></app-flex-container>
        <div class="divider mt-lg"></div>
        <div class="total-amt">
            <div class="title">{{ "common.totalAmount" | translate }}</div>
            <div class="value">RM {{ getTotalPrice(endorsementData$ | async) | number: '1.2-2' }}</div>
        </div> 
        <div class="divider mb-lg"></div>
    </div>
</ng-template>

<ng-template #paymentOption>
	<app-payment-options 
		[totalPrice]="getTotalPrice(endorsementData$ | async)"
		[totalPriceBeforeCharges]="getTotalPrice(endorsementData$ | async)"
		[bankCharge]="null"
		[paymentFormGroup]="paymentFormGroup"
		[onlyFullPayment]="true"
        [allowEwallet]="false"
	></app-payment-options>
</ng-template>

<ng-template #payBtnTemplate>
    <button
        mat-flat-button
        [disabled]="paymentFormGroup?.invalid"
        (click)="$event.stopPropagation(); handleSubmit()"
        className="allow-pointer"
    >
        {{ "plans.readyToPay" | translate }}
    </button>
</ng-template>