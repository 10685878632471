import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-faq-takaful-dialog",
	templateUrl: "./faq-takaful-dialog.component.html",
	styleUrls: ["../faq-dialog.component.scss"],
})
export class FaqTakafulDialogComponent implements OnInit {
	currentLang: string;
	constructor(private translate: TranslateService) {}

	ngOnInit(): void {}

	switch(lang: string) {
		this.translate.use(lang);
	}
}
