<div class="bg-light-grey refund-request pv-lg">
	<div class="content-container small">
		<p class="color-primary title-f">{{ "refundReq.title" | translate }}</p>
		<p>
			{{ "refundReq.desc1" | translate }}
		</p>

		<p>
			{{ "refundReq.desc2a" | translate }}
			<span class="bold">{{ "refundReq.fee" | translate }}</span>
			{{ "refundReq.desc2b" | translate }}
		</p>

		<p>
			{{ "refundReq.desc3" | translate }}
		</p>

		<div class="vehicle-reg-container">
			<div fxFlex fxLayout="row" fxLayoutAlign=" center">
				<div>
					<img
						src="https://assets.policystreet.com/shared/white-car.png"
						class="pr-sm"
					/>
				</div>
				<p class="desc-f color-white pt-xs">
					{{ "common.vehicleRegNo" | translate }}:
				</p>
			</div>
			<p class="desc-f color-white pt-xs">JTT2751</p>
		</div>
		<mat-card class="mv-md p-md">
			<mat-form-field
				appearance="outline"
				floatLabel="never"
				class="full-width"
			>
				<mat-select placeholder="Bank">
					<mat-option *ngFor="let item of bankList" [value]="item">{{
						item
					}}</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field appearance="outline" class="full-width">
				<mat-label> {{ "refundReq.bankNumb" | translate }}</mat-label>
				<input matInput />
			</mat-form-field>

			<mat-form-field appearance="outline" class="full-width">
				<mat-label> {{ "refundReq.fullName" | translate }}</mat-label>
				<input matInput />
			</mat-form-field>

			<p>
				{{ "refundReq.noteIC" | translate }}
			</p>

			<mat-form-field appearance="outline" class="full-width">
				<mat-label> {{ "refundReq.icNumb" | translate }} </mat-label>
				<input matInput formControlName="insurancePeriod" />
			</mat-form-field>

			<p>{{ "refundReq.provideReason" | translate }}</p>
			<mat-form-field appearance="outline" class="full-width">
				<textarea matInput class="textarea"></textarea>
			</mat-form-field>
		</mat-card>
		<button (click)="handleSubmit()" mat-flat-button class="secondary-btn">
			{{ "common.submit" | translate }}
		</button>
	</div>
</div>
