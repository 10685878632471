import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import * as $ from "jquery";
import * as color from "./../../../assets/styles/colors/partner-colors.json";

interface PartnerListAttributesInterface {
	code: string;
	title: string;
	hostName: string[];
	gtmID: string;
	uniquePageViews: boolean;
	takaful: boolean;
}

@Injectable({
	providedIn: "root",
})
export class PartnerService {
	colorObject = (color as any).default;

	psHostName: string[] = [
		"motor.policystreet.com",
		"motor.policystreet.app",
		"car.policystreet.com",
		"car.policystreet.app",
		"uat-car.policystreet.app",
		"localhost",
	];
	partnerList: PartnerListAttributesInterface[] = [
		{
			code: "ctos",
			title: "CTOS | Car Insurance",
			hostName: ["ctos.policystreet.com", "ctos.policystreet.app"],
			gtmID: "GTM-MRN9J57",
			uniquePageViews: true,
			takaful: false,
		},
		{
			code: "carsome",
			title: "Carsome | Car Insurance",
			hostName: [
				"carsome-v2.policystreet.com",
				"carsome.policystreet.com",
				"carsome.policystreet.app",
				"chubb-carsome.policystreet.app"
			],
			gtmID: "",
			uniquePageViews: false,
			takaful: false,
		},
		{
			code: "carsometelemarketing",
			title: "Carsome | Car Insurance",
			hostName: [
				"carsometelemarketing.policystreet.com",
				"carsometelemarketing.policystreet.app",
			],
			gtmID: "",
			uniquePageViews: false,
			takaful: false,
		},
		{
			code: "cbp",
			title: "Co-opbank Pertama | Car Insurance",
			hostName: ["cbp.policystreet.app", "cbp.policystreet.com"],
			gtmID: "",
			uniquePageViews: false,
			takaful: true,
		},
		{
			code: "wapcar",
			title: "Wapcar | Car Insurance",
			hostName: ["wapcar.policystreet.app", "wapcar.policystreet.com"],
			gtmID: "",
			uniquePageViews: false,
			takaful: false,
		},
		{
			code: "mytukarInsure",
			title: "myTukar | Car Insurance",
			hostName: [
				"mytukarinsure.policystreet.app",
				"mytukarinsure.policystreet.com",
			],
			gtmID: "",
			uniquePageViews: false,
			takaful: false,
		},
		{
			code: "grab",
			title: "Grab | Car Insurance",
			hostName: [
				"grab-v2.policystreet.com",
				"grab.policystreet.app",
				"grab.policystreet.com",
			],
			gtmID: "",
			uniquePageViews: false,
			takaful: false,
		},
		{
			code: "airasia",
			title: "Airasia | Car Insurance",
			hostName: [
				"airasia-v2.policystreet.com",
				"airasia.policystreet.app",
				"airasia.policystreet.com",
			],
			gtmID: "",
			uniquePageViews: false,
			takaful: false,
		},
		{
			code: "airasiaride",
			title: "Airasia Ride | Car Insurance",
			hostName: [
				"airasiaride.policystreet.com",
				"airasiaride.policystreet.app",
			],
			gtmID: "",
			uniquePageViews: false,
			takaful: false,
		},
		{
			code: "mahsing",
			title: "Mahsing | Car Insurance",
			hostName: [
				"mahsing-v2.policystreet.com",
				"mahsing.policystreet.app",
				"mahsing.policystreet.com",
			],
			gtmID: "",
			uniquePageViews: false,
			takaful: false,
		},
		{
			code: "bonuslink",
			title: "Bonuslink | Car Insurance",
			hostName: [
				"bonuslink-v2.policystreet.com",
				"bonuslink.policystreet.app",
				"bonuslink.policystreet.com",
			],
			gtmID: "",
			uniquePageViews: false,
			takaful: false,
		},
		{
			code: "carlist",
			title: "Carlist | Car Insurance",
			hostName: [
				"carlist-v2.policystreet.com",
				"carlist.policystreet.app",
				"carlist.policystreet.com",
			],
			gtmID: "",
			uniquePageViews: false,
			takaful: false,
		},
    {
      code: "ktmb",
      title: "KTMB | Car Insurance",
      hostName: [
        "ktmb.policystreet.app",
        "ktmb.policystreet.com",
      ],
      gtmID: "",
      uniquePageViews: false,
      takaful: false,
    },
	];
	constructor(private titleService: Title) {}
	getPartnerCode() {
		let hostName = window.location.hostname?.toLowerCase();
		let urlParams = new URLSearchParams(window.location.search);
		let partnerCode = urlParams.get("partnerCode");

		// set PartnerCode on query params
		if (partnerCode) {
			let partner = this.partnerList.find((x) => x.code == partnerCode);
			if (!partner) {
				partnerCode = partner?.code;
			}
		}

		// set PartnerCode on custom subdomain
		if (hostName && !this.psHostName.some((x) => x.toLowerCase() == hostName)) {
			let selectedPartner = this.partnerList.find((x) =>
				x.hostName.includes(hostName),
			);
			partnerCode = selectedPartner?.code;
		}

		// Change favicon
		if (partnerCode) {
			this.titleService.setTitle(
				this.partnerList.find((x) => x.code == partnerCode)?.title,
			);
			$("link[rel*='icon']").attr(
				"href",
				`assets/icons/affiliate/${partnerCode}.ico`,
			);
		}
		// Switch the styles on partnerCode
		let colorPartnerCode = "default";
		if (partnerCode) {
			colorPartnerCode = partnerCode;
		}
		let colorList = this.colorObject[colorPartnerCode];
		colorList.forEach((x: { root: string; value: string }) => {
			document.documentElement.style.setProperty(x.root, x.value);
		});
		return partnerCode;
	}

	getPartnerAttribute(partnerCode: string, type: string) {
		let partner = this.partnerList.find((x) => x[type] == true);
		if (partner.code == partnerCode) return true;
		else return false;
	}

	setPartnerAttribute(
		partnerCode: string,
		type: string,
		value: boolean | string,
	) {
		let partner = this.partnerList.find((x) => x[partnerCode] === x.code);
		if (partner) {
			partner[type] = value;
		}
	}

	appendGtmScript(gtmCode: string) {
		var headScript = `<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${gtmCode}');</script>`;
		var bodyScript = `<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${gtmCode}"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`;
		this.appendHeaderScript(headScript);
		this.appendBodyScript(bodyScript);
	}

	appendHeaderScript(script: string) {
		$("head").append(script);
	}

	appendBodyScript(script: string) {
		$("body").append(script);
	}
}
