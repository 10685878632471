import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ThankyouDialogComponent } from "../thankyou-dialog/thankyou-dialog.component";

@Component({
	selector: "app-refund-request",
	templateUrl: "./refund-request.component.html",
	styleUrls: ["./refund-request.component.scss"],
})
export class RefundRequestComponent {
	constructor(private dialog: MatDialog) {}

	bankList: string[] = ["test", "test", "test"];

	handleSubmit() {
		this.dialog.open(ThankyouDialogComponent, {
			panelClass: "success-dialog",
			data: {
				title: "Thank you",
				body: "refundReq.thankyouMsg",
			},
		});
	}
}
