<form [formGroup]="frmGroup" (ngSubmit)="save()">
  <div class="dialog-title">Edit car details</div>

  <mat-dialog-content>
    <mat-form-field appearance="outline" floatLabel="never">
      <mat-select
        (infiniteScroll)="getNextBatch()"
        [complete]="isCompleted"
        msInfiniteScroll
        placeholder="Car model"
        formControlName="vehicleId"
      >
        <mat-option>
          <ngx-mat-select-search
            [placeholderLabel]="'common.carModelPH' | translate"
            [clearSearchInput]="false"
            [searching]="isLoading"
            [disableScrollToActiveOnOptionsChanged]="true"
            [ngModelOptions]="{ standalone: true }"
            noEntriesFoundLabel="'no matching car model found'"
            [(ngModel)]="searchText"
            (ngModelChange)="filterVehicle()"
          >
          </ngx-mat-select-search>
        </mat-option>
        <mat-option
          *ngFor="let item of vehicleList"
          [value]="item.id"
          (click)="selectVehicle = true"
        >
          {{ item.vehicle }}</mat-option
        >
      </mat-select>
      <mat-icon
        matSuffix
        *ngIf="this.frmGroup.value.vehicleId"
        (click)="clearVehicle()"
        >close</mat-icon
      >
    </mat-form-field>
  </mat-dialog-content>

  <button class="full-btn" mat-button [disabled]="isDisabledSubmit()">
    {{ "common.submit" | translate }}
  </button>
</form>
