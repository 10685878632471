<div class="benefits" [class.benefits--first]="isFirst">
  <div class="benefits__list">
    <div class="benefits__item">
      <img alt="Car Icon" src="/assets/image/ktmb/{{ isFirst ? 'car-gold' : 'car'}}.svg">
      <div>{{ "benefits.additionalProtectedDriver" | translate }}: <span class="benefits__highlight">
          {{ "benefits.freeForOne" | translate }}</span></div>
    </div>
    <div class="benefits__item">
      <img alt="Towing Icon" src="/assets/image/ktmb/{{ isFirst ? 'towing-gold' : 'towing'}}.svg">
      <div><span class="benefits__highlight">{{ "benefits.inclusive" | translate }}</span> {{ "benefits.24HourTowing" | translate }}</div>
    </div>
    <div class="benefits__item">
      <img alt="Check Icon" src="/assets/image/ktmb/{{ isFirst ? 'check-gold' : 'check'}}.svg">
      <div><span class="benefits__highlight">{{ "benefits.inclusive" | translate }}</span> {{ "benefits.24HourAssistance" | translate }}</div>
    </div>
  </div>
</div>
