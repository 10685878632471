// Angular Core
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router, NavigationExtras } from "@angular/router";
// Angular Material
import { MatDialog } from "@angular/material/dialog";
// Services
import { AlertService } from "../../../share/service/alert.service";
import { TranslateService } from "@ngx-translate/core";
import { EndorsementService } from "../../data-service/endorsement.service";
import { LoadingOverlayService } from "src/app/share/service/loading-overlay.service";
// Utilities
import { ValidatorFunction } from "../../../share/function/validator.function";
import { ID_TYPE } from "src/app/share/constants/common.types";
import SwiperCore, { Pagination, Autoplay, Navigation } from "swiper";
// Component
import { ErrorDialogComponent } from "../../ui/error-dialog/error-dialog.component";
import { ConfirmationDialogComponent } from "../../ui/confirmation-dialog/confirmation-dialog.component";

SwiperCore.use([Pagination, Autoplay, Navigation]);
@Component({
	selector: "app-endorsement",
	templateUrl: "./endorsement.component.html",
	styleUrls: ["./endorsement.component.scss"],
})
export class EndorsementComponent implements OnInit {
	card1Group: FormGroup;
	idTypeOption = ID_TYPE;
	idType = this.idTypeOption.malaysian;
	policyHolderData: any;

	constructor(
		public translate: TranslateService,
		private endorsementService: EndorsementService,
		private alertService: AlertService,
		private router: Router,
		private dialog: MatDialog,
		private loadingService: LoadingOverlayService,
	) {}

	ngOnInit(): void {
		this.initCard1GroupForm();
	}

	private initCard1GroupForm() {
		this.card1Group = new FormGroup({
			vehicleNo: new FormControl(null, Validators.required),
			identityType: new FormControl("Malaysian", Validators.required),
			identityNo: new FormControl(null),
		});

		// Initialize Identity type with Malaysian
		this.setMalaysian();

		this.card1Group.controls.identityType.valueChanges.subscribe((value) => {
			if (value == "Police/Army") {
				this.setPoliceArmy();
				this.idType = this.idTypeOption.police;
			}
			if (value == "Malaysian") {
				this.setMalaysian();
				this.idType = this.idTypeOption.malaysian;
			} else if (value == "Company") {
				this.setIsCompany();
				this.idType = this.idTypeOption.company;
			} else if (value == "Foreigner") {
				this.setNonMalaysian();
				this.idType = this.idTypeOption.foreigner;
			}
		});
	}

	private setMalaysian() {
		this.card1Group.controls.identityNo.setValidators([
			Validators.required,
			ValidatorFunction.nricValidator(),
		]);
		this.card1Group.controls.identityNo.updateValueAndValidity();
	}

	private setPoliceArmy() {
		this.card1Group.controls.identityNo.setValidators([
			Validators.required,
			ValidatorFunction.nricValidator(),
		]);
		this.card1Group.controls.identityNo.updateValueAndValidity();
	}

	private setIsCompany() {
		this.card1Group.controls.identityNo.setValidators([Validators.required]);
		this.card1Group.controls.identityNo.updateValueAndValidity();
	}

	private setNonMalaysian() {
		this.card1Group.controls.identityNo.setValidators([Validators.required]);
		this.card1Group.controls.identityNo.updateValueAndValidity();
	}

	getIdentityNoMasking() {
		return "000000000000";
	}

	//Api functions
	verifyPolicyHolder(
		urlVehicleNo?: string,
		urlIdentityType?: string,
		urlIdentityNo?: string,
	) {
		this.loadingService.setLoading(true);
		let vehicleNo = "";
		let identityType = "";
		let identityNo = "";
		if (urlVehicleNo && urlIdentityType && urlIdentityNo) {
			vehicleNo = urlVehicleNo;
			identityType = urlIdentityType;
			identityNo = urlIdentityNo;
		} else {
			let endorsementvalue = this.card1Group.getRawValue();
			vehicleNo = endorsementvalue.vehicleNo;
			identityType = endorsementvalue.identityType;
			identityNo = endorsementvalue.identityNo;
		}
		this.endorsementService
			.verifyPolicyHolder(vehicleNo, identityNo)
			.subscribe({
				next: (x) => {
					this.loadingService.setLoading(false);
					this.policyHolderData = x;
					if (this.policyHolderData.isPolicyExpired) {
						this.openErrorDialog("expired");
						return;
					}

					const navigationExtras: NavigationExtras = {
						queryParams: {
							vehicleNo: vehicleNo,
							identityType: identityType,
							identityNo: identityNo,
						},
						state: {
							policyHolderData: { identityType, ...this.policyHolderData },
						},
					};

					this.router.navigate(["/endorsement/details"], navigationExtras);
				},
				error: (err) => {
					this.loadingService.setLoading(false);
					if (err.status === 404) {
						this.openErrorDialog("notFound");
					} else {
						this.alertService.openSnackBar(`Error: ${err.message}`);
						console.log(err);
					}
				},
			});
	}

	openErrorDialog(contentType: string): void {
		const dialogRef = this.dialog.open(ErrorDialogComponent, {
			panelClass: "no-padding-border-radius-dialog",
			data: {
				contentType: contentType,
			},
		});
		dialogRef.afterClosed().subscribe((result) => {
			this.router.navigate(["/endorsement"]);
		});
	}

	openConfirmationDialog(): void {
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			panelClass: "no-padding-border-radius-dialog",
		});

		dialogRef.afterClosed().subscribe((result: boolean) => {
			if (result) {
				return true;
			} else {
				return false;
			}
		});
	}
}
