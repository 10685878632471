// Angular Core
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
	selector: "app-endorsement-summary",
	templateUrl: "./endorsement-summary.component.html",
	styleUrls: ["./endorsement-summary.component.scss"],
})
export class EndorsementSummaryComponent implements OnInit {
	endorsementSummaryPage = {
		vehicleNo: "",
		identityNo: "",
		name: "",
		email: "",
		mobileNo: "",
		roadtaxStatus: "",
		endorsementTypeName: "",
	};
    idType: any = "Malaysian";
	constructor(private router: Router) {}

	ngOnInit(): void {
		if (!history.state.endorsementSummaryData) {
			this.router.navigate(["/endorsement"]);
		} else {
			const data = history.state.endorsementSummaryData;
			this.idType = history.state.idType;
			this.endorsementSummaryPage.vehicleNo = data.vehicleNo;
			this.endorsementSummaryPage.identityNo = data.identityNo;
			this.endorsementSummaryPage.name = data.name;
			this.endorsementSummaryPage.email = data.email;
			this.endorsementSummaryPage.mobileNo = data.mobileNo;
			this.endorsementSummaryPage.roadtaxStatus = data.roadtaxStatus;
			this.endorsementSummaryPage.endorsementTypeName =
				data.endorsementTypeName;
		}
	}

	onHomepage() {
		this.router.navigate(["/endorsement"]);
	}
}
