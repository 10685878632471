import { Overlay } from "@angular/cdk/overlay";
import { MotorQuotationDataService } from "./../../share/data-service/motor/motor-quotation.data.service";
import { MotorQuotationInterface } from "./../../share/interface/motor-quotation.interface";
import { ValidatorFunction } from "./../../share/function/validator.function";
import { AlertService } from "./../../share/service/alert.service";
import { MasterDataDataService } from "./../../share/data-service/master-data.data.service";
import { Component, HostListener, ViewChild, OnInit } from "@angular/core";
import { FormControl, FormGroup, NgForm, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { SubmitSuccessDialogComponent } from "../submit-success-dialog/submit-success-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import SwiperCore, { Pagination, Autoplay, Navigation } from "swiper";
import { BeforeExpiredDialogComponent } from "../before-expired-dialog/before-expired-dialog.component";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute } from "@angular/router";
import { MatStepper } from "@angular/material/stepper";
import { MatSelectChange } from "@angular/material/select";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { PartnerService } from "src/app/share/service/partner.service";
import { OpenImageViewerDirective } from "src/app/share/directive/open-image-viewer.directive";

SwiperCore.use([Pagination, Autoplay, Navigation]);
@Component({
	selector: "app-dashboard",
	templateUrl: "./dashboard.component.html",
	styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
	partnerCode: string;
	currentLang: string;

	@ViewChild("stepper") stepper: MatStepper;
	vehicleFormGroup: FormGroup;
	custFormGroup: FormGroup;
	@ViewChild("vehicleForm") vehicleForm: NgForm;
	@ViewChild("custForm") custForm: NgForm;
	@HostListener("window:resize", [])
	onResize() {
		var width = window.innerWidth;
		this.mobileView = width < 960;
	}

	countryList: string[] = [];
	filterCountryList: string[] = [];
	isCompany: boolean = false;
	isMalaysian: boolean = true;
	pdpaAgreement: boolean = false;
	clickedNext: boolean = false;
	isLoading: boolean = false;
	showSuccessGif: boolean = false;
	eHailingChecked: boolean = false;
	mobileView: boolean = false;

	idType: Number = 1;
	utmCampaign: string;
	utmSource: string;
	utmMedium: string;
	utmContent: string;
	maxDob: Date = new Date();
	contactInfo: string;
	successGifType: string;

	// banner images
	banners = [
		{
			lang: "en",
			src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/banner_1-en.png",
			show: true,
		},
		{
			lang: "en",
			src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/banner_2-en.png",
			show: true,
		},
		{
			lang: "en",
			src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/banner_3-en.png",
			show: true,
		},
		{
			lang: "en",
			src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/banner_4-en.png",
			show: true,
		},
		{
			lang: "en",
			src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/banner_5-en.png",
			show: true,
		},
		{
			lang: "en",
			src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/banner_6-en.png",
			show: true,
		},
		{
			lang: "bm",
			src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/banner_1-bm.png",
			show: true,
		},
		{
			lang: "bm",
			src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/banner_2-bm.png",
			show: true,
		},
		{
			lang: "bm",
			src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/banner_3-bm.png",
			show: true,
		},
		{
			lang: "bm",
			src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/banner_4-bm.png",
			show: true,
		},
		{
			lang: "bm",
			src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/banner_5-bm.png",
			show: true,
		},
		{
			lang: "bm",
			src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/banner_6-bm.png",
			show: true,
		},
	];

	idTypeList = [
		{ id: 1, name: "common.icNo" },
		{ id: 2, name: "common.foreigner" },
		{ id: 3, name: "common.company" },
	];

	typeOfInsuranceList = [
		// { id: "TP", name: "common.thirdPartyOnlyIns" },
		{ id: "TPFT", name: "common.TPFT" },
		{ id: "COM", name: "common.COM" },
	];

	showWhatsappBtn: Boolean = true;
	hideWhatsappBtnList: string[] = ["wapcar", "carsome", "carlist"];

	constructor(
		gtmService: GoogleTagManagerService,
		private ngxSpinnerService: NgxSpinnerService,
		private activatedRoute: ActivatedRoute,
		public translate: TranslateService,
		private masterDataDataService: MasterDataDataService,
		private motorQuotationDataService: MotorQuotationDataService,
		private partnerService: PartnerService,
		private alertService: AlertService,
		private dialog: MatDialog,
		public overlay: Overlay,
	) {
		gtmService.pushTag({
			event: "car_lp",
		});
		this.getParams();
		this.initForm();
	}

	ngOnInit() {
		if (window.screen.width < 960) {
			this.mobileView = true;
		}
	}

	private getParams() {
		let snapshotParam = this.activatedRoute.snapshot.queryParams;
		let lang: string = snapshotParam.lang;
		this.partnerCode = this.partnerService.getPartnerCode();
		if (this.hideWhatsappBtnList.includes(this.partnerCode)) {
			this.showWhatsappBtn = false;
		}

		!this.partnerCode ? (this.partnerCode = "ps") : null;

		if (lang) this.translate.use(lang);
		this.utmSource = snapshotParam.utm_source;
		this.utmMedium = snapshotParam.utm_medium;
		this.utmCampaign = snapshotParam.utm_campaign;
		this.utmContent = snapshotParam.utm_content;

		if (!this.utmSource) {
			let searchEngineList = ["google", "yahoo", "bing"];
			let referrer = document.referrer;
			if (
				referrer != window.location.href ||
				referrer.includes("policystreet.com")
			) {
				let searchEngine = searchEngineList.find((x) =>
					referrer.toLowerCase().includes(x),
				);
				if (searchEngine) {
					this.utmSource = searchEngine;
					this.utmMedium = "organic";
				} else {
					if (!referrer) this.utmSource = "direct";
					else {
						let url = new URL(referrer);
						this.utmSource = url.hostname;
						this.utmMedium = "referral";
					}
				}
			} else {
				this.utmSource = "direct";
			}
		}
	}

	private initForm() {
		this.vehicleFormGroup = new FormGroup({
			insuranceType: new FormControl("COM", Validators.required),
			carPlate: new FormControl(null, Validators.required),
			postcode: new FormControl(null),
			insuredType: new FormControl(null, Validators.required),
			includeRoadTax: new FormControl(false),
			eHailingUsed: new FormControl(false),

			// Only for PS D2C
			psIdentityType: new FormControl("Malaysian", Validators.required),
			psIdentityNo: new FormControl(null),
			psPolicIdentityNo: new FormControl(null),
		});

		this.custFormGroup = new FormGroup({
			identityNo: new FormControl(null, Validators.required),
			name: new FormControl(null, Validators.required),
			policIdentityNo: new FormControl(null),
			nationality: new FormControl(null, Validators.required),
			dob: new FormControl(),
			gender: new FormControl(null),
			maritalStatus: new FormControl(null, Validators.required),
			contactMethod: new FormControl(null),
			mobileNo: new FormControl(null, Validators.required),
			email: new FormControl(null, [Validators.required, Validators.email]),

			// Only for PS D2C
			psPostCode: new FormControl(null),
		});

		this.vehicleFormGroup.controls.insuranceType.setValue("COM");
		this.vehicleFormGroup.controls.insuredType.setValue("MV");
		this.custFormGroup.controls.maritalStatus.setValue("Single");
		this.custFormGroup.controls.contactMethod.setValue("Email");

		if (this.partnerCode !== "ps") {
			this.setMalaysian();
			this.vehicleFormGroup.controls.postcode.setValidators([
				Validators.required,
			]);
			this.vehicleFormGroup.controls.postcode.updateValueAndValidity();
		} else {
			// Validations for d2c
			this.setPSMalaysian();
			this.setPSStep2Validation();
		}

		// Get dob and gender on Affiliate
		this.custFormGroup.controls.identityNo.valueChanges.subscribe((value) => {
			if (
				this.custFormGroup.controls.identityNo.valid &&
				this.isMalaysian &&
				!this.isCompany
			) {
				this.getGenderAndDate(value);
			}
		});

		// set dob and gender on PS
		this.vehicleFormGroup.controls.psIdentityNo.valueChanges.subscribe(
			(value) => {
				if (
					this.vehicleFormGroup.controls.psIdentityNo.valid &&
					this.isMalaysian &&
					!this.isCompany
				) {
					this.getGenderAndDate(value);
				}
			},
		);

		// set validations for PS ID types
		this.vehicleFormGroup.controls.psIdentityType.valueChanges.subscribe(
			(value) => {
				if (value == "Police/Army") {
					this.setPSPoliceArmy();
				}
				if (value == "Malaysian") {
					this.setPSMalaysian();
				} else if (value == "Company") {
					this.setPSIsCompany();
				} else if (value == "Foreigner") {
					this.setPSNonMalaysian();
				}
			},
		);
	}

	private getGenderAndDate(value) {
		let date = moment(value.substring(0, 6), "YYMMDD");
		if (moment().isBefore(date)) {
			date.subtract(100, "year");
		}
		let lastChar = value.substring(11);
		this.custFormGroup.controls.dob.setValue(date.toDate());
		let gender = lastChar % 2 ? "M" : "F";
		this.custFormGroup.controls.gender.setValue(gender);
	}

	private setPSStep2Validation() {
		this.custFormGroup.controls.identityNo.setValidators([]);
		this.custFormGroup.controls.identityNo.updateValueAndValidity();

		this.custFormGroup.controls.psPostCode.setValidators([Validators.required]);
		this.custFormGroup.controls.psPostCode.updateValueAndValidity();
	}

	private setNonCompanyValidation() {
		this.custFormGroup.controls.dob.setValidators([Validators.required]);
		this.custFormGroup.controls.gender.setValidators([Validators.required]);
		this.custFormGroup.controls.maritalStatus.setValidators([
			Validators.required,
		]);

		this.custFormGroup.controls.dob.updateValueAndValidity();
		this.custFormGroup.controls.gender.updateValueAndValidity();
		this.custFormGroup.controls.maritalStatus.updateValueAndValidity();
	}

	private setCompanyValidation() {
		this.custFormGroup.controls.dob.setValidators([]);
		this.custFormGroup.controls.gender.setValidators([]);
		this.custFormGroup.controls.maritalStatus.setValidators([]);
		this.custFormGroup.controls.nationality.setValidators([]);

		this.custFormGroup.controls.dob.updateValueAndValidity();
		this.custFormGroup.controls.gender.updateValueAndValidity();
		this.custFormGroup.controls.maritalStatus.updateValueAndValidity();
		this.custFormGroup.controls.nationality.updateValueAndValidity();
	}

	private getCountryList() {
		if (this.countryList.length != 0) {
			this.filterCountry();
			return;
		}
		this.masterDataDataService.getCountrySelection().subscribe({
			next: (x) => {
				this.countryList = x;
				this.filterCountry();
			},
			error: (err) => {
				this.alertService.openSnackBar(err.error);
			},
		});
	}

	private resetForm() {
		this.pdpaAgreement = false;
		this.vehicleFormGroup.reset();
		// this.vehicleForm.resetForm();
		this.custFormGroup.reset();
		// this.custForm.resetForm();

		this.custFormGroup.controls.mobileNo.setValue(" ");
		this.custFormGroup.controls.mobileNo.updateValueAndValidity();
		this.initForm();
		this.stepper.reset();
	}

	private setMalaysian() {
		this.isCompany = false;
		this.isMalaysian = true;
		this.custFormGroup.controls.identityNo.setValue(null);
		this.custFormGroup.controls.identityNo.setValidators([
			Validators.required,
			ValidatorFunction.nricValidator(),
		]);
		this.custFormGroup.controls.dob.disable();
		this.custFormGroup.controls.gender.disable();
		this.custFormGroup.controls.identityNo.updateValueAndValidity();
		this.custFormGroup.controls.nationality.setValidators([]);
		this.custFormGroup.controls.nationality.updateValueAndValidity();

		this.setNonCompanyValidation();
	}

	private setPSMalaysian() {
		this.isCompany = false;
		this.isMalaysian = true;

		this.vehicleFormGroup.controls.psIdentityNo.setValidators([
			Validators.required,
			ValidatorFunction.nricValidator(),
		]);
		this.vehicleFormGroup.controls.psIdentityNo.updateValueAndValidity();
		this.vehicleFormGroup.controls.psPolicIdentityNo.setValidators([]);
		this.vehicleFormGroup.controls.psPolicIdentityNo.updateValueAndValidity();
		this.custFormGroup.controls.nationality.setValidators([]);
		this.custFormGroup.controls.nationality.updateValueAndValidity();

		this.setNonCompanyValidation();
	}

	private setNonMalaysian() {
		this.isCompany = false;
		this.isMalaysian = false;
		this.getCountryList();
		this.custFormGroup.controls.identityNo.setValidators([Validators.required]);
		this.custFormGroup.controls.nationality.setValidators([
			Validators.required,
		]);
		this.custFormGroup.controls.dob.enable();
		this.custFormGroup.controls.gender.enable();
		this.custFormGroup.controls.nationality.updateValueAndValidity();
		this.custFormGroup.controls.identityNo.updateValueAndValidity();

		this.setNonCompanyValidation();
	}

	private setPSNonMalaysian() {
		this.isCompany = false;
		this.isMalaysian = false;
		this.getCountryList();

		this.custFormGroup.controls.nationality.setValidators([
			Validators.required,
		]);
		this.custFormGroup.controls.nationality.updateValueAndValidity();
		this.vehicleFormGroup.controls.psIdentityNo.setValidators([
			Validators.required,
		]);
		this.vehicleFormGroup.controls.psIdentityNo.updateValueAndValidity();
		this.vehicleFormGroup.controls.psPolicIdentityNo.setValidators([]);
		this.vehicleFormGroup.controls.psPolicIdentityNo.updateValueAndValidity();
		this.setNonCompanyValidation();
	}

	private setPSPoliceArmy() {
		this.isCompany = false;
		this.isMalaysian = true;

		this.vehicleFormGroup.controls.psIdentityNo.setValue(null);
		this.vehicleFormGroup.controls.psIdentityNo.setValidators([
			Validators.required,
			ValidatorFunction.nricValidator(),
		]);
		this.vehicleFormGroup.controls.psIdentityNo.updateValueAndValidity();

		this.custFormGroup.controls.nationality.setValidators([]);
		this.custFormGroup.controls.nationality.updateValueAndValidity();

		this.vehicleFormGroup.controls.psPolicIdentityNo.setValidators([
			Validators.required,
		]);
		this.vehicleFormGroup.controls.psPolicIdentityNo.updateValueAndValidity();
	}

	private setIsCompany() {
		this.isCompany = true;
		this.isMalaysian = true;
		this.custFormGroup.controls.identityNo.setValidators([Validators.required]);
		this.custFormGroup.controls.identityNo.updateValueAndValidity();

		this.setCompanyValidation();
	}

	private setPSIsCompany() {
		this.isCompany = true;
		this.isMalaysian = true;

		this.vehicleFormGroup.controls.psIdentityNo.setValidators([
			Validators.required,
		]);
		this.vehicleFormGroup.controls.psIdentityNo.updateValueAndValidity();
		this.vehicleFormGroup.controls.psPolicIdentityNo.setValidators([]);
		this.vehicleFormGroup.controls.psPolicIdentityNo.updateValueAndValidity();
		this.setCompanyValidation();
	}

	idTypeChange(e: MatSelectChange) {
		let value = e.value;
		switch (value) {
			case 1:
				this.setMalaysian();
				break;
			case 2:
				this.setNonMalaysian();
				break;
			case 3:
				this.setIsCompany();
				break;
		}
	}

	getIdentityNoLabel() {
		if (this.isMalaysian && !this.isCompany) return "common.icNoLabel";
		else if (!this.isMalaysian) return "common.passportLabel";
		return "common.businessRegistrationNoLabel";
	}

	getNameLabel() {
		if (this.isMalaysian && !this.isCompany) return "common.namePerIc";
		else if (!this.isMalaysian) return "common.namePerPassport";
		return "common.companyName";
	}

	getIdentityNoMasking() {
		if (this.isMalaysian && !this.isCompany) return "000000000000";
		return "";
	}

	filterCountry(val: string = null) {
		if (val) {
			let txt = val.trim().toLowerCase();
			this.filterCountryList = this.countryList.filter((x) =>
				x.trim().toLowerCase().startsWith(txt),
			);
		} else this.filterCountryList = Object.assign([], this.countryList);
	}

	eHailingUsedChange(e: boolean) {
		this.eHailingChecked = !this.eHailingChecked;
		if (e) {
			this.vehicleFormGroup.controls.includeRoadTax.setValue(false);
			this.vehicleFormGroup.controls.includeRoadTax.disable();
		} else {
			this.vehicleFormGroup.controls.includeRoadTax.enable();
		}
		this.vehicleFormGroup.controls.includeRoadTax.updateValueAndValidity();
	}

	submit() {
		if (!this.vehicleFormGroup.valid || !this.custFormGroup.valid) return;
		if (this.partnerCode == "ps") {
			this.isLoading = true;
		} else {
			this.ngxSpinnerService.show();
		}
		let vehicleValue = this.vehicleFormGroup.getRawValue();
		let custValue = this.custFormGroup.getRawValue();
		const d2c = this.partnerCode == "ps";
		this.successGifType = custValue.contactMethod;
		custValue.contactMethod == "Mobile"
			? (this.contactInfo = custValue.mobileNo)
			: (this.contactInfo = custValue.email);

		let contactMethod =
			custValue.contactMethod == "Mobile"
				? "Mobile (Whatsapp)"
				: custValue.contactMethod;

		let input: MotorQuotationInterface = {
			insuranceType: vehicleValue.insuranceType,
			vehicleNo: vehicleValue.carPlate,
			typeofSum: vehicleValue.insuredType,
			includeRoadTax: vehicleValue.includeRoadTax,
			eHailingUsed: vehicleValue.eHailingUsed,
			isMalaysian: this.isMalaysian,
			isCompany: this.isCompany,
			identityNo: d2c ? vehicleValue.psIdentityNo : custValue.identityNo,
			postcode: d2c ? custValue.psPostCode : vehicleValue.postcode,
			policIdentityNo: d2c
				? vehicleValue.psPolicIdentityNo
				: custValue.policIdentityNo,
			preferredChannel: contactMethod,
			name: custValue.name,
			nationality: custValue.nationality,
			dob: custValue.dob,
			gender: custValue.gender,
			maritalStatus: custValue.maritalStatus,
			mobileNo: custValue.mobileNo,
			email: custValue.email,
			language: this.translate.currentLang,
			utmSouce: this.utmSource,
			utmMedium: this.utmMedium,
			utmCampaign: this.utmCampaign,
			utmContent: this.utmContent,
			isRenewal: false,
			motorLinkId: null,
		};

		this.motorQuotationDataService.submit(input).subscribe({
			next: (x) => {
				if (x.isSuccess) {
					window.scrollTo(0, 0);
					if (this.partnerCode == "ps") {
						this.showSuccessGif = true;
						this.isLoading = false;
					} else {
						const preferredChannelMobile = custValue.contactMethod == "Mobile";
						const preferredChannelEmail = custValue.contactMethod == "Email";

						this.ngxSpinnerService.hide();
						if (preferredChannelMobile) {
							this.dialog.open(SubmitSuccessDialogComponent, {
								panelClass: "success-dialog",
								data: {
									content: "dashboard.preferredChannelMobile",
									boldContent: custValue.mobileNo,
								},
							});
						} else if (preferredChannelEmail) {
							this.dialog.open(SubmitSuccessDialogComponent, {
								panelClass: "success-dialog",
								data: {
									content: "dashboard.preferredChannelDesktop",
									boldContent: custValue.email,
								},
							});
						} else if (!custValue.contactMethod) {
							this.dialog.open(SubmitSuccessDialogComponent, {
								data: {
									editQuotations: true,
								},
								panelClass: "success-dialog",
							});
						}
					}
				} else {
					this.ngxSpinnerService.hide();
					this.dialog.open(BeforeExpiredDialogComponent, {
						panelClass: "",
						data: x.data,
					});
				}
				this.resetForm();
			},
			error: (err) => {
				if (this.partnerCode == "ps") {
					this.isLoading = false;
				} else {
					this.ngxSpinnerService.hide();
				}
				window.scrollTo(0, 0);
				this.alertService.openSnackBar("Please contact policy street.");
			},
		});
	}

	whatsappNumber = 60182822320;
	entityName = "Policystreet";
	whatsappContent = "";

	whatsppRedirect() {
		const whatsappNumber = 60182822320;
		const entityName = "Policystreet";
		let whatsappContent = "";
		let lang = this.translate.currentLang == "en" ? "en" : "ms";
		whatsappContent =
			lang == "ms"
				? `Hai ${entityName}, saya ingin tahu dengan lebih lanjut mengenai cukai jalan dan insurans kenderaan`
				: `Hi ${entityName}, I would like to know more about road tax and car insurance`;

		let url = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(
			whatsappContent,
		)}&source=&data=&app_absent=&lang=${lang}`;

		window.open(url, "_blank");
	}

	checkCss() {
		this.clickedNext = true;
	}

	onHomepage() {
		this.contactInfo = "";
		this.successGifType = "";
		this.showSuccessGif = false;
	}
}
