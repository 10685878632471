import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatMenuTrigger } from "@angular/material/menu";
import { TranslateService } from "@ngx-translate/core";
import { ScreenSizeService } from "src/app/share/service/screen-size.service";

@Component({
  selector: "app-addon-card",
  templateUrl: "./addon-card.component.html",
  styleUrls: ["./addon-card.component.scss"],
})
export class AddonCardComponent implements OnInit {
  @Input() img: string = "";
  @Input() class: string = "";
  @Input() title: string = "";
  @Input() label: boolean = false;
  @Input() code: string = "";
  @Input() includedAddOn: boolean = false;
  @Input() tooltip: string | null = "";
  @Input() desc: string = "";
  @Input() price: any = 0;

  @Input() selected: boolean = false;
  @Input() addOnSelected: boolean = false;
  @Input() isRoadtaxPromo?: boolean = false;
  @Input() roadtaxPromoPrice?: number = 0;

  @Output() toggleCheckbox = new EventEmitter();

  constructor(
    public translate: TranslateService,
    public screenSizeService: ScreenSizeService,
  ) {}

  public get showRHBRibbon() {
    return this.code == "MPA";
  }

  public get isFreeRoadtax() {
    return this.roadtaxPromoPrice == 0;
  }

  // Fallback for null or undefined tooltip
  get processedTooltip(): string {
    return this.tooltip || "";
  }

  ngOnInit(): void {
    this.screenSizeService.notifyResize();
    window.addEventListener("resize", () =>
      this.screenSizeService.notifyResize(),
    );
  }

  toggleAddOn(e: any) {
    this.toggleCheckbox.emit(e);
  }
}
