<div>
    <div class="section-title">
        <app-section-title
            title="excess.sectionTitle"
            [longTitle]="true"
        ></app-section-title>
    </div>
    <div class="excess-card-container" *ngIf="screenWidth >= 600">
        <div class="excess-card" *ngFor="let x of data" [ngClass]="isExcessSelected(x) ? 'selected' : ''"  (click)="switchExcess(x)">
            <ng-container *ngIf="isMaxSaving(x)">
                <div class="excess-ribbon">
                    <img src="https://storage.googleapis.com/dev-ps-asset/Car_Insurance/icons/max-savings-ribbon-2.png" alt="">
                </div>
                <div class="excess-ribbon-text">
                    {{ "excess.excessCardRibbon" | translate }}
                </div>
            </ng-container>
            <div class="save-text">{{ "excess." + x.name + ".saveTitle" | translate }}</div>
            <div class="excess-details">
                <div class="title">
                    {{ "excess." + x.name + ".contentTitle" | translate }}
                </div>
                <div class="desc">
                    {{ "excess." + x.name + ".contentDesc" | translate  }}
                </div>
            </div>
            <div class="excess-upon-text">
                {{ "excess.excessUponClaim" | translate }} <br /> RM {{ getExcessAmount(x) | number: '1.2-2' }}
            </div>
    
            <div class="btn-container">
                <button 
                    mat-flat-button 
                    class="excess-btn"
                    [ngClass]="isExcessSelected(x) ? 'selected' : ''"
                    (click)="switchExcess(x)"
                >
                    <span *ngIf="isExcessSelected(x)">{{ "excess.selected" | translate }}</span>
                    <span *ngIf="!isExcessSelected(x)">{{ "excess.select" | translate }}</span>
                </button>
            </div>
        </div>
        <div class="floating-strip">
            <div class="title">
                {{ "excess.totalSumInsured" | translate }} RM {{ quotationData?.sumInsured | number: '1.2-2' }}
            </div>
            <div class="desc">
                <img src="https://storage.googleapis.com/dev-ps-asset/Car_Insurance/icons/tick-icon.png" alt="" />
                {{ "excess.coverageIncludes" | translate }}
            </div>
        </div>
    </div>

    <div class="excess-card-container-mobile" *ngIf="screenWidth < 600">
        <div class="mobile-banner">
            <div class="content">
                <div class="content__title">
                    {{ "excess.totalSumInsured" | translate }} RM {{ quotationData?.sumInsured | number: '1.2-2' }}
                </div>
                <div class="content__flex">
                    <img src="https://storage.googleapis.com/dev-ps-asset/Car_Insurance/icons/tick-icon.png" alt="" />
                    <div>
                        {{ "excess.coverageIncludes" | translate }}
                    </div>
                </div>
            </div>
        </div>
        <div class="swiper-card-container">
            <swiper
                #swiper
                class="multiple"
                [pagination]="true"
                [pagination]="{ clickable: true }"
                [slidesPerView]="1.5"
                [slidesPerGroup]="1"
                [initialSlide]="1"
                [centeredSlides]="true"
                [grabCursor]="true"
                [spaceBetween]="20"
                [breakpoints]="{
                    '400': {
                        slidesPerView: 1.6
                    },
                    '450': {
                        slidesPerView: 1.7
                    },
                    '500': {
                        slidesPerView: 1.8
                    },
                    '550': {
                        slidesPerView: 1.9
                    },
                    '600': {
                        slidesPerView: 2
                    }
                }"
            >
                <ng-template
                    swiperSlide
                    *ngFor="let x of mobileData; index as i"
                >
                    <ng-container *ngTemplateOutlet="excessMobileCard; context: {data: x, index: i}">
                    </ng-container>
                </ng-template>
            </swiper>
        </div>
    </div>

    <div class="excess-faq-container">
        <mat-expansion-panel class="excess-expandable border-radius-top" [expanded]="false" #tab hideToggle>
            <mat-expansion-panel-header class="header-container">
                <div class="header">    
                    <div>
                        <div class="title">
                            {{ "excess.faq.q1" | translate }}
                        </div>
                        <div class="see-less" *ngIf="!tab.expanded">{{ "excess.seeMore" | translate }}</div>
                        <div class="see-less" *ngIf="tab.expanded">{{ "excess.seeLess" | translate }}</div>
                    </div>
                    <div>
                        <button mat-icon-button [disableRipple]="true">
                            <mat-icon *ngIf="!tab.expanded">add</mat-icon>
                            <mat-icon *ngIf="tab.expanded">remove</mat-icon>
                        </button>
                    </div>
                </div>
            </mat-expansion-panel-header>
            <div class="content-container">
                {{ "excess.faq.a1" | translate }}
                <img [src]="'https://storage.googleapis.com/dev-ps-asset/Car_Insurance/excess-graphic-1-' + translate.currentLang + '.png'" alt="">
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="excess-expandable border-radius-bottom" [expanded]="false" #tab2 hideToggle>
            <mat-expansion-panel-header class="header-container">
                <div class="header">    
                    <div>
                        <div class="title">
                            {{ "excess.faq.q2" | translate }}
                        </div>
                        <div class="see-less" *ngIf="!tab2.expanded">{{ "excess.seeMore" | translate }}</div>
                        <div class="see-less" *ngIf="tab2.expanded">{{ "excess.seeLess" | translate }}</div>
                    </div>
                    <div>
                        <button mat-icon-button [disableRipple]="true">
                            <mat-icon *ngIf="!tab2.expanded">add</mat-icon>
                            <mat-icon *ngIf="tab2.expanded">remove</mat-icon>
                          </button>
                    </div>
                </div>
            </mat-expansion-panel-header>
            <div class="content-container">
                {{ "excess.faq.a2" | translate }}
                <img [src]="'https://storage.googleapis.com/dev-ps-asset/Car_Insurance/excess-graphic-2-' + translate.currentLang + '.png'" alt="">
            </div>
        </mat-expansion-panel>
    </div>
</div>

<ng-template #excessMobileCard let-data="data" let-index="index">
    <div class="excess-card-mobile"  [ngClass]="{'max-savings': isMaxSaving(data), 'selected': isExcessSelected(data)}" (click)="switchExcess(data)">
        <ng-container *ngIf="isMaxSaving(data)">
            <div class="excess-ribbon">
                <img src="https://storage.googleapis.com/dev-ps-asset/Car_Insurance/icons/max-savings-ribbon-mobile.png" alt="">
            </div>
            <div class="excess-ribbon-text">
                {{ "excess.excessCardRibbon" | translate }}
            </div>
        </ng-container>
        <div class="header">
            <div class="header-title">
                {{ "excess." + data?.name + ".contentTitle" | translate }}
            </div>
        </div>
        <div class="content" [ngClass]="isExcessSelected(data) ? 'selected' : ''">
            <div class="headline">{{ "excess." + data?.name + ".saveTitle" | translate }}</div>
            <div class="content-desc">
                {{ "excess." + data?.name + ".contentDesc" | translate  }}
            </div>
            <div class="excess-amount">  {{ "excess.excessUponClaim" | translate }} RM {{ getExcessAmount(data) | number: '1.2-2' }}</div>
        </div>
    </div>
    <div class="select-btn-container" (click)="switchExcess(data)">
        <button class="select-btn" *ngIf="showButton(index)"  [ngClass]="isExcessSelected(data)? 'selected' : ''">
            <span *ngIf="isExcessSelected(data)">{{ "excess.selected" | translate }}</span>
            <span *ngIf="!isExcessSelected(data)">{{ "excess.select" | translate }}</span>
        </button>
    </div>
</ng-template>
