<div class="upload-mileage-dialog">
  <div class="upload-mileage-dialog__title" translate>{{ 'uploadMileage.dialogTitle' }}</div>

  <div class="upload-mileage-dialog__description" translate>{{ 'uploadMileage.reuploadDescription' }}</div>

  <div class="upload-mileage-dialog__preview" *ngIf="preview">
    <iframe *ngIf="isPdf" [src]="preview" width="100%" height="100%"></iframe>
    <img *ngIf="!isPdf" [alt]="file.name" [src]="preview">
  </div>

  <div class="upload-mileage-dialog__actions">
    <button
      class="secondary-btn"
      [mat-dialog-close]="true"
      mat-flat-button>
      {{ "uploadMileage.reupload" | translate }}
    </button>

    <button
      class="white-btn"
      mat-dialog-close
      mat-flat-button>
      {{ "uploadMileage.close" | translate }}
    </button>
  </div>
</div>
