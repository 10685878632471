import {Component, Input} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-sompo-benefit',
  templateUrl: './sompo-benefit.component.html',
  styleUrls: ['./sompo-benefit.component.scss', '../benefit.component.scss']
})
export class SompoBenefitComponent {
  @Input() isFirst = false;

  constructor(private translate: TranslateService) {
  }

  isLangEn(): boolean {
    return this.translate.currentLang === 'en'
  };
}
