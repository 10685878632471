import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, NavigationExtras } from '@angular/router';
import { PartnerService } from '../share/service/partner.service';

@Injectable({
  providedIn: 'root'
})
export class AffiliatesGuard implements CanActivate {
    constructor(
      private router: Router,
      private partnerService: PartnerService
      ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
      
    const paramsPartnerCode = route.queryParams.partnerCode;
    const partnerCode = this.partnerService.getPartnerCode();
    
    if (!paramsPartnerCode && !partnerCode) {
      return Promise.resolve(true);
    } else {
      const queryParams = paramsPartnerCode ?? partnerCode
        ? { partnerCode: paramsPartnerCode }
        : undefined;

      const navigationExtras: NavigationExtras = {
        queryParams,
      };

      return this.router.navigate(['/affiliates'], navigationExtras);
    }
  }
}
