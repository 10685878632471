<div
  class="insurer-list"
  [ngClass]="isProductCheapest ? 'cheapest' : 'insurer-list'"
>
  <div *ngIf="isProductCheapest">
    <img class="banner-2" src="assets/image/cheapest-banner-2.png" alt="" />
    <div class="banner-text-2">
      {{ "common.cheapest" | translate }} <br />
      <span style="font-weight: 400; font-size: 10px; color: #fff">
        {{ "common.inMalaysia" | translate }}
      </span>
    </div>
  </div>
  <div class="insurer-content">
    <div class="insurer-logo">
      <img [src]="item.image" />
    </div>
    <div class="insurer-details">
      <div style="display: flex; justify-content: space-between">
        <div>
          <div class="label">
            {{ "common.sumInsured" | translate }} ({{
              "common." + item?.typeOfSum | translate
            }}):
          </div>
          <div class="value">RM {{ item?.sumInsured | number : "1.2-2" }}</div>
        </div>
        <button
          mat-stroked-button
          class="benefit-btn"
          (click)="benefitDialog(item)"
        >
          {{ "comparison.viewBenefit" | translate }}
        </button>
      </div>

      <div fxLayout="row" class="mt-sm">
        <app-insurer-details
          [code]="item.code"
          [first]="first"
          [insuranceType]="insuranceType"
          class="full-width"
          *ngIf="insuranceType !== 'TPFT' || item.code === 'Zurich'"
        >
        </app-insurer-details>
      </div>
    </div>
    <div class="insurer-select">
      <div class="insurer-amount">
        <div *ngIf="!item?.rebate">
          <span>RM</span>{{ getTotalBasePremium() | number : "1.2-2" }}
        </div>
        <div *ngIf="item?.rebate">
          <span>RM</span
          >{{ getAfterRebateTotalBasePremium() | number : "1.2-2" }}
        </div>
      </div>
      <span *ngIf="item?.rebate">
        {{ "common.priceBeforeRebate" | translate }} :
        <s style="color: red"
          ><span style="color: black"
            >RM{{ getTotalBasePremium() | number : "1.2-2" }}</span
          ></s
        >
        <div class="rebate-notice">
          <img src="assets/image/rebate-crown-icon.png" />
          {{ "common.includedRebate" | translate }}
          <b>RM{{ item?.rebate | number : "1.2-2" }}</b>
        </div>
      </span>
      <!-- ## SPLIT TAG -->
      <!-- <div class="insurer-instalment">
                {{ "common.pay" | translate }}
                <b>RM{{ item.total / 3 | number : "1.2-2" }}</b>
                {{ "common.everyMonth" | translate }} <br />{{
                    "common.duration" | translate
                }}!
            </div> -->
      <!-- ## END SPLIT TAG -->
      <!-- <button
                mat-flat-button
                class="buy-btn"
                (click)="selectQuotation(item.id)"
                [disabled]="isLoadingBundleData"
            >
                {{ "comparison.buyNow" | translate }}
            </button> -->
      <button
        mat-flat-button
        class="buy-btn"
        (click)="openCarVariantDialog(item.id)"
      >
        {{ "comparison.buyNow" | translate }}
      </button>
    </div>
  </div>
</div>

<div
  class="mobile-insurer-list-2"
  [ngClass]="isProductCheapest ? 'cheapest' : ''"
>
  <img
    *ngIf="isProductCheapest"
    src="assets/image/cheapest-banner.png"
    alt=""
    class="banner-2"
  />
  <div class="rebate-notice" *ngIf="item?.rebate">
    <img src="assets/image/rebate-crown-icon.png" />
    {{ "common.includedRebate" | translate }}
    <b>RM{{ item?.rebate | number : "1.2-2" }}</b>
  </div>
  <div class="info-section">
    <div class="insurer-logo">
      <img [src]="item?.image" />
    </div>

    <div class="price">
      <div *ngIf="!item?.rebate">
        <span>RM</span>{{ getTotalBasePremium() | number : "1.2-2" }}
      </div>
      <div *ngIf="item?.rebate">
        <span>RM</span>{{ getAfterRebateTotalBasePremium() | number : "1.2-2" }}
      </div>
      <div class="price_slash" *ngIf="item?.rebate">
        {{ "common.priceBeforeRebate" | translate }} :
        <s style="color: red"
          ><span style="color: black"
            >RM{{ getTotalBasePremium() | number : "1.2-2" }}</span
          ></s
        >
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="insurer-details">
    <div class="benefit">
      <div>
        <div class="label">
          {{ "common.sumInsured" | translate }} ({{
            "common." + item?.typeOfSum | translate
          }}):
        </div>
        <div class="value">RM {{ item?.sumInsured | number : "1.2-2" }}</div>
      </div>
      <button
        mat-stroked-button
        class="benefit-btn"
        (click)="benefitDialog(item)"
      >
        {{ "comparison.viewBenefit" | translate }}
      </button>
    </div>

    <app-insurer-details
      [code]="item.code"
      [first]="first"
      [insuranceType]="insuranceType"
      class="full-width"
      *ngIf="insuranceType !== 'TPFT' || item.code === 'Zurich'"
    >
    </app-insurer-details>
    <!-- <app-insurer-details
      [code]="item?.code"
      [first]="first"
      [insuranceType]="insuranceType"
      fxFlex
      *ngIf="insuranceType !== 'TPFT' || item.code === 'Zurich'"
    ></app-insurer-details> -->
  </div>
  <div class="price-section" [ngClass]="first ? 'cheapest' : ''">
    <div fxLayout="row">
      <div style="flex: 1">
        <button
          mat-flat-button
          class="buy-btn"
          (click)="openCarVariantDialog(item?.id)"
        >
          {{ "comparison.buyNow" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
