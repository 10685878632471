import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-rhb-benefit',
  templateUrl: './rhb-benefit.component.html',
  styleUrls: ['./rhb-benefit.component.scss', '../benefit.component.scss']
})
export class RhbBenefitComponent {
  @Input() isFirst = false;
}
