<footer class="ktmb-footer">
  <div class="ftr__first">
    <div class="ftr__column">
      <div class="ftr__custom__logo">
        <img alt="KTMB Logo" src="/assets/image/ktmb/ktmb-logo-footer.png" />
      </div>
      <div class="ftr__address">{{ info.address.value }}</div>
      <div class="mt-md ftr__phone">
        <img
          alt=""
          class="ftr__icon"
          src="/assets/icons/footer/whatsapp-icon.png"
        />
        <span> {{ info.mobile.value }} </span>
      </div>
      <div class="ftr__email">
        <img
          alt=""
          class="ftr__icon"
          src="/assets/icons/footer/email-fill.png"
        />
        <span> {{ info.email.value }} </span>
      </div>
    </div>
  </div>

  <ng-content></ng-content>
</footer>
