import { Component, Inject, OnDestroy } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import * as moment from "moment";
import { SharedDataService } from "../../data-service/shared.data.service";
import { Subscription } from "rxjs";

@Component({
	selector: "app-before-expired-dialog",
	templateUrl: "./before-expired-dialog.component.html",
	styleUrls: ["./before-expired-dialog.component.css"],
})
export class BeforeExpiredDialogComponent implements OnDestroy {
	sharedSubscription: Subscription;
	customerInput: {
		vehicleNo: string;
		eHailingUsed: boolean;
		isMalaysian: boolean;
		isCompany: boolean;
		identityNo: string;
		postcode: string;
		policIdentityNo: string;
		name: string;
		nationality: string;
		dob: string;
		gender: string;
		maritalStatus: string;
		mobileNo: string;
		email: string;
	};
	day: number;
	expiryDate: string;
	constructor(
		private dialogRef: MatDialogRef<BeforeExpiredDialogComponent>,
		private sharedDataService: SharedDataService,
		@Inject(MAT_DIALOG_DATA) data: { expiryDate: string; customerInput: any },
	) {
		this.customerInput = data.customerInput;
		this.day = moment(data.expiryDate).diff(moment(), "day");
		this.expiryDate = data.expiryDate;
	}

	handleSubmitReminder() {
    /*
     * As of 10/07/24, following a discussion with Winter, the Slack notification feature has been deactivated.
     * Reason: The Slack channel is not actively used or connected by any team members.
     * Note: The related code is commented out rather than removed, in case needed in the future.
    */
    this.dialogRef.close(true);

		// this.sharedSubscription = this.sharedDataService
		// 	.postSlackReminder({
		// 		text: `*[Too Early - ${moment(new Date())}]*\n\n
		// 		\n Expiry  date: *${moment(this.expiryDate).format("DD/MM/yyyy")}*
		// 		\n Expiry month & Year: *${moment(this.expiryDate).format("MMMM - yyyy")}*
		// 		\n Vehicle number: *${this.customerInput.vehicleNo}*
		// 		\n E-hailing: *${this.customerInput.eHailingUsed}*
		// 		\n Name: *${this.customerInput.name}*
		// 		\n Malaysian: *${this.customerInput.isMalaysian}*
		// 		\n Company: *${this.customerInput.isCompany}*
		// 		\n Identity No.: *${this.customerInput.identityNo}*
		// 		\n Police/Army Identity No.: *${this.customerInput.policIdentityNo}*
		// 		\n Nationality: *${this.customerInput.nationality}*
		// 		\n DoB: *${this.customerInput.dob}*
		// 		\n Gender: *${this.customerInput.gender}*
		// 		\n Marital status: *${this.customerInput.maritalStatus}*
		// 		\n Mobile number: *${this.customerInput.mobileNo}*
		// 		\n Email: *${this.customerInput.email}*
		// 		\n Postcode: *${this.customerInput.postcode}*`,
		// 	})
		// 	.subscribe({
		// 		next: (x) => {
		// 			this.dialogRef.close(true);
		// 		},
		// 		error: (err) => {},
		// 	});
	}

	ngOnDestroy(): void {
		this.sharedSubscription.unsubscribe();
	}
}
