<div class="submitted">
	<div class="submitted__content card-background">
		<div class="submitted__header">
			<div class="submitted__header__img">
				<img
					src="../../../../assets/image/endorsement-submitted.png"
					width="40px"
					alt=""
				/>
			</div>
			<div class="submitted__header__title">
				{{ "endorsement.requestSubmitted" | translate }}
			</div>
		</div>
		<div class="submitted__body">
			<div class="submitted__body__row">
				<div class="submitted__body__title">
					{{ "common.carPlateNo" | translate }}
				</div>
				<div class="submitted__body__value">
					{{ endorsementSummaryPage.vehicleNo }}
				</div>
			</div>
			<div class="submitted__body__row">
				<div class="submitted__body__title">
					<ng-container [ngSwitch]="idType">
						<ng-container *ngSwitchCase="'Malaysian'">{{
							"common.icNo" | translate
						}}</ng-container>
						<ng-container *ngSwitchCase="'Company'">{{
							"common.businessRegistrationNo" | translate
						}}</ng-container>
						<ng-container *ngSwitchCase="'Foreigner'">{{
							"common.passportLabel" | translate
						}}</ng-container>
						<ng-container *ngSwitchCase="'Police/Army'"
							>{{ "common.icNo" | translate }}</ng-container
						>
					</ng-container>
				</div>
				<div class="submitted__body__value">
					{{ endorsementSummaryPage.identityNo }}
				</div>
			</div>
			<div class="submitted__body__row">
				<div class="submitted__body__title">
					{{ "common.namePerIc" | translate }}
				</div>
				<div class="submitted__body__value">
					{{ endorsementSummaryPage.name }}
				</div>
			</div>
			<div class="submitted__body__row">
				<div class="submitted__body__title">
					{{ "common.email" | translate }}
				</div>
				<div class="submitted__body__value">
					{{ endorsementSummaryPage.email }}
				</div>
			</div>
			<div class="submitted__body__row">
				<div class="submitted__body__title">
					{{ "common.mobileNo" | translate }}
				</div>
				<div class="submitted__body__value">
					{{ endorsementSummaryPage.mobileNo }}
				</div>
			</div>
			<div class="submitted__body__row">
				<div class="submitted__body__title">
					{{ "endorsement.roadtaxStatus" | translate }}
				</div>
				<div class="submitted__body__value">
					{{ endorsementSummaryPage.roadtaxStatus }}
				</div>
			</div>
			<div class="submitted__body__row">
				<div class="submitted__body__title">
					{{ "endorsement.endorsementType" | translate }}
				</div>
				<div class="submitted__body__value">
					{{ endorsementSummaryPage.endorsementTypeName }}
				</div>
			</div>
		</div>
	</div>
	<div class="submitted__footer">
		{{ "endorsement.thanksForYour" | translate }}
	</div>
	<button class="btn--yellow" (click)="onHomepage()" mat-flat-button>
		{{ "response.backToHome" | translate }}
	</button>
</div>
