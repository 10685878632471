import { Type } from "@angular/compiler/src/core";
import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { MotorDataDataService } from "src/app/share/data-service/motor.data.service";
import { AlertService } from "src/app/share/service/alert.service";
import { ThankyouDialogComponent } from "../thankyou-dialog/thankyou-dialog.component";
import { SampleDialogComponent } from "./sample-dialog/sample-dialog.component";

@Component({
	selector: "app-upload-documents",
	templateUrl: "./upload-documents.component.html",
	styleUrls: ["./upload-documents.component.scss"],
})
export class UploadDocumentsComponent {
	fileToUpload: any[] = [];

	file: any = {};

	id: string;

	errorActivatedPolicy: boolean = false;

	constructor(
		private dialog: MatDialog,
		private motorDataDataService: MotorDataDataService,
		private activatedRoute: ActivatedRoute,
		private alertService: AlertService,
		private ngxSpinnerService: NgxSpinnerService,
	) {
		this.id = this.activatedRoute.snapshot.params.id;
		this.getData();
	}

	private getData() {
		// this.ngxSpinnerService.show();
		this.motorDataDataService.getPolicyPhotos(this.id).subscribe({
			next: (data: any) => {
				data.forEach((data) => {
					this.file[data.result.refType] = data.result;
				});
				this.ngxSpinnerService.hide();
			},
			error: (err) => {
				// window.location.href = "/";
			},
		});
	}

	showSampleDialog(data: string) {
		this.dialog.open(SampleDialogComponent, {
			data: data,
			panelClass: "success-dialog",
		});
	}

	deleteFile(file, id?: string) {
		if (id){
			this.motorDataDataService.deletePolicyPhoto(id).subscribe({
				next: (x: any) => {
				},
				error: (err) => {
					this.alertService.openSnackBar(err.error);
				},
			});
		}

		this.file[file] = "";
		this.fileToUpload = this.fileToUpload.filter((value) => value.type != file);
	}

	handleSubmit() {
		if (this.fileToUpload) {
			let fd = new FormData();
			for (let i = 0; i < this.fileToUpload.length; i++) {
				fd.append(`fileList[${i}].file`, this.fileToUpload[i].file);
				fd.append(`fileList[${i}].type`, this.fileToUpload[i].type);
			}

			this.motorDataDataService.uploadPhoto(this.id, fd).subscribe({
				next: (x: any) => {
					this.fileToUpload = [];
					this.getData();
					this.dialog.open(ThankyouDialogComponent, {
						panelClass: "success-dialog",
						data: {
							title: "Thank you",
							body: "uploadDocs.thankyouMsg",
						},
					});
				},
				error: (err) => {
					this.alertService.openSnackBar(err.error);
				},
			});
		}
	}

	handleFileInput(files, type) {
		this.fileToUpload.push({
			type: type,
			file: files.target.files[0],
		});

		this.file[type] = files.target.files[0].name;
		files.target.value = null;
	}
}
