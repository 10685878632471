import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
	providedIn: "root",
})
export class SharedDataService {
	constructor(private http: HttpClient) {}

	postSlackReminder(data: any) {
		const headers = new HttpHeaders().set(
			"Content-type",
			"application/x-www-form-urlencoded; charset=UTF-8",
		);
		const body = { text: data.text };
		const url =
			"https://hooks.slack.com/services/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";
		return this.http.post<any>(url, body, {
			headers,
			responseType: "text" as "json",
		});
	}
}
