<div class="remind-container">
  <div class="header">
    <img src="/assets/image/reminder.png" alt="Remind Icon">

    <div class="title" translate>{{ 'remindMe.title' }}</div>
  </div>

  <div class="content" translate>
    {{ 'remindMe.description' }}
    <br />
    <br />
    {{ 'remindMe.reminderDetail' }}
  </div>

  <div class="actions">
    <button matRipple mat-dialog-close translate>{{ 'remindMe.closeButton' }}</button>
  </div>
</div>
