<ng-container *ngTemplateOutlet="summaryMode ? summary : upload"></ng-container>

<ng-template #summary>
  <div class="reupload-mileage__description" translate>{{ "uploadMileage.reuploadDescription" }}</div>

  <div class="reupload-mileage__file-list">
    <div *ngFor="let file of fileControl.value; let i = index" class="reupload-mileage__file-row">
      <div class="upload-mileage__file-item">
        <span (click)="viewFile(i)" class="upload-mileage__file-name">{{ file.name }}</span>
      </div>

      <button (click)="onReupload(i)" class="reupload-mileage__file-button" matRipple
              translate>{{ "uploadMileage.reupload" }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #upload>
  <div class="upload-mileage">
    <div class="upload-mileage__primary">
      <img alt="Meter icon" src="/assets/icons/meter.svg">

      <div class="upload-mileage__text">
        <div class="upload-mileage-dialog__title" translate>{{ 'uploadMileage.title' }}</div>
        <div class="upload-mileage__description" translate>{{ 'uploadMileage.description' }}</div>
      </div>

      <button (click)="onUpload()" [disabled]="isUploadDisabled()" class="upload-mileage__button" matRipple
              type="button">
        <img alt="Add circle icon" src="/assets/icons/add_circle.svg">
        <span translate>{{ 'common.upload' }}</span>
      </button>
    </div>

    <div *ngIf="fileControl.value.length" class="upload-mileage__file-list">
      <div *ngFor="let file of fileControl.value; let i = index" class="upload-mileage__file-item">
        <span (click)="viewFile(i)" class="upload-mileage__file-name">{{ file.name }}</span>
        <div (click)="removeImage(i)" class="upload-mileage__file-remove">
          <img alt="Close icon" src="/assets/icons/close.svg"/>
        </div>
      </div>
    </div>
  </div>

  <div class="upload-mileage__hint">
    <span translate>{{ 'uploadMileage.supportedFormats' }}</span>
    <span translate>{{ 'uploadMileage.maxFileSize' }}</span>
  </div>
</ng-template>

<input #fileInput (change)="onFileChange($event)" (click)="fileInput.value = null"
       accept="image/jpeg,image/png,application/pdf" class="upload-mileage__input"
       multiple
       type="file"/>
