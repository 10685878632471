import { Component, Input } from "@angular/core";

@Component({
	selector: "app-benefit",
	templateUrl: "./benefit.component.html",
	styleUrls: ["./benefit.component.scss"],
})
export class BenefitComponent {
  @Input() partnerCode = "";
	_code: string;
	_insuranceType: string;

	get code(): string {
		return this._code;
	}
	@Input() set code(value: string) {
		this._code = value;
	}

	get insuranceType(): string {
		return this._insuranceType;
	}
	@Input() set insuranceType(value: string) {
		this._insuranceType = value;
	}
}
