import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { MotorDataDataService } from 'src/app/share/data-service/motor.data.service';
import {
  MotorRequestInterface,
  VehicleListingInterface,
} from 'src/app/share/interface/motor.interface';
import { AlertService } from 'src/app/share/service/alert.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-edit-quotation',
  templateUrl: './edit-quotation.component.html',
  styleUrls: ['./edit-quotation.component.scss'],
})
export class EditQuotationComponent implements OnInit {
  frmGroup: FormGroup;
  vehicleList: VehicleListingInterface[] = [];
  limit: number = 20;
  offset: number = 1;
  isCompleted: boolean = false;

  searchChange: Subject<void> = new Subject<void>();
  searchText: string;

  selectVehicle: boolean = false;

  isLoading: boolean = false;
  firstInit: boolean = true;

  constructor(
    private ngxSpinnerService: NgxSpinnerService,
    private motorDataDataService: MotorDataDataService,
    private alertService: AlertService,
    public dialogRef: MatDialogRef<EditQuotationComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      id: string;
      request: MotorRequestInterface;
    }
  ) {}

  ngOnInit(): void {
    this.getVehicleListing();
    this.initForm();
  }

  private initForm() {
    this.frmGroup = new FormGroup({
      vehicleId: new FormControl(null),
    });
  }

  private getVehicleListing() {
    this.isLoading = true;
    this.motorDataDataService
      .getVehicleListing(
        this.limit,
        this.offset,
        this.searchText,
        this.data.request.make
      )
      .subscribe({
        next: (x) => {
          this.isCompleted = x.length < this.limit;
          this.vehicleList = this.vehicleList.concat(x);
          this.isLoading = false;
          let vehicleId: string;
          if (this.firstInit) {
            if (this.vehicleList.length != 0) {
              vehicleId = this.vehicleList[0].id;
              this.frmGroup.controls.vehicleId.setValue(vehicleId);
            }
            this.firstInit = false;
          }
        },
      });
  }

  filterVehicle() {
    if (!this.selectVehicle) this.searchChange.next();
    else this.selectVehicle = false;
  }

  getNextBatch() {
    if (!this.isCompleted) {
      this.offset++;
      this.getVehicleListing();
    }
  }

  clearVehicle() {
    event.stopImmediatePropagation();
    this.frmGroup.controls.vehicleId.setValue(null);
  }

  isDisabledSubmit() {
    if (this.frmGroup.invalid || !this.frmGroup.dirty) {
      return true;
    } else return false;
  }

  save() {
    if (this.frmGroup.invalid) return;

    this.ngxSpinnerService.show();
    let value = Object.assign({}, this.frmGroup.value);
    this.motorDataDataService.editRequest(this.data.id, value).subscribe({
      next: () => {
        this.ngxSpinnerService.hide();
        this.dialogRef.close(true);
      },
      error: (err) => {
        this.ngxSpinnerService.hide();
        this.alertService.openSnackBar(err.error);
      },
    });
  }
}
