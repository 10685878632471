// Angular Core
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
// Forms 
import { FormControl, FormGroup, NgForm, Validators } from "@angular/forms";
// Angular Material
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from "@angular/material/bottom-sheet";
import { MatSelectChange } from "@angular/material/select";
// Service 
import { AlertService } from "../../../share/service/alert.service";
import { MasterDataDataService } from "../../../share/data-service/master-data.data.service";
import { TranslateService } from "@ngx-translate/core";
// Utility
import { ValidatorFunction } from "../../../share/function/validator.function";
import * as moment from "moment";

@Component({
	selector: "app-customer-details-bottomsheet",
	templateUrl: "./customer-details-bottomsheet.component.html",
	styleUrls: ["./customer-details-bottomsheet.component.scss"],
})
export class CustomerDetailsBottomsheetComponent implements OnInit {
	partnerCode: string;
	currentLang: string;

	vehicleFormGroup: FormGroup;
	custFormGroup: FormGroup;
	@ViewChild("vehicleForm") vehicleForm: NgForm;
	@ViewChild("custForm") custForm: NgForm;

	countryList: string[] = [];
	filterCountryList: string[] = [];
	isCompany: boolean = false;
	isMalaysian: boolean = true;
	pdpaAgreement: boolean = false;
	clickedNext: boolean = false;
	isLoading: boolean = false;
	showSuccessGif: boolean = false;
	eHailingChecked: boolean = false;
	mobileView: boolean = false;

	idType: Number = 1;
	utmCampaign: string;
	utmSource: string;
	utmMedium: string;
	utmContent: string;
	maxDob: Date = new Date();
	contactInfo: string;
	successGifType: string;

	idTypeList = [
		{ id: 1, name: "common.icNo" },
		{ id: 2, name: "common.foreigner" },
		{ id: 3, name: "common.company" },
	];

	typeOfInsuranceList = [
		// { id: "TP", name: "common.thirdPartyOnlyIns" },
		{ id: "TPFT", name: "common.TPFT" },
		{ id: "COM", name: "common.COM" },
	];

	showWhatsappBtn: Boolean = true;
	hideWhatsappBtnList: string[] = ["wapcar", "carsome", "carlist"];

	constructor(
		@Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData: any,
		public translate: TranslateService,
		private masterDataDataService: MasterDataDataService,
		private alertService: AlertService,
		public bottomSheetRef: MatBottomSheetRef<CustomerDetailsBottomsheetComponent>,
	) {
		this.initForm();
	}

	ngOnInit(): void {}

	private initForm() {
		this.vehicleFormGroup = new FormGroup({
			insuranceType: new FormControl("COM", Validators.required),
			carPlate: new FormControl(null, Validators.required),
			// postcode: new FormControl(null), // affiliate
			insuredType: new FormControl(null, Validators.required),
			includeRoadTax: new FormControl(false),
			eHailingUsed: new FormControl(false),

			// Only for PS D2C
			psIdentityType: new FormControl("Malaysian", Validators.required),
			psIdentityNo: new FormControl(null),
			psPolicIdentityNo: new FormControl(null),
		});

		this.custFormGroup = new FormGroup({
			// identityNo: new FormControl(null, Validators.required), // affiliate
			name: new FormControl(null),
			// policIdentityNo: new FormControl(null), // affiliate
			nationality: new FormControl(null, Validators.required),
			dob: new FormControl(),
			gender: new FormControl(null),
			maritalStatus: new FormControl(null, Validators.required),
			contactMethod: new FormControl(null),
			mobileNo: new FormControl(null, Validators.required),
			email: new FormControl(null, [Validators.required, Validators.email]),

			// to display the checkbox at customer form section
			eHailingUsed: new FormControl(false),

			// Only for PS D2C
			psPostCode: new FormControl(null),
		});

		this.vehicleFormGroup.controls.insuranceType.setValue("COM");
		this.vehicleFormGroup.controls.insuredType.setValue("MV");
		this.custFormGroup.controls.maritalStatus.setValue("Single");
		this.custFormGroup.controls.contactMethod.setValue("Email");

		if (this.partnerCode !== "ps") {
			// this.setMalaysian();
			// this.vehicleFormGroup.controls.postcode.setValidators([
			// 	Validators.required,
			// ]);
			// this.vehicleFormGroup.controls.postcode.updateValueAndValidity();
		} else {
			// Validations for d2c
			this.setPSMalaysian();
			this.setPSStep2Validation();
		}

		// Get dob and gender on Affiliate
		// this.custFormGroup.controls.identityNo.valueChanges.subscribe((value) => {
		// 	if (
		// 		this.custFormGroup.controls.identityNo.valid &&
		// 		this.isMalaysian &&
		// 		!this.isCompany
		// 	) {
		// 		this.getGenderAndDate(value);
		// 	}
		// });

		// set dob and gender on PS
		this.vehicleFormGroup.controls.psIdentityNo.valueChanges.subscribe(
			(value) => {
				if (
					this.vehicleFormGroup.controls.psIdentityNo.valid &&
					this.isMalaysian &&
					!this.isCompany
				) {
					this.getGenderAndDate(value);
				}
			},
		);

		// set validations for PS ID types
		this.vehicleFormGroup.controls.psIdentityType.valueChanges.subscribe(
			(value) => {
				if (value == "Police/Army") {
					this.setPSPoliceArmy();
				}
				if (value == "Malaysian") {
					this.setPSMalaysian();
				} else if (value == "Company") {
					this.setPSIsCompany();
				} else if (value == "Foreigner") {
					this.setPSNonMalaysian();
				}
			},
		);

		//set data from renew component
		const custDetails = this.bottomSheetData.custDetails;
		const vehicleFormGroup = this.vehicleFormGroup;
		const custFormGroup = this.custFormGroup;
	  
		vehicleFormGroup.controls.psIdentityType.setValue(this.bottomSheetData.idType);
		vehicleFormGroup.controls.carPlate.setValue(custDetails.vehicleNo);
		vehicleFormGroup.controls.psIdentityNo.setValue(custDetails.identityNo);
		vehicleFormGroup.controls.psPolicIdentityNo.setValue(custDetails.policeIdentityNo);
		vehicleFormGroup.controls.eHailingUsed.setValue(custDetails.eHailingUsed);
		vehicleFormGroup.controls.includeRoadTax.setValue(custDetails.includeRoadTax);
	  
		custFormGroup.controls.eHailingUsed.setValue(custDetails.eHailingUsed);
		this.eHailingChecked = custDetails.eHailingUsed;
		custFormGroup.controls.name.setValue(custDetails.name);
		custFormGroup.controls.psPostCode.setValue(custDetails.postcode);
		custFormGroup.controls.mobileNo.setValue(custDetails.mobileNo);
		custFormGroup.controls.email.setValue(custDetails.email);
		custFormGroup.controls.maritalStatus.setValue(custDetails.maritalStatus);
		custFormGroup.controls.contactMethod.setValue(custDetails.preferredChannel);
		custFormGroup.controls.gender.setValue(custDetails.gender);
		custFormGroup.controls.dob.setValue(custDetails.dob);
		custFormGroup.controls.nationality.setValue(custDetails.nationality);
	}

	private getGenderAndDate(value) {
		let date = moment(value.substring(0, 6), "YYMMDD");
		if (moment().isBefore(date)) {
			date.subtract(100, "year");
		}
		let lastChar = value.substring(11);
		this.custFormGroup.controls.dob.setValue(date.toDate());
		let gender = lastChar % 2 ? "M" : "F";
		this.custFormGroup.controls.gender.setValue(gender);
	}

	private setPSStep2Validation() {
		// this.custFormGroup.controls.identityNo.setValidators([]);
		// this.custFormGroup.controls.identityNo.updateValueAndValidity();

		this.custFormGroup.controls.psPostCode.setValidators([Validators.required]);
		this.custFormGroup.controls.psPostCode.updateValueAndValidity();
	}

	private setNonCompanyValidation() {
		this.custFormGroup.controls.dob.setValidators([Validators.required]);
		this.custFormGroup.controls.gender.setValidators([Validators.required]);
		this.custFormGroup.controls.maritalStatus.setValidators([
			Validators.required,
		]);

		this.custFormGroup.controls.dob.updateValueAndValidity();
		this.custFormGroup.controls.gender.updateValueAndValidity();
		this.custFormGroup.controls.maritalStatus.updateValueAndValidity();
	}

	private setCompanyValidation() {
		this.custFormGroup.controls.dob.setValidators([]);
		this.custFormGroup.controls.gender.setValidators([]);
		this.custFormGroup.controls.maritalStatus.setValidators([]);
		this.custFormGroup.controls.nationality.setValidators([]);

		this.custFormGroup.controls.dob.updateValueAndValidity();
		this.custFormGroup.controls.gender.updateValueAndValidity();
		this.custFormGroup.controls.maritalStatus.updateValueAndValidity();
		this.custFormGroup.controls.nationality.updateValueAndValidity();
	}

	private getCountryList() {
		if (this.countryList.length != 0) {
			this.filterCountry();
			return;
		}
		this.masterDataDataService.getCountrySelection().subscribe({
			next: (x) => {
				this.countryList = x;
				this.filterCountry();
			},
			error: (err) => {
				this.alertService.openSnackBar(err.error);
			},
		});
	}

	private resetForm() {
		this.pdpaAgreement = false;
		this.vehicleFormGroup.reset();
		// this.vehicleForm.resetForm();
		this.custFormGroup.reset();
		// this.custForm.resetForm();

		this.custFormGroup.controls.mobileNo.setValue(" ");
		this.custFormGroup.controls.mobileNo.updateValueAndValidity();
		this.initForm();
	}

	private setMalaysian() {
		this.isCompany = false;
		this.isMalaysian = true;
		this.custFormGroup.controls.identityNo.setValue(null);
		this.custFormGroup.controls.identityNo.setValidators([
			Validators.required,
			ValidatorFunction.nricValidator(),
		]);
		this.custFormGroup.controls.dob.disable();
		this.custFormGroup.controls.gender.disable();
		this.custFormGroup.controls.identityNo.updateValueAndValidity();
		this.custFormGroup.controls.nationality.setValidators([]);
		this.custFormGroup.controls.nationality.updateValueAndValidity();

		this.setNonCompanyValidation();
	}

	private setPSMalaysian() {
		this.isCompany = false;
		this.isMalaysian = true;

		this.vehicleFormGroup.controls.psIdentityNo.setValidators([
			Validators.required,
			ValidatorFunction.nricValidator(),
		]);
		this.vehicleFormGroup.controls.psIdentityNo.updateValueAndValidity();
		this.vehicleFormGroup.controls.psPolicIdentityNo.setValidators([]);
		this.vehicleFormGroup.controls.psPolicIdentityNo.updateValueAndValidity();
		this.custFormGroup.controls.nationality.setValidators([]);
		this.custFormGroup.controls.nationality.updateValueAndValidity();

		this.setNonCompanyValidation();
	}

	private setNonMalaysian() {
		this.isCompany = false;
		this.isMalaysian = false;
		this.getCountryList();
		this.custFormGroup.controls.identityNo.setValidators([Validators.required]);
		this.custFormGroup.controls.nationality.setValidators([
			Validators.required,
		]);
		this.custFormGroup.controls.dob.enable();
		this.custFormGroup.controls.gender.enable();
		this.custFormGroup.controls.nationality.updateValueAndValidity();
		this.custFormGroup.controls.identityNo.updateValueAndValidity();

		this.setNonCompanyValidation();
	}

	private setPSNonMalaysian() {
		this.isCompany = false;
		this.isMalaysian = false;
		this.getCountryList();

		this.custFormGroup.controls.nationality.setValidators([
			Validators.required,
		]);
		this.custFormGroup.controls.nationality.updateValueAndValidity();
		this.vehicleFormGroup.controls.psIdentityNo.setValidators([
			Validators.required,
		]);
		this.vehicleFormGroup.controls.psIdentityNo.updateValueAndValidity();
		this.vehicleFormGroup.controls.psPolicIdentityNo.setValidators([]);
		this.vehicleFormGroup.controls.psPolicIdentityNo.updateValueAndValidity();
		this.setNonCompanyValidation();
	}

	private setPSPoliceArmy() {
		this.isCompany = false;
		this.isMalaysian = true;

		this.vehicleFormGroup.controls.psIdentityNo.setValue(null);
		this.vehicleFormGroup.controls.psIdentityNo.setValidators([
			Validators.required,
			ValidatorFunction.nricValidator(),
		]);
		this.vehicleFormGroup.controls.psIdentityNo.updateValueAndValidity();

		this.custFormGroup.controls.nationality.setValidators([]);
		this.custFormGroup.controls.nationality.updateValueAndValidity();

		this.vehicleFormGroup.controls.psPolicIdentityNo.setValidators([
			Validators.required,
		]);
		this.vehicleFormGroup.controls.psPolicIdentityNo.updateValueAndValidity();
	}

	private setIsCompany() {
		this.isCompany = true;
		this.isMalaysian = true;
		this.custFormGroup.controls.identityNo.setValidators([Validators.required]);
		this.custFormGroup.controls.identityNo.updateValueAndValidity();

		this.setCompanyValidation();
	}

	private setPSIsCompany() {
		this.isCompany = true;
		this.isMalaysian = true;

		this.vehicleFormGroup.controls.psIdentityNo.setValidators([
			Validators.required,
		]);
		this.vehicleFormGroup.controls.psIdentityNo.updateValueAndValidity();
		this.vehicleFormGroup.controls.psPolicIdentityNo.setValidators([]);
		this.vehicleFormGroup.controls.psPolicIdentityNo.updateValueAndValidity();
		this.custFormGroup.controls.name.setValidators([]);
		this.custFormGroup.controls.name.updateValueAndValidity();
		this.setCompanyValidation();
	}

	idTypeChange(e: MatSelectChange) {
		let value = e.value;
		switch (value) {
			case 1:
				this.setMalaysian();
				break;
			case 2:
				this.setNonMalaysian();
				break;
			case 3:
				this.setIsCompany();
				break;
		}
	}

	getIdentityNoLabel() {
		if (this.isMalaysian && !this.isCompany) return "common.icNoLabel";
		else if (!this.isMalaysian) return "common.passportLabel";
		return "common.businessRegistrationNoLabel";
	}

	getNameLabel() {
		if (this.isMalaysian && !this.isCompany) return "common.fullNamePerICPassport";
		else if (!this.isMalaysian) return "common.namePerPassport";
		return "common.companyName";
	}

	getIdentityNoMasking() {
		if (this.isMalaysian && !this.isCompany) return "000000000000";
		return "";
	}

	filterCountry(val: string = null) {
		if (val) {
			let txt = val.trim().toLowerCase();
			this.filterCountryList = this.countryList.filter((x) =>
				x.trim().toLowerCase().startsWith(txt),
			);
		} else this.filterCountryList = Object.assign([], this.countryList);
	}

	eHailingUsedChange(e: boolean) {
		this.eHailingChecked = !this.eHailingChecked;
		this.vehicleFormGroup.controls.eHailingUsed.setValue(e);
		if (e) {
			this.vehicleFormGroup.controls.includeRoadTax.setValue(false);
			this.vehicleFormGroup.controls.includeRoadTax.disable();
		} else {
			this.vehicleFormGroup.controls.includeRoadTax.enable();
		}
		this.vehicleFormGroup.controls.includeRoadTax.updateValueAndValidity();
	}

	whatsappNumber = 60182822320;
	entityName = "Policystreet";
	whatsappContent = "";

	checkCss() {
		this.clickedNext = true;
	}

	onHomepage() {
		this.contactInfo = "";
		this.successGifType = "";
		this.showSuccessGif = false;
	}

	onUpdateDetails(): void {
		this.bottomSheetRef.dismiss({
			vehicle: this.vehicleFormGroup.getRawValue(),
			customer: this.custFormGroup.getRawValue(),
		});
	}
}
