import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class FooterMarginService {
	private stepperIndexSubject = new Subject<number>();
	public stepperIndex$ = this.stepperIndexSubject.asObservable();

	private componentSubject = new Subject<string>();
	public component$ = this.componentSubject.asObservable();

	public updateStepperIndex(index: number): void {
		this.stepperIndexSubject.next(index);
	}

	public updateComponent(component: string): void {
		this.componentSubject.next(component);
	}
}
