import { Component, OnInit, AfterViewInit } from '@angular/core';
import { LoadingOverlayService } from '../../service/loading-overlay.service';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-loading-overlay',
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.scss']
})
export class LoadingOverlayComponent implements OnInit {

  constructor(
    public loadingService: LoadingOverlayService
  ) { }

  ngOnInit(): void {
  }
  
  animationOptions: AnimationOptions = {
    path: '../../assets/animations/ps_loading_animation.json', // Path to your animation JSON file
  };
}
