<div class="title-f">
	<img class="title-img" src="assets/image/car-icon.png" alt="" />
	{{ "common.carDetails" | translate }}
</div>
<div class="content-wrapper">
	<div>
		<div class="label-desc">{{ "common.carNumber" | translate }}:</div>
		<div class="label-value">
			{{ data.vehicleNo }}
		</div>
	</div>
	<div>
		<div class="label-desc">{{ "common.carModel" | translate }}:</div>
		<div class="label-value">
			{{ data.vehicle }}
		</div>
	</div>
</div>
