import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { FaqDialogComponent } from "src/app/dashboard/faq-dialog/faq-dialog.component";
import { FaqTakafulDialogComponent } from "src/app/dashboard/faq-dialog/faq-takaful-dialog/faq-takaful-dialog.component";
import { GuidesDialogComponent } from "src/app/dashboard/guides-dialog/guides-dialog.component";
import { PartnerService } from "src/app/share/service/partner.service";

@Component({
	selector: "app-menu",
	templateUrl: "./menu.component.html",
	styleUrls: ["./menu.component.scss"],
})
export class MenuComponent implements OnInit {
	partnerCode: string = "ps";

	showFAQ: Boolean = false;
	showFAQList: string[] = ["ps", "cbp"];

	constructor(
		private dialog: MatDialog,
		public translate: TranslateService,
		private partnerService: PartnerService,
	) {}

	ngOnInit(): void {
		this.getPartnerCode();
	}

	private getPartnerCode() {
		this.partnerCode = this.partnerService.getPartnerCode();
		!this.partnerCode ? (this.partnerCode = "ps") : null;
		if (this.showFAQList.includes(this.partnerCode)) {
			this.showFAQ = true;
		}
	}

	guideDialog() {
		this.dialog.open(GuidesDialogComponent, {
			panelClass: "guide-dialog",
		});
	}

	switch(lang: string) {
		this.translate.use(lang);
	}

	faqDialog() {
		if (this.partnerService.getPartnerAttribute(this.partnerCode, "takaful")) {
			this.dialog.open(FaqTakafulDialogComponent, {
				panelClass: "faq-dialog",
			});
		} else {
			this.dialog.open(FaqDialogComponent, {
				panelClass: "faq-dialog",
			});
		}
	}
}
