<div class="bg-light-grey upload-documents p-sm">
	<div class="content-container small mv-md">
		<p class="color-primary title-f">{{ "uploadDocs.title" | translate }}</p>
		<p>{{ "uploadDocs.desc" | translate }}</p>

		<mat-card>
			<div fxLayout="row" fxLayoutAlign="space-between">
				<div>
					<div fxFlex>
						<img src="assets/image/vehicle-grant.png" alt="" />
					</div>
					<div>
						<div class="desc-f bold">
							{{ "uploadDocs.vehicleGrant" | translate }}
						</div>
						<div class="mdDesc-f">
							{{ "uploadDocs.vehicleGrantDesc" | translate }}
						</div>
						<div class="mdDesc-f bold pt-sm" *ngIf="file.VehicleGrant">
							{{ file.VehicleGrant.fileName ? file.VehicleGrant.fileName : file.VehicleGrant }}
							<button
								mat-icon-button
								(click)="deleteFile('VehicleGrant', file.VehicleGrant.id)"
								*ngIf="errorActivatedPolicy === false"
							>
								<mat-icon>cancel</mat-icon>
							</button>
						</div>
					</div>
				</div>

				<div class="pr-md">
					<input
						type="file"
						accept="image/*"
						id="vehicleGrant"
						(change)="handleFileInput($event, 'VehicleGrant')"
						style="display: none"
					/>
					<div
						class="mdDesc-f color-blue cursor-pointer"
						(click)="showSampleDialog('VehicleGrant')"
					>
						View sample here
					</div>
					<button
						mat-stroked-button
						class="mt-sm"
						*ngIf="errorActivatedPolicy === false"
					>
						<label
							for="vehicleGrant"
							style="
								display: flex;
								justify-content: center;
								align-items: center;
							"
						>
							<mat-icon class="">add_circle</mat-icon
							>{{ "common.upload" | translate }}
						</label>
					</button>
				</div>
			</div>
		</mat-card>
		<mat-card>
			<div fxLayout="row">
				<div>
					<img src="assets/image/car-icon.png" alt="" />
				</div>
				<div>
					<div fxLayoutAlign="space-between">
						<div class="desc-f bold">
							{{ "uploadDocs.vehiclePhoto" | translate }}
						</div>
						<div
							class="mdDesc-f color-blue cursor-pointer"
							(click)="showSampleDialog('VehiclePhoto')"
						>
							View sample here
						</div>
					</div>
					<div class="mdDesc-f">
						{{ "uploadDocs.vehiclePhotoDesc" | translate }}
					</div>

					<mat-card>
						<input
							type="file"
							accept="image/*"
							id="FrontCar"
							(change)="handleFileInput($event, 'FrontCar')"
							style="display: none"
						/>
						<div fxFlex>
							<p class="mdDesc-f bold">{{ "uploadDocs.fcar" | translate }}</p>
							<div class="mdDesc-f bold" *ngIf="file.FrontCar">
								{{ file.FrontCar.fileName ? file.FrontCar.fileName : file.FrontCar }}
								<button
									mat-icon-button
									(click)="deleteFile('FrontCar', file.FrontCar.id)"
									*ngIf="errorActivatedPolicy === false"
								>
									<mat-icon>cancel</mat-icon>
								</button>
							</div>
						</div>
						<div>
							<button mat-stroked-button *ngIf="errorActivatedPolicy === false">
								<label
									for="FrontCar"
									style="
										display: flex;
										justify-content: center;
										align-items: center;
									"
								>
									<mat-icon class="color-dark-blue">add_circle</mat-icon
									>{{ "common.upload" | translate }}
								</label>
							</button>
						</div>
					</mat-card>
					<mat-card>
						<input
							type="file"
							accept="image/*"
							id="BackCar"
							(change)="handleFileInput($event, 'BackCar')"
							style="display: none"
						/>
						<div fxFlex>
							<p class="mdDesc-f bold">{{ "uploadDocs.bcar" | translate }}</p>
							<div class="mdDesc-f bold" *ngIf="file.BackCar">
								{{ file.BackCar.fileName ? file.BackCar.fileName : file.BackCar }}
								<button
									mat-icon-button
									(click)="deleteFile('BackCar', file.BackCar.id)"
									*ngIf="errorActivatedPolicy === false"
								>
									<mat-icon>cancel</mat-icon>
								</button>
							</div>
						</div>
						<div>
							<button mat-stroked-button *ngIf="errorActivatedPolicy === false">
								<label
									for="BackCar"
									style="
										display: flex;
										justify-content: center;
										align-items: center;
									"
								>
									<mat-icon class="">add_circle</mat-icon
									>{{ "common.upload" | translate }}
								</label>
							</button>
						</div>
					</mat-card>
					<mat-card>
						<input
							type="file"
							accept="image/*"
							id="LeftCar"
							(change)="handleFileInput($event, 'LeftCar')"
							style="display: none"
						/>
						<div fxFlex>
							<p class="mdDesc-f bold">
								{{ "uploadDocs.lside" | translate }}
							</p>
							<div class="mdDesc-f bold" *ngIf="file.LeftCar">
								{{ file.LeftCar.fileName ? file.LeftCar.fileName : file.LeftCar }}
								<button
									mat-icon-button
									(click)="deleteFile('LeftCar', file.LeftCar.id)"
									*ngIf="errorActivatedPolicy === false"
								>
									<mat-icon>cancel</mat-icon>
								</button>
							</div>
						</div>
						<div>
							<button mat-stroked-button *ngIf="errorActivatedPolicy === false">
								<label
									for="LeftCar"
									style="
										display: flex;
										justify-content: center;
										align-items: center;
									"
								>
									<mat-icon class="">add_circle</mat-icon
									>{{ "common.upload" | translate }}
								</label>
							</button>
						</div>
					</mat-card>
					<mat-card>
						<input
							type="file"
							accept="image/*"
							id="RightCar"
							(change)="handleFileInput($event, 'RightCar')"
							style="display: none"
						/>
						<div fxFlex>
							<p class="mdDesc-f bold">
								{{ "uploadDocs.rside" | translate }}
							</p>
							<div class="mdDesc-f bold" *ngIf="file.RightCar">
								{{ file.RightCar.fileName ? file.RightCar.fileName : file.RightCar }}
								<button
									mat-icon-button
									(click)="deleteFile('RightCar', file.RightCar.id)"
									*ngIf="errorActivatedPolicy === false"
								>
									<mat-icon>cancel</mat-icon>
								</button>
							</div>
						</div>
						<div>
							<button mat-stroked-button *ngIf="errorActivatedPolicy === false">
								<label
									for="RightCar"
									style="
										display: flex;
										justify-content: center;
										align-items: center;
									"
								>
									<mat-icon class="">add_circle</mat-icon
									>{{ "common.upload" | translate }}
								</label>
							</button>
						</div>
					</mat-card>
				</div>
			</div>
		</mat-card>

		<button
			mat-flat-button
			class="secondary-btn mt-md"
			(click)="handleSubmit()"
			[disabled]="
				fileToUpload.length === 0 ||
				(!file.VehicleGrant &&
					(!file.FrontCar ||
						!file.BackCar ||
						!file.LeftCar ||
						!file.RightCar))
			"
		>
			{{ "common.submit" | translate }}
		</button>
	</div>
</div>
