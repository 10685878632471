export const STATE_LIST = [
    "Selangor",
    "Wilayah Persekutuan Kuala Lumpur",
    "Wilayah Persekutuan Labuan",
    "Wilayah Persekutuan Putrajaya",
    "Johor",
    "Kedah",
    "Kelantan",
    "Melaka",
    "Negeri Sembilan",
    "Pahang",
    "Perak",
    "Perlis",
    "Pulau Pinang",
    "Sarawak",
    "Sabah",
    "Terengganu",
];

export const RELATIONSHIP_LIST  = [
    { id: "Parent/Parent-in-law", name: "common.parents" },
    { id: "Spouse", name: "common.spouse" },
    { id: "Son/Daughter", name: "common.child" },
    { id: "Sibling/Sibling-in-law/Cousin/Relative", name: "common.sibling" },
    { id: "Friend/Co-worker", name: "common.friend" },
]