<div>
    <div class="total-amount-container">
        <div class="total-amount-container__flex">
            <div class="total-amount-container__title">Total Amount</div>
            <div class="total-amount-container__value">RM {{ totalAmount | number: '1.2-2' }}</div>
        </div>
        <div class="total-amount-container__label__container">
            <div class="total-amount-container__label" [innerHTML]="label"></div>
        </div>
    </div>
</div>