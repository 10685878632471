import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-insurer-card',
  templateUrl: './insurer-card.component.html',
  styleUrls: ['./insurer-card.component.scss']
})
export class InsurerCardComponent implements OnInit {
  @Input() first: boolean = false;
  @Input() item: InsurerDataInterface;
  @Input() insuranceType: string;

  @Output() onSubmit = new EventEmitter();
  @Output() onBenefitDialogClick = new EventEmitter();

  constructor() { }

  get isPolicystreetProduct() {
    return this.first && this.item.code == 'PolicyStreet'
  }

  get isProductCheapest() {
    return this.first && this.item.code !== 'PolicyStreet'
  }

  ngOnInit(): void {}

  getTotalBasePremium() {
    return this.item?.totalBasePremium;
  }

  getAfterRebateTotalBasePremium(){
    return this.item?.totalBasePremium - this.item?.rebate;
  }

  openCarVariantDialog(id: string){
    this.onSubmit.emit();
  }

  benefitDialog(item: any){
    this.onBenefitDialogClick.emit();
  }

}

interface InsurerDataInterface {
  image: string;
  typeOfSum: string;
  sumInsured: number;
  code: string;
  total: number;
  id: string;
  basePremium: number;
  totalBasePremium: number;
  serviceTaxRate: number;
  taxRate: number;
  stampDutyPrice: number;
  defaultExcessDiscount?: number;
  rebate?: number;
}