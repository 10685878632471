import {Component, Input} from '@angular/core';
import {PartnerInfoInterface} from "../../../share/interface/partner.interface";

@Component({
  selector: 'app-ktmb-footer',
  templateUrl: './ktmb-footer.component.html',
  styleUrls: [ '../footer.component.scss', './ktmb-footer.component.scss']
})
export class KtmbFooterComponent {
  @Input() info: PartnerInfoInterface;

  constructor() {
  }
}
