import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ComparisonComponent } from "./comparison/comparison.component";
import { ComplainComponent } from "./complain/complain.component";
import { CustomerPolicyComponent } from "./customer-policy/customer-policy.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { RefundRequestComponent } from "./refund-request/refund-request.component";
import { StaticStylesComponent } from "./static-styles/static-styles.component";
import { ThankyouPageComponent } from "./thankyou-page/thankyou-page.component";
import { TopupRequestComponent } from "./topup-request/topup-request.component";
import { UploadDocumentsComponent } from "./upload-documents/upload-documents.component";
import { AffiliateslandingComponent } from "../affiliates/screens/affiliates-landing/affiliates-landing.component";

const routes: Routes = [
	{
		path: "",
		children: [
			{
				path: "comparison/:id",
				component: ComparisonComponent,
			},
			{
				path: "customer-policy",
				component: CustomerPolicyComponent,
			},
			{
				path: "refund-request",
				component: RefundRequestComponent,
			},
			{
				path: "upload-documents/:id",
				component: UploadDocumentsComponent,
			},
			{
				path: "topup-request/:id",
				component: TopupRequestComponent,
			},
			{
				path: "checkout",
				component: ThankyouPageComponent,
			},
			{
				path: "complain",
				component: ComplainComponent,
			},
			{
				path: "styles",
				component: StaticStylesComponent,
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class DashboardRoutingModule {}
