export const floodExpandedData: any = [
  {
    title:
      'plans.Flood.additionalInfo1',
    desc: [],
  },
  {
    title:
      'plans.Flood.additionalInfo2',
    desc: [
      'plans.Flood.additionalInfo2_1',
      'plans.Flood.additionalInfo2_2',
      'plans.Flood.additionalInfo2_3',
    ],
  },
  {
    title:
      'plans.Flood.additionalInfo3',
    desc: [],
  },
];

export const femaleExpandedData: any = [
  {
    title: 'plans.Female.additionalInfo1',
    desc: [
      'plans.Female.additionalInfo1_1',
      'plans.Female.additionalInfo1_2',
    ],
  },
  {
    title:
      'plans.Female.additionalInfo2',
    desc: [],
  },
  {
    title:
      'plans.Female.additionalInfo3',
  },
];

export const familyExpandedData: any = [
  {
    title:
      'plans.Family.additionalInfo1',
    desc: [],
  },
  {
    title:
      'plans.Family.additionalInfo2',
    desc: [],
  },
  {
    title:
      'plans.Family.additionalInfo3',
    desc: [
      'plans.Family.additionalInfo3_1',
    ],
  },
  {
    title:
      'plans.Family.additionalInfo4',
    desc: [],
  },
];
