import { Component, Input, OnInit } from "@angular/core";
import { D2CQuotationInterface } from "src/app/share/interface/motor.interface";

@Component({
  selector: "app-loading-insurer-card",
  templateUrl: "./loading-insurer-card.component.html",
  styleUrls: ["./loading-insurer-card.component.scss"],
})
export class LoadingInsurerCardComponent implements OnInit {

  @Input() insurer: D2CQuotationInterface;
  constructor() {}

  ngOnInit(): void {}
}
