import { Component, Input, Output, OnInit, EventEmitter } from "@angular/core";

@Component({
  selector: "app-summary-card",
  templateUrl: "./summary-card.component.html",
  styleUrls: ["./summary-card.component.scss"],
})
export class SummaryCardComponent implements OnInit {
  @Input() title: string = "";
  @Input() editable: boolean = false;
  @Input() disableBtn?: boolean;

  @Output() onEdit = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  edit(e: any) {
    e.stopPropagation();
    this.onEdit.emit();
  }
}
