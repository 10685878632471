<!-- *** NEW DESIGN *** -->

<div mat-dialog-title style="font-size: 22px; font-weight: 600">
	{{ "faq.mainTitle" | translate }}
</div>
<mat-divider></mat-divider>

<mat-dialog-content>
	<div>
		<h4 class="section-title">{{ "faq.title1" | translate }}</h4>
		<mat-accordion>
			<mat-expansion-panel
				*ngFor="let question of faqQuestions.general"
				(opened)="panelOpenState = true"
				(closed)="panelOpenState = false"
			>
				<mat-expansion-panel-header>
					<mat-panel-title>
						{{ question.question }}
					</mat-panel-title>
				</mat-expansion-panel-header>
				<p
					class="answer-text"
					[innerHTML]="getSanitizedAnswer(question.answer)"
				>
					{{ question.answer }}
				</p>
			</mat-expansion-panel>
		</mat-accordion>
	</div>

	<div>
		<h4 class="section-title">{{ "faq.title2" | translate }}</h4>
		<mat-accordion>
			<mat-expansion-panel
				*ngFor="let question of faqQuestions.renewal"
				(opened)="panelOpenState = true"
				(closed)="panelOpenState = false"
			>
				<mat-expansion-panel-header>
					<mat-panel-title>
						{{ question.question }}
					</mat-panel-title>
				</mat-expansion-panel-header>
				<p
					class="answer-text"
					[innerHTML]="getSanitizedAnswer(question.answer)"
				>
					{{ question.answer }}
				</p>
			</mat-expansion-panel>
		</mat-accordion>
	</div>

	<div>
		<h4 class="section-title">{{ "faq.title3" | translate }}</h4>
		<mat-accordion>
			<mat-expansion-panel
				*ngFor="let question of faqQuestions.claims"
				(opened)="panelOpenState = true"
				(closed)="panelOpenState = false"
			>
				<mat-expansion-panel-header>
					<mat-panel-title>
						{{ question.question }}
					</mat-panel-title>
				</mat-expansion-panel-header>
				<p
					class="answer-text"
					[innerHTML]="getSanitizedAnswer(question.answer)"
				>
					{{ question.answer }}
				</p>
			</mat-expansion-panel>
		</mat-accordion>
	</div>

	<div>
		<h4 class="section-title">{{ "faq.title4" | translate }}</h4>
		<mat-accordion>
			<mat-expansion-panel
				*ngFor="let question of faqQuestions.roadtax"
				(opened)="panelOpenState = true"
				(closed)="panelOpenState = false"
			>
				<mat-expansion-panel-header>
					<mat-panel-title>
						{{ question.question }}
					</mat-panel-title>
				</mat-expansion-panel-header>
				<p
					class="answer-text"
					[innerHTML]="getSanitizedAnswer(question.answer)"
				>
					{{ question.answer }}
				</p>
			</mat-expansion-panel>
		</mat-accordion>
	</div>
</mat-dialog-content>
