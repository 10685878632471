import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {ProductDocumentInterface, QuotationInterface} from "../../../share/interface/motor.interface";

@Component({
  selector: 'app-ktmb-insurer-card',
  templateUrl: './ktmb-insurer-card.component.html',
  styleUrls: ['./ktmb-insurer-card.component.scss']
})
export class KtmbInsurerCardComponent {
  @Input() quotation: QuotationInterface;
  @Input() isFirst = false;
  @Output() buyNow = new EventEmitter<void>();

  constructor(private translate: TranslateService) {
  }

  isLangEn(): boolean {
    return this.translate.currentLang === 'en'
  };

  onClickProductDocument(pd: ProductDocumentInterface): void {
    window.open(pd.url, "_blank");
  }

  filterProductDocuments(): ProductDocumentInterface[] {
    return this.quotation.productDocuments.filter(pd => pd.language === this.translate.currentLang);
  }

  onBuyNow(): void {
    this.buyNow.emit();
  }
}
