import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-total-amount',
  templateUrl: './mobile-total-amount.component.html',
  styleUrls: ['./mobile-total-amount.component.scss']
})
export class MobileTotalAmountComponent implements OnInit {

  @Input() totalAmount: number;
  @Input() label: string;

  constructor() { }

  ngOnInit(): void {
  }

}
