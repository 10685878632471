<div class="complain-bg">
	<div class="bg-blue p-md">
		<div>
			<p class="color-white bold title-f complain-w">
				{{ "complain.title" | translate }}
			</p>
			<p class="color-white mdDesc-f complain-w">
				{{ "complain.desc" | translate }}
			</p>
			<div fxLayoutAlign="center center">
				<img
					src="https://assets.policystreet.com/motor/complain-page/paper-award.png"
					alt=""
					width="65px"
					class="mt-md mr-sm"
				/>
				<div fxFlex>
					<p class="color-white pt-sm complain-w">
						{{ "complain.psNote" | translate }}
					</p>
					<a
						href="https://www.bnm.gov.my/-/approved-financial-advisers"
						class="color-white"
					>
						{{ "complain.clickHere" | translate }}</a
					>
				</div>
			</div>
		</div>
	</div>
	<img
		src="https://assets.policystreet.com/motor/complain-page/bg-cs-woman.png"
		alt="background"
		class="complain-bg-right"
	/>
</div>
<div class="bg-light-grey">
	<div class="content-container small pt-lg pb-lg">
		<form [formGroup]="mainForm">
			<mat-form-field appearance="outline" class="full-width">
				<mat-label>{{ "common.name" | translate }} </mat-label>
				<input matInput formControlName="name" type="text" />
				<mat-error>Invalid name</mat-error>
			</mat-form-field>
			<mat-form-field appearance="outline" class="full-width">
				<mat-label>{{ "common.email" | translate }} </mat-label>
				<input matInput formControlName="email" type="text" />
				<mat-error>Not a valid email address</mat-error>
			</mat-form-field>
			<mat-form-field appearance="outline" class="full-width">
				<mat-label>{{ "common.mobileNo" | translate }}</mat-label>
				<input matInput formControlName="mobileNumb" type="text" />
				<mat-error>Invalid mobile number</mat-error>
			</mat-form-field>
		</form>

		<mat-tab-group class="mt-md" mat-align-tabs="center">
			<mat-tab label="{{ 'complain.tab1Title' | translate }}">
				<form [formGroup]="msgForm" (ngSubmit)="handleSubmitMsg(msgForm)">
					<div class="mt-md mb-lg flex-center column">
						<textarea
							matInput
							placeholder="{{ 'complain.writeMsg' | translate }}"
							class="textarea message"
							formControlName="message"
						></textarea>
						<div class="mt-lg full-width">
							<button
								mat-flat-button
								class="secondary-btn"
								[disabled]="!mainForm.valid || !msgForm.valid"
							>
								{{ "common.submit" | translate }}
							</button>
						</div>
					</div>
				</form>
			</mat-tab>
			<mat-tab label="{{ 'complain.tab2Title' | translate }}">
				<form
					[formGroup]="feedbackForm"
					(ngSubmit)="handleSubmitFeedbackForm(feedbackForm)"
				>
					<mat-card class="center mt-md">
						<p>{{ "complain.q1" | translate }}</p>
						<div class="mt-xs ml-sm" *ngFor="let item of whyChoosePs">
							<mat-checkbox formControlName="questionOne"
								>{{ item | translate }}
							</mat-checkbox>
						</div>
						<div class="flex mt-xs ml-sm">
							<mat-checkbox class="mr-md"
								>{{ "common.other" | translate }}:

								<input
									type="text"
									class="line-input"
									formControlName="questionOneOther"
								/>
							</mat-checkbox>
						</div>
					</mat-card>
					<mat-card class="center mt-md">
						<p>{{ "complain.q2" | translate }}</p>
						<mat-radio-group formControlName="questionTwo">
							<div class="ml-sm mt-xs">
								<mat-radio-button
									[value]="item"
									*ngFor="let item of heardAboutUsFrom"
									>{{ item | translate }}</mat-radio-button
								>
							</div>
							<div class="flex mt-xs ml-sm">
								<mat-radio-button class="mr-md" value="other"
									>{{ "common.other" | translate }}:
									<input type="text" class="line-input" />
								</mat-radio-button>
							</div>
						</mat-radio-group>
					</mat-card>

					<mat-card class="mt-md" formGroupName="questionThree">
						<p>
							{{ "complain.q3" | translate }}
						</p>
						<div>
							<div>
								<div
									class="rate-txt-desktop"
									fxFlex
									fxLayout="row"
									fxLayoutAlign="space-between"
								>
									<p style="width: 180px" class="ml-lg mr-md"></p>
									<p class="xsDesc-f mr-md text-center color-blue">
										Very <br />important
									</p>
									<p class="xsDesc-f mr-md text-center color-blue">Important</p>
									<p class="xsDesc-f mr-md text-center color-blue">
										Moderately <br />important
									</p>
									<p class="xsDesc-f mr-md text-center color-blue">
										Slightly <br />important
									</p>
									<p class="xsDesc-f text-center color-blue">
										Not <br />important
									</p>
								</div>
								<div
									class="rate-txt-mobile"
									fxFlex
									fxLayout="row"
									fxLayoutAlign="space-between"
								>
									<p style="width: 105px" class="ml-lg mr-md"></p>
									<p class="xsDesc-f mr-md text-center color-blue bold">1</p>
									<p class="xsDesc-f mr-md text-center color-blue bold">2</p>
									<p class="xsDesc-f mr-md text-center color-blue bold">3</p>
									<p class="xsDesc-f mr-md text-center color-blue bold" bold>
										4
									</p>
									<p class="xsDesc-f mr-sm text-center color-blue bold">5</p>
								</div>
							</div>
							<div class="mv-md">
								<mat-radio-group
									class="mat-radio-group-rate"
									formControlName="q1"
								>
									<p style="width: 150px" class="rate-title">
										{{ "complain.displayCheapest" | translate }}
									</p>
									<mat-radio-button value="1"></mat-radio-button>
									<mat-radio-button value="2"></mat-radio-button>
									<mat-radio-button value="3"></mat-radio-button>
									<mat-radio-button value="4"></mat-radio-button>
									<mat-radio-button value="5"></mat-radio-button>
								</mat-radio-group>
								<mat-divider></mat-divider>
							</div>
							<div class="mv-md">
								<mat-radio-group
									class="mat-radio-group-rate"
									formControlName="q2"
								>
									<p style="width: 150px" class="rate-title">
										{{ "complain.compareLike" | translate }}
									</p>
									<mat-radio-button value="1"></mat-radio-button>
									<mat-radio-button value="2"></mat-radio-button>
									<mat-radio-button value="3"></mat-radio-button>
									<mat-radio-button value="4"></mat-radio-button>
									<mat-radio-button value="5"></mat-radio-button>
								</mat-radio-group>
								<mat-divider></mat-divider>
							</div>
							<div class="mv-md">
								<mat-radio-group
									class="mat-radio-group-rate"
									formControlName="q3"
								>
									<p style="width: 150px" class="rate-title">
										{{ "complain.manyInsurers" | translate }}
									</p>
									<mat-radio-button value="1"></mat-radio-button>
									<mat-radio-button value="2"></mat-radio-button>
									<mat-radio-button value="3"></mat-radio-button>
									<mat-radio-button value="4"></mat-radio-button>
									<mat-radio-button value="5"></mat-radio-button>
								</mat-radio-group>

								<mat-divider></mat-divider>
							</div>
							<div class="mv-md">
								<mat-radio-group
									class="mat-radio-group-rate"
									formControlName="q4"
								>
									<p style="width: 150px" class="rate-title">
										{{ "complain.fastService" | translate }}
									</p>
									<mat-radio-button value="1"></mat-radio-button>
									<mat-radio-button value="2"></mat-radio-button>
									<mat-radio-button value="3"></mat-radio-button>
									<mat-radio-button value="4"></mat-radio-button>
									<mat-radio-button value="5"></mat-radio-button>
								</mat-radio-group>

								<mat-divider></mat-divider>
							</div>
							<div class="mv-md">
								<mat-radio-group
									class="mat-radio-group-rate"
									formControlName="q5"
								>
									<p style="width: 150px" class="rate-title">
										{{ "complain.easyToUse" | translate }}
									</p>
									<mat-radio-button value="1"></mat-radio-button>
									<mat-radio-button value="2"></mat-radio-button>
									<mat-radio-button value="3"></mat-radio-button>
									<mat-radio-button value="4"></mat-radio-button>
									<mat-radio-button value="5"></mat-radio-button>
								</mat-radio-group>

								<mat-divider></mat-divider>
							</div>
							<div class="mv-md">
								<mat-radio-group
									class="mat-radio-group-rate"
									formControlName="q6"
								>
									<p style="width: 150px" class="rate-title">
										{{ "complain.payMonthly" | translate }}
									</p>
									<mat-radio-button value="1"></mat-radio-button>
									<mat-radio-button value="2"></mat-radio-button>
									<mat-radio-button value="3"></mat-radio-button>
									<mat-radio-button value="4"></mat-radio-button>
									<mat-radio-button value="5"></mat-radio-button>
								</mat-radio-group>

								<mat-divider></mat-divider>
							</div>
							<div class="mv-md">
								<mat-radio-group
									class="mat-radio-group-rate"
									formControlName="q7"
								>
									<p style="width: 150px" class="rate-title">
										{{ "complain.easyToUnderstand" | translate }}
									</p>
									<mat-radio-button value="1"></mat-radio-button>
									<mat-radio-button value="2"></mat-radio-button>
									<mat-radio-button value="3"></mat-radio-button>
									<mat-radio-button value="4"></mat-radio-button>
									<mat-radio-button value="5"></mat-radio-button>
								</mat-radio-group>

								<mat-divider></mat-divider>
							</div>
							<div class="mv-md">
								<mat-radio-group
									class="mat-radio-group-rate"
									formControlName="q8"
								>
									<p style="width: 150px" class="rate-title">
										{{ "complain.good" | translate }}
									</p>
									<mat-radio-button value="1"></mat-radio-button>
									<mat-radio-button value="2"></mat-radio-button>
									<mat-radio-button value="3"></mat-radio-button>
									<mat-radio-button value="4"></mat-radio-button>
									<mat-radio-button value="5"></mat-radio-button>
								</mat-radio-group>
							</div>
						</div>
					</mat-card>

					<mat-card class="mt-md">
						<p>
							{{ "complain.q4" | translate }}
						</p>
						<div class="mt-xs ml-sm" *ngFor="let item of describedOurService">
							<mat-checkbox formControlName="questionFour"
								>{{ item | translate }}
							</mat-checkbox>
						</div>
					</mat-card>

					<mat-card class="mt-md">
						<p>
							{{ "complain.q5" | translate }}
						</p>
						<mat-radio-group formControlName="questionFive">
							<div fxFlex class="ml-sm mt-xs">
								<mat-radio-button
									[value]="item"
									*ngFor="let item of satisfactionList"
									>{{ item | translate }}</mat-radio-button
								>
							</div>
						</mat-radio-group>
					</mat-card>

					<button
						class="secondary-btn mt-md"
						type="submit"
						[disabled]="!mainForm.valid || !feedbackForm.valid"
						mat-flat-button
					>
						{{ "common.submit" | translate }}
					</button>
				</form>
			</mat-tab>
			<mat-tab label="{{ 'complain.tab3Title' | translate }}">
				<form
					[formGroup]="complaintForm"
					(ngSubmit)="handleSubmitComplaintForm(complaintForm)"
				>
					<div class="m-sm">
						<mat-form-field
							appearance="outline"
							floatLabel="never"
							class="full-width mv-sm"
						>
							<mat-label>{{ "complain.nature" | translate }}</mat-label>
							<mat-select formControlName="natureOfComplaint">
								<mat-option
									*ngFor="let item of natureOfComplaintList"
									[value]="item"
									>{{ item | translate }}</mat-option
								>
							</mat-select>
						</mat-form-field>
						<p>Description:</p>
						<textarea
							matInput
							placeholder="{{ 'complain.writeMsg' | translate }}"
							class="textarea message"
							formControlName="message"
						></textarea>
						<div class="mv-md">
							<button
								class="secondary-btn"
								type="submit"
								[disabled]="!mainForm.valid || !complaintForm.valid"
								mat-flat-button
							>
								{{ "common.submit" | translate }}
							</button>
						</div>
					</div>
				</form>
			</mat-tab>
		</mat-tab-group>
	</div>
</div>
