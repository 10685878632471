<div class="box">
	<div class="preview-container" *ngIf="previewFile.type.startsWith('image/')">
		<img [src]="previewFile.preview" alt="Image Preview" />
	</div>
	<div class="preview-container" *ngIf="previewFile.type === 'application/pdf'">
		<iframe [src]="previewFile.preview" width="100%" height="100%"></iframe>
	</div>
	<div class="preview-container" *ngIf="previewFile.type.startsWith('video/')">
		<ng-container *ngIf="previewFile.type !== 'video/mpeg'; else notAvailable">
			<video
				width="100%"
				controls
				class="video"
				#videoPlayer
				(click)="handleVideoClick($event)"
			>
				<source [src]="previewFile.preview" type="{{ previewFile.type }}" />
			</video>
			<div
				class="play-button"
				[ngClass]="{ hidden: playButtonHidden || isMozilla }"
			></div>
		</ng-container>

		<ng-template #notAvailable>
			<p class="preview-not-available">
				Preview not available for mpeg format.
			</p>
		</ng-template>
	</div>
	<div class="header">
		<div class="file-name">{{ previewFile.fileName }}</div>
		<div class="delete-button" (click)="onDelete()">
			<img src="../../../../assets/image/endorsement-x-button.svg" alt="" />
		</div>
	</div>
	<div class="download-button" (click)="onDownload()">
		{{ "common.download" | translate }}
	</div>
</div>
