<div class="insurer-list cheapest">
    <div class="banner-container">
        <img
            class="banner"
            src="https://storage.googleapis.com/dev-ps-asset/Car_Insurance/icons/max-savings-ribbon.png"
            alt=""
        />
        <div class="banner-text">
            <span>
                {{ "excess.maxSavings" | translate }}
            </span>
        </div>
    </div>
    <div class="rebate-notice" *ngIf="item?.rebate">
        <img src="assets/image/rebate-crown-icon.png" />
        {{ "common.includedRebate" | translate }}
        <b>RM{{ item?.rebate | number : "1.2-2" }}</b>
    </div>
    <div class="insurer-content">
        <div class="insurer-logo">
            <img [src]="item?.image" />
        </div>
        <div class="insurer-details">
            <div style="display: flex; justify-content: space-between;">
                <div>
                    <div class="label">
                        {{ "common.sumInsured" | translate }} ({{
                            "common." + item?.typeOfSum | translate
                        }}):
                    </div>
                    <div class="value">
                        RM {{ item?.sumInsured | number : "1.2-2" }}
                    </div>
                </div>
                <button
                    mat-stroked-button
                    class="benefit-btn"
                    (click)="benefitDialog(item)"
                >
                    {{ "comparison.viewBenefit" | translate }}
                </button>
            </div>
    
            <div fxLayout="row" class="mt-sm mb-md">
                <app-insurer-details
                    [code]="item?.code"
                    [first]="first"
                    [insuranceType]="insuranceType"
                    class="full-width"
                    *ngIf="
                        insuranceType !== 'TPFT' ||
                        item?.code === 'Zurich'
                    "
                >
                </app-insurer-details>
            </div>
            <!-- <div class="approved-by-section">
                {{ "excess.licensedBy" | translate }}
                <img src="https://storage.googleapis.com/dev-ps-asset/Car_Insurance/icons/approved-by-icon.png" 
                    alt=""
                    class="approved-by-icons"
                >
            </div> -->
        </div>
        <div class="insurer-select">
            <div class="insurer-amount" >
                <div *ngIf="!item?.rebate"><span>RM</span>{{ getTotalBasePremium() | number : "1.2-2" }}</div>
                <div *ngIf="item?.rebate"><span>RM</span>{{ getAfterRebateTotalBasePremium() | number : "1.2-2" }}</div>
            </div>
            <span *ngIf="item?.rebate">
                {{ "common.priceBeforeRebate" | translate }} : <s style="color:red"><span style="color: black;">RM{{ getRebate() | number : "1.2-2" }}</span></s>
            </span>
            <!-- ## SPLIT TAG -->
            <ng-container>
                <div class="insurer-instalment">
                    {{ "common.pay" | translate }}
                    <b>RM{{ getTotalPrice() / 12 | number : "1.2-2" }}</b>
                    {{ "common.everyMonth" | translate }} <br />{{
                        "common.duration12" | translate
                    }}! <br />
                    <span>
                        {{ "common.epp" | translate }}
                    </span>
                </div>
            </ng-container>
            <button
                mat-flat-button
                class="buy-btn"
                (click)="openCarVariantDialog(item.id)"
            >
                {{ "comparison.buyNow" | translate }}
            </button>
        </div>
    </div>
</div>


<div
    class="mobile-insurer-list-2 cheapest"
>
    <div style="position: relative">
        <img
            class="banner"
            src="https://storage.googleapis.com/dev-ps-asset/Car_Insurance/icons/max-savings-ribbon.png"
            alt=""
        />
        <div class="banner-text">
            <span>
                {{ "excess.maxSavings" | translate }}
            </span>
        </div>
    </div>
    <div class="rebate-notice" *ngIf="item?.rebate">
        <img src="assets/image/rebate-crown-icon.png" />
        {{ "common.includedRebate" | translate }}
        <b>RM{{ item?.rebate | number : "1.2-2" }}</b>
    </div>
    <div class="info-section">
        <div fxLayout="row">
            <div class="insurer-logo">
                <img [src]="item?.image" />
            </div>
            <div style="padding: 10px 5px">
                <div style="font-size: 11px; align-items: center">
                    {{ "common.sumInsured" | translate }} ({{
                        item?.typeOfSum
                    }}):
                </div>
                <div class="bold">
                    RM {{ item?.sumInsured | number : "1.2-2" }}
                </div>
            </div>
        </div>
        <div>
            <button
                mat-stroked-button
                class="view-benefits-btn"
                (click)="benefitDialog(item)"
            >
                {{ "comparison.viewBenefit" | translate }}
            </button>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div class="insurer-details">
        <app-insurer-details
            [code]="item?.code"
            [first]="first"
            [insuranceType]="insuranceType"
            fxFlex
            *ngIf="
                insuranceType !== 'TPFT' ||
                item?.code === 'Zurich'
            "
        ></app-insurer-details>
    </div>
    <div class="price-section cheapest">
        <div fxLayout="row">
            <div style="flex: 1.5">
                <div class="price">
                    <div *ngIf="!item?.rebate"><span>RM</span>{{ getTotalBasePremium() | number : "1.2-2" }}</div>
                    <div *ngIf="item?.rebate"><span>RM</span>{{ getAfterRebateTotalBasePremium() | number : "1.2-2" }}</div>
                </div>
                <span *ngIf="item?.rebate">
                    {{ "common.priceBeforeRebate" | translate }} : <s style="color:red"><span style="color: black;">RM{{ getRebate() | number : "1.2-2" }}</span></s>
                </span>
                <!-- ## SPLIT TAG -->
                <div class="split-price">
                    {{ "common.pay" | translate }}
                    <ng-container>
                        <b>RM{{ getTotalPrice() / 12 | number : "1.2-2" }}</b>
                        {{ "common.everyMonth" | translate }} <br />{{
                            "common.duration12" | translate
                        }}! <br />
                        <span>
                            {{ "common.epp" | translate }}
                        </span>
                    </ng-container>
                </div>
                <!-- ## END SPLIT TAG -->
            </div>
            <div style="flex: 1; padding-top: 32px">
                <div></div>
                <button
                    mat-flat-button
                    class="buy-btn"
                    (click)="openCarVariantDialog(item.id)"
                >
                    {{ "comparison.buyNow" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>