<div class="dialog-container">
	<div class="close-button">
		<button mat-icon-button (click)="closeDialog()">
			<mat-icon>close</mat-icon>
		</button>
	</div>
	<div class="content">
		<img
			src="../../../../assets/image/something-went-wrong.svg"
			width="50px"
			alt=""
		/>
		<div>
			<div class="title">
				{{ "endorsement.dialog.somethingWentWrong" | translate }}
			</div>
			<div class="body">
				{{ "endorsement.dialog.thereWasAProblem" | translate }}
			</div>
		</div>
		<button class="btn--red" mat-flat-button (click)="onRetryClick()">
			<div *ngIf="!showSpinner">
				{{ "common.retry" | translate }}
			</div>
			<mat-icon class="spinner" *ngIf="showSpinner"
				><mat-spinner diameter="20"></mat-spinner
			></mat-icon>
		</button>
	</div>
</div>
