import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AffiliateslandingComponent } from "./screens/affiliates-landing/affiliates-landing.component";

const routes: Routes = [
	{
		path: "",
		children: [
			{
				path: "",
				component: AffiliateslandingComponent,
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class AffiliatesRouting {}
