<div class="dialog-container">
	<div class="close-button">
		<button mat-icon-button mat-dialog-close [disabled]="showSpinner">
			<mat-icon>close</mat-icon>
		</button>
	</div>
	<div class="content">
		<img src="../../../../assets/image/warning-sign.svg" width="50px" alt="" />
		<div>
			<div class="title">
				{{ "endorsement.dialog.areYouSure" | translate }}
			</div>
			<div class="body">
				{{ "endorsement.dialog.youWillNot" | translate }}
			</div>
		</div>
		<div class="buttons">
			<button
				class="btn grey"
				[disabled]="showSpinner"
				mat-flat-button
				(click)="onNoClick()"
			>
				{{ "common.no" | translate }}
			</button>
			<button
				class="btn blue"
				mat-flat-button
				(click)="onYesClick()"
				[disabled]="showSpinner"
			>
				<div *ngIf="!showSpinner">
					{{ "common.yes" | translate }}
				</div>
				<mat-icon class="spinner" *ngIf="showSpinner"
					><mat-spinner diameter="20"></mat-spinner
				></mat-icon>
			</button>
		</div>
	</div>
</div>
