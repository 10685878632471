// Angular Core
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router, NavigationExtras } from "@angular/router";
// Angular Material
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
// Services
import { AlertService } from "../../../share/service/alert.service";
import { TranslateService } from "@ngx-translate/core";
import { EndorsementService } from "../../data-service/endorsement.service";
import { LoadingOverlayService } from "src/app/share/service/loading-overlay.service";
import { ValidatorFunction } from "../../../share/function/validator.function";
import { ID_TYPE } from "src/app/share/constants/common.types";
import { SwiperComponent } from "swiper/angular";
import { NgxFileDropEntry, FileSystemFileEntry } from "ngx-file-drop";
// Component
import { ErrorDialogComponent } from "../../ui/error-dialog/error-dialog.component";
import { ConfirmationDialogComponent } from "../../ui/confirmation-dialog/confirmation-dialog.component";
import { WentWrongDialogComponent } from "../../ui/went-wrong-dialog/went-wrong-dialog.component";

@Component({
	selector: "app-endorsement-details",
	templateUrl: "./endorsement-details.component.html",
	styleUrls: ["./endorsement-details.component.scss"],
})
export class EndorsementDetailsComponent implements OnInit {
	@ViewChild("swiper", { static: false }) swiper!: SwiperComponent;
	@ViewChild("fileInput") fileInput: ElementRef;
	documentRequired: boolean = false;
	card1Group: FormGroup;
	card2Card3Group: FormGroup;
	endorsementTypes: any[] = [];
	selectedEndorsement: any;
	idTypeOption = ID_TYPE;
	idType = this.idTypeOption.malaysian;
	policyHolderData: any;
	allowedFileTypes = ["jpg", "jpeg", "pdf", "png", "mp4", "mpeg"];
	fileHovering: boolean = false;
	mouseHovering: boolean = false;
	files: NgxFileDropEntry[] = [];
	previewFiles: any[] = [];
	totalFileSize: number = 0;
	hasSubmittedOnce = false;
	confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>;
	wentWrongDialogRef: MatDialogRef<WentWrongDialogComponent>;

	swiperConfig = {
		spaceBetween: 8,
		breakpoints: {
			960: {
				slidesPerView: 5,
				spaceBetween: 8,
			},
			720: {
				slidesPerView: 4,
				spaceBetween: 6,
			},
			540: {
				slidesPerView: 3,
				spaceBetween: 4,
			},
			0: {
				slidesPerView: 2,
				spaceBetween: 4,
			},
		},
		grabCursor: false,
		autoplay: false,
	};

	constructor(
		public translate: TranslateService,
		private sanitizer: DomSanitizer,
		private endorsementService: EndorsementService,
		private alertService: AlertService,
		private route: ActivatedRoute,
		private router: Router,
		private dialog: MatDialog,
		private loadingService: LoadingOverlayService,
		private fb: FormBuilder,
	) {}

	ngOnInit(): void {
		this.getEndorsementTypeList();
		this.initCard1GroupForm();
		this.initCard2Card3GroupForm();

		const queryParams = this.route.snapshot.queryParams;
		const vehicleNo = queryParams["vehicleNo"];
		const identityType = queryParams["identityType"];
		const identityNo = queryParams["identityNo"];

		if (!history.state.policyHolderData) {
			if (!vehicleNo || !identityType || !identityNo) {
				this.router.navigate(["/endorsement"]);
			} else {
				this.verifyPolicyHolder(vehicleNo, identityType, identityNo);
			}
		} else {
			this.policyHolderData = history.state.policyHolderData;

			this.card1Group.patchValue({
				vehicleNo: this.policyHolderData.vehicleNo,
				identityType: this.policyHolderData.identityType,
				identityNo: this.policyHolderData.identityNo,
			});
			this.card2Card3Group.patchValue({
				name: this.policyHolderData.name,
				email: this.policyHolderData.email,
				mobileNo: this.policyHolderData.mobileNo,
			});
		}
	}

	private initCard1GroupForm() {
		this.card1Group = new FormGroup({
			vehicleNo: new FormControl({ value: "", disabled: true }),
			identityType: new FormControl({ value: "", disabled: true }),
			identityNo: new FormControl({ value: "", disabled: true }),
		});

		this.card1Group.controls.identityType.valueChanges.subscribe((value) => {
			if (value == "Police/Army") {
				this.setPoliceArmy();
				this.idType = this.idTypeOption.police;
			}
			if (value == "Malaysian") {
				this.setMalaysian();
				this.idType = this.idTypeOption.malaysian;
			} else if (value == "Company") {
				this.setIsCompany();
				this.idType = this.idTypeOption.company;
			} else if (value == "Foreigner") {
				this.setNonMalaysian();
				this.idType = this.idTypeOption.foreigner;
			}
		});
	}

	private initCard2Card3GroupForm(): void {
		this.card2Card3Group = this.fb.group({
			name: [{ value: "", disabled: true }],
			email: [{ value: "", disabled: true }],
			mobileNo: [{ value: "", disabled: true }],
			isRenewRoadTax: [null, Validators.required],
			endorsementType: [null, Validators.required],
			description: [null, Validators.required],
			files: this.fb.array([], Validators.required),
		});

		this.card2Card3Group
			.get("endorsementType")
			.valueChanges.subscribe((value) => {
				this.selectedEndorsement = this.endorsementTypes.find(
					(endorsement) => endorsement.id === value,
				);
				this.onDeleteAllFiles();
				this.documentRequired = this.selectedEndorsement.isDocumentRequired;
				if (this.documentRequired) {
					this.card2Card3Group.controls.files.setValidators([
						Validators.required,
					]);
					this.card2Card3Group.controls.files.updateValueAndValidity();
				} else {
					this.card2Card3Group.controls.files.setValidators(null);
					this.card2Card3Group.controls.files.updateValueAndValidity();
				}
			});
	}

	get filesArray() {
		return this.card2Card3Group.get("files") as FormArray;
	}

	getAllFilesFromForm(): File[] {
		return this.card2Card3Group.get("files").value;
	}

	private setMalaysian() {
		this.card1Group.controls.identityNo.setValidators([
			Validators.required,
			ValidatorFunction.nricValidator(),
		]);
		this.card1Group.controls.identityNo.updateValueAndValidity();
	}

	private setPoliceArmy() {
		this.card1Group.controls.identityNo.setValidators([
			Validators.required,
			ValidatorFunction.nricValidator(),
		]);
		this.card1Group.controls.identityNo.updateValueAndValidity();
	}

	private setIsCompany() {
		this.card1Group.controls.identityNo.setValidators([Validators.required]);
		this.card1Group.controls.identityNo.updateValueAndValidity();
	}

	private setNonMalaysian() {
		this.card1Group.controls.identityNo.setValidators([Validators.required]);
		this.card1Group.controls.identityNo.updateValueAndValidity();
	}

	getIdentityNoMasking() {
		return "000000000000";
	}

	uploadFiles(event: any) {
		const files = event.target.files;
		if (files && files.length > 0) {
			for (const file of files) {
				const fileName = file.name;
				this.addFileToFormAndRead(file, fileName);
			}
		}
	}

	handleFiles(files: NgxFileDropEntry[]) {
		this.files = files;
		for (const droppedFile of files) {
			if (droppedFile.fileEntry.isFile) {
				const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
				fileEntry.file((file: File) => {
					const fileName = file.name;
					this.addFileToFormAndRead(file, fileName);
				});
			} else {
				this.alertService.openSnackBar(`Not valid file`);
			}
		}
	}

	addFileToFormAndRead(file: any, fileName: any) {
		const fileType = file.name.split(".").pop()?.toLowerCase();
		if (this.allowedFileTypes.includes(fileType)) {
			if (this.totalFileSize + file.size <= 50 * 1024 * 1024) {
				this.totalFileSize += file.size;
				this.filesArray.push(this.fb.control(file));

				const reader = new FileReader();
				reader.onload = (e: any) => {
					const preview = this.sanitizer.bypassSecurityTrustResourceUrl(
						e.target.result,
					);
					const fileType = file.type.toLowerCase();
					this.previewFiles.push({ type: fileType, preview, fileName });
				};
				reader.readAsDataURL(file);
			} else {
				// First snackbar to fix a bug where it displays on top when drag and drop
				this.alertService.openSnackBar("", null, null, true);
				this.alertService.openSnackBar(`Total File Size exceeded 50MB`);
			}
		} else {
			// First snackbar to fix a bug where it displays on top when drag and drop
			this.alertService.openSnackBar("", null, null, true);
			this.alertService.openSnackBar(
				`Only support JPG, JPEG, PDF, PNG, MP4, MPEG format`,
			);
		}
	}

	onDeletePreview(index: any) {
		const deletedFile = this.filesArray.at(index).value as File;
		this.totalFileSize -= deletedFile.size;
		this.previewFiles.splice(index, 1);
		this.filesArray.removeAt(index);
	}

	onDeleteAllFiles() {
		this.previewFiles.splice(0);
		while (this.filesArray.length > 0) {
			this.filesArray.removeAt(0);
		}
	}

	fileOver() {
		this.fileHovering = true;
	}

	fileLeave() {
		this.fileHovering = false;
	}

	mouseEnter() {
		this.mouseHovering = true;
		this.fileHovering = false;
	}

	mouseleave() {
		this.mouseHovering = false;
	}

	onFileInputClick(event) {
		event.target.value = "";
	}

	onDownloadPreview(index: any) {
		const files = this.getAllFilesFromForm();
		const file = files[index];
		const blob = new Blob([file], {
			type: file.type,
		});
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.href = url;
		a.download = file.name;
		document.body.appendChild(a);
		a.click();
		window.URL.revokeObjectURL(url);
		document.body.removeChild(a);
	}

	// Swiper
	slideNext() {
		this.swiper.swiperRef.slideNext(200);
	}
	slidePrev() {
		this.swiper.swiperRef.slidePrev(200);
	}

	openErrorDialog(contentType: string): void {
		const dialogRef = this.dialog.open(ErrorDialogComponent, {
			panelClass: "no-padding-border-radius-dialog",
			data: {
				contentType: contentType,
			},
		});
		dialogRef.afterClosed().subscribe((result) => {
			this.router.navigate(["/endorsement"]);
		});
	}

	//Api functions
	getEndorsementTypeList() {
		this.endorsementService.getEndorsementTypeList().subscribe({
			next: (x: any) => {
				this.endorsementTypes = x;
			},
			error: (err) => {
				this.alertService.openSnackBar(`Error: ${err.message}`);
				console.log(err);
			},
		});
	}

	submitEndorsement() {
		const formData = new FormData();
		const files = this.getAllFilesFromForm();

		for (const file of files) {
			formData.append("Files", file);
		}

		const card2Card3value = this.card2Card3Group.getRawValue();
		formData.append("MotorPolicyId", this.policyHolderData.motorPolicyId);
		formData.append(
			"IsRenewRoadTax",
			card2Card3value.isRenewRoadTax.toString(),
		);
		formData.append("EndorsementTypeId", this.selectedEndorsement.id);
		formData.append("Description", card2Card3value.description);
		formData.append("Language", this.translate.currentLang);

		// Function to actually submit the endorsement
		const submitOperation = () => {
			this.endorsementService.submitEndorsement(formData).subscribe({
				next: (x) => {
					if (this.confirmationDialogRef) {
						this.confirmationDialogRef.afterClosed().subscribe(() => {
							this.navigateAfterDialogClosed(x);
						});
					}
					if (this.wentWrongDialogRef) {
						this.wentWrongDialogRef.afterClosed().subscribe(() => {
							this.navigateAfterDialogClosed(x);
						});
					}

					this.closeConfirmationDialog();
					this.closeWentWrongDialog();
				},
				error: (err) => {
					this.closeConfirmationDialog();
					this.closeWentWrongDialog();
					// Show the "Went Wrong" dialog with a retry button.
					this.showWentWrongDialogWithRetry();
					console.log(err);
				},
			});
		};

		// Check if it's the first time, if so, show the confirmation dialog
		if (!this.hasSubmittedOnce) {
			this.confirmationDialogRef = this.dialog.open(
				ConfirmationDialogComponent,
				{
					panelClass: "no-padding-border-radius-dialog",
					disableClose: true,
				},
			);

			this.confirmationDialogRef.componentInstance.yesClicked.subscribe(() => {
				this.confirmationDialogRef.componentInstance.showSpinner = true;
				this.hasSubmittedOnce = true;

				// Proceed with the submission
				submitOperation();
			});
		} else {
			// It's not the first time, just proceed with the submission
			// this is coming from wentWrongDialog
			submitOperation();
		}
	}

	navigateAfterDialogClosed(x: any) {
		const endorsementSummaryData = x;
		const navigationExtras: NavigationExtras = {
			state: {
				endorsementSummaryData,
                idType: this.idType
			},
		};

		this.router.navigate(["/endorsement/summary"], navigationExtras);
	}

	showWentWrongDialogWithRetry() {
		this.wentWrongDialogRef = this.dialog.open(WentWrongDialogComponent, {
			panelClass: "no-padding-border-radius-dialog",
			disableClose: true,
		});

		this.wentWrongDialogRef.afterClosed().subscribe((result) => {
			if (result) {
				// if close button is clicked instead of retry,
				// show confirmation dialog when submit is clicked again
				this.hasSubmittedOnce = false;
			}
		});

		this.wentWrongDialogRef.componentInstance.retryClicked.subscribe(() => {
			this.wentWrongDialogRef.componentInstance.showSpinner = true;

			// Retry the submitEndorsement operation.
			this.submitEndorsement();
		});
	}

	closeConfirmationDialog() {
		// Close the confirmation dialog if it's open.
		if (this.confirmationDialogRef) {
			this.confirmationDialogRef.close();
		}
	}

	closeWentWrongDialog() {
		// Close the "Went Wrong" dialog if it's open.
		if (this.wentWrongDialogRef) {
			this.wentWrongDialogRef.close();
		}
	}

	verifyPolicyHolder(
		urlVehicleNo: string,
		urlIdentityType: string,
		urlIdentityNo: string,
	) {
		this.loadingService.setLoading(true);
		if (!urlVehicleNo && !urlIdentityType && !urlIdentityNo) {
			return;
		}
		this.endorsementService
			.verifyPolicyHolder(urlVehicleNo, urlIdentityNo)
			.subscribe({
				next: (x) => {
					this.loadingService.setLoading(false);
					this.policyHolderData = x;
					if (this.policyHolderData.isPolicyExpired) {
						this.openErrorDialog("expired");
						return;
					}

					this.card1Group.patchValue({
						vehicleNo: this.policyHolderData.vehicleNo,
						identityType: urlIdentityType,
						identityNo: this.policyHolderData.identityNo,
					});

					this.card2Card3Group.patchValue({
						name: this.policyHolderData.name,
						email: this.policyHolderData.email,
						mobileNo: this.policyHolderData.mobileNo,
					});
				},
				error: (err) => {
					this.loadingService.setLoading(false);
					if (err.status === 404) {
						this.openErrorDialog("notFound");
					} else {
						this.alertService.openSnackBar(`Error: ${err.message}`);
						console.log(err);
					}
				},
			});
	}
}
