<div>
	<div class="renewal-graphic">
		<img src="assets/image/renewal-graphic.png" alt="" />
	</div>
	<div class="renewal-content">
		<div fxLayout="row">
			<mat-icon> info_outline </mat-icon>
			<div>
				<b>
					{{ "renewal.oopsCarInsurance" | translate }}
				</b>
				<br />
				<br />
				<div>
					{{ "renewal.youHaveLeft" | translate }} {{ day }}
					{{ "renewal.beforeAble" | translate }}
				</div>
				<br />
				<div>
					{{ "renewal.letUsKnow" | translate }}
				</div>
			</div>
		</div>
	</div>

	<div fxLayout="row" fxLayoutGap="16px grid">
		<div fxFlex>
			<button
				mat-flat-button
				class="secondary-btn"
				(click)="handleSubmitReminder()"
			>
				{{ "renewal.yes" | translate }}
			</button>
		</div>
		<div fxFlex class="mt-xs">
			<button mat-dialog-close mat-flat-button class="white-btn">
				{{ "renewal.no" | translate }}
			</button>
		</div>
	</div>
</div>
