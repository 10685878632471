import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {NgxMaskModule} from "ngx-mask";
import { BeforeExpiredDialogComponent } from "./before-expired-dialog/before-expired-dialog.component";
import {RangeSliderComponent} from "./range-slider/range-slider.component";
import { SubmitSuccessDialogComponent } from "./submit-success-dialog/submit-success-dialog.component";
import { MaterialModule } from "../../material-module";
import { TranslateModule } from "@ngx-translate/core";
import { LottieModule } from "ngx-lottie";
import player from "lottie-web";
import { LoadingOverlayComponent } from "./loading-overlay/loading-overlay.component";
import { CarVariantDialogComponent } from './car-variant-dialog/car-variant-dialog.component';

export function playerFactory() {
	return player;
}

@NgModule({
	declarations: [
		BeforeExpiredDialogComponent,
		SubmitSuccessDialogComponent,
		LoadingOverlayComponent,
  CarVariantDialogComponent,
    RangeSliderComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		MaterialModule,
		TranslateModule,
    ReactiveFormsModule,
    NgxMaskModule,
		LottieModule.forRoot({ player: playerFactory }),
	],
	exports: [BeforeExpiredDialogComponent, LoadingOverlayComponent, RangeSliderComponent],
})
export class SharedComponentModule {}
