import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { FaqQuestions } from "./faq-dialog.type";

@Component({
	selector: "app-faq-dialog",
	templateUrl: "./faq-dialog.component.html",
	styleUrls: ["./faq-dialog.component.scss"],
})
export class FaqDialogComponent implements OnInit {
	currentLang: string;
	panelOpenState = false;
	faqQuestions: FaqQuestions = {
		general: [],
		renewal: [],
		claims: [],
		roadtax: [],
	};

	constructor(
		private translate: TranslateService,
		private sanitizer: DomSanitizer,
	) {}

	ngOnInit(): void {
		const faqKeys = Object.keys(this.faqQuestions);

		faqKeys.forEach((key) => {
			this.translate.get(`faq.${key}`).subscribe((translatedText) => {
				this.faqQuestions[key] = translatedText;
			});
		});
	}

	switch(lang: string) {
		this.translate.use(lang);
	}

	getSanitizedAnswer(html: string): SafeHtml {
		const urlRegex = /https?:\/\/[^\s]+/g;
		const replacedHtml = html.replace(
			urlRegex,
			(url) =>
				`<a href="${url}" target="_blank" style="color: #0091FF">${url}</a>`,
		);
		return this.sanitizer.bypassSecurityTrustHtml(replacedHtml);
	}
}
