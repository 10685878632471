import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { PartnerService } from "src/app/share/service/partner.service";

@Component({
	selector: "app-guides-dialog",
	templateUrl: "./guides-dialog.component.html",
	styleUrls: ["./guides-dialog.component.scss"],
})
export class GuidesDialogComponent implements OnInit {
	partnerCode: string;
	forTakaful: boolean = false;

	constructor(
		private partnerService: PartnerService,
		public translate: TranslateService,
	) {}

	ngOnInit(): void {
		this.getPartnerCode();
		this.forTakaful = this.partnerService.getPartnerAttribute(
			this.partnerCode,
			"takaful",
		);
	}

	getPartnerCode() {
		this.partnerCode = this.partnerService.getPartnerCode();
		!this.partnerCode ? (this.partnerCode = "ps") : null;
	}

	switch(lang: string) {
		this.translate.use(lang);
	}
}
