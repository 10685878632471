<mat-card
  class="container {{ class }}"
  [ngClass]="{
    'bundle-selected': selected,
    'addon-selected': addOnSelected,
    'addon-included': includedAddOn,
    'mt-lg': isRoadtaxPromo
  }"
>
  <img
    [src]="'/assets/icons/included_addon_' + translate.currentLang + '.png'"
    alt=""
    *ngIf="includedAddOn"
    class="ribbon"
  />
  <img
    [src]="'/assets/image/roadtax-promo-' + translate.currentLang + '.png'"
    alt=""
    *ngIf="isRoadtaxPromo && !isFreeRoadtax"
    class="ribbon roadtax-ribbon"
  />
  <img
    [src]="'/assets/image/roadtax-promo-full.png'"
    alt=""
    *ngIf="isRoadtaxPromo && isFreeRoadtax"
    class="ribbon roadtax-ribbon"
  />
  <div class="addon-info">
    <div class="addon-main" [ngClass]="isRoadtaxPromo ? 'pt-sm' : ''">
      <div>
        <img [src]="img" alt="" class="addon-img" />
      </div>
      <div class="content">
        <div *ngIf="showRHBRibbon" class="content__header">
          {{ "addOn.exclusiveRHB" | translate }}
        </div>
        <div class="content__title">{{ title }}</div>
        <div class="content__label" *ngIf="label">
          {{ "plans.onlyOn" | translate }}
          <span class="bold">PolicyStreet</span>
        </div>
        <div class="content__desc">{{ desc }}</div>
        <ng-container *ngIf="price && !isRoadtaxPromo">
          <div class="content__price" *ngIf="selected">
            RM 0.00 {{ "plans.included" | translate }}
          </div>
          <div class="content__price" *ngIf="!selected">
            RM {{ price | number : "1.2-2" }}
          </div>
        </ng-container>
        <!-- FOR ROADTAX PROMOTION -->
        <ng-container *ngIf="isRoadtaxPromo">
          <div class="content__price" *ngIf="selected">
            <span class="strikethrough"> RM 0.00 </span>
          </div>
          <div class="content__price" *ngIf="!selected">
            <span class="strikethrough">
              RM {{ price | number : "1.2-2" }}
            </span>
            <span class="roadtax-promo-price">
              RM {{ roadtaxPromoPrice | number : "1.2-2" }}
              <span *ngIf="!isFreeRoadtax">
                {{ "common.only" | translate }}
              </span>
            </span>
          </div>
        </ng-container>
        <ng-content select="div[slot=normalWidth]"></ng-content>
      </div>
    </div>
    <div class="flex">
      <ng-container *ngIf="tooltip && !includedAddOn">
        <!-- For mobile -->
        <ng-container *ngIf="screenSizeService.isMobile$ | async">
          <button
            mat-icon-button
            type="button"
            [matMenuTriggerFor]="Tooltip"
            #tooltipTrigger="matMenuTrigger"
            class="tooltip-button"
          >
            <img src="assets/icons/info-outline.png" alt="" />
          </button>
          <mat-menu
            #Tooltip="matMenu"
            [overlapTrigger]="false"
            class="tooltip-popover-d2c"
          >
            <span (mouseleave)="tooltipTrigger.closeMenu()">
              {{ tooltip }}
            </span>
          </mat-menu>
        </ng-container>

        <!-- For desktop -->
        <ng-container *ngIf="!(screenSizeService.isMobile$ | async)">
          <button
            mat-icon-button
            #tooltip="matTooltip"
            [matTooltip]="processedTooltip"
            matTooltipPosition="below"
          >
            <img src="assets/icons/info-outline.png" alt="" />
          </button>
        </ng-container>
      </ng-container>
      <mat-checkbox
        [checked]="addOnSelected || selected"
        [disabled]="selected"
        (change)="toggleAddOn($event)"
        *ngIf="!includedAddOn"
      ></mat-checkbox>
    </div>
  </div>
  <ng-content select="div[slot=fullWidth]"></ng-content>
</mat-card>
