import { TranslateLoader } from "@ngx-translate/core";

import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { PartnerService } from "./partner.service";

export class CustomLoader implements TranslateLoader {
	private basePath: string = "/assets/i18n";
	constructor(
		private http: HttpClient,
		private partnerService: PartnerService,
	) {}
	getTranslation(langCountry: string): Observable<any> {
		let selected = this.partnerService.partnerList.find(
			(x) => x.code == this.partnerService.getPartnerCode(),
		);

		if (selected?.takaful) {
			return new Observable((obs) => {
				this.http
					.get(`${this.basePath}/${langCountry}.json`)
					.subscribe((parent) => {
						this.http
							.get(`${this.basePath}/${langCountry}-takaful.json`)
							.subscribe((child) => {
								var value = Object.assign({}, parent, child);
								obs.next(value);
							});
					});
			});
		}
		return this.http.get(`${this.basePath}/${langCountry}.json`);
	}
}
