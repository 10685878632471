<mat-card class="bundle-card-container mat-elevation-z0">
	<div *ngIf="recommended">
		<img
			src="https://i.ibb.co/P4SWN7g/Group-10-1.png"
			class="recommended-ribbon"
		/>
		<div class="recommended-text">
			{{ "plans.step1Content.recommended" | translate }}
		</div>
	</div>

	<img [src]="item?.imageUrl" class="main-img" />
	<div class="main-title-container">
		<div class="title bold">{{ item?.code }}</div>
		Protection+
	</div>
	<mat-expansion-panel
		hideToggle
		(opened)="expandedState = true"
		(closed)="expandedState = false"
	>
		<mat-expansion-panel-header class="content-container">
			<div class="desc-container">
				{{ "plans." + item?.code + ".description" | translate }}
				<button mat-icon-button class="icon-button" [disableRipple]="true">
					<mat-icon *ngIf="expandedState"> keyboard_arrow_up </mat-icon>
					<mat-icon *ngIf="!expandedState"> keyboard_arrow_down </mat-icon>
				</button>
			</div>
			<div class="price">
				<div class="price__title">
					RM {{ item?.evaluate | number : "1.2-2" }}
					<div class="price__title__save-label">
						{{ "plans.saveMoreThan" | translate }}
						<span class="bold">RM 100</span>
					</div>
				</div>
				<button
					mat-stroked-button
					color="primary"
					class="price__add-btn"
					(click)="add($event)"
					*ngIf="!selectedBundle.includes(item)"
				>
					{{ "plans.add" | translate }}
					<mat-icon>add</mat-icon>
				</button>
				<button
					mat-stroked-button
					color="primary"
					class="price__add-btn added"
					(click)="remove($event)"
					*ngIf="selectedBundle.includes(item)"
				>
					{{ "plans.added" | translate }}
					<img src="https://i.ibb.co/T8sMvd4/Check-Solid.png" alt="" />
				</button>
			</div>
		</mat-expansion-panel-header>
		<div class="coverage-content">
			<div *ngFor="let item of expandedData" class="coverage">
				<div class="coverage__title">
					<img src="https://i.ibb.co/YTRgCSC/Group-59356.png" alt="" />
					<span
						[innerHTML]="
							item?.title
								| translate : { sumInsured: sumInsured | number : '1.0-0' }
						"
					></span>
				</div>
				<ng-container *ngIf="item?.desc">
					<li *ngFor="let x of item?.desc" class="coverage__desc">
						{{ x | translate }}
					</li>
				</ng-container>
			</div>
			<div *ngIf="expandedData.length" class="view-more-container">
				<div>{{ "plans.andMore" | translate }}</div>
				<button
					mat-stroked-button
					class="view-more-container__btn"
					(click)="downloadLink()"
				>
					{{ "plans.step1Content.viewFullCoverage" | translate }}
				</button>
			</div>
		</div>
	</mat-expansion-panel>
</mat-card>
