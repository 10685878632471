// Angular Core
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
// Angular Material
import { MaterialModule } from "../material-module";
import { FlexLayoutModule } from "@angular/flex-layout";
// Services
import { AffiliatesRouting } from "./affiliates-routing.module";
import { TranslateModule } from "@ngx-translate/core";
// Components
import { AffiliateslandingComponent } from "./screens/affiliates-landing/affiliates-landing.component";
// Utilities
import { NgxMaskModule } from "ngx-mask";
import { NgxMatIntlTelInputModule } from "ngx-mat-intl-tel-input";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { SwiperModule } from "swiper/angular";
import { MatSelectInfiniteScrollModule } from "ng-mat-select-infinite-scroll";
import { CustomPipeModule } from "../share/pipe/custom-pipe.module";
import { BenefitModule } from "../share/component/benefit/benefit.module";
import { InsurerDetailsModule } from "./../share/component/insurer-details/insurer-details.module";
import { SharedComponentModule } from "../share/component/shared-component.module";
import { LoadingQuotationComponent } from "../share/component/loading-quotation/loading-quotation.component";

@NgModule({
	declarations: [AffiliateslandingComponent, LoadingQuotationComponent],
	imports: [
		AffiliatesRouting,
		CommonModule,
		MaterialModule,
		FlexLayoutModule,
		FormsModule,
		SwiperModule,
		ReactiveFormsModule,
		TranslateModule,
		CustomPipeModule,
		NgxMaskModule,
		NgxMatIntlTelInputModule,
		NgxMatSelectSearchModule,
		MatSelectInfiniteScrollModule,
		BenefitModule,
		InsurerDetailsModule,
		SharedComponentModule,
	],
})
export class AffiliatesModule {}
