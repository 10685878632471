import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-expandable-card",
  templateUrl: "./expandable-card.component.html",
  styleUrls: ["./expandable-card.component.scss"],
})
export class ExpandableCardComponent implements OnInit {
  @Input() forCarDetails: boolean = false;
  @Input() title: string = "";
  @Input() disableBtn?: boolean;

  @Output() onEdit = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  edit(e: any) {
    e.stopPropagation();
    this.onEdit.emit();
  }
}
