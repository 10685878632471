import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { EndorsementPaymentDetailInterface, EndorsementPaymentFormInterface } from "src/app/dashboard/endorsement-payment/endorsement.interface";

@Injectable({
	providedIn: "root",
})
export class EndorsementService {
	private basePath: string = `${environment.url}/api/EndorsementSubmission/`;
	private basePathPayment: string = `${environment.url}/api/Endorsement/`;

	constructor(private http: HttpClient) {}

	verifyPolicyHolder(vehicleNo: string, identityNo: string) {
		return this.http.get(
			`${this.basePath}VerifyPolicyHolder?vehicleNo=${vehicleNo}&identityNo=${identityNo}`,
		);
	}

	getEndorsementTypeList() {
		return this.http.get(`${this.basePath}GetEndorsementTypeList`);
	}

	submitEndorsement(formData: FormData) {
		return this.http.post(`${this.basePath}SubmitEndorsement/`, formData);
	}

	getEndorsementPaymentDetail(id: string) {
		return this.http.get<EndorsementPaymentDetailInterface>(`${this.basePathPayment}GetEndorsementPaymentDetail/${id}`);
	}

	generateEndorsementPayment(id: string, data: EndorsementPaymentFormInterface) {
		return this.http.post(`${this.basePathPayment}GenerateEndorsementPayment/${id}`, data);
	}

}
