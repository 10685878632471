<div [class.insurer-card--first]="isFirst" class="insurer-card">
  <div class="insurer-card__header">
    <div class="insurer-card__logo-container">
      <img [src]="quotation.image" alt="insurer logo"/>
    </div>

    <div class="insurer-card__header-info">
      <div class="insurer-card__price-container">
        RM
        <div class="insurer-card__price">{{ quotation.total | number: "1.2-2" }}</div>
      </div>
      <div class="insurer-card__sum-insured">
        <div> {{ "common.sumInsured" | translate }} ({{ "common." + quotation?.typeOfSum | translate }}):</div>
        <div>{{ quotation.sumInsured | currency: "RM " }}</div>
      </div>
    </div>
  </div>

  <div class="insurer-card__body">
    <div class="benefits__title">{{ "benefits.title" | translate }}</div>

    <ng-container [ngSwitch]="quotation.code">
      <app-sompo-benefit *ngSwitchCase="'Sompo'"
       [isFirst]="isFirst"
      ></app-sompo-benefit>
      <app-msig-benefit *ngSwitchCase="'MSIG'"
        [isFirst]="isFirst"
      ></app-msig-benefit>
      <app-msig-benefit *ngSwitchCase="'RHB'"
        [isFirst]="isFirst"
      ></app-msig-benefit>
    </ng-container>

    <div class="insurer-card__divider"></div>

    <button (click)="onBuyNow()" class="insurer-card__button" matRipple type="button">
      {{ "comparison.buyNow" | translate }}
    </button>

    <div class="insurer-card__divider"></div>

    <div class="insurer-card__documents">
      <ng-container *ngFor="let pd of filterProductDocuments(); let last = last">
        <div (click)="onClickProductDocument(pd)" class="insurer-card__document-item">
          <img alt="Open In New" src="/assets/image/ktmb/open-{{ isFirst ? 'white' : 'blue' }}.svg">
          <div>{{ pd.name }}</div>
        </div>

        <div *ngIf="!last" class="insurer-card__document-divider"></div>
      </ng-container>
    </div>
  </div>
</div>
