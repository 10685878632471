import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-msig-benefit',
  templateUrl: './msig-benefit.component.html',
  styleUrls: ['./msig-benefit.component.scss', '../benefit.component.scss']
})
export class MsigBenefitComponent {
  @Input() isFirst = false;
}
