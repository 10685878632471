<div class="ftr">
  <div class="ktmb-note" *ngIf="partnerCode === 'ktmb' && stepperIndex !== undefined"><b>{{ "footer.importantNote" | translate }}</b>: {{ "footer.pleaseContact" | translate }}
  </div>
  <div class="ftr__container">
    <ng-container [ngSwitch]="isCustomFooter">
      <!-- Non Custom footer -->
      <ng-container *ngSwitchCase="false">
        <!-- Logo -->
        <div class="ftr__first">
          <div class="ftr__column">
            <div class="ftr__logo">
              <span> {{ "footer.poweredBy" | translate }} </span>
              <img
                src="{{ _commonInfo.policystreet.logoUrl }}"
                alt="policystreet"
              />
            </div>
          </div>
          <div class="ftr__column address">
            <!-- Address -->
            <div class="ftr__address">
              <span> {{ _commonInfo.policystreet.address }} </span>
            </div>

            <!-- Contacts -->
            <div class="mt-md">
              <img
                class="ftr__icon"
                src="./assets/icons/footer/whatsapp-icon.png"
                alt=""
              />
              <span> {{ _commonInfo.policystreet.whatsapp }} </span>
              <span>({{ "footer.whatsAppMessage" | translate }})</span>
            </div>

            <div>
              <img
                class="ftr__icon"
                src="./assets/icons/footer/email-fill.png"
                alt=""
              />
              <span> {{ _commonInfo.policystreet.email }} </span>
            </div>
          </div>
        </div>

        <ng-container *ngTemplateOutlet="payment"></ng-container>
      </ng-container>

      <!-- Custom footer -->
      <ng-container *ngSwitchCase="true">
        <ng-container [ngSwitch]="partnerCode">
          <!-- Custom footer that's not carsome -->
          <ng-container *ngSwitchDefault>
            <!-- Logo -->
            <div class="ftr__column">
              <div class="ftr__custom__logo">
                <img
                  src="./assets/image/{{ partnerCode }}/{{
                    partnerCode
                  }}-logo.png"
                  alt=""
                />
                <span> {{ partnerInfo.copyright.value }} </span>
              </div>
            </div>
            <!-- Contacts -->
            <div class="ftr__column" *ngIf="partnerInfo.mobile.isDisplay">
              <div>
                <img
                  class="ftr__icon"
                  src="./assets/icons/footer/whatsapp-fill.png"
                  alt=""
                />
                <span> {{ partnerInfo.mobile.value }} </span>
              </div>
            </div>
            <div class="ftr__column" *ngIf="partnerInfo.email.isDisplay">
              <div>
                <img
                  class="ftr__icon"
                  src="./assets/icons/footer/email-fill.png"
                  alt=""
                />
                <span> {{ partnerInfo.email.value }} </span>
              </div>
            </div>
          </ng-container>
          <!-- Custom carsome footer -->
          <ng-container *ngSwitchCase="'carsome'">
            <div class="carsome__ftr">
              <div class="carsome__ftr__left">
                <img
                  src="./assets/image/{{ partnerCode }}/{{
                    partnerCode
                  }}-logo.png"
                  alt=""
                />
                <p class="copyright-text">{{ partnerInfo.copyright.value }}</p>
              </div>

              <mat-divider [vertical]="true"></mat-divider>

              <div class="carsome__ftr__right">
                <div class="carsome__ftr__right__question">
                  {{ "footer.haveAQuestion" | translate }}
                </div>

                <div class="carsome__ftr__right__email">
                  <div class="email-icon-container">
                    <mat-icon class="icon-display">mail_outlined</mat-icon>
                  </div>
                  <span class="email-content">
                    {{ partnerInfo.email.value }}
                  </span>
                </div>
              </div>
            </div>
          </ng-container>
          <app-ktmb-footer *ngSwitchCase="'ktmb'" [info]="partnerInfo">
            <ng-container *ngTemplateOutlet="payment"></ng-container>
          </app-ktmb-footer>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>

  <div class="ftr__bottom" [ngClass]="getFooterClasses()">
    <span>
      {{ "footer.licensed1" | translate }}
      <span>{{ _commonInfo.policystreet.regNo }}</span>
      {{ "footer.licensed2" | translate }}
    </span>
  </div>
</div>


<ng-template #payment>
  <div class="ftr__second">
    <div class="ftr__column">
      <div>
        <span>{{ "footer.paymentMethod" | translate }}</span>
        <div>
          <img src="./assets/icons/bank_payment/visa.svg" alt="visa" />
          <img
            src="./assets/icons/bank_payment/mastercard.svg"
            alt="mastercard"
          />
          <img src="./assets/icons/bank_payment/fpx.svg" alt="fpx" />
          <img src="./assets/icons/bank_payment/tng.svg" alt="tng" />
          <img
            src="./assets/icons/bank_payment/grabpay.svg"
            alt="grabpay"
          />
          <img src="./assets/icons/bank_payment/boost.svg" alt="boost" />
          <img
            src="./assets/icons/bank_payment/shopback.svg"
            alt="shopback"
          />
          <img
            src="./assets/icons/bank_payment/spay.png"
            alt="spaylater_spay"
          />
          <img
            src="./assets/icons/bank_payment/spaylater.png"
            alt="spaylater_spay"
          />
        </div>
      </div>
    </div>

    <div class="ftr__column">
      <div>
        <span>{{ "footer.acceptedEppBanks" | translate }}</span>
        <div>
          <img
            src="./assets/icons/bank_payment/maybank.svg"
            alt="maybank"
          />
          <img src="./assets/icons/bank_payment/cimb.svg" alt="cimb" />
          <img src="./assets/icons/bank_payment/rhb.svg" alt="rhb" />
          <img src="./assets/icons/bank_payment/am.svg" alt="ambank" />
          <img src="./assets/icons/bank_payment/ocbc.svg" alt="ocbc" />
          <img
            src="./assets/icons/bank_payment/public.svg"
            alt="public"
          />
          <img src="./assets/icons/bank_payment/affin.svg" alt="affin" />
          <img src="./assets/icons/bank_payment/hlb.svg" alt="hlb" />
          <img src="./assets/icons/bank_payment/uob.svg" alt="uob" />
        </div>
      </div>
    </div>
  </div>
</ng-template>
