<!-- <div id="loading-container" *ngIf="loadingService.loading$ | async"></div> -->
<!-- <div id="loading-container"></div> -->
<!-- <div class="loading-container" [ngStyle]="{ 'display': (loadingService.loading$ | async) ? 1000 : -1000 }"> -->
<!-- <div class="loading-container" [ngStyle]="{ 'display': (loadingService.loading$ | async) ? 'flex' : 'none' }"
    [ngClass]="{ 'fade-out': !(loadingService.loading$ | async) }">
    <div id="loading-animation"></div>
</div> -->
<div class="loading-container"
    [ngClass]="{ 'fade-out': !(loadingService.loading$ | async) }">
    <ng-lottie 
    [options]="animationOptions"
    width="300px"
    height="300px"></ng-lottie>
</div>