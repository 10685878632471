<div class="page">
	<div class="page-content">
		<div class="card1 card-background">
			<h1 class="card1__header">
				{{ "endorsement.whatWouldYouLIke" | translate }}
			</h1>
			<div class="card1__subheading">
				<h3>
					{{ "endorsement.enterDetails" | translate }}
				</h3>
			</div>
			<div [formGroup]="card1Group" class="card1__forms forms">
				<mat-form-field appearance="outline" class="upperc-input">
					<mat-label class="label">{{
						"common.carPlateNo" | translate
					}}</mat-label>
					<input
						matInput
						required
						formControlName="vehicleNo"
						mask="A{255}"
						[validation]="false"
						autocomplete="off"
					/>
				</mat-form-field>
				<ng-container>
					<div class="forms__identity mt-xs">
						<div class="forms__identity__dropdown">
							<mat-form-field appearance="outline">
								<mat-select formControlName="identityType">
									<mat-option [value]="idTypeOption.malaysian">{{
										"common.Malaysian" | translate
									}}</mat-option>
									<mat-option [value]="idTypeOption.company">{{
										"common.Company" | translate
									}}</mat-option>
									<mat-option [value]="idTypeOption.foreigner">{{
										"common.Foreigner" | translate
									}}</mat-option>
									<mat-option [value]="idTypeOption.police">{{
										"common.Police/Army" | translate
									}}</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div class="forms__identity__input">
							<ng-container [ngSwitch]="idType">
								<ng-container *ngSwitchCase="idTypeOption.malaysian">
									<mat-form-field appearance="outline">
										<mat-label class="label">
											{{ "common.icNoLabel" | translate }}
										</mat-label>
										<input
											matInput
											required
											formControlName="identityNo"
											[mask]="getIdentityNoMasking()"
											autocomplete="off"
											[maxLength]="12"
										/>
									</mat-form-field>
								</ng-container>
								<ng-container *ngSwitchCase="idTypeOption.company">
									<mat-form-field appearance="outline">
										<mat-label class="label">{{
											"common.businessRegistrationNo" | translate
										}}</mat-label>
										<input
											matInput
											required
											formControlName="identityNo"
											autocomplete="off"
										/>
									</mat-form-field>
								</ng-container>
								<ng-container *ngSwitchCase="idTypeOption.foreigner">
									<mat-form-field appearance="outline">
										<mat-label class="label">{{
											"common.passportLabel" | translate
										}}</mat-label>
										<input
											matInput
											required
											formControlName="identityNo"
											autocomplete="off"
										/>
									</mat-form-field>
								</ng-container>
								<ng-container *ngSwitchCase="idTypeOption.police">
									<mat-form-field appearance="outline">
										<mat-label class="label">
											{{ "common.icNoLabel" | translate }}
										</mat-label>
										<input
											matInput
											required
											formControlName="identityNo"
											[mask]="getIdentityNoMasking()"
											autocomplete="off"
											[maxLength]="12"
										/>
									</mat-form-field>
								</ng-container>
							</ng-container>
						</div>
					</div>
				</ng-container>
				<button
					class="forms__next-btn btn--blue"
					mat-flat-button
					[disabled]="!card1Group.valid"
					(click)="verifyPolicyHolder()"
				>
					{{ "common.next" | translate }}
				</button>
			</div>
		</div>
	</div>
</div>
