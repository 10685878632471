import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppBlankComponent } from './layouts/Blank/blank.component';
import { AppFullComponent } from './layouts/full/full.component';

// DashboardComponents
import { ComparisonComponent } from "./dashboard/comparison/comparison.component";
import { ComplainComponent } from "./dashboard/complain/complain.component";
import { CustomerPolicyComponent } from "./dashboard/customer-policy/customer-policy.component";
import { RefundRequestComponent } from "./dashboard/refund-request/refund-request.component";
import { StaticStylesComponent } from "./dashboard/static-styles/static-styles.component";
import { ThankyouPageComponent } from "./dashboard/thankyou-page/thankyou-page.component";
import { TopupRequestComponent } from "./dashboard/topup-request/topup-request.component";
import { UploadDocumentsComponent } from "./dashboard/upload-documents/upload-documents.component";
import { EndorsementPaymentComponent } from './dashboard/endorsement-payment/endorsement-payment.component';

const routes: Routes = [
  {
    path: '',
    component: AppFullComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./d2c/d2c.module').then((m) => m.D2cModule),
      },
      {
        path: 'affiliates',
        loadChildren: () => import('./affiliates/affiliates.module').then((m) => m.AffiliatesModule),
      },
      {
				path: "comparison/:id",
				component: ComparisonComponent,
			},
			{
				path: "customer-policy",
				component: CustomerPolicyComponent,
			},
			{
				path: "refund-request",
				component: RefundRequestComponent,
			},
			{
				path: "upload-documents/:id",
				component: UploadDocumentsComponent,
			},
			{
				path: "topup-request/:id",
				component: TopupRequestComponent,
			},
			{
				path: "endorsement-payment/:id",
				component: EndorsementPaymentComponent,
			},
			{
				path: "checkout",
				component: ThankyouPageComponent,
			},
			{
				path: "complain",
				component: ComplainComponent,
			},
			{
				path: "styles",
				component: StaticStylesComponent,
			},
    ],
  },
  {
    path: '',
    component: AppFullComponent,
    children: [
      {
        path: 'payment',
        loadChildren: () =>
          import('./payment/payment.module').then((m) => m.PaymentModule),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
