<div class="range-slider" [class.range-slider-small]="variantSmall">
  <div class="range-slider__field">
    <button (click)="onRemove()" class="range-slider__action range-slider__action--remove" mat-icon-button type="button">
      <mat-icon class="range-slider__icon">remove</mat-icon>
    </button>
    <mat-form-field appearance="outline">
      <input (blur)="onTouched()" [dropSpecialCharacters]="false" [formControl]="formControl"
             class="range-slider__input" mask="0*.00" matInput>
    </mat-form-field>
    <button (click)="onAdd()" class="range-slider__action range-slider__action--add" mat-icon-button type="button">
      <mat-icon class="range-slider__icon">add</mat-icon>
    </button>
  </div>

  <div class="range-slider__hint">
    <span>{{ min | currency: 'RM ' }}</span>
    <span>{{ max | currency: 'RM ' }}</span>
  </div>

  <div class="range-slider__errors">
    <span *ngIf="formControl.hasError('min')">
      {{ this.minError | translate : { minSI : min | number : "1.2-2"} }}
    </span>
    <span *ngIf="formControl.hasError('max')">
      {{ this.maxError | translate : { maxSI : max | number : "1.2-2"} }}
    </span>
  </div>
</div>
