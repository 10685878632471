<button mat-icon-button class="dismiss-btn" (click)="dismissDialog()">
	<mat-icon>close</mat-icon>
</button>
<!-- <a href="">
  Insurance
</a>

<a href="">
  Blog
</a>

<a href="">
  Contact Us
</a> -->

<a (click)="faqDialog()" *ngIf="showFAQ">
	{{ "floatingMenu.faq" | translate }}
</a>

<a (click)="guideDialog()">
	{{ "floatingMenu.guide" | translate }}
</a>
