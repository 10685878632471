import { InsurerDetailsModule } from "./../share/component/insurer-details/insurer-details.module";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "../material-module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { CustomPipeModule } from "../share/pipe/custom-pipe.module";
import { NgxMaskModule } from "ngx-mask";
import { NgxMatIntlTelInputModule } from "ngx-mat-intl-tel-input";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { SwiperModule } from "swiper/angular";
import { MatSelectInfiniteScrollModule } from "ng-mat-select-infinite-scroll";
import { D2cRoutingModule } from "./d2c-routing.module";
import { PlansComponent } from "./screens/plans/plans.component";
import { SectionTitleComponent } from "./ui/section-title/section-title.component";
import { ExpandableCardComponent } from "./ui/expandable-card/expandable-card.component";
import { BundleCardComponent } from "./ui/bundle-card/bundle-card.component";
import { FlexContainerComponent } from "./ui/flex-container/flex-container.component";
import { AddonCardComponent } from "./ui/addon-card/addon-card.component";
import { SummaryCardComponent } from "./ui/summary-card/summary-card.component";
import { EditQuotationComponent } from "./screens/edit-quotation/edit-quotation.component";
import { MobileBottombarComponent } from "./ui/mobile-bottombar/mobile-bottombar.component";
import { RenewQuotationComponent } from "./screens/renew-quotation/renew-quotation.component";
import { CustomerDetailsDialogComponent } from "./ui/customer-details-dialog/customer-details-dialog.component";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatDialogModule } from "@angular/material/dialog";
import { CustomerDetailsBottomsheetComponent } from "./ui/customer-details-bottomsheet/customer-details-bottomsheet.component";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { CustomerDetailsCardComponent } from "./ui/customer-details-card/customer-details-card.component";
import { LandingComponent } from "./screens/landing/landing.component";
import { SharedComponentModule } from "../share/component/shared-component.module";
import { LoadingQuotationComponent } from "./screens/loading-quotation/loading-quotation.component";
import { EndorsementComponent } from "./screens/endorsement/endorsement.component";
import { UploadCarouselPreviewCardComponent } from "./ui/upload-carousel-preview-card/upload-carousel-preview-card.component";
import { ErrorDialogComponent } from "./ui/error-dialog/error-dialog.component";
import { ConfirmationDialogComponent } from "./ui/confirmation-dialog/confirmation-dialog.component";
import { WentWrongDialogComponent } from "./ui/went-wrong-dialog/went-wrong-dialog.component";
import { EndorsementSummaryComponent } from "./screens/endorsement-summary/endorsement-summary.component";
import { EndorsementDetailsComponent } from "./screens/endorsement-details/endorsement-details.component";
import { NgxFileDropModule } from "ngx-file-drop";
import { PaymentOptionsComponent } from "./ui/payment-options/payment-options.component";
import { MobileTotalAmountComponent } from "./ui/mobile-total-amount/mobile-total-amount.component";
import { OrderSummaryNoteComponent } from "./ui/order-summary-note/order-summary-note.component";
import { PsInsurerCardComponent } from "./ui/comparison/ps-insurer-card/ps-insurer-card.component";
import { ExcessComponent } from "./ui/excess/excess.component";
import { InsurerCardComponent } from "./ui/comparison/insurer-card/insurer-card.component";
import { InsurerCardV2Component } from "./ui/comparison/insurer-card-v2/insurer-card-v2.component";
import { MatDividerModule } from "@angular/material/divider";
import { RemindMeDialogComponent } from "./ui/remind-me-dialog/remind-me-dialog.component";
import { LoadingInsurerCardComponent } from "./ui/comparison/loading-insurer-card/loading-insurer-card.component";
import { OtherInsurerCardComponent } from "./ui/comparison/other-insurer-card/other-insurer-card.component";

@NgModule({
  declarations: [
    PlansComponent,
    SectionTitleComponent,
    ExpandableCardComponent,
    BundleCardComponent,
    FlexContainerComponent,
    AddonCardComponent,
    SummaryCardComponent,
    EditQuotationComponent,
    MobileBottombarComponent,
    RenewQuotationComponent,
    CustomerDetailsDialogComponent,
    CustomerDetailsBottomsheetComponent,
    CustomerDetailsCardComponent,
    LandingComponent,
    LoadingQuotationComponent,
    EndorsementComponent,
    UploadCarouselPreviewCardComponent,
    ErrorDialogComponent,
    ConfirmationDialogComponent,
    WentWrongDialogComponent,
    EndorsementSummaryComponent,
    EndorsementDetailsComponent,
    PaymentOptionsComponent,
    MobileTotalAmountComponent,
    OrderSummaryNoteComponent,
    ExcessComponent,
    PsInsurerCardComponent,
    InsurerCardComponent,
    InsurerCardV2Component,
    RemindMeDialogComponent,
    LoadingInsurerCardComponent,
    OtherInsurerCardComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    D2cRoutingModule,
    FlexLayoutModule,
    FormsModule,
    SwiperModule,
    ReactiveFormsModule,
    TranslateModule,
    CustomPipeModule,
    NgxMaskModule,
    MatDividerModule,
    NgxMatIntlTelInputModule,
    NgxMatSelectSearchModule,
    MatSelectInfiniteScrollModule,
    InsurerDetailsModule,
    MatBottomSheetModule,
    DragDropModule,
    MatDialogModule,
    ClipboardModule,
    SharedComponentModule,
    NgxFileDropModule,
  ],
  exports: [
    LoadingQuotationComponent,
    PaymentOptionsComponent,
    ExpandableCardComponent,
    MobileBottombarComponent,
    FlexContainerComponent,
    SectionTitleComponent,
    OrderSummaryNoteComponent,
    MobileTotalAmountComponent,
  ],
})
export class D2cModule {}
