import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import * as moment from "moment";
import { MotorDataDataService } from "src/app/share/data-service/motor.data.service";
import { MotorQuotationInterface } from "src/app/share/interface/motor-quotation.interface";

@Component({
  selector: "app-submit-success-dialog",
  templateUrl: "./submit-success-dialog.component.html",
  styleUrls: ["./submit-success-dialog.component.scss"],
})
export class SubmitSuccessDialogComponent {
  data: any;
  editQuotations: boolean = false;
  onOperation: boolean;
  responseTime: string = "5 - 20";
  channel;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    data: {
      content?: string;
      boldContent?: string;
      editQuotations?: boolean;
      customerInput: Partial<MotorQuotationInterface>;
    },
    gtmService: GoogleTagManagerService,
    private motorDataService: MotorDataDataService,
  ) {
    this.data = data;
    this.editQuotations = data.editQuotations;
    gtmService.pushTag({
      event: "car_leads",
    });
    this.isOnOperation();
    this.channel = this.data.customerInput?.preferredChannel;
  }

  private isOnOperation() {
    this.motorDataService.checkIsOfficeHour().subscribe((response) => {
      this.onOperation = response === "YES";
    });
  }
}
