<div class="ktmb-dialog">
  <div class="ktmb-dialog__title" translate>{{ "faq.mainTitle" }}</div>
  <div class="ktmb-dialog__close">
    <button mat-dialog-close mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<div class="ktmb-dialog__content">
  <div *ngFor="let faq of faqList" class="ktmb-dialog__item">
    <div class="ktmb-dialog__item-header">
      <div class="ktmb-dialog__item-icon">
        <img alt="PDF" src="/assets/icons/pdf.svg" width="32"/>
      </div>
      <div class="ktmb-dialog__item-title">{{ faq.label }}</div>
    </div>

    <button color="primary" mat-flat-button (click)="onDownloadHere(faq)">
      <span translate>{{ "common.downloadHere" }}</span>
    </button>
  </div>
</div>
