import { HttpClient } from "@angular/common/http";
import { Injectable, KeyValueDiffers } from "@angular/core";
import { environment } from "../../../environments/environment";
import { API_DATA } from "../static/api-data";

@Injectable({
	providedIn: "root",
})
export class MotorBundleService {
	private basePath: string = `${environment.url}/api/Motor/`;

	constructor(private http: HttpClient) {}

	getAllBundleData() {
		return API_DATA;
	}

	getMotorBundle(id: string, quotationId: string) {
		return this.http.get(
			`${this.basePath}GetMotorBundle/${id}?quotationId=${quotationId}`
		);
	}

	getMotorBundleAddOn(id: string, quotationId: string) {
		return this.http.get(
			`${this.basePath}GetMotorBundleAddOn/${id}?quotationId=${quotationId}`
		);
	}

	generateBundlePayment(id: string, values: any){
		return this.http.post(
			`${this.basePath}GenerateBundlePayment/${id}`, values
		);
	}
	
	renewalSubmission(id: string, values: any){
		return this.http.post(
			`${this.basePath}RenewalSubmission/${id}`, values
		);
	}
}
