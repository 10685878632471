<div class="dialog-container">
	<div class="close-button">
		<button mat-icon-button mat-dialog-close>
			<mat-icon>close</mat-icon>
		</button>
	</div>
	<div class="content">
		<img src="../../../../assets/image/warning-sign.svg" width="50px" alt="" />
		<div class="title" *ngIf="contentType === 'expired'">
			{{ "endorsement.dialog.policyExpired" | translate }}
		</div>
		<div *ngIf="contentType === 'notFound'">
			<div class="title">
				{{ "endorsement.dialog.policyNotFound" | translate }}
			</div>
			<div class="body">
				{{ "endorsement.dialog.enterCorrectPlate" | translate }}
			</div>
		</div>
		<button class="btn--blue" mat-flat-button (click)="closeDialog()">
			{{ "common.ok" | translate }}
		</button>
	</div>
</div>
