import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-uploaded-image-dialog',
  templateUrl: './uploaded-image-dialog.component.html',
  styleUrls: ['./uploaded-image-dialog.component.scss']
})
export class UploadedImageDialogComponent implements OnInit {
  preview: SafeResourceUrl;
  file = this.data.file;
  isPdf = this.data.file.type === "application/pdf";

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: UploadedImageDialogData,
    private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.getFilePreview();
  }

  private getFilePreview(): void {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.preview = this.sanitizer.bypassSecurityTrustResourceUrl(e.target.result as string);
    }
    fileReader.readAsDataURL(this.data.file);
  }
}

export interface UploadedImageDialogData {
  file: File
}
