export interface ILoadingWords {
	first: string;
	second: string;
}

export const LOADING_KEYWORDS_LIST: string[] = [
	"plans.loadingPage.keyword_1",
	"plans.loadingPage.keyword_2",
	"plans.loadingPage.keyword_3",
	"plans.loadingPage.keyword_4",
	"plans.loadingPage.keyword_5",
];

export const LOADING_WORDINGS_LIST: ILoadingWords[] = [
	{
		first: "plans.loadingPage.loadingText1_1",
		second: "plans.loadingPage.loadingText1_2",
	},
	{
		first: "plans.loadingPage.loadingText2_1",
		second: "plans.loadingPage.loadingText2_2",
	},
	{
		first: "plans.loadingPage.loadingText1_1",
		second: "plans.loadingPage.loadingText1_2",
	},
	{
		first: "plans.loadingPage.loadingText2_1",
		second: "plans.loadingPage.loadingText2_2",
	},
	{
		first: "plans.loadingPage.loadingText3_1",
		second: "plans.loadingPage.loadingText3_2",
	},
];
