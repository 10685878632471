import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-summary-note',
  templateUrl: './order-summary-note.component.html',
  styleUrls: ['./order-summary-note.component.scss']
})
export class OrderSummaryNoteComponent {


  @Input() text: string;

  constructor() { }

}
