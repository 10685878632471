import { Injectable } from "@angular/core";
import { Subject, Observable, interval, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class TimerService {
  private timerExpiredSource = new Subject<void>();
  timerExpired$: Observable<void> = this.timerExpiredSource.asObservable();
  private timerSubscription: Subscription | null = null;

  startTimer(duration: number = 5 * 60 * 1000) {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }

    this.timerSubscription = interval(duration)
      .pipe(takeUntil(this.timerExpiredSource))
      .subscribe(() => this.timerExpiredSource.next());
  }

  stopTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }
  }
}
