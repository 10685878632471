<div style="background: #eef4fc">
	<div style="max-width: 950px; margin: 0 auto; padding: 20px">
		<div class="topup-form">
			<form>
				<div fxLayout="row wrap" fxLayoutGap="16px grid">
					<div fxFlex.lt-md="100" fxFlex.gt-sm="60">
						<div class="section-container mb-lg">
							<div class="title-sec">
								<div class="title-f">
									{{ "common.orderSummary" | translate }}
									{{ "topup.revised" | translate }}
								</div>
								<div class="desc">
									{{ "common.orderSummaryDesc" | translate }}
								</div>
							</div>
						</div>
						<div class="mb-sm">
							<div fxLayout="row" class="mb-sm" fxLayoutAlign=" center">
								<div class="mdTitle-f">
									{{ "common.carProtectionPlan" | translate }}
								</div>
								<div class="summary-divider grey" fxFlex></div>
							</div>
							<div fxLayout="row" fxLayoutGap="16px grid">
								<div fxFlex="50">
									<mat-card>
										<img
											class="full-width"
											[src]="topupUserData?.policyDetail?.productImage"
											alt=""
										/>
									</mat-card>
								</div>
								<div class="content-wrapper m-t-0" fxFlex="50">
									<div>
										<div class="label-desc">
											{{ "common.insuranceProvider" | translate }} :
										</div>
										<div class="label-value">
											{{ topupUserData?.policyDetail?.productName }}
										</div>
									</div>
									<div>
										<div class="label-desc">
											{{ "common.typeOfInsurance" | translate }} :
										</div>
										<div class="label-value">
											{{ "common." + topupUserData?.policyDetail?.insuranceType | translate }}
										</div>
									</div>
									<div>
										<div class="label-desc">
											{{ "common.sumInsured" | translate }} :
										</div>
										<div class="label-value">
											RM
											{{ topupUserData?.policyDetail?.sumInsured | number : "1.2-2" }}
										</div>
									</div>
									<div>
										<div class="label-desc">
											{{ "common.effectivePeriod" | translate }} :
										</div>
										<div class="label-value">
											{{ topupUserData?.policyDetail?.effectiveDate | date : "dd MMM yyyy" }}
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- ROADTAX SECTION -->
						<div class="mb-sm" *ngIf="topupUserData?.policyDetail?.includeRoadTax">
							<div fxLayout="row" class="mb-sm" fxLayoutAlign=" center">
								<div class="mdTitle-f">{{ "addOn.addOns" | translate }}</div>
								<div class="summary-divider grey" fxFlex></div>
							</div>
							<div class="add-ons-container">
								<div class="add-ons-cover" *ngIf="topupUserData?.policyDetail?.includeRoadTax">
									{{ "addOn.roadTax" | translate }}
								</div>
								<div class="add-ons-cover" *ngFor="let item of topupUserData?.addOn">
									{{ "addOn." + item.code | translate }}
								</div>
							</div>
						</div>
						<div class="mb-sm">
							<div fxLayout="row" class="mb-sm" fxLayoutAlign=" center">
								<div class="mdTitle-f">
									{{ "common.carDetails" | translate }} :
								</div>
								<div class="summary-divider grey" fxFlex></div>
							</div>
							<div class="content-wrapper">
								<div class="left label-desc">
									{{ "common.carNumber" | translate }} :
								</div>
								<div class="right label-value">
									{{ topupUserData?.policyDetail?.vehicleNo }}
								</div>
								<div class="left label-desc">
									{{ "common.carModel" | translate }} :
								</div>
								<div class="right label-value">
									{{ topupUserData?.policyDetail?.make }} {{ topupUserData?.policyDetail?.model }}
									{{ topupUserData?.policyDetail?.transmission }}
								</div>
							</div>
						</div>
						<div class="mb-sm">
							<div fxLayout="row" class="mb-sm" fxLayoutAlign=" center">
								<div class="mdTitle-f">
									{{ "common.personalDetails" | translate }}
								</div>
								<div class="summary-divider grey" fxFlex></div>
							</div>
							<div class="content-wrapper">
								<div class="label-desc left">
									{{ getNameLabel() | translate }} :
								</div>
								<div class="label-value right">
									{{ topupUserData?.policyDetail?.name }}
								</div>

								<ng-container *ngTemplateOutlet="personalDetail">
								</ng-container>

								<div class="label-desc left">
									{{ "common.occupation" | translate }} :
								</div>
								<div class="label-value right">
									{{ topupUserData?.policyDetail?.occupation }}
								</div>

								<div class="label-desc left">
									{{ "common.mobileNo" | translate }} :
								</div>
								<div class="label-value right">
									{{ topupUserData?.policyDetail?.mobileNo }}
								</div>

								<div class="label-desc left">
									{{ "common.email" | translate }} :
								</div>
								<div class="label-value right">
									{{ topupUserData?.policyDetail?.email }}
								</div>
							</div>
						</div>

						<div class="mb-sm">
							<div fxLayout="row" class="mb-sm" fxLayoutAlign=" center">
								<div class="mdTitle-f">
									{{ "common.residentialAddress" | translate }}
								</div>
								<div class="summary-divider grey" fxFlex></div>
							</div>
							<div class="content-wrapper">
								<div class="label-desc left">
									{{ "common.addressLineOne" | translate }} :
								</div>
								<div class="label-value right">
									{{ topupUserData?.policyDetail?.address }}
								</div>
								<div class="label-desc left">
									{{ "common.addressLineTwo" | translate }} :
								</div>
								<div class="label-value right">
									{{ topupUserData?.policyDetail?.address2 }}
								</div>
								<div class="label-desc left">
									{{ "common.city" | translate }} :
								</div>
								<div class="label-value right">
									{{ topupUserData?.policyDetail?.city }}
								</div>
								<div class="label-desc left">
									{{ "common.postcode" | translate }} :
								</div>
								<div class="label-value right">
									{{ topupUserData?.policyDetail?.postcode }}
								</div>
								<div class="label-desc left">
									{{ "common.state" | translate }} :
								</div>
								<div class="label-value right">
									{{ topupUserData?.policyDetail?.state }}
								</div>
							</div>
						</div>

						<!-- ROADTAX SECTION -->
						<div
							class="mb-sm"
							*ngIf="
								topupUserData?.policyDetail?.includeRoadTax &&
								!topupUserData?.policyDetail?.includeDigitalRoadTax
							"
						>
							<div fxLayout="row" class="mb-sm" fxLayoutAlign=" center">
								<div class="mdTitle-f">
									{{ "common.deliveryAddress" | translate }}
								</div>
								<div class="summary-divider grey" fxFlex></div>
							</div>
							<div class="content-wrapper">
								<div class="label-desc left">
									{{ "common.addressLineOne" | translate }} :
								</div>
								<div class="label-value right">
									{{ topupUserData?.policyDetail?.deliveryAddress }}
								</div>
								<div class="label-desc left">
									{{ "common.addressLineTwo" | translate }} :
								</div>
								<div class="label-value right">
									{{ topupUserData?.policyDetail?.deliveryAddress2 }}
								</div>
								<div class="label-desc left">
									{{ "common.city" | translate }} :
								</div>
								<div class="label-value right">
									{{ topupUserData?.policyDetail?.deliveryCity }}
								</div>
								<div class="label-desc left">
									{{ "common.postcode" | translate }} :
								</div>
								<div class="label-value right">
									{{ topupUserData?.policyDetail?.deliveryPostcode }}
								</div>
								<div class="label-desc left">
									{{ "common.state" | translate }} :
								</div>
								<div class="label-value right">
									{{ topupUserData?.policyDetail?.deliveryState }}
								</div>
							</div>
						</div>

						<div class="mt-md" *ngIf="(screenSizeService.isMobile$ | async)">
							<div class="mt-lg">								
								<app-section-title
									[title]="'common.orderSummary' | translate"
								></app-section-title>	
							</div>
							<ng-container *ngTemplateOutlet="priceListDetail"> </ng-container>
							<ng-container *ngTemplateOutlet="paymentOption"> </ng-container>
						</div>
					</div>
					<div fxFlex.lt-md="100" fxFlex.gt-sm="40">
						<div class="sticky-total-amt">
							<app-mobile-total-amount [totalAmount]="getUnpaidRemainingAmount()" [label]="null"></app-mobile-total-amount>
						</div>
						<div class="expanded-content" *ngIf="!(screenSizeService.isMobile$ | async)">
							<app-expandable-card [title]="'common.orderSummary' | translate">
								<ng-container snippet>
									<ng-container
										*ngTemplateOutlet="orderSummarySnippet"
									></ng-container>
								</ng-container>
								<ng-container hidden>
									<ng-container *ngTemplateOutlet="priceList">
									</ng-container>
								</ng-container>
								<ng-container share>
									<ng-container *ngTemplateOutlet="paymentOption"></ng-container>
								</ng-container>
							</app-expandable-card>
						</div>
					</div>
				</div>
			</form>

			<ng-template matStepperIcon="edit">
				<mat-icon>edit</mat-icon>
			</ng-template>

			<ng-template matStepperIcon="close">
				<mat-icon>close</mat-icon>
			</ng-template>
		</div>

		<ng-template #priceList>
			<img [src]="topupUserData?.policyDetail?.productImage" class="selected-insurer" alt="" />
			<mat-expansion-panel class="summary-expand" (click)="$event.stopPropagation()">
				<mat-expansion-panel-header
					[collapsedHeight]="'100%'"
					[expandedHeight]="'100%'"
				>
					<mat-panel-title>
						{{ "common.benefits" | translate }}
					</mat-panel-title>
				</mat-expansion-panel-header>
				<div class="expand-content">
					<app-benefit [code]="topupUserData?.policyDetail?.productName"></app-benefit>
				</div>
			</mat-expansion-panel>
			<ng-container *ngTemplateOutlet="priceListDetail"> </ng-container>
		</ng-template>

		<ng-template #orderSummarySnippet>
			<div class="order-summary-container">
				<div class="content-wrapper remaining-amount-container">
					<div class="left mt-md">
						{{ "topup.topupAmount" | translate }}
					</div>
					<div class="right mt-md">
						RM {{ getTopupAmount() | number : "1.2-2" }}
					</div>
					<div class="left">
						{{ "topup.serviceCharge" | translate }}
					</div>
					<div class="right">
						RM {{ getServiceCharge() | number : "1.2-2" }}
					</div>
					<div class="divider"></div>
					<div class="left">
						{{ "topup.remainingAmount" | translate }}
					</div>
					<div class="right">
						<p class="remaining-amount pt-sm">
							RM {{ getUnpaidRemainingAmount() | number : "1.2-2" }}
						</p>
					</div>
				</div>
			</div>
		</ng-template>

		<ng-template #priceListDetail>
			<div *ngIf="topupUserData?.topUpRequest?.type !== 'PPP'">
				<div class="content-wrapper">
					<div class="left">
						<div class="label-desc">
							{{ "common.sumInsured" | translate }} (SI)
						</div>
					</div>
					<div class="right">
						<div class="label-value text-right">
							RM {{ topupUserData?.policyDetail?.sumInsured | number : "1.2-2" }}
						</div>
					</div>
					<div class="left">
						<div class="label-desc">{{ "common.basePremium" | translate }}</div>
					</div>
					<div class="right">
						<div class="label-value text-right">
							RM {{ topupUserData?.policyDetail?.basePremium | number : "1.2-2" }}
						</div>
					</div>
					<div class="left">
						<div class="label-desc">
							{{ "common.noClaimDiscount" | translate }} <br />
							<b>({{ topupUserData?.policyDetail?.ncd * 100 | number : "1.0-2" }}%)</b>
							<b *ngIf="!topupUserData?.policyDetail?.ncd"> - </b>
						</div>
					</div>
					<div class="right">
						<div class="label-value text-right color-red">
							- RM
							{{ getNCD() | number : "1.2-2" }}
						</div>
					</div>
				</div>
				<mat-divider></mat-divider>
				<div class="content-wrapper">
					<div class="left">
						<div class="label-desc">
							{{ "common.totalBasePremium" | translate }}
						</div>
					</div>
					<div class="right">
						<div class="label-value text-right">
							RM {{ getBasePremium() | number : "1.2-2" }}
						</div>
					</div>

					<div class="mt-xs mb-sm" *ngIf="topupUserData?.policyBundle">
						<span class="cover-label">{{ "plans.specialPackage" | translate }}</span>
					</div>

					<ng-container *ngFor="let item of topupUserData?.policyBundle">
						<div class="left">
							<div class="label-desc">
								{{ item?.description }}
							</div>
						</div>
						<div class="right">
							<div class="label-value text-right">
								RM {{ item?.amount | number : "1.2-2" }}
							</div>
						</div>
					</ng-container>


					<div class="mt-xs mb-sm" *ngIf="topupUserData?.addOn || topupUserData?.policyBundleAddOn">
						<span class="cover-label">{{
							"addOn.addOnsCapital" | translate
						}}</span>
					</div>

					<!-- Bundle AddOns here -->
					<ng-container *ngFor="let item of topupUserData?.policyBundleAddOn">
						<div class="left">
							<div class="label-desc">
								{{ item?.description }}
							</div>
						</div>
						<div class="right">
							<div class="label-value text-right">
								RM {{ item?.amount | number : "1.2-2" }}
							</div>
						</div>
					</ng-container>

					<!-- AddOns here -->
					<ng-container *ngFor="let item of topupUserData?.addOn">
						<ng-container *ngIf="!item.hidePriceBreakdown">
							<div class="left">
								<div class="label-desc">
									{{ "addOn." + item.code | translate }}
								</div>
							</div>
							<div class="right">
								<ng-container [ngSwitch]="item.code">
									<ng-container *ngSwitchCase="'Windshield'">
										<div class="label-value text-right">
											RM
											{{ item.amount | number : "1.2-2" }}
										</div>
									</ng-container>
									<ng-container *ngSwitchCase="'AdditionalDrivers'">
										<div class="label-value text-right">
											RM
											{{ item.amount | number : "1.2-2" }}
										</div>
									</ng-container>
									<ng-container *ngSwitchDefault>
										<div class="label-value text-right">
											RM
											{{ item.amount | number : "1.2-2" }}
										</div>
									</ng-container>
								</ng-container>
							</div>
						</ng-container>
					</ng-container>
				</div>
				<mat-divider></mat-divider>

				<mat-divider></mat-divider>

				<div class="content-wrapper">
					<div class="left">
						<div class="label-desc">
							{{ "common.totalGrossPremium" | translate }}
						</div>
					</div>
					<div class="right">
						<div class="label-value text-right">
							RM
							{{ getGrossPremium() | number : "1.2-2" }}
						</div>
					</div>
					<div class="left">
						<div class="label-desc">
							{{ "common.serviceTax" | translate }}
						</div>
					</div>
					<div class="right">
						<div class="label-value text-right">
							RM
							{{ getTax() | number : "1.2-2" }}
						</div>
					</div>

					<div class="left">
						<div class="label-desc">{{ "common.stampDuty" | translate }}</div>
					</div>
					<div class="right">
						<div class="label-value text-right">
							RM {{ topupUserData?.policyDetail?.stampDutyPrice | number : "1.2-2" }}
						</div>
					</div>
				</div>

				<mat-divider></mat-divider>

				<div class="content-wrapper">
					<div class="left">
						<div class="label-desc">
							{{ "common.totalNetPremium" | translate }}
						</div>
					</div>
					<div class="right">
						<div class="label-value text-right">
							RM
							{{ getTotalNetPremium() | number : "1.2-2" }}
						</div>
					</div>

					<ng-container *ngIf="topupUserData?.policyDetail?.platformPrice">
						<div class="left">
							<div class="label-desc">
								{{ "common.platformOffer" | translate }}
							</div>
						</div>
						<div class="right">
							<div class="label-value text-right color-red">
								- RM
								{{ getPlatformDiscountRate() | number : "1.2-2" }}
							</div>
						</div>
					</ng-container>
					<ng-container *ngIf="topupUserData?.policyDetail?.includeRoadTax">
						<div class="left">
							<div class="label-desc">
								{{ "addOn.roadTax" | translate }}
							</div>
						</div>
						<div class="right">
							<div class="label-value text-right">
								RM {{ topupUserData?.policyDetail?.roadTaxPrice | number : "1.2-2" }}
							</div>
						</div>
						<div class="left">
							<div
								class="label-desc"
								*ngIf="topupUserData?.policyDetail?.includeDigitalRoadTax"
							>
								{{ "digitalRoadTaxFee" | translate }}
							</div>
							<div
								class="label-desc"
								*ngIf="!topupUserData?.policyDetail?.includeDigitalRoadTax"
							>
								{{ "addOn.roadTaxDelivery" | translate }}
							</div>
						</div>
						<div class="right">
							<div
								class="label-value text-right color-red"
								*ngIf="topupUserData?.policyDetail?.includeDigitalRoadTax"
							>
								RM {{ topupUserData?.policyDetail?.digitalRoadTaxFee | number : "1.2-2" }}
							</div>
							<div
								class="label-value text-right"
								*ngIf="!topupUserData?.policyDetail?.includeDigitalRoadTax"
							>
								RM {{ topupUserData?.policyDetail?.roadTaxDeliveryPrice | number : "1.2-2" }}
							</div>
						</div>
					</ng-container>
				</div>

				<mat-divider></mat-divider>

				<div class="content-wrapper">
					<div class="left">
						<div class="label-desc">
							{{ "common.totalCharges" | translate }}
						</div>
					</div>
					<div class="right">
						<div class="label-value text-right">
							RM {{ getTotalCharge() | number : "1.2-2" }}
						</div>
					</div>

					<!-- PROMO SECTION -->
					<ng-container *ngIf="topupUserData?.policyDetail?.promoCodePrice">
						<div class="left">
							<div class="label-desc">Promo</div>
						</div>
						<div class="right">
							<div class="label-value text-right color-red">
								- RM {{ getTotalPromo() | number : "1.2-2" }}
							</div>
						</div>
					</ng-container>

					<div class="left">
						<div class="label-desc">{{ "common.serviceFee" | translate }}</div>
					</div>
					<div class="right">
						<div class="label-value text-right">
							RM {{ getServiceFee() | number : "1.2-2" }}
						</div>
					</div>
				</div>

				<mat-divider></mat-divider>
			</div>

			<div class="content-wrapper mt-md">
				<div class="left">
					<div class="label-desc">
						{{ "topup.totalRevised" | translate }}
						<span class="bold">
							({{
								"topup.totalRevisedNote" + topupUserData?.topUpRequest?.type | translate
							}})</span
						>
					</div>
				</div>
				<div class="right">
					<div class="label-value text-right">
						RM {{ getTotal() | number : "1.2-2" }}
					</div>
				</div>

				<div class="left">
					<div class="label-desc">{{ "topup.totalPaid" | translate }}</div>
				</div>
				<div class="right">
					<div class="label-value text-right color-dark-green">
						RM {{ getTotalPaid() | number : "1.2-2" }}
					</div>
				</div>
			</div>

			<mat-divider></mat-divider>

			<div class="content-wrapper remaining-amount-container">
				<div class="left mt-md">
					{{ "topup.topupAmount" | translate }}
				</div>
				<div class="right mt-md">
					RM {{ getTopupAmount() | number : "1.2-2" }}
				</div>
				<div class="left">
					{{ "topup.serviceCharge" | translate }}
				</div>
				<div class="right">
					RM {{ getServiceCharge() | number : "1.2-2" }}
				</div>
				<div class="divider"></div>
				<div class="left">
					{{ "topup.remainingAmount" | translate }}
				</div>
				<div class="right">
					<p class="remaining-amount pt-sm">
						RM {{ getUnpaidRemainingAmount() | number : "1.2-2" }}
					</p>
				</div>
			</div>
		</ng-template>


		<ng-template #personalDetail>
			<div class="label-desc left">
				{{ getIdentityNoLabel() | translate }} :
			</div>
			<div class="label-value right">
				{{ topupUserData?.policyDetail?.identityNo }}
			</div>

			<ng-container *ngIf="!topupUserData?.policyDetail?.isCompany">
				<ng-container *ngIf="topupUserData?.policyDetail?.isMalaysian">
					<ng-container *ngIf="topupUserData?.policyDetail?.policIdentityNo">
						<div class="label-desc left">
							{{ "common.policeOrArmyIcNo" | translate }} :
						</div>
						<div class="label-value right">
							{{ topupUserData?.policyDetail?.policIdentityNo || "-" }}
						</div>
					</ng-container>
				</ng-container>

				<ng-container *ngIf="!topupUserData?.policyDetail?.isMalaysian">
					<div class="label-desc left">
						{{ "common.nationality" | translate }} :
					</div>
					<div class="label-value right">
						{{ topupUserData?.policyDetail?.nationality }}
					</div>
				</ng-container>

				<div class="label-desc left">{{ "common.gender" | translate }} :</div>
				<div class="label-value right">
					{{ "common." + topupUserData?.policyDetail?.gender | translate }}
				</div>
				<div class="label-desc left">
					{{ "common.maritalStatus" | translate }} :
				</div>
				<div class="label-value right">
					{{ "common." + topupUserData?.policyDetail?.maritalStatus | translate }}
				</div>

				<div class="label-desc left">{{ "common.dob" | translate }} :</div>
				<div class="label-value right">
					{{ topupUserData?.policyDetail?.dob | date : "dd MMM yyyy" }}
				</div>
			</ng-container>
		</ng-template>

		<ng-template #mobileNextBtn>
			<div class="title">
				{{ "common.payNow" | translate }}
			</div>
			<div>
				<div class="value">
					RM {{ getUnpaidRemainingAmount() | number : "1.2-2" }}
				</div>
				<!-- <div class="desc">
          {{ "common.pay" | translate }} RM
          {{ getTotal() / 3 | number: "1.2-2" }}
          {{ "common.everyMonth" | translate }}
          {{ "common.duration" | translate }}!
        </div> -->
			</div>
		</ng-template>
	</div>
</div>

<ng-template #paymentOption>
	<div class="pt-xs" (click)="$event.stopPropagation()">
		<app-section-title
			title="plans.step3Content.paymentOption"
		></app-section-title>
	</div>
	<app-payment-options 
		[totalPrice]="getUnpaidRemainingAmount()"
		[totalPriceBeforeCharges]="getUnpaidRemainingAmount()"
		[bankCharge]="null"
		[paymentFormGroup]="paymentFormGroup"
		[onlyFullPayment]="true"
	></app-payment-options>
	<button
		mat-flat-button
		[disabled]="paymentFormGroup?.invalid"
		(click)="$event.stopPropagation(); handleSubmit()"
		className="allow-pointer"
	>
		{{ "plans.readyToPay" | translate }}
	</button>
</ng-template>

