import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	ViewChild,
	ElementRef,
} from "@angular/core";
// Services
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-upload-carousel-preview-card",
	templateUrl: "./upload-carousel-preview-card.component.html",
	styleUrls: ["./upload-carousel-preview-card.component.scss"],
})
export class UploadCarouselPreviewCardComponent implements OnInit {
	@Input() previewFile: any = {};
	@Output() onDeletePreview: EventEmitter<any> = new EventEmitter();
	@Output() onDownloadPreview: EventEmitter<any> = new EventEmitter();
	@ViewChild("videoPlayer") videoPlayer!: ElementRef;
	playButtonHidden = false;
	isMozilla = false;

	constructor(public translate: TranslateService) {}

	ngOnInit(): void {
		if (/Firefox/i.test(navigator.userAgent)) {
			this.isMozilla = true;
		}
	}

	onDelete() {
		this.onDeletePreview.emit();
	}

	onDownload() {
		this.onDownloadPreview.emit();
	}

	playVideo(video: HTMLVideoElement) {
		if (video.paused) {
			video.play();
			this.playButtonHidden = true;
		} else {
			video.pause();
			this.playButtonHidden = false;
		}
	}

	handleVideoClick(event: Event) {
		this.playButtonHidden = !this.playButtonHidden;
	}
}
