export const API_DATA: any = {
    "motorBundle": [
        {
            "category": "Flood",
            "code": "Flood",
            "description": "Flood Protection+",
            "explanation": "Covering claims for repairs due to flood/ landslide, including engine, interior, and exterior cleaning.",
            "coverage": "",
            "imageUrl": "https://storage.googleapis.com/ps-asset/motor/FFF/Bundle/flood%20protection%20desktop.png",
            "insuranceType": null,
            "condition": null,
            "evaluate": "34.5",
            "isConditionFirst": true,
            "rules": null,
            "customCode": null,
            "included": false,
            "hidePriceBreakDown": false,
            "motorProductId": null,
            "motorPartnerId": null,
            "seq": 1,
            "isRecommend": true,
            "isActive": true,
            "createdDate": "2023-04-13T21:56:23.4271857+08:00",
            "modifyDate": null,
            "id": "407a4d03-05ec-4fc9-bfe2-f61709304c10"
        },
        {
            "category": "Female",
            "code": "Female",
            "description": "Female Driver Protection+",
            "explanation": "Covering claims due to snatch theft, skin injuries, and emergency roadside assists.",
            "coverage": "",
            "imageUrl": "https://storage.googleapis.com/ps-asset/motor/FFF/Bundle/female%20protection%20desktop.png",
            "insuranceType": null,
            "condition": null,
            "evaluate": "50",
            "isConditionFirst": true,
            "rules": null,
            "customCode": null,
            "included": false,
            "hidePriceBreakDown": false,
            "motorProductId": null,
            "motorPartnerId": null,
            "seq": 2,
            "isRecommend": false,
            "isActive": true,
            "createdDate": "2023-04-13T21:56:23.4325639+08:00",
            "modifyDate": null,
            "id": "b6ae03c2-e31b-40bd-b58b-2fe54789d1fe"
        },
        {
            "category": "Family",
            "code": "Family",
            "description": "Family Protection+",
            "explanation": "Covering claims for damage to baby equipment & child seat PLUS cash claims for driver and passengers due to car accident.",
            "coverage": "",
            "imageUrl": "https://storage.googleapis.com/ps-asset/motor/FFF/Bundle/family%20protection%20desktop.png",
            "insuranceType": null,
            "condition": null,
            "evaluate": "50",
            "isConditionFirst": true,
            "rules": null,
            "customCode": null,
            "included": false,
            "hidePriceBreakDown": false,
            "motorProductId": null,
            "motorPartnerId": null,
            "seq": 3,
            "isRecommend": false,
            "isActive": true,
            "createdDate": "2023-04-13T21:56:23.4335691+08:00",
            "modifyDate": null,
            "id": "fc022a08-da91-428f-8e4b-60bce80ea498"
        }
    ],
    "motorBundleAddOn": [
        {
            "motorBundleId": "407a4d03-05ec-4fc9-bfe2-f61709304c10",
            "motorAddOnId": "7b98a0c5-c7db-4e87-a7eb-77d5b099c397",
            "category": "Flood",
            "code": "Flood",
            "description": "Standard Flood Coverage & Repairs",
            "explanation": "",
            "insuranceType": null,
            "condition": "",
            "evaluate": "6.00000",
            "isConditionFirst": true,
            "isPopular": true,
            "rules": "",
            "customCode": "",
            "included": false,
            "includedInBundle": true,
            "hidePriceBreakdown": false,
            "motorProductId": "91862710-0e65-4873-80be-2dbf614de30c",
            "motorPartnerId": null,
            "imageUrl": "https://storage.googleapis.com/ps-asset/motor/FFF/Add-on/Basic%20Flood%20Coverage%20_%20Repairs.png",
            "seq": 2,
            "isActive": true,
            "createdDate": "2023-04-16T22:32:58.5258779+08:00",
            "modifyDate": null,
            "id": "450608cb-b052-46ff-b011-b03673b47c09"
        },
        {
            "motorBundleId": null,
            "motorAddOnId": null,
            "category": "Windshield",
            "code": "Windshield",
            "description": "Windscreen coverage",
            "explanation": "",
            "insuranceType": null,
            "condition": "",
            "evaluate": "0",
            "isConditionFirst": true,
            "isPopular": true,
            "rules": "{\"min\":500,\"max\":10000}",
            "customCode": "",
            "included": false,
            "includedInBundle": true,
            "hidePriceBreakdown": false,
            "motorProductId": "e4786700-9f52-46c4-832e-e474f3a6686d",
            "motorPartnerId": null,
            "imageUrl": "https://storage.googleapis.com/ps-asset/motor/FFF/Add-on/Windscreen%20coverage.png",
            "seq": 4,
            "isActive": true,
            "createdDate": "2023-04-16T22:32:58.5258779+08:00",
            "modifyDate": null,
            "id": "62510f85-b0b5-46f5-b6cf-c5f50f93c130"
        },
        {
            "motorBundleId": "407a4d03-05ec-4fc9-bfe2-f61709304c10",
            "motorAddOnId": null,
            "category": "Flood",
            "code": "Flood",
            "description": "Instant Cash for Flood Restoration Benefits",
            "explanation": "",
            "insuranceType": null,
            "condition": "",
            "evaluate": "9",
            "isConditionFirst": true,
            "isPopular": false,
            "rules": "",
            "customCode": "",
            "included": false,
            "includedInBundle": true,
            "hidePriceBreakdown": false,
            "motorProductId": "e4786700-9f52-46c4-832e-e474f3a6686d",
            "motorPartnerId": null,
            "imageUrl": "https://storage.googleapis.com/ps-asset/motor/FFF/Add-on/Instant%20Cash%20for%20Flood%20Restoration%20Benefits.png",
            "seq": 7,
            "isActive": true,
            "createdDate": "2023-04-16T22:32:58.5258779+08:00",
            "modifyDate": null,
            "id": "141991ca-fc83-44b6-bdbd-79af118802fb"
        },
        {
            "motorBundleId": "b6ae03c2-e31b-40bd-b58b-2fe54789d1fe",
            "motorAddOnId": null,
            "category": "Female",
            "code": "Female",
            "description": "Snatch Theft Protection",
            "explanation": "",
            "insuranceType": null,
            "condition": "",
            "evaluate": "9",
            "isConditionFirst": true,
            "isPopular": false,
            "rules": "",
            "customCode": "",
            "included": false,
            "includedInBundle": true,
            "hidePriceBreakdown": false,
            "motorProductId": "e4786700-9f52-46c4-832e-e474f3a6686d",
            "motorPartnerId": null,
            "imageUrl": "https://storage.googleapis.com/ps-asset/motor/FFF/Add-on/Snatch%20Theft%20Protection.png",
            "seq": 8,
            "isActive": true,
            "createdDate": "2023-04-16T22:32:58.5258779+08:00",
            "modifyDate": null,
            "id": "7a6bab1b-68a8-41c8-b094-97c918ec647a"
        },
        {
            "motorBundleId": "b6ae03c2-e31b-40bd-b58b-2fe54789d1fe",
            "motorAddOnId": null,
            "category": "Female",
            "code": "Female",
            "description": "Skin Injury Claims",
            "explanation": "",
            "insuranceType": null,
            "condition": "",
            "evaluate": "9",
            "isConditionFirst": true,
            "isPopular": false,
            "rules": "",
            "customCode": "",
            "included": false,
            "includedInBundle": true,
            "hidePriceBreakdown": false,
            "motorProductId": "e4786700-9f52-46c4-832e-e474f3a6686d",
            "motorPartnerId": null,
            "imageUrl": "https://storage.googleapis.com/ps-asset/motor/FFF/Add-on/Skin%20Injury%20Claims.png",
            "seq": 9,
            "isActive": true,
            "createdDate": "2023-04-16T22:32:58.5258779+08:00",
            "modifyDate": null,
            "id": "7433c3c4-c022-4f08-839a-8e4033ccb0bc"
        },
        {
            "motorBundleId": "fc022a08-da91-428f-8e4b-60bce80ea498",
            "motorAddOnId": null,
            "category": "Family",
            "code": "Family",
            "description": "Medical Expenses for Family due to Car Accident",
            "explanation": "",
            "insuranceType": null,
            "condition": "",
            "evaluate": "9",
            "isConditionFirst": true,
            "isPopular": false,
            "rules": "",
            "customCode": "",
            "included": false,
            "includedInBundle": true,
            "hidePriceBreakdown": false,
            "motorProductId": "e4786700-9f52-46c4-832e-e474f3a6686d",
            "motorPartnerId": null,
            "imageUrl": "https://storage.googleapis.com/ps-asset/motor/FFF/Add-on/Medical%20Expenses%20for%20Family%20due%20to%20Car%20Accident.png",
            "seq": 10,
            "isActive": true,
            "createdDate": "2023-04-16T22:32:58.5258779+08:00",
            "modifyDate": null,
            "id": "ace62dee-8826-487b-9d15-169aab848c08"
        },
        {
            "motorBundleId": "fc022a08-da91-428f-8e4b-60bce80ea498",
            "motorAddOnId": null,
            "category": "Family",
            "code": "Family",
            "description": "Claims for Damage to Baby / Child Equipment",
            "explanation": "",
            "insuranceType": null,
            "condition": "",
            "evaluate": "9",
            "isConditionFirst": true,
            "isPopular": false,
            "rules": "",
            "customCode": "",
            "included": false,
            "includedInBundle": true,
            "hidePriceBreakdown": false,
            "motorProductId": "e4786700-9f52-46c4-832e-e474f3a6686d",
            "motorPartnerId": null,
            "imageUrl": "https://storage.googleapis.com/ps-asset/motor/FFF/Add-on/Claims%20for%20Damage%20to%20Baby%20Child%20Equipment.png",
            "seq": 11,
            "isActive": true,
            "createdDate": "2023-04-16T22:32:58.5258779+08:00",
            "modifyDate": null,
            "id": "55900a00-8294-47f4-90b4-106a1ec44378"
        }
    ],
    "motorAddOn": [
        {
            "motorBundleId": null,
            "motorAddOnId": null,
            "category": "Drivers",
            "code": "UnlimitedDrivers",
            "description": null,
            "explanation": null,
            "insuranceType": "COM",
            "condition": "",
            "evaluate": "20",
            "isConditionFirst": true,
            "isPopular": false,
            "rules": "",
            "customCode": "",
            "included": false,
            "includedInBundle": false,
            "hidePriceBreakdown": false,
            "motorProductId": "91862710-0e65-4873-80be-2dbf614de30c",
            "motorPartnerId": null,
            "imageUrl": null,
            "seq": 1,
            "isActive": null,
            "createdDate": null,
            "modifyDate": null,
            "id": "6eab289f-b460-4da7-b688-07caf57b4bb7"
        },
        {
            "motorBundleId": null,
            "motorAddOnId": null,
            "category": "Drivers",
            "code": "AdditionalDrivers",
            "description": null,
            "explanation": null,
            "insuranceType": null,
            "condition": null,
            "evaluate": "({val}-1)*0",
            "isConditionFirst": true,
            "isPopular": false,
            "rules": "{\"max\":9}",
            "customCode": null,
            "included": false,
            "includedInBundle": false,
            "hidePriceBreakdown": false,
            "motorProductId": "91862710-0e65-4873-80be-2dbf614de30c",
            "motorPartnerId": null,
            "imageUrl": null,
            "seq": 1,
            "isActive": null,
            "createdDate": null,
            "modifyDate": null,
            "id": "a4b546fc-3d45-4d8d-a4a5-95fdce9267b3"
        },
        {
            "motorBundleId": null,
            "motorAddOnId": null,
            "category": "Windshield",
            "code": "Windshield",
            "description": null,
            "explanation": null,
            "insuranceType": "COM",
            "condition": "",
            "evaluate": "{val} *(75/500)",
            "isConditionFirst": true,
            "isPopular": false,
            "rules": "{\"min\":500,\"max\":10000}",
            "customCode": "",
            "included": false,
            "includedInBundle": false,
            "hidePriceBreakdown": false,
            "motorProductId": "91862710-0e65-4873-80be-2dbf614de30c",
            "motorPartnerId": null,
            "imageUrl": null,
            "seq": 2,
            "isActive": null,
            "createdDate": null,
            "modifyDate": null,
            "id": "a06b23bd-13f5-4d84-b550-23e94c0154b5"
        },
        {
            "motorBundleId": null,
            "motorAddOnId": null,
            "category": "LLTP",
            "code": "LLTP",
            "description": null,
            "explanation": null,
            "insuranceType": null,
            "condition": "",
            "evaluate": "0",
            "isConditionFirst": true,
            "isPopular": false,
            "rules": "",
            "customCode": "LLTP",
            "included": false,
            "includedInBundle": false,
            "hidePriceBreakdown": false,
            "motorProductId": "91862710-0e65-4873-80be-2dbf614de30c",
            "motorPartnerId": null,
            "imageUrl": null,
            "seq": 4,
            "isActive": null,
            "createdDate": null,
            "modifyDate": null,
            "id": "1dfaad28-c7a0-41d0-9149-8e81fd13e08f"
        },
        {
            "motorBundleId": null,
            "motorAddOnId": null,
            "category": "LLOPNA",
            "code": "LLOPNA",
            "description": null,
            "explanation": null,
            "insuranceType": null,
            "condition": "",
            "evaluate": "7.5",
            "isConditionFirst": true,
            "isPopular": false,
            "rules": "",
            "customCode": "",
            "included": false,
            "includedInBundle": false,
            "hidePriceBreakdown": false,
            "motorProductId": "91862710-0e65-4873-80be-2dbf614de30c",
            "motorPartnerId": null,
            "imageUrl": null,
            "seq": 5,
            "isActive": null,
            "createdDate": null,
            "modifyDate": null,
            "id": "6f3b9c1d-ff38-49ec-b48b-aa29281c94ee"
        },
        {
            "motorBundleId": null,
            "motorAddOnId": null,
            "category": "SRCC",
            "code": "SRCC",
            "description": null,
            "explanation": null,
            "insuranceType": null,
            "condition": "",
            "evaluate": "9.00000",
            "isConditionFirst": true,
            "isPopular": false,
            "rules": "",
            "customCode": "",
            "included": false,
            "includedInBundle": false,
            "hidePriceBreakdown": false,
            "motorProductId": "91862710-0e65-4873-80be-2dbf614de30c",
            "motorPartnerId": null,
            "imageUrl": null,
            "seq": 6,
            "isActive": null,
            "createdDate": null,
            "modifyDate": null,
            "id": "d84b379b-8b7c-419e-aacd-7fd770fc55bd"
        },
        {
            "motorBundleId": null,
            "motorAddOnId": null,
            "category": "EHailing",
            "code": "EHailing",
            "description": null,
            "explanation": null,
            "insuranceType": "COM",
            "condition": null,
            "evaluate": "320",
            "isConditionFirst": true,
            "isPopular": false,
            "rules": null,
            "customCode": "Zurich_EHailing",
            "included": false,
            "includedInBundle": false,
            "hidePriceBreakdown": false,
            "motorProductId": "91862710-0e65-4873-80be-2dbf614de30c",
            "motorPartnerId": null,
            "imageUrl": null,
            "seq": 7,
            "isActive": null,
            "createdDate": null,
            "modifyDate": null,
            "id": "64d03924-5a5b-4962-89bd-032462621470"
        }
    ]
}