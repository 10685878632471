import { Component, OnInit } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { FaqDialogComponent } from "../faq-dialog/faq-dialog.component";
import { GuidesDialogComponent } from "../guides-dialog/guides-dialog.component";

@Component({
	selector: "app-customer-policy",
	templateUrl: "./customer-policy.component.html",
	styleUrls: [
		"./customer-policy.component.scss",
		"../../../assets/styles/space.scss",
	],
})
export class CustomerPolicyComponent {
	genderStateTemp = true;
	formGroup: FormGroup;
	stateList: string[] = [
		"Selangor",
		"Wilayah Persekutuan Kuala Lumpur",
		"Wilayah Persekutuan Labuan",
		"Wilayah Persekutuan Putrajaya",
		"Johor",
		"Kedah",
		"Kelantan",
		"Melaka",
		"Negeri Sembilan",
		"Pahang",
		"Perak",
		"Perlis",
		"Pulau Pinang",
		"Sarawak",
		"Sabah",
		"Terengganu",
	];

	cancelInsuranceReasons: string[] = [
		"Reason one",
		"Reason two",
		"Reason three",
		"Reason four",
		"Reason five",
	];

	typeOfInsuranceList = [
		{ id: "TPFT", name: "common.TPFT" },
		{ id: "COM", name: "common.COM" },
	];

	relationshipList = [
		{ id: "Parent/Parent-in-law", name: "common.parents" },
		{ id: "Spouse", name: "common.spouse" },
		{ id: "Son/Daughter", name: "common.child" },
		{ id: "Sibling/Sibling-in-law/Cousin/Relative", name: "common.sibling" },
		{ id: "Friend/Co-worker", name: "common.friend" },
	];

	sumInsuredValue = {
		min: 35000,
		max: 50000,
	};

	constructor(public dialog: MatDialog, public translate: TranslateService) {
		this.initInfoForm();
	}

	addonLabel(value: number) {
		return "RM " + value.toFixed(2).toLocaleString();
	}

	switch(lang: string) {
		this.translate.use(lang);
	}

	private initInfoForm() {
		this.formGroup = new FormGroup({
			name: new FormControl("Mohd Farish Azmam", [Validators.required]),
			idNumber: new FormControl("901101016071", [Validators.required]),
			dob: new FormControl("2021-05-17T13:51:50.417-07:00", [
				Validators.required,
			]),
			gender: new FormControl("male", [Validators.required]),
			maritalStatus: new FormControl("single", [Validators.required]),
			residentialAddress: new FormGroup({
				address1: new FormControl("Test test test", [Validators.required]),
				address2: new FormControl("Test test test", [Validators.required]),
				city: new FormControl("Wilayah Persekutuan Kuala Lumpur", [
					Validators.required,
				]),
				postCode: new FormControl("58200", [Validators.required]),
				state: new FormControl("Wilayah Persekutuan Kuala Lumpur", [
					Validators.required,
				]),
			}),
			mobileNo: new FormControl("+60182822320", [Validators.required]),

			carPlateNo: new FormControl("FA8442", [Validators.required]),
			vehicleModel: new FormControl(
				"MERCEDES-BENZ 400 L HYBRID (CKD) 7 SP AUTOMATIC",
				[Validators.required],
			),

			typeOfVehicleInsurance: new FormControl("Comprehensive", [
				Validators.required,
			]),
			sumInsured: new FormGroup({
				type: new FormControl("Market Value", [Validators.required]),
				value: new FormControl("43000", [Validators.required]),
			}),
			insurancePeriod: new FormControl(1, [Validators.required]),
			addOns: new FormControl(null),
			additionalDrivers: new FormControl(null),

			withdrawNcd: new FormControl(null),
			hirePurchaseDetails: new FormControl(null),
			cancelInsuranceReasons: new FormControl(null),
		});
	}

	get residentialAddress() {
		return this.formGroup.get("residentialAddress") as FormGroup;
	}

	get sumInsured() {
		return this.formGroup.get("sumInsured") as FormGroup;
	}

	onSubmit() {
		const newForm = this.formGroup.value;

		// ----- send form data through service here
	}

	handleGenderBtn(value) {
		this.formGroup.patchValue({
			gender: value,
		});
	}
	handleMaritalStatus(value) {
		this.formGroup.patchValue({
			maritalStatus: value,
		});
	}

	handleMvAv(value) {
		this.formGroup.patchValue({
			sumInsured: {
				type: value,
			},
		});
	}

	faqDialog() {
		this.dialog.open(FaqDialogComponent, {
			panelClass: "faq-dialog",
		});
	}

	guideDialog() {
		this.dialog.open(GuidesDialogComponent, {
			panelClass: "guide-dialog",
		});
	}
}
