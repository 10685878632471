<div class="mobile-bottombar-container">
  <mat-expansion-panel
    class="summary-card"
    hideToggle
    disabled
    #addon3="matExpansionPanel"
    [expanded]="screenWidth > 960"
  >
    <mat-expansion-panel-header
      [collapsedHeight]="'100%'"
      [expandedHeight]="'100%'"
    >
      <button
        mat-icon-button
        class="expand-btn"
        (click)="addon3.expanded = !addon3.expanded"
        *ngIf="!addon3.expanded"
      >
        <mat-icon>keyboard_arrow_up</mat-icon>
      </button>

      <div class="mobile-proceed-btn">
        <ng-container
          *ngTemplateOutlet="buttonTemplate"
        ></ng-container>
        <ng-container *ngIf="!disabledContactPS; else disabledBtnTemplate">
          <button mat-flat-button [disabled]="disabledCondition" (click)="submit()">
            {{ "common.continue" | translate }}
          </button>
        </ng-container>
        <ng-template #disabledBtnTemplate>
          <div
            matTooltip="Please contact PolicyStreet to proceed"
            [matTooltipDisabled]="false"
            #tooltip="matTooltip"
            (click)="tooltip.toggle()"
          >
            <button
              mat-flat-button
              [disabled]="true"
              class="disabled-btn"
            >
              {{ "plans.readyToPay" | translate }}
            </button>
          </div>
        </ng-template>

      </div>


    </mat-expansion-panel-header>
    <button
      mat-icon-button
      class="expand-btn"
      (click)="addon3.expanded = !addon3.expanded"
      *ngIf="addon3.expanded"
    >
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
    <div class="expanded-content">
      <ng-content></ng-content>
    </div>
  </mat-expansion-panel>

  <div
    class="expand-blackdrop"
    (click)="addon3.expanded = !addon3.expanded"
    *ngIf="addon3.expanded"
  ></div>
</div>