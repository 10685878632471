<div class="car-details">
  <div class="car-details__information">
    <div class="car-details__section">
      <div class="car-details__header">
        <div class="car-details__header-image">
          <img alt="Car Details" src="/assets/image/ktmb/car-details.svg">
        </div>
        <div class="car-details__header-title">
          {{ "common.carDetails" | translate }}
        </div>
      </div>

      <div class="car-details__item">
        <div>{{ "common.carNumber" | translate }}</div>
        <div class="car-details__item-value">{{ carDetails.vehicleNo }}</div>
      </div>

      <div class="car-details__item">
        <div>{{ "common.carModel" | translate }}</div>
        <div class="car-details__item-value">{{ carDetails.vehicle }}</div>
      </div>
    </div>

    <div class="car-details__section">
      <div class="car-details__header">
        <div class="car-details__header-image">
          <img alt="Car Protection Plan" src="/assets/image/ktmb/car-protection-plan.svg">
        </div>
        <div class="car-details__header-title">
          {{ "common.carProtectionPlan" | translate }}
        </div>
      </div>

      <div class="car-details__item">
        <div>{{ "common.typeOfInsurance" | translate }}</div>
        <div class="car-details__item-value">{{ "common." + carDetails.insuranceType | translate }}</div>
      </div>

      <div class="car-details__item">
        <div>{{ "common.effectivePeriod" | translate }}</div>
        <div class="car-details__item-value">{{ carDetails.effectiveDate | date : "dd MMM yyyy" }}</div>
      </div>
    </div>
  </div>

  <button (click)="onEditQuotation()" class="car-details__edit-button" matRipple type="button">{{ "common.editQuotation" | translate }}</button>
</div>
