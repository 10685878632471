import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-bundle-card',
  templateUrl: './bundle-card.component.html',
  styleUrls: ['./bundle-card.component.scss'],
})
export class BundleCardComponent implements OnInit {
  @Input() item: any;
  @Input() recommended: boolean;
  @Input() selectedBundle: string[] = [];
  @Input() expandedData: { title: string; desc: string[] }[] = [];
  @Input() coverageLink: string = '';
  @Input() sumInsured: number = 0;

  @Output() addBundle = new EventEmitter();
  @Output() removeBundle = new EventEmitter();

  expandedState: boolean = false;
  bundleAddedState: boolean = false;

  constructor() {}

  ngOnInit(): void {
  
  }

  downloadLink() {
    if (this.coverageLink) {
      const a = document.createElement('a');
      a.href= this.coverageLink;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  add(e: any) {
    e.stopPropagation();
    this.addBundle.emit(null);
    this.bundleAddedState = true;
  }

  remove(e: any) {
    e.stopPropagation();
    this.removeBundle.emit(null);
    this.bundleAddedState = false;
  }
}
