import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { ThankyouDialogComponent } from "../thankyou-dialog/thankyou-dialog.component";

@Component({
	selector: "app-complain",
	templateUrl: "./complain.component.html",
	styleUrls: ["./complain.component.scss"],
})
export class ComplainComponent implements OnInit {
	currentLang: string;
	mainForm: FormGroup;
	msgForm: FormGroup;
	feedbackForm: FormGroup;
	complaintForm: FormGroup;

	whyChoosePs: string[] = [
		"complain.cheaper",
		"complain.manyInsurers",
		"complain.fastService",
		"complain.easyToUse",
		"complain.compareLike",
		"complain.trustworthy",
		"complain.easyToUnderstand",
		"complain.good",
	];

	heardAboutUsFrom: string[] = [
		"Google",
		"complain.wom",
		"Facebook",
		"Instagram",
		"Blog post",
	];

	describedOurService: string[] = [
		"complain.reliable",
		"complain.fast",
		"complain.useful",
		"complain.helpful",
		"complain.unique",
		"complain.goodValue",
		"complain.overpriced",
		"complain.slow",
		"complain.poorQuality",
		"complain.unreliable",
	];

	satisfactionList: string[] = [
		"complain.verySatisfied",
		"complain.somewhatSatisfied",
		"complain.neitherSatisfied",
		"complain.somewhatDissatisfied",
		"complain.veryDissatisfied",
	];

	natureOfComplaintList: string[] = [
		"complain.quote",
		"complain.cn",
		"addOn.roadTax",
		"complain.payment",
		"complain.endorsement",
		"complain.refund",
		"complain.other",
	];

	constructor(private dialog: MatDialog, public translate: TranslateService) {}

	ngOnInit(): void {
		this.mainForm = new FormGroup({
			name: new FormControl(null, [
				Validators.required,
				Validators.minLength(3),
			]),
			email: new FormControl(null, [Validators.required, Validators.email]),
			mobileNumb: new FormControl(null, [
				Validators.required,
				Validators.pattern("^[0-9]*$"),
				Validators.minLength(5),
			]),
		});
		this.msgForm = new FormGroup({
			message: new FormControl(null, [Validators.required]),
		});
		this.feedbackForm = new FormGroup({
			questionOne: new FormControl(null, [Validators.required]),
			questionOneOther: new FormControl(null),
			questionTwo: new FormControl(null, [Validators.required]),
			questionThree: new FormGroup({
				q1: new FormControl(null, [Validators.required]),
				q2: new FormControl(null, [Validators.required]),
				q3: new FormControl(null, [Validators.required]),
				q4: new FormControl(null, [Validators.required]),
				q5: new FormControl(null, [Validators.required]),
				q6: new FormControl(null, [Validators.required]),
				q7: new FormControl(null, [Validators.required]),
				q8: new FormControl(null, [Validators.required]),
			}),
			questionFour: new FormControl(null, [Validators.required]),
			questionFive: new FormControl(null, [Validators.required]),
		});
		this.complaintForm = new FormGroup({
			natureOfComplaint: new FormControl(null, [Validators.required]),
			message: new FormControl(null, [
				Validators.required,
				Validators.minLength(3),
			]),
		});
	}

	handleSubmitMsg(form: FormGroup) {
		this.dialog.open(ThankyouDialogComponent, {
			panelClass: "success-dialog",
			data: {
				title: "Thank you",
				body: "complain.thankyou1",
			},
		});
	}

	handleSubmitFeedbackForm(form: FormGroup) {
		this.dialog.open(ThankyouDialogComponent, {
			panelClass: "success-dialog",
			data: {
				title: "Thank you",
				body: "complain.thankyou2",
			},
		});
	}

	handleSubmitComplaintForm(form: FormGroup) {
		this.dialog.open(ThankyouDialogComponent, {
			panelClass: "success-dialog",
			data: {
				title: "Thank you",
				body: "complain.thankyou3",
			},
		});
	}
}
