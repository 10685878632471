<!-- <div class="logo">
		<img src="assets/image/{{ partnerCode }}-logo.png" alt="" />
	</div>
	<div style="border: 1px solid red;">
		<div class="guide-step-content">
			<div fxFlex="50" class="desktop-graphic">
				<img
					class="guide-step-graphic"
					src="assets/image/guide-graphic.jpg"
					alt=""
					*ngIf="!forTakaful"
				/>
				<img
					class="guide-step-graphic"
					src="assets/image/guide-graphic-takaful.png"
					alt=""
					*ngIf="forTakaful"
				/>
			</div>
			<div fxFlex>
				<div class="guide-title">
					{{ "guide.title" | translate }}
				</div>
				<div class="guide-highlight">
					{{ "guide.highlight" | translate }}
				</div>
				<div class="guide-step-wrapper">
					<img class="step-icon" src="assets/image/guide-step-1.png" alt="" />
					<div>
						<div class="step-title">{{ "guide.step1" | translate }}</div>
						<div class="step-desc">{{ "guide.step1Desc" | translate }}</div>
					</div>
				</div>
	
				<div class="guide-step-wrapper">
					<img class="step-icon" src="assets/image/guide-step-2.png" alt="" />
					<div>
						<div class="step-title">{{ "guide.step2" | translate }}</div>
						<div class="step-desc">
							{{ "guide.step2Desc" | translate }}
						</div>
					</div>
				</div>
	
				<div class="guide-step-wrapper">
					<img class="step-icon" src="assets/image/guide-step-3.png" alt="" />
					<div>
						<div class="step-title">{{ "guide.step3" | translate }}</div>
						<div class="step-desc">{{ "guide.step3Desc" | translate }}</div>
					</div>
				</div>
			</div>
		</div>
	
		<img
			class="mobile-graphic"
			src="assets/image/guide-graphic-mobile.jpg"
			alt=""
		/>
		<div class="guide-highlight-content">
			<div fxLayout="row">
				<div class="highlight-icon-wrapper">
					<img src="assets/image/guide-icon-1.png" alt="" />
					<div>
						{{ "guide.note1" | translate
						}}<span
							[ngClass]="
								partnerCode !== 'ps'
									? partnerCode + '-highlight-color'
									: 'color-yellow'
							"
							>{{ "guide.1hr" | translate }}</span
						>*.
					</div>
				</div>
				<div class="highlight-icon-wrapper">
					<img src="assets/image/guide-icon-2.png" alt="" />
					<div>
						{{ "guide.note2a" | translate
						}}<span
							[ngClass]="
								partnerCode !== 'ps'
									? partnerCode + '-highlight-color'
									: 'color-yellow'
							"
							>{{ "guide.2days" | translate }}</span
						>*. {{ "guide.note2b" | translate }}
					</div>
				</div>
			</div>
			<div class="desc">
				{{ "guide.disclaimer" | translate }}
			</div>
		</div>
	</div> -->

<!-- *** NEW DESIGN *** -->
<!-- * When the language in English * -->
<ng-container *ngIf="translate.currentLang === 'en'">
	<img
		style="width: 100%"
		class="desktop-graphic"
		src="assets/image/guide_desktop_eng.png"
		alt="guide_desktop_eng"
	/>
	<div
		mat-dialog-content
		class="mobile-dialog-content"
		style="padding: 0; margin: 0"
	>
		<img
			src="assets/image/guide_mobile_eng.png"
			alt="guide_mobile_eng"
		/>
	</div>
</ng-container>
<!-- * When the language in Malay * -->
<ng-container *ngIf="translate.currentLang !== 'en'">
	<img
		style="width: 100%"
		class="desktop-graphic"
		src="assets/image/guide_desktop_bm.png"
		alt=""
	/>
	<div
		mat-dialog-content
		class="mobile-dialog-content"
		style="padding: 0; margin: 0"
	>
		<img
			src="assets/image/guide_mobile_bm.png"
			alt="guide_mobile_eng"
		/>
	</div>
</ng-container>
