import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {Observable} from "rxjs";
import { environment } from "../../../environments/environment";
import {DropdownItem} from "../interface/common.interface";

@Injectable({
	providedIn: "root",
})
export class MasterDataDataService {
	private basePath: string = `${environment.url}/api/Microsite/`;

	constructor(private http: HttpClient) {}

	getCountrySelection() {
		return this.http.get<string[]>(`${this.basePath}GetCountrySelection`);
	}

	selectAllPostcodes(){
		return this.http.get<string[]>(`${this.basePath}SelectAllPostcodes`)
	}

  getMotorMileagePlans(): Observable<DropdownItem[]> {
    return this.http.get<DropdownItem[]>(`${this.basePath}MotorMileagePlans`);
  }
}
