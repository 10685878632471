import { Component, EventEmitter, Output } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
// Services
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-confirmation-dialog",
	templateUrl: "./confirmation-dialog.component.html",
	styleUrls: ["./confirmation-dialog.component.scss"],
})
export class ConfirmationDialogComponent {
	@Output() yesClicked: EventEmitter<void> = new EventEmitter<void>();
	showSpinner: boolean = false;

	constructor(
		public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
		public translate: TranslateService,
	) {}

	onNoClick(): void {
		this.dialogRef.close(false);
	}

	onYesClick(): void {
		this.yesClicked.emit();
	}
}
