<div class="cust-details">
    <div class="cust-details__header">
        <div class="cust-details__header__title">
            <img src="..\assets\image\renew-quotation-receipt.png" alt="">
            <span>{{ "renewQuotation.yourDetails" | translate }}</span>
        </div>
        <button mat-button class="cust-details__header__edit-button" 
            [disabled]="reSubmitted"
            [ngStyle]="{ 'width': translate.currentLang !== 'en' ? '62px' : '' }" 
            (click)="onEditDetailsClick()">
            {{ "common.edit" | translate }}
        </button>
    </div>
    <div class="cust-details__body">
        <div class="cust-details__body__row">
            <div class="cust-details__body__title">
                {{ "common.typeOfInsurance" | translate }}
            </div>
            <div class="cust-details__body__value">
                <li>
                    <ng-container [ngSwitch]="renewPage.insuranceType">
                        <ng-container *ngSwitchCase="'COM'">{{ "common.COM" | translate }}</ng-container>
                        <ng-container *ngSwitchCase="'TPFT'">{{ "common.TPFT" | translate }}</ng-container>
                    </ng-container>
                </li>
            </div>
        </div>
        <div class="cust-details__body__row">
            <div class="cust-details__body__title">{{ "common.carPlateNoShort" | translate }}</div>
            <div class="cust-details__body__value">
                <li>
                    {{ renewPage.vehicleNo || "-" }}
                </li>
            </div>
        </div>
        <div class="cust-details__body__row">
            <div class="cust-details__body__title">{{ "common.fullNamePerICPassport" | translate }}</div>
            <div class="cust-details__body__value">
                <li>
                    {{ renewPage.name || "-"  }}
                </li>
            </div>
        </div>
        <div class="cust-details__body__row">
            <div class="cust-details__body__title">
                <ng-container [ngSwitch]="idType">
                    <ng-container *ngSwitchCase="'Malaysian'">{{ "common.icNoLabelShort" | translate }}</ng-container>
                    <ng-container *ngSwitchCase="'Company'">{{ "common.businessRegistrationNo" | translate }}</ng-container>
                    <ng-container *ngSwitchCase="'Foreigner'">{{ "common.passportLabel" | translate }}</ng-container>
                    <ng-container *ngSwitchCase="'Police/Army'">{{ "common.Police/Army" | translate }} no.</ng-container>
                </ng-container>
            </div>
            <div class="cust-details__body__value" 
                *ngIf="idType == 'Police/Army'">
                <li>
                    {{ renewPage.policeIdentityNo || "-"  }}
                </li>
            </div>
            <div class="cust-details__body__value"
                *ngIf="idType != 'Police/Army'">
                <li>
                    {{ renewPage.identityNo || "-"  }}
                </li>
            </div>
        </div>
        <div class="cust-details__body__row" *ngIf="idType == 'Police/Army'">
            <div class="cust-details__body__title">
                {{ "common.icNoLabelShort" | translate }}
            </div>
            <div class="cust-details__body__value">
                <li>
                    {{ renewPage.identityNo || "-"  }}
                </li>
            </div>
        </div>
        <div class="cust-details__body__row" *ngIf="idType == 'Foreigner'">
            <div class="cust-details__body__title">
                {{ "common.nationality" | translate }}
            </div>
            <div class="cust-details__body__value">
                <li>
                    {{ renewPage.nationality || "-"  }}
                </li>
            </div>
        </div>
        <div class="cust-details__body__row">
            <div class="cust-details__body__title">{{ "common.residentialPostcode" | translate }}</div>
            <div class="cust-details__body__value">
                <li>
                    {{ renewPage.postcode || "-"  }}
                </li>
            </div>
        </div>
        <div class="cust-details__body__row">
            <div class="cust-details__body__title">{{ "common.mobileNoShort" | translate }}</div>
            <div class="cust-details__body__value">
                <li>
                    {{ renewPage.mobileNo || "-"  }}
                </li>
            </div>
        </div>
        <div class="cust-details__body__row">
            <div class="cust-details__body__title">{{ "common.emailAddress" | translate }}</div>
            <div class="cust-details__body__value">
                <li>
                    {{ renewPage.email || "-"  }}
                </li>
            </div>
        </div>
        <div class="cust-details__body__row">
            <div class="cust-details__body__title">{{ "common.gender" | translate }}</div>
            <div class="cust-details__body__value">
                <li>
                    <ng-container [ngSwitch]="renewPage.gender">
                        <ng-container *ngSwitchCase="'M'">{{ "common.M" | translate }}</ng-container>
                        <ng-container *ngSwitchCase="'F'">{{ "common.F" | translate }}</ng-container>
                    </ng-container>
                </li>
            </div>
        </div>
        <div class="cust-details__body__row">
            <div class="cust-details__body__title">
                {{ "common.preferredContact" | translate }}
            </div>
            <div class="cust-details__body__value">
                <li>
                    <ng-container [ngSwitch]="renewPage.preferredChannel">
                        <ng-container *ngSwitchCase="'Email'">{{ "common.email" | translate }}</ng-container>
                        <ng-container *ngSwitchCase="'Mobile'">{{ "common.WhatsApp" | translate }}</ng-container>
                        <ng-container *ngSwitchDefault> - </ng-container>
                    </ng-container>
                </li>
            </div>
        </div>
        <div class="cust-details__body__row">
            <div class="cust-details__body__title">{{ "common.carUsedForEHailing" | translate }}</div>
            <div class="cust-details__body__value">
                <li>
                    <ng-container [ngSwitch]="renewPage.eHailingUsed">
                        <ng-container *ngSwitchCase="true">{{ "common.yes" | translate }}</ng-container>
                        <ng-container *ngSwitchCase="false">{{ "common.no" | translate }}</ng-container>
                    </ng-container>
                </li>
            </div>
        </div>
    </div>
</div>