<div class="plans-bg" *ngIf="!quotationIsExpired">
  <mat-stepper
    linear
    labelPosition="bottom"
    #stepper
    (selectionChange)="onStepChange($event)"
    [ngClass]="hideStepperLabel"
  >
    <!-- ## Step: First step -->
    <mat-step [stepControl]="quotationFormGroup">
      <form [formGroup]="quotationFormGroup">
        <ng-container *ngIf="ctaBanner.length != 0">
          <div *ngFor="let banner of ctaBanner" class="cta-banner constraint">
            <img
              [src]="banner.src"
              [alt]="banner.alt"
              *ngIf="translate.currentLang == banner.lang && banner.show"
            />
          </div>
        </ng-container>
        <mat-card class="border-card">
          <div class="border-card__flex">
            <div class="border-card__container">
              <div class="border-card__main-title">
                <img
                  src="https://storage.googleapis.com/dev-ps-asset/Car_Insurance/icons/car-icon.png"
                  alt=""
                />
                {{ "common.carDetails" | translate }}
              </div>
              <div class="border-card__content-wrapper">
                <div class="border-card__label-container">
                  <div class="label-desc">
                    {{ "common.carNumber" | translate }} :
                  </div>
                  <div class="label-value">
                    {{ personalInfo?.vehicleNo }}
                  </div>
                </div>
                <div class="border-card__label-container">
                  <div class="label-desc">
                    {{ "common.carModel" | translate }} :
                  </div>
                  <div class="label-value">
                    {{ personalInfo?.vehicle }}
                  </div>
                </div>
              </div>
            </div>

            <div class="border-card__container">
              <div class="border-card__main-title">
                <img
                  src="https://storage.googleapis.com/dev-ps-asset/Car_Insurance/icons/shield-icon.png"
                  alt=""
                />
                {{ "common.carProtectionPlan" | translate }}
              </div>
              <div class="border-card__content-wrapper">
                <div class="border-card__label-container">
                  <div class="label-desc">
                    {{ "common.typeOfInsurance" | translate }}
                    :
                  </div>
                  <div class="label-value">
                    {{ "common." + personalInfo?.insuranceType | translate }}
                  </div>
                </div>
                <div class="border-card__label-container">
                  <div class="label-desc">
                    {{ "common.effectivePeriod" | translate }} :
                  </div>
                  <div class="label-value">
                    {{ personalInfo?.effectiveDate | date : "dd MMM yyyy" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            mat-stroked-button
            (click)="editQuotation()"
            class="stroked-btn"
            [matTooltip]="'common.editQuotationDisabledTooltip' | translate"
            [matTooltipDisabled]="!disableEditQuotationBtn"
            [disabled]="disableEditQuotationBtn"
          >
            {{ "common.editQuotation" | translate }}
          </button>
        </mat-card>
        <div class="comparison-section-title">
          <app-section-title
            title="comparison.quotationAvalTxt"
            desc="comparison.quotationAvalDesc"
          >
          </app-section-title>
        </div>
        <div *ngFor="let item of quotationList; let first = first">
          <!--Other insurers card -->
          <app-insurer-card-v2
            [first]="first"
            [item]="item"
            (onSubmit)="openCarVariantDialog(item?.id)"
            *ngIf="item?.status == 'Available'"
          >
          </app-insurer-card-v2>
        </div>
        <ng-container *ngFor="let item of sortedQuotationList">
          <app-loading-insurer-card
            [insurer]="item"
            *ngIf="item?.status == 'New' || item?.status == 'Rejected'"
          ></app-loading-insurer-card>
        </ng-container>
        <div class="other-insurer-section">
          <app-section-title
            title="plans.step1Content.exploreOther"
            desc="plans.step1Content.exploreOtherDesc"
          ></app-section-title>
          <div class="other-insurer-section__cards">
            <app-other-insurer-card
              (clickEvent)="updateSelectedInsurers($event)"
              *ngFor="let x of otherInsurerList"
              [insurer]="x"
            ></app-other-insurer-card>
          </div>
          <button
            mat-stroked-button
            class="stroked-btn mv-lg"
            (click)="submitOtherInsurer()"
          >
            {{ "comparison.getQuoteNow" | translate }}
          </button>
        </div>
      </form>
    </mat-step>

    <!-- ## Step: Second step -->
    <mat-step state="edit">
      <ng-template matStepLabel>
        {{ "plans.step1" | translate }}
      </ng-template>
      <button mat-button matStepperPrevious class="step-back-btn">
        <mat-icon>arrow_back</mat-icon> {{ "common.back" | translate }}
      </button>

      <form [formGroup]="addOnFormGroup">
        <div class="plans-container">
          <div class="main-container">
            <!-- MTR-578: TEMPORARILY HIDE FFF BUNDLE -->
            <div class="bundle-container" style="display: none">
              <app-section-title
                title="plans.step1Content.bundleSecTitle"
                desc="plans.step1Content.bundleSecDesc"
              ></app-section-title>
              <mat-chip-list>
                <mat-chip
                  [selected]="
                    floodChip.selected || floodAddonChip.selected || true
                  "
                  #floodChip="matChip"
                  (click)="floodChip.selected = true"
                  >{{ "plans.step1Content.floodChip" | translate }}</mat-chip
                >
                <mat-chip
                  #femaleChip="matChip"
                  [selected]="femaleChip.selected || femaleAddonChip.selected"
                  (click)="femaleChip.selected = true"
                  >{{ "plans.step1Content.femaleChip" | translate }}</mat-chip
                >
                <mat-chip
                  #familyChip="matChip"
                  [selected]="familyChip.selected || familyAddonChip.selected"
                  (click)="familyChip.selected = true"
                  >{{ "plans.step1Content.familyChip" | translate }}</mat-chip
                >
              </mat-chip-list>
              <ng-container *ngFor="let item of data?.motorBundle">
                <ng-container
                  *ngIf="
                    (floodAddonChip.selected || floodChip.selected) &&
                    item.code == 'Flood'
                  "
                >
                  <app-bundle-card
                    (addBundle)="addBundle(item)"
                    (removeBundle)="removeBundle(item)"
                    [item]="item"
                    [selectedBundle]="selectedBundle"
                    [expandedData]="floodBenefitsSnippets"
                    [sumInsured]="selectedQuotation?.sumInsured"
                    [coverageLink]="getBundleBrochure(item.code)"
                    [recommended]="true"
                  ></app-bundle-card>
                </ng-container>
                <ng-container
                  *ngIf="femaleChip.selected && item.code == 'Female'"
                >
                  <app-bundle-card
                    (addBundle)="addBundle(item)"
                    (removeBundle)="removeBundle(item)"
                    [item]="item"
                    [selectedBundle]="selectedBundle"
                    [expandedData]="femaleBenefitsSnippets"
                    [coverageLink]="getBundleBrochure(item.code)"
                    [recommended]="false"
                  ></app-bundle-card>
                </ng-container>
                <ng-container
                  *ngIf="familyChip.selected && item.code == 'Family'"
                >
                  <app-bundle-card
                    (addBundle)="addBundle(item)"
                    (removeBundle)="removeBundle(item)"
                    [item]="item"
                    [selectedBundle]="selectedBundle"
                    [expandedData]="familyBenefitsSnippets"
                    [coverageLink]="getBundleBrochure(item.code)"
                    [recommended]="false"
                  ></app-bundle-card>
                </ng-container>
              </ng-container>
            </div>
            <div class="pt-xs">
              <div class="ph-sm">
                <app-section-title
                  title="plans.step1Content.addOnsTitle"
                  desc="plans.step1Content.addOnsDesc"
                ></app-section-title>

                <mat-chip-list>
                  <mat-chip
                    #allAddonChip="matChip"
                    [selected]="true"
                    (click)="allAddonChip.selected = true"
                    (selectionChange)="allAddonChipSelected($event)"
                    >{{ "plans.bundleCategory.all" | translate }}</mat-chip
                  >
                  <mat-chip
                    #popularAddonChip="matChip"
                    (click)="popularAddonChip.selected = true"
                    (selectionChange)="popularAddonChipSelected($event)"
                    >{{ "plans.bundleCategory.popular" | translate }}</mat-chip
                  >
                  <!-- MTR-578: TEMPORARILY HIDE FFF BUNDLE -->
                  <mat-chip
                    style="display: none"
                    #floodAddonChip="matChip"
                    [selected]="floodAddonChip.selected || floodChip.selected"
                    (click)="
                      floodAddonChip.selected = true; floodChip.selected = true
                    "
                    >{{ "plans.step1Content.floodChip" | translate }}</mat-chip
                  >
                  <!-- MTR-578: TEMPORARILY HIDE FFF BUNDLE -->
                  <mat-chip
                    style="display: none"
                    #femaleAddonChip="matChip"
                    [selected]="femaleAddonChip.selected || femaleChip.selected"
                    (click)="
                      femaleAddonChip.selected = true;
                      femaleChip.selected = true
                    "
                    >{{ "plans.step1Content.femaleChip" | translate }}</mat-chip
                  >
                  <!-- MTR-578: TEMPORARILY HIDE FFF BUNDLE -->
                  <mat-chip
                    style="display: none"
                    #familyAddonChip="matChip"
                    [selected]="familyAddonChip.selected || familyChip.selected"
                    (click)="
                      familyAddonChip.selected = true;
                      familyChip.selected = true
                    "
                    >{{ "plans.step1Content.familyChip" | translate }}</mat-chip
                  >
                </mat-chip-list>
              </div>

              <div class="addons-container">
                <ng-container *ngFor="let addOn of addOnListToDisplay">
                  <!-- Included Addon: Always show on top -->
                  <ng-container *ngIf="addOn.included">
                    <app-addon-card
                      [title]="'addOn.' + addOn.code | translate"
                      [tooltip]="'addOn.' + addOn.code + 'Tooltip' | translate"
                      [code]="addOn.code"
                      [price]="addOn.evaluate"
                      [label]="false"
                      [includedAddOn]="true"
                      [desc]="'addOn.' + addOn?.code + 'Desc' | translate"
                      img="https://storage.googleapis.com/ps-asset/motor/FFF/Add-on/{{
                        addOn.category
                      }}.png"
                      [addOnSelected]="hasSelectedCategory(addOn.category)"
                      (toggleCheckbox)="toggleAddOn($event, addOn)"
                    ></app-addon-card>
                  </ng-container>
                  <ng-container *ngIf="!addOn.included">
                    <ng-container *ngIf="addOn.isBundleAddOn">
                      <ng-container *ngIf="addOn.category == 'Flood'">
                        <app-addon-card
                          [title]="
                            'plans.bundleAddOns.' + addOn.code | translate
                          "
                          [desc]="
                            'plans.bundleAddOns.' + addOn.code + 'Desc'
                              | translate
                                : {
                                    sumInsured:
                                      selectedQuotation?.sumInsured
                                      | number : '1.2-2'
                                  }
                          "
                          [tooltip]="
                            'plans.bundleAddOns.' + addOn.code + 'TT'
                              | translate
                          "
                          [price]="addOn.evaluate"
                          [label]="addOn.code == 'FloodCoverage' ? false : true"
                          [img]="addOn.imageUrl"
                          [selected]="getIsBundleSelected(addOn.motorBundleId)"
                          [addOnSelected]="getIsBundleAddOnSelected(addOn.code)"
                          (toggleCheckbox)="
                            toggleBundleAddonCheckbox($event, addOn)
                          "
                          *ngIf="
                            floodAddonChip.selected ||
                            allAddonChip.selected ||
                            (addOn.isPopular && popularAddonChip.selected)
                          "
                        ></app-addon-card>
                      </ng-container>
                      <ng-container *ngIf="addOn.category == 'Female'">
                        <app-addon-card
                          [title]="
                            'plans.bundleAddOns.' + addOn.code | translate
                          "
                          [desc]="
                            'plans.bundleAddOns.' + addOn.code + 'Desc'
                              | translate
                          "
                          [tooltip]="
                            'plans.bundleAddOns.' + addOn.code + 'TT'
                              | translate
                          "
                          [price]="addOn.evaluate"
                          [label]="true"
                          [img]="addOn.imageUrl"
                          [selected]="getIsBundleSelected(addOn.motorBundleId)"
                          [addOnSelected]="getIsBundleAddOnSelected(addOn.code)"
                          (toggleCheckbox)="
                            toggleBundleAddonCheckbox($event, addOn)
                          "
                          *ngIf="
                            femaleAddonChip.selected ||
                            allAddonChip.selected ||
                            (addOn.isPopular && popularAddonChip.selected)
                          "
                        ></app-addon-card>
                      </ng-container>
                      <ng-container *ngIf="addOn.category == 'Family'">
                        <app-addon-card
                          [title]="
                            'plans.bundleAddOns.' + addOn.code | translate
                          "
                          [desc]="
                            'plans.bundleAddOns.' + addOn.code + 'Desc'
                              | translate
                          "
                          [tooltip]="
                            'plans.bundleAddOns.' + addOn.code + 'TT'
                              | translate
                          "
                          [price]="addOn.evaluate"
                          [label]="true"
                          [img]="addOn.imageUrl"
                          [selected]="getIsBundleSelected(addOn.motorBundleId)"
                          [addOnSelected]="getIsBundleAddOnSelected(addOn.code)"
                          (toggleCheckbox)="
                            toggleBundleAddonCheckbox($event, addOn)
                          "
                          *ngIf="
                            familyAddonChip.selected ||
                            allAddonChip.selected ||
                            (addOn.isPopular && popularAddonChip.selected)
                          "
                        ></app-addon-card>
                      </ng-container>
                      <!-- Add on that shows on all category -->
                      <ng-container
                        *ngIf="
                          addOn.category !== 'Flood' &&
                          addOn.category !== 'Female' &&
                          addOn.category !== 'Family'
                        "
                      >
                        <app-addon-card
                          [title]="
                            'plans.bundleAddOns.' + addOn.code | translate
                          "
                          [desc]="
                            'plans.bundleAddOns.' + addOn.code + 'Desc'
                              | translate
                          "
                          [tooltip]="
                            'plans.bundleAddOns.' + addOn.code + 'TT'
                              | translate
                          "
                          [price]="addOn.evaluate"
                          [label]="false"
                          [img]="addOn.imageUrl"
                          [selected]="isAnyBundleSelected()"
                          [addOnSelected]="getIsBundleAddOnSelected(addOn.code)"
                          (toggleCheckbox)="
                            toggleBundleAddonCheckbox($event, addOn)
                          "
                        ></app-addon-card>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="!addOn.isBundleAddOn">
                      <ng-container *ngIf="addOn.isRoadTaxRenewal">
                        <app-addon-card
                          [title]="'dashboard.roadTaxRenewTitle' | translate"
                          [desc]="'addOn.roadTaxDesc' | translate"
                          [tooltip]="'addOn.roadTaxTT' | translate"
                          [price]="getRoadtaxDisplayPrice()"
                          img="https://storage.googleapis.com/dev-ps-asset/Car_Insurance/icons/roadtax-logo.png"
                          [addOnSelected]="
                            addOnFormGroup.value?.isIncludeRoadtax
                          "
                          (toggleCheckbox)="toggleRoadTax($event)"
                          [isRoadtaxPromo]="
                            personalInfo?.roadTaxPrice !=
                            personalInfo?.originalRoadTaxPrice
                          "
                          [roadtaxPromoPrice]="personalInfo?.roadTaxPrice"
                        >
                          <div slot="fullWidth" class="roadtax-content">
                            <mat-radio-group
                              *ngIf="showRoadTaxList"
                              (change)="radioDigitalRoadTaxChange($event)"
                              class="roadtax-radio-group"
                            >
                              <mat-radio-button
                                value="digitalRoadTax"
                                [checked]="
                                  personalInfo.isMalaysian &&
                                  !personalInfo.isCompany
                                "
                                *ngIf="
                                  personalInfo.isMalaysian &&
                                  !personalInfo.isCompany
                                "
                              >
                                <ng-container>
                                  <div
                                    fxLayout="column"
                                    fxLayoutAlign=" flex-start"
                                  >
                                    <div
                                      class="title"
                                      fxFlex
                                      [innerHTML]="
                                        'addOn.digitalRoadTax' | translate
                                      "
                                    ></div>
                                    <div class="value sub-right text-right">
                                      <!-- <del>RM 8.00</del> -->
                                      <span class="color-free">
                                        {{ "capitalFree" | translate }}
                                      </span>
                                      ({{ "limitedTimeOnly" | translate }})
                                    </div>
                                  </div>
                                </ng-container>
                              </mat-radio-button>
                              <mat-radio-button
                                value="roadTax"
                                [checked]="
                                  !personalInfo.isMalaysian ||
                                  personalInfo.isCompany
                                "
                              >
                                <ng-container>
                                  <div
                                    fxLayout="column"
                                    fxLayoutAlign=" flex-start"
                                  >
                                    <div
                                      class="title"
                                      fxFlex
                                      [innerHTML]="
                                        'addOn.physicalRoadtaxInnerHTML'
                                          | translate
                                      "
                                    ></div>
                                    <div class="value sub-right text-right">
                                      RM 15.00 {{ "handlingFee" | translate }}
                                    </div>
                                  </div>
                                </ng-container>
                              </mat-radio-button>
                              <ng-container
                                *ngIf="
                                  addOnFormGroup.value?.isIncludeRoadtax &&
                                  showRoadTaxList
                                "
                              >
                                <div fxLayout="row">
                                  <mat-checkbox
                                    fxFlex
                                    color="warn"
                                    formControlName="isNotBlacklisted"
                                    class="blacklist"
                                  >
                                    <div
                                      [innerHTML]="
                                        'addOn.isNotBlacklistedBR' | translate
                                      "
                                    ></div>
                                  </mat-checkbox>
                                </div>
                              </ng-container>
                            </mat-radio-group>
                            <div
                              *ngIf="addOnFormGroup.value?.isIncludeRoadtax"
                              class="roadtax-content__notice"
                            >
                              <div class="roadtax-content__notice__title">
                                {{ "addOn.roadTaxNoticeTitle" | translate }}
                              </div>
                              <div class="roadtax-content__notice__desc">
                                {{ "addOn.roadTaxNoticeDesc" | translate }}
                              </div>
                            </div>
                          </div>
                        </app-addon-card>
                      </ng-container>
                      <ng-container *ngIf="!addOn.isRoadTaxRenewal">
                        <ng-container
                          *ngIf="
                            addOn.category !== 'Windshield' &&
                            addOn.category !== 'Drivers' &&
                            addOn.category !== 'LLTP' &&
                            addOn.category !== 'MPA' &&
                            addOn.category !== 'PA' &&
                            addOn.category !== 'MERW'
                          "
                        >
                          <app-addon-card
                            [class]="addOn.category"
                            [title]="'addOn.' + addOn.code | translate"
                            [tooltip]="
                              'addOn.' + addOn.code + 'Tooltip' | translate
                            "
                            [price]="addOn.evaluate"
                            [label]="false"
                            [desc]="
                              'addOn.' + addOn.code + 'Desc'
                                | translate
                                  : {
                                      sumInsured:
                                        selectedQuotation?.sumInsured
                                        | number : '1.2-2'
                                    }
                            "
                            img="https://storage.googleapis.com/ps-asset/motor/FFF/Add-on/{{
                              addOn.category
                            }}.png"
                            [addOnSelected]="
                              hasSelectedCategory(addOn.category)
                            "
                            (toggleCheckbox)="toggleAddOn($event, addOn)"
                          ></app-addon-card>
                        </ng-container>
                        <ng-container *ngIf="addOn.category == 'Windshield'">
                          <app-addon-card
                            [title]="'addOn.' + addOn.code | translate"
                            [desc]="'addOn.' + addOn.code + 'Desc' | translate"
                            [tooltip]="
                              'addOn.' + addOn.code + 'Tooltip' | translate
                            "
                            [price]="
                              getPrice(addOn, addOnFormGroup.value.windshield)
                            "
                            [label]="false"
                            img="https://storage.googleapis.com/ps-asset/motor/FFF/Add-on/{{
                              addOn.category
                            }}.png"
                            [addOnSelected]="
                              hasSelectedCategory(addOn.category)
                            "
                            (toggleCheckbox)="toggleAddOn($event, addOn)"
                          >
                            <div slot="fullWidth">
                              <ng-container *ngIf="isAddOnSelected(addOn)">
                                <div class="slider-desc">
                                  {{ "addOn.coverage" | translate }} :
                                </div>
                                <app-range-slider
                                  [max]="getRule(addOn)?.max || 10000"
                                  [min]="getRule(addOn)?.min || 500"
                                  [step]="50"
                                  [variantSmall]="true"
                                  formControlName="windshield"
                                  maxError="addOn.maxWindscreenError"
                                  minError="addOn.minWindscreenError"
                                ></app-range-slider>
                              </ng-container>
                            </div>
                          </app-addon-card>
                        </ng-container>

                        <ng-container *ngIf="addOn.category == 'Drivers'">
                          <ng-container *ngIf="driverAddOnCount === 1">
                            <app-addon-card
                              [title]="'addOn.' + addOn.code | translate"
                              [tooltip]="
                                'addOn.' + addOn.code + 'Tooltip' | translate
                              "
                              [price]="getPrice(addOn) | number : '1.2-2'"
                              [label]="false"
                              img="https://storage.googleapis.com/ps-asset/motor/FFF/Add-on/{{
                                addOn.category
                              }}.png"
                              [addOnSelected]="
                                hasSelectedCategory(addOn.category)
                              "
                              (toggleCheckbox)="toggleAddOn($event, addOn)"
                            >
                            </app-addon-card>
                          </ng-container>
                          <ng-container *ngIf="driverAddOnCount !== 1">
                            <app-addon-card
                              [title]="'addOn.AdditionalDrivers' | translate"
                              [tooltip]="
                                'addOn.' + addOn.code + 'Tooltip' | translate
                              "
                              [price]="null"
                              [label]="false"
                              img="https://storage.googleapis.com/ps-asset/motor/FFF/Add-on/{{
                                addOn.category
                              }}.png"
                              [addOnSelected]="
                                hasSelectedCategory(addOn.category)
                              "
                              (toggleCheckbox)="driverRadioChange(addOn, true)"
                            >
                              <div slot="fullWidth">
                                <mat-radio-group
                                  *ngIf="hasSelectedCategory('Drivers')"
                                  (change)="driverRadioChange($event.value)"
                                  class="roadtax-radio-group"
                                >
                                  <mat-radio-button
                                    *ngFor="
                                      let item of driversAddOn;
                                      let first = first
                                    "
                                    [value]="item"
                                    [checked]="first"
                                  >
                                    <ng-container
                                      *ngIf="item.code !== 'AdditionalDrivers'"
                                    >
                                      <div fxLayout="column">
                                        <div class="title" fxFlex>
                                          {{
                                            "addOn.UnlimitedDrivers" | translate
                                          }}
                                        </div>
                                        <div class="value sub-right">
                                          <span>RM</span>
                                          {{
                                            getPrice(item) | number : "1.2-2"
                                          }}
                                        </div>
                                      </div>
                                    </ng-container>

                                    <ng-container
                                      *ngIf="item.code === 'AdditionalDrivers'"
                                    >
                                      <div fxLayout="column">
                                        <div class="title" fxFlex>
                                          {{
                                            "addOn.additionalDriver" | translate
                                          }}
                                        </div>
                                        <div fxLayout="row">
                                          <div
                                            class="value sub-right"
                                            *ngIf="
                                              getDriversFormArray()?.controls
                                                .length > 1;
                                              else freeDriverText
                                            "
                                          >
                                            <span>RM</span>
                                            {{
                                              getPrice(
                                                item,
                                                getDriversFormArray()?.controls
                                                  .length
                                              ) | number : "1.2-2"
                                            }}
                                          </div>
                                          <ng-template #freeDriverText>
                                            <div class="value sub-right">
                                              {{ "addOn.free" | translate }}
                                              ({{
                                                "addOn.FreeForFirst1"
                                                  | translate
                                              }}
                                              {{
                                                "addOn.FreeForFirst2"
                                                  | translate
                                              }})
                                            </div>
                                          </ng-template>
                                          <mat-icon
                                            class="tooltip-icon"
                                            [matMenuTriggerFor]="
                                              AdditionalDriversTooltip
                                            "
                                            #AdditionalDriversTrigger="matMenuTrigger"
                                            (mouseenter)="
                                              AdditionalDriversTrigger.openMenu()
                                            "
                                          >
                                            info
                                          </mat-icon>
                                        </div>
                                      </div>
                                      <mat-menu
                                        #AdditionalDriversTooltip="matMenu"
                                        [overlapTrigger]="false"
                                        class="tooltip-popover"
                                      >
                                        <span
                                          (mouseleave)="
                                            AdditionalDriversTrigger.closeMenu()
                                          "
                                        >
                                          {{
                                            "addOn.AdditionalDriversTooltip"
                                              | translate
                                          }}
                                        </span>
                                      </mat-menu>
                                    </ng-container>
                                  </mat-radio-button>
                                </mat-radio-group>
                                <ng-container
                                  *ngIf="hasSelectedCode('AdditionalDrivers')"
                                >
                                  <div
                                    formArrayName="drivers"
                                    *ngFor="
                                      let item of getDriversFormArray()
                                        ?.controls;
                                      let i = index
                                    "
                                  >
                                    <br />
                                    <ng-container [formGroupName]="i">
                                      <div
                                        fxLayout="row"
                                        fxLayoutAlign=" center"
                                      >
                                        <div class="driver-count bold">
                                          {{
                                            "addOn.additionalDriver" | translate
                                          }}
                                          #{{ i + 1 }}
                                        </div>
                                        <div
                                          class="driver-divider"
                                          fxFlex
                                        ></div>
                                        <button
                                          class="driver-delete"
                                          (click)="deleteDriver(i)"
                                          mat-icon-button
                                          *ngIf="i !== 0"
                                          type="button"
                                        >
                                          <mat-icon>delete_outline</mat-icon>
                                        </button>
                                      </div>

                                      <br />

                                      <div class="form-field">
                                        <mat-form-field
                                          floatLabel="always"
                                          class="left"
                                        >
                                          <mat-label
                                            >{{ "common.name" | translate }}
                                            :
                                          </mat-label>
                                          <input
                                            matInput
                                            autocomplete="off"
                                            formControlName="name"
                                          />
                                          <mat-error>
                                            {{ "common.name" | translate }}
                                            {{
                                              getFormArrayControlError(
                                                i,
                                                "name"
                                              )
                                                | inputError
                                                | translate
                                            }}
                                          </mat-error>
                                        </mat-form-field>
                                        <mat-form-field
                                          floatLabel="always"
                                          class="right"
                                        >
                                          <mat-label
                                            >{{ "common.icNo" | translate }}
                                            :
                                          </mat-label>
                                          <input
                                            matInput
                                            autocomplete="off"
                                            mask="000000000000"
                                            (input)="
                                              additionalDriverIdentityNoChange(
                                                i
                                              )
                                            "
                                            formControlName="identityNo"
                                          />
                                          <mat-error>
                                            {{ "common.icNo" | translate }}
                                            {{
                                              getFormArrayControlError(
                                                i,
                                                "identityNo"
                                              )
                                                | inputError
                                                | translate
                                            }}
                                          </mat-error>
                                        </mat-form-field>

                                        <mat-form-field
                                          floatLabel="always"
                                          class="left"
                                        >
                                          <mat-label
                                            >{{ "common.dob" | translate }}
                                            :
                                          </mat-label>
                                          <input
                                            matInput
                                            [matDatepicker]="picker"
                                            formControlName="dob"
                                          />
                                          <mat-datepicker
                                            #picker
                                          ></mat-datepicker>
                                          <mat-error>
                                            {{ "common.dob" | translate }}
                                            {{
                                              getFormArrayControlError(i, "dob")
                                                | inputError
                                                | translate
                                            }}
                                          </mat-error>
                                        </mat-form-field>

                                        <section
                                          class="right additional-radio-btn"
                                        >
                                          <mat-label
                                            >{{ "common.gender" | translate }}
                                            :
                                          </mat-label>
                                          <mat-radio-group
                                            aria-label="Select an option"
                                            formControlName="gender"
                                          >
                                            <mat-radio-button
                                              [value]="genderOption.male"
                                            >
                                              {{ "common.M" | translate }}
                                            </mat-radio-button>
                                            <mat-radio-button
                                              [value]="genderOption.female"
                                            >
                                              {{ "common.F" | translate }}
                                            </mat-radio-button>
                                          </mat-radio-group>
                                          <mat-error>
                                            {{ "common.gender" | translate }}
                                            {{
                                              getFormArrayControlError(
                                                i,
                                                "gender"
                                              )
                                                | inputError
                                                | translate
                                            }}
                                          </mat-error>
                                        </section>

                                        <section
                                          class="additional-radio-btn full"
                                        >
                                          <mat-label
                                            >{{
                                              "common.maritalStatus" | translate
                                            }}
                                            :
                                          </mat-label>
                                          <mat-radio-group
                                            aria-label="Select an option"
                                            formControlName="maritalStatus"
                                          >
                                            <mat-radio-button
                                              [value]="
                                                maritalStatusOption.single
                                              "
                                            >
                                              {{ "common.Single" | translate }}
                                            </mat-radio-button>
                                            <mat-radio-button
                                              [value]="
                                                maritalStatusOption.married
                                              "
                                            >
                                              {{ "common.Married" | translate }}
                                            </mat-radio-button>
                                          </mat-radio-group>
                                        </section>

                                        <div>
                                          <div class="f-w-500">
                                            {{
                                              "common.relationship" | translate
                                            }}
                                            :
                                          </div>

                                          <mat-form-field
                                            appearance="outline"
                                            floatLabel="never"
                                          >
                                            <mat-select
                                              formControlName="relationship"
                                              placeholder="Please select"
                                            >
                                              <mat-option
                                                *ngFor="
                                                  let item of relationshipList
                                                "
                                                [value]="item.id"
                                              >
                                                {{ item.name | translate }}
                                              </mat-option>
                                            </mat-select>
                                            <mat-error>
                                              {{
                                                "common.relationship"
                                                  | translate
                                              }}
                                              {{
                                                getFormArrayControlError(
                                                  i,
                                                  "relationship"
                                                )
                                                  | inputError
                                                  | translate
                                              }}
                                            </mat-error>
                                          </mat-form-field>
                                        </div>
                                      </div>
                                    </ng-container>
                                  </div>
                                  <button
                                    *ngIf="
                                      !maxAdditionalDriver ||
                                      getDriversFormArray()?.length <
                                        maxAdditionalDriver
                                    "
                                    (click)="addDriver()"
                                    type="button"
                                    mat-stroked-button
                                    class="add-driver-btn"
                                  >
                                    <mat-icon>add_circle</mat-icon>
                                    {{ "addOn.addDriver" | translate }}
                                  </button>
                                </ng-container>
                              </div>
                            </app-addon-card>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="addOn.category == 'LLTP'">
                          <app-addon-card
                            [title]="'addOn.' + addOn.code | translate"
                            [desc]="'addOn.' + addOn.code + 'Desc' | translate"
                            [tooltip]="
                              'addOn.' + addOn.code + 'Tooltip' | translate
                            "
                            [price]="
                              getPrice(addOn, addOnFormGroup.value.lltpSeats)
                            "
                            [label]="false"
                            img="https://storage.googleapis.com/ps-asset/motor/FFF/Add-on/{{
                              addOn.category
                            }}.png"
                            [addOnSelected]="
                              hasSelectedCategory(addOn.category)
                            "
                            (toggleCheckbox)="toggleAddOn($event, addOn)"
                          >
                            <div slot="fullWidth">
                              <ng-container *ngIf="isAddOnSelected(addOn)">
                                <div class="mt-md">
                                  {{ "addOn.coverage" | translate }} :
                                </div>

                                <mat-form-field
                                  appearance="outline"
                                  floatLabel="never"
                                >
                                  <mat-select
                                    formControlName="lltpSeats"
                                    placeholder="Select seats"
                                  >
                                    <mat-option
                                      *ngFor="let value of numberOfLltpSeats"
                                      [value]="value"
                                    >
                                      {{ value }}
                                      {{ "common.seats" | translate }}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </ng-container>
                            </div>
                          </app-addon-card>
                        </ng-container>

                        <ng-container *ngIf="addOn.category == 'PA'">
                          <app-addon-card
                            [title]="'addOn.' + addOn.code | translate"
                            [tooltip]="
                              'addOn.' + addOn.code + 'Tooltip' | translate
                            "
                            [price]="getPrice(addOn) | number : '1.2-2'"
                            [label]="false"
                            [desc]="
                              'addOn.' + addOn.code + 'Desc'
                                | translate
                                  : {
                                      sumInsured:
                                        selectedQuotation?.sumInsured
                                        | number : '1.2-2'
                                    }
                            "
                            img="https://storage.googleapis.com/ps-asset/motor/FFF/Add-on/{{
                              addOn.category
                            }}.png"
                            [addOnSelected]="
                              hasSelectedCategory(addOn.category)
                            "
                            (toggleCheckbox)="toggleAddOn($event, addOn)"
                          ></app-addon-card>
                        </ng-container>

                        <ng-container *ngIf="addOn.category == 'MERW'">
                          <app-addon-card
                            [title]="'addOn.' + addOn.code | translate"
                            [tooltip]="
                              'addOn.' + addOn.code + 'Tooltip' | translate
                            "
                            [price]="getPrice(addOn) | number : '1.2-2'"
                            [label]="false"
                            [desc]="
                              'addOn.' + addOn.code + 'Desc'
                                | translate
                                  : {
                                      sumInsured:
                                        selectedQuotation?.sumInsured
                                        | number : '1.2-2'
                                    }
                            "
                            img="https://storage.googleapis.com/ps-asset/motor/FFF/Add-on/{{
                              addOn.category
                            }}.png"
                            [addOnSelected]="
                              hasSelectedCategory(addOn.category)
                            "
                            (toggleCheckbox)="toggleAddOn($event, addOn)"
                          ></app-addon-card>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </div>

            <div class="btn-group-container">
              <button mat-stroked-button matStepperPrevious>
                {{ "common.back" | translate }}
              </button>
              <button
                mat-flat-button
                matStepperNext
                [disabled]="addOnFormGroup.invalid"
              >
                {{ "common.continue" | translate }}
              </button>
            </div>
          </div>
          <div class="sidebar-container">
            <app-expandable-card
              [forCarDetails]="true"
              [title]="'common.carDetails' | translate"
              (onEdit)="editQuotation()"
              [disableBtn]="disableEditQuotationBtn"
            >
              <ng-container snippet>
                <div class="vec-info">
                  {{ personalInfo?.vehicleNo }}
                  <div class="dot"></div>
                  {{ personalInfo?.vehicle }}
                </div>
                <div class="mt-sm">
                  <ng-container *ngTemplateOutlet="inCollabText"></ng-container>
                </div>
              </ng-container>
              <ng-container hidden>
                <div class="vec-info">
                  {{ personalInfo?.vehicleNo }}
                  <div class="dot"></div>
                  {{ personalInfo?.vehicle }}
                </div>
                <div class="mt-sm">
                  <ng-container *ngTemplateOutlet="inCollabText"></ng-container>
                </div>
                <div class="divider"></div>
                <div class="mt-children-md mt-sm">
                  <app-flex-container
                    [title]="'common.typeOfInsurance' | translate"
                    [value]="
                      'common.' + personalInfo?.insuranceType | translate
                    "
                  ></app-flex-container>
                  <app-flex-container
                    [title]="'common.sumInsured' | translate"
                    [value]="selectedQuotation?.sumInsured | number : '1.2-2'"
                    [currency]="true"
                  ></app-flex-container>
                  <app-flex-container
                    [title]="'common.effectivePeriod' | translate"
                    [value]="personalInfo?.effectiveDate | date : 'dd MMM yyyy'"
                  ></app-flex-container>
                </div>
              </ng-container>
            </app-expandable-card>
            <div
              class="order-summary"
              *ngIf="!(screenSizeService.isMobile$ | async)"
            >
              <app-expandable-card [title]="'common.orderSummary' | translate">
                <ng-container snippet>
                  <ng-container
                    *ngTemplateOutlet="orderSummarySnippet"
                  ></ng-container>
                </ng-container>
                <ng-container hidden>
                  <ng-container *ngTemplateOutlet="orderSummary">
                  </ng-container>
                </ng-container>
                <ng-container share>
                  <ng-container *ngTemplateOutlet="promoTemplate">
                  </ng-container>
                </ng-container>
              </app-expandable-card>
            </div>
          </div>

          <app-mobile-bottombar
            [disabledCondition]="addOnFormGroup.invalid"
            [buttonTemplate]="psMobileNextBtn"
            (onSubmit)="backToStep(2)"
          >
            <ng-container *ngTemplateOutlet="orderSummary"> </ng-container>
            <ng-container *ngTemplateOutlet="promoTemplate"> </ng-container>
          </app-mobile-bottombar>
        </div>
      </form>
    </mat-step>

    <!-- ## Step: Third step -->
    <mat-step state="edit" [stepControl]="infoFormGroup">
      <ng-template matStepLabel>
        {{ "plans.step2" | translate }}
      </ng-template>
      <button mat-button matStepperPrevious class="step-back-btn">
        <mat-icon>arrow_back</mat-icon> {{ "common.back" | translate }}
      </button>

      <div class="personal-details-container">
        <div class="main-container">
          <form class="forms" [formGroup]="infoFormGroup">
            <app-section-title
              title="common.personalDetails"
            ></app-section-title>

            <!-- Name Input -->
            <mat-form-field appearance="outline">
              <mat-label>{{ getNameLabel() | translate }}</mat-label>
              <input matInput autocomplete="name" formControlName="name" />
            </mat-form-field>

            <!-- Nationality Input -->
            <mat-form-field
              appearance="outline"
              *ngIf="idType === idTypeOption.foreigner"
            >
              <mat-label>{{ "common.nationality" | translate }}</mat-label>
              <input matInput formControlName="nationality" />
            </mat-form-field>

            <!-- Identity Input -->
            <ng-container [ngSwitch]="idType">
              <ng-container *ngSwitchCase="idTypeOption.malaysian">
                <mat-form-field appearance="outline">
                  <mat-label>
                    {{ "common.icNoLabel" | translate }}
                  </mat-label>
                  <input matInput formControlName="identityNo" />
                </mat-form-field>
              </ng-container>
              <ng-container *ngSwitchCase="idTypeOption.company">
                <mat-form-field appearance="outline">
                  <mat-label>{{
                    "common.businessRegistrationNo" | translate
                  }}</mat-label>
                  <input
                    matInput
                    formControlName="identityNo"
                    autocomplete="off"
                  />
                </mat-form-field>
              </ng-container>
              <ng-container *ngSwitchCase="idTypeOption.foreigner">
                <mat-form-field appearance="outline">
                  <mat-label>{{
                    "common.passportLabel" | translate
                  }}</mat-label>
                  <input
                    matInput
                    formControlName="identityNo"
                    autocomplete="off"
                  />
                </mat-form-field>
              </ng-container>
              <ng-container *ngSwitchCase="idTypeOption.police">
                <mat-form-field appearance="outline">
                  <mat-label>{{
                    "common.policeOrArmyIcNo" | translate
                  }}</mat-label>
                  <input matInput formControlName="policIdentityNo" />
                </mat-form-field>
              </ng-container>
            </ng-container>

            <!-- Police Identity Input -->
            <ng-container *ngIf="idType === idTypeOption.police">
              <mat-form-field appearance="outline">
                <mat-label>{{ "common.icNoLabel" | translate }}</mat-label>
                <input matInput formControlName="identityNo" />
              </mat-form-field>
            </ng-container>

            <!-- Mobile No. Input -->
            <mat-form-field appearance="outline">
              <mat-label>{{ "common.mobileNo" | translate }}</mat-label>
              <ngx-mat-intl-tel-input
                [enableSearch]="true"
                [preferredCountries]="['my']"
                formControlName="mobileNo"
              >
              </ngx-mat-intl-tel-input>
              <mat-error
                >{{ "common.mobileNo" | translate }}
                {{
                  infoFormGroup.controls.mobileNo.errors
                    | inputError
                    | translate
                }}</mat-error
              >
            </mat-form-field>

            <!-- Email Input -->
            <mat-form-field appearance="outline" class="pb-sm">
              <mat-label>{{ "common.emailAddress" | translate }}</mat-label>
              <input matInput formControlName="email" autocomplete="email" />
              <mat-error
                style="display: block !important"
                [hidden]="!infoFormGroup.controls.email?.errors?.email"
              >
                {{ "error.invalidEmail" | translate }}</mat-error
              >
              <mat-error
                >{{ "common.emailAddress" | translate }}
                {{
                  infoFormGroup.controls.email.errors | inputError | translate
                }}</mat-error
              >
            </mat-form-field>

            <!-- Marital status Input -->
            <section *ngIf="idType !== idTypeOption.company">
              <mat-label>{{ "common.maritalStatus" | translate }}:</mat-label>
              <mat-radio-group
                class="b2c-radio"
                aria-label="Select an option"
                formControlName="maritalStatus"
              >
                <mat-radio-button [value]="maritalStatusOption.single"
                  >{{ "common.Single" | translate }}
                </mat-radio-button>
                <mat-radio-button [value]="maritalStatusOption.married"
                  >{{ "common.Married" | translate }}
                </mat-radio-button>
              </mat-radio-group>
            </section>

            <!-- Gender Input -->
            <section
              class="box-selection mb-md"
              *ngIf="idType !== idTypeOption.company"
            >
              <mat-label>{{ "common.gender" | translate }}</mat-label>
              <mat-radio-group
                class="b2c-radio"
                aria-label="Select an option"
                formControlName="gender"
              >
                <mat-radio-button [value]="genderOption.male"
                  >{{ "common.M" | translate }}
                </mat-radio-button>
                <mat-radio-button [value]="genderOption.female"
                  >{{ "common.F" | translate }}
                </mat-radio-button>
              </mat-radio-group>
              <mat-error
                >{{ "common.gender" | translate }}
                {{
                  infoFormGroup.controls.gender.errors | inputError | translate
                }}</mat-error
              >
            </section>

            <app-section-title
              title="plans.step2Content.residentialAddressTitle"
            ></app-section-title>
            <mat-form-field appearance="outline">
              <mat-label>{{ "common.addressLineOne" | translate }}</mat-label>
              <input matInput formControlName="address1" />
              <mat-error>
                {{ "common.addressLineOne" | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{ "common.addressLineTwo" | translate }}</mat-label>
              <input matInput formControlName="address2" />
              <mat-error>
                {{ "common.addressLineTwo" | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{ "common.city" | translate }}</mat-label>
              <input matInput formControlName="city" />
              <mat-error>
                {{ "common.city" | translate }}
              </mat-error>
            </mat-form-field>
            <div class="forms__flex">
              <mat-form-field appearance="outline">
                <mat-label>{{ "common.postcode" | translate }}</mat-label>
                <input matInput disabled [value]="personalInfo?.postcode" />
                <mat-error>
                  {{ "common.postcode" | translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>{{ "common.state" | translate }}</mat-label>
                <mat-select formControlName="state">
                  <mat-option *ngFor="let item of stateList" [value]="item">{{
                    item
                  }}</mat-option>
                </mat-select>
                <mat-error>
                  {{ "common.state" | translate }}
                </mat-error>
              </mat-form-field>
            </div>
            <mat-checkbox
              class="mb-md"
              (change)="deliveryAddressChecked($event)"
              *ngIf="
                addOnFormGroup.value?.isIncludeRoadtax &&
                !isIncludeDigitalRoadtax
              "
            >
              {{ "common.sendToDifferentAddress" | translate }}
            </mat-checkbox>
            <ng-container *ngIf="enableDeliveryAddress">
              <app-section-title
                title="plans.step2Content.deliveryAddressTitle"
              ></app-section-title>
              <mat-form-field appearance="outline">
                <mat-label>{{ "common.addressLineOne" | translate }}</mat-label>
                <input matInput formControlName="deliveryAddress1" />
                <mat-error>
                  {{ "common.addressLineOne" | translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>{{ "common.addressLineTwo" | translate }}</mat-label>
                <input matInput formControlName="deliveryAddress2" />
                <mat-error>
                  {{ "common.addressLineTwo" | translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>{{ "common.city" | translate }}</mat-label>
                <input matInput formControlName="deliveryCity" />
                <mat-error>
                  {{ "common.city" | translate }}
                </mat-error>
              </mat-form-field>
              <div class="forms__flex">
                <mat-form-field appearance="outline">
                  <mat-label>{{ "common.postcode" | translate }}</mat-label>
                  <input
                    matInput
                    formControlName="deliveryPostcode"
                    mask="00000"
                  />
                  <mat-error>
                    {{ "common.postcode" | translate }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>{{ "common.state" | translate }}</mat-label>
                  <mat-select formControlName="deliveryState">
                    <mat-option *ngFor="let item of stateList" [value]="item">{{
                      item
                    }}</mat-option>
                  </mat-select>
                  <mat-error>
                    {{ "common.state" | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
            </ng-container>
          </form>
          <!-- Max Savings -->
          <ng-container
            *ngIf="excessData?.length && stepper?.selectedIndex == 2"
          >
            <app-excess
              [data]="excessData"
              [quotationData]="selectedQuotation"
              [selectedExcess]="selectedExcess"
              (onChangeExcess)="changeExcess($event)"
              *ngIf="isPolicystreetProduct"
            >
            </app-excess>
          </ng-container>
          <div class="btn-group-container">
            <button mat-stroked-button matStepperPrevious>
              {{ "common.back" | translate }}
            </button>
            <button
              mat-flat-button
              matStepperNext
              [disabled]="infoFormGroup.invalid"
            >
              {{ "common.continue" | translate }}
            </button>
          </div>
        </div>
        <div class="sidebar-container">
          <mat-card class="insurance-card-container">
            <div class="flex">
              <div class="flex__left">
                <div class="flex__img">
                  <img src="assets/image/ps-logo-flex-column.png" alt="" />
                </div>
                <div class="flex__title">
                  {{
                    "common." + personalInfo?.insuranceType + "CarInsurance"
                      | translate
                  }}
                  <div class="mt-xs">
                    <span
                      class="bundle-selected"
                      *ngFor="let item of selectedBundle"
                    >
                      <img
                        src="https://i.ibb.co/8XbtGsw/Ellipse-37.png"
                        alt=""
                        class="pr-xs"
                      />
                      <span class="bold">{{ item?.description }}</span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="flex__edit-btn">
                <button mat-stroked-button (click)="stepper.selectedIndex = 0">
                  {{ "common.edit" | translate }}
                </button>
              </div>
            </div>
            <div class="divider"></div>
            <ng-container *ngTemplateOutlet="inCollabText"></ng-container>
          </mat-card>
          <div
            class="order-summary"
            *ngIf="!(screenSizeService.isMobile$ | async)"
          >
            <app-expandable-card [title]="'common.orderSummary' | translate">
              <ng-container snippet>
                <ng-container
                  *ngTemplateOutlet="orderSummarySnippet"
                ></ng-container>
              </ng-container>
              <ng-container hidden>
                <ng-container *ngTemplateOutlet="orderSummary"> </ng-container>
              </ng-container>
              <ng-container share>
                <ng-container *ngTemplateOutlet="promoTemplate"> </ng-container>
              </ng-container>
            </app-expandable-card>
          </div>
        </div>
        <app-mobile-bottombar
          [disabledCondition]="infoFormGroup.invalid"
          [buttonTemplate]="psMobileNextBtn"
          (onSubmit)="backToStep(3)"
        >
          <ng-container *ngTemplateOutlet="orderSummary"> </ng-container>
          <ng-container *ngTemplateOutlet="promoTemplate"> </ng-container>
        </app-mobile-bottombar>
      </div>
    </mat-step>

    <!-- ##Step: Fourth step -->
    <mat-step
      state="edit"
      [state]="agreementFrmGroup.valid ? 'edit' : 'close'"
      [stepControl]="agreementFrmGroup"
    >
      <form [formGroup]="agreementFrmGroup">
        <ng-template matStepLabel>
          {{ "plans.step3" | translate }}
        </ng-template>
        <button mat-button matStepperPrevious class="step-back-btn">
          <mat-icon>arrow_back</mat-icon> {{ "common.back" | translate }}
        </button>

        <div class="summary-page-container">
          <div class="main-container">
            <ng-container *ngIf="ctaBanner.length != 0">
              <div
                *ngFor="let banner of ctaBanner"
                class="cta-banner summary-page"
              >
                <img
                  [src]="banner.src"
                  [alt]="banner.alt"
                  *ngIf="translate.currentLang == banner.lang && banner.show"
                />
              </div>
            </ng-container>
            <app-section-title
              title="plans.step3Content.summaryTitle"
              desc="plans.step3Content.summaryDesc"
            ></app-section-title>
            <div class="summary-card-container">
              <!-- Protection details -->
              <mat-card>
                <div class="protection-details-card">
                  <div class="header-container">
                    <div>{{ "common.protectionDetails" | translate }}</div>
                    <button
                      mat-stroked-button
                      color="primary"
                      class="edit-btn"
                      (click)="stepper.selectedIndex = 0"
                    >
                      {{ "common.edit" | translate }}
                    </button>
                  </div>

                  <div class="content">
                    <div class="content__img">
                      <img src="assets/image/ps-logo-flex-column.png" alt="" />
                    </div>
                    <div class="content__title">
                      {{
                        "common." + personalInfo?.insuranceType + "CarInsurance"
                          | translate
                      }}
                      <div class="desc pt-xs">
                        {{ "plans.forYourCar" | translate }}
                        <strong>{{ personalInfo?.vehicleNo }}</strong>
                      </div>
                    </div>
                  </div>
                  <div class="divider"></div>
                  <div class="mt-children-md">
                    <div class="flex-container" *ngIf="selectedBundle.length">
                      <div class="flex-container__title">
                        {{ "plans.step2Content.protectionType" | translate }}
                      </div>
                      <div class="flex-container__value">
                        <div
                          *ngFor="let item of selectedBundle"
                          class="flex-container__bold"
                        >
                          {{ item?.description }}
                        </div>
                      </div>
                    </div>

                    <app-flex-container
                      [title]="'common.sumInsured' | translate"
                      [value]="selectedQuotation?.sumInsured | number : '1.2-2'"
                      [currency]="true"
                    ></app-flex-container>
                    <app-flex-container
                      [title]="'common.effectivePeriod' | translate"
                      [value]="
                        personalInfo?.effectiveDate | date : 'dd MMM yyyy'
                      "
                    ></app-flex-container>
                  </div>
                  <div class="divider"></div>
                  <ng-container *ngTemplateOutlet="inCollabText"></ng-container>
                </div>
              </mat-card>
              <!-- Car details -->
              <app-summary-card
                [title]="'common.carDetails' | translate"
                [editable]="true"
                (onEdit)="editQuotation()"
                [disableBtn]="disableEditQuotationBtn"
              >
                <app-flex-container
                  [title]="'common.carNumber' | translate"
                  [value]="personalInfo?.vehicleNo"
                ></app-flex-container>
                <app-flex-container
                  [title]="'common.carModel' | translate"
                  [value]="personalInfo?.vehicle"
                ></app-flex-container>
              </app-summary-card>
              <!-- Add ons -->
              <ng-container
                *ngIf="
                  selectedBundleAddOn.length ||
                  selectedAddOn.length ||
                  includedAddOn.length
                "
              >
                <app-summary-card
                  [title]="'addOn.addOns' | translate"
                  [editable]="true"
                  (onEdit)="backToStep(0)"
                >
                  <ng-content *ngTemplateOutlet="addOnList"></ng-content>
                </app-summary-card>
              </ng-container>
              <!-- Personal details -->
              <app-summary-card [title]="'common.personalDetails' | translate">
                <ng-container
                  *ngTemplateOutlet="personalDetailsCard"
                ></ng-container>
              </app-summary-card>
              <!-- Residential address -->
              <app-summary-card
                [title]="
                  'plans.step2Content.residentialAddressTitle' | translate
                "
                [editable]="true"
                (onEdit)="backToStep(1)"
              >
                <app-flex-container
                  [title]="'common.addressLineOne' | translate"
                  [value]="infoFormGroup.value.address1"
                ></app-flex-container>
                <app-flex-container
                  [title]="'common.addressLineTwo' | translate"
                  [value]="infoFormGroup.value.address2"
                ></app-flex-container>
                <app-flex-container
                  [title]="'common.city' | translate"
                  [value]="infoFormGroup.value.city"
                ></app-flex-container>
                <app-flex-container
                  [title]="'common.postcode' | translate"
                  [value]="personalInfo?.postcode"
                ></app-flex-container>
                <app-flex-container
                  [title]="'common.state' | translate"
                  [value]="infoFormGroup.value.state"
                ></app-flex-container>
              </app-summary-card>
              <!-- Delivery address -->
              <ng-container *ngIf="enableDeliveryAddress">
                <app-summary-card
                  [title]="
                    'plans.step2Content.deliveryAddressTitle' | translate
                  "
                  [editable]="true"
                  (onEdit)="backToStep(1)"
                >
                  <app-flex-container
                    [title]="'common.addressLineOne' | translate"
                    [value]="infoFormGroup.value.deliveryAddress1"
                  ></app-flex-container>
                  <app-flex-container
                    [title]="'common.addressLineTwo' | translate"
                    [value]="infoFormGroup.value.deliveryAddress2"
                  ></app-flex-container>
                  <app-flex-container
                    [title]="'common.city' | translate"
                    [value]="infoFormGroup.value.deliveryCity"
                  ></app-flex-container>
                  <app-flex-container
                    [title]="'common.postcode' | translate"
                    [value]="infoFormGroup.value.deliveryPostcode"
                  ></app-flex-container>
                  <app-flex-container
                    [title]="'common.state' | translate"
                    [value]="infoFormGroup.value.deliveryState"
                  ></app-flex-container>
                </app-summary-card>
              </ng-container>
            </div>
            <div
              class="mobile-order-summary"
              *ngIf="screenSizeService.isMobile$ | async"
            >
              <ng-container *ngTemplateOutlet="paymentOption"> </ng-container>
              <div class="mt-lg">
                <app-section-title
                  [title]="'common.orderSummary' | translate"
                ></app-section-title>
              </div>
              <ng-container *ngTemplateOutlet="orderSummary"></ng-container>
              <ng-container *ngTemplateOutlet="promoTemplate"> </ng-container>
              <ng-container
                *ngTemplateOutlet="
                  multiCheckbox;
                  context: { form: agreementFrmGroup }
                "
              ></ng-container>
            </div>
          </div>
          <div class="sidebar-container">
            <div
              class="order-summary"
              *ngIf="!(screenSizeService.isMobile$ | async)"
            >
              <app-expandable-card [title]="'common.orderSummary' | translate">
                <ng-container snippet>
                  <ng-container
                    *ngTemplateOutlet="orderSummarySnippet"
                  ></ng-container>
                </ng-container>
                <ng-container hidden>
                  <ng-container *ngTemplateOutlet="orderSummary"></ng-container>
                </ng-container>
                <ng-container share>
                  <ng-container
                    *ngTemplateOutlet="promoTemplate"
                  ></ng-container>
                  <ng-container
                    *ngTemplateOutlet="paymentOption"
                  ></ng-container>
                  <ng-container
                    *ngTemplateOutlet="
                      multiCheckbox;
                      context: { form: agreementFrmGroup }
                    "
                  ></ng-container>
                </ng-container>
              </app-expandable-card>
            </div>
          </div>
          <div class="sticky-total-amt">
            <app-mobile-total-amount
              [totalAmount]="getFinalTotal()"
              [label]="getPaymentDurationLabel()"
            ></app-mobile-total-amount>
          </div>
          <!-- <app-mobile-bottombar
						[disabledCondition]="agreementFrmGroup.invalid"
						[buttonTemplate]="psMobileNextBtn"
						(onSubmit)="submit()"
					>
						<ng-container *ngTemplateOutlet="orderSummary"> </ng-container>
						<ng-container *ngTemplateOutlet="promoTemplate"> </ng-container>
					</app-mobile-bottombar> -->
        </div>
      </form>
    </mat-step>

    <!-- #Step: Fifth step (for display only) -->
    <mat-step state="pay" [aria-labelledby]="'disabled'">
      <ng-template matStepLabel>
        {{ "plans.step4" | translate }}
      </ng-template>
    </mat-step>
  </mat-stepper>
</div>
<div class="footer-bnm-bg" *ngIf="stepper?.selectedIndex !== 0">
  {{ "footer.BNM1" | translate : { insurer: insurerFullName } }}<br /><br />
  <span>
    {{
      "comparison.orderSummaryNote"
        | translate : { commission: getPSComissionPrice() | number : "1.2-2" }
    }}. {{ "footer.BNM2" | translate : { insurer: insurerFullName } }}
  </span>
</div>

<!-- ## Template: all the templates starts here -->
<ng-template matStepperIcon="edit">
  <mat-icon>edit</mat-icon>
</ng-template>

<ng-template matStepperIcon="close">
  <mat-icon>close</mat-icon>
</ng-template>

<ng-template matStepperIcon="pay">
  <mat-icon>payment</mat-icon>
</ng-template>

<ng-template #personalDetailsCard>
  <ng-container *ngFor="let x of personalDetailsCardData">
    <ng-container *ngIf="x.translateValue">
      <app-flex-container
        [title]="x.title | translate"
        [value]="x.value | translate"
      ></app-flex-container>
    </ng-container>
    <ng-container *ngIf="!x.translateValue">
      <app-flex-container
        [title]="x.title | translate"
        [value]="x.value"
      ></app-flex-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #orderSummary>
  <div class="order-summary-container mt-children-md">
    <app-flex-container
      [title]="'common.sumInsured' | translate"
      [value]="selectedQuotation?.sumInsured | number : '1.2-2'"
      [currency]="true"
    ></app-flex-container>
    <app-flex-container
      [title]="'common.basePremium' | translate"
      [value]="selectedQuotation?.basePremium | number : '1.2-2'"
      [currency]="true"
    ></app-flex-container>
    <ng-container *ngIf="selectedExcess && selectedExcess?.price >= 0">
      <app-flex-container
        [title]="'common.additionalDiscount' | translate"
        [titleBelow]="
          '(' +
          ('excess.' + selectedExcess?.name + '.contentTitle' | translate) +
          ')'
        "
        [value]="selectedExcess?.price | number : '1.2-2'"
        type="discount"
        [currency]="true"
      ></app-flex-container>
    </ng-container>
    <app-flex-container
      [title]="
        ('common.noClaimDiscount' | translate) +
        '(' +
        (personalInfo?.ncd * 100 | number : '1.0-2') +
        '%)'
      "
      [value]="getNcdPrice() | number : '1.2-2'"
      type="discount"
    ></app-flex-container>
    <div class="divider"></div>
    <app-flex-container
      [title]="'common.totalBasePremium' | translate"
      [value]="getTotalBasePremium() | number : '1.2-2'"
      type="total"
      [currency]="true"
    ></app-flex-container>
    <!-- Special package -->
    <div class="tag" *ngIf="selectedBundle.length">
      {{ "plans.specialPackage" | translate }}
    </div>
    <ng-container *ngIf="selectedBundle.length">
      <ng-container *ngFor="let item of selectedBundle">
        <app-flex-container
          [title]="item?.description"
          [value]="item?.evaluate | number : '1.2-2'"
          [currency]="true"
        ></app-flex-container>
      </ng-container>
    </ng-container>
    <!-- End Special package -->
    <!-- Add ons -->
    <div
      class="tag"
      *ngIf="
        selectedBundleAddOn.length ||
        selectedAddOn.length ||
        includedAddOn.length
      "
    >
      {{ "addOn.addOns" | translate }}
    </div>
    <ng-content *ngTemplateOutlet="addOnList"></ng-content>
    <!-- End Add ons -->
    <div class="divider"></div>

    <app-flex-container
      [title]="'common.totalGrossPremium' | translate"
      [value]="getTotalGrossPremium() | number : '1.2-2'"
      type="total"
      [currency]="true"
    ></app-flex-container>
    <app-flex-container
      [title]="'common.serviceTax' | translate"
      [value]="getServiceTax() | number : '1.2-2'"
      [currency]="true"
      type="normal"
    ></app-flex-container>
    <app-flex-container
      [title]="'common.stampDuty' | translate"
      [value]="selectedQuotation?.stampDutyPrice | number : '1.2-2'"
      [currency]="true"
      type="normal"
    ></app-flex-container>
    <div class="divider"></div>
    <app-flex-container
      [title]="'common.totalNetPremium' | translate"
      [value]="getTotalNetPremium() | number : '1.2-2'"
      type="total"
      [currency]="true"
    ></app-flex-container>
    <ng-container *ngIf="this.selectedQuotation?.platformDiscountRate">
      <app-flex-container
        [title]="'common.platformOffer' | translate"
        [value]="getPlatformDiscountRate() | number : '1.2-2'"
        type="discount"
        [currency]="true"
      ></app-flex-container>
    </ng-container>
    <ng-container *ngIf="this.selectedQuotation?.rebate">
      <app-flex-container
        [title]="'common.rebate' | translate"
        [value]="this.selectedQuotation?.rebate | number : '1.2-2'"
        type="discount"
        [currency]="true"
      ></app-flex-container>
    </ng-container>
    <ng-container *ngIf="addOnFormGroup.value?.isIncludeRoadtax">
      <app-flex-container
        [title]="'addOn.roadTaxRenewal' | translate"
        [value]="personalInfo.roadTaxPrice | number : '1.2-2'"
        [currency]="true"
        type="normal"
      ></app-flex-container>
      <ng-container *ngIf="isIncludeDigitalRoadtax">
        <app-flex-container
          [title]="'digitalRoadTaxFee' | translate"
          [value]="0 | number : '1.2-2'"
          type="normal"
          [currency]="true"
        ></app-flex-container>
      </ng-container>
      <ng-container *ngIf="!isIncludeDigitalRoadtax">
        <app-flex-container
          [title]="'addOn.roadTaxDelivery' | translate"
          type="normal"
          [value]="personalInfo.roadTaxDeliveryPrice | number : '1.2-2'"
          [currency]="true"
        ></app-flex-container>
      </ng-container>
    </ng-container>
    <div class="divider"></div>

    <app-flex-container
      [title]="'common.totalCharges' | translate"
      [value]="getTotalCharge() | number : '1.2-2'"
      type="total"
      [currency]="true"
    ></app-flex-container>

    <ng-container *ngIf="promo">
      <app-flex-container
        [title]="promo.name"
        [value]="getTotalPromo() | number : '1.2-2'"
        type="discount"
        [currency]="true"
      ></app-flex-container>
    </ng-container>

    <app-flex-container
      [title]="'common.serviceFee' | translate"
      [value]="getServiceFee() | number : '1.2-2'"
      type="normal"
      [currency]="true"
    ></app-flex-container>
    <app-flex-container
      [title]="'common.bankInstalmentCharges' | translate"
      [value]="getBankInstalmentCharges() | number : '1.2-2'"
      *ngIf="isPaymentTypeEPP"
      [currency]="true"
      [type]="getBankInstalmentCharges() == 0 ? 'zeroInstalment' : 'normal'"
    >
    </app-flex-container>
    <div class="divider"></div>
    <div class="total-amt">
      <div class="title">{{ "common.totalAmount" | translate }}</div>
      <div class="value">RM {{ getFinalTotal() | number : "1.2-2" }}</div>
    </div>
    <div
      class="order-summary-container__note"
      *ngIf="getPaymentDurationLabel()"
      [innerHTML]="getPaymentDurationLabel()"
    ></div>
    <div class="divider"></div>
  </div>
</ng-template>

<ng-template #orderSummarySnippet>
  <div class="order-summary-container">
    <div class="total-amt">
      <div class="title">{{ "common.totalAmount" | translate }}</div>
      <div class="value">RM {{ getFinalTotal() | number : "1.2-2" }}</div>
    </div>
    <div
      class="order-summary-container__note"
      *ngIf="getPaymentDurationLabel()"
      [innerHTML]="getPaymentDurationLabel()"
    ></div>
    <app-order-summary-note
      [text]="
        'comparison.orderSummaryNote'
          | translate : { commission: getPSComissionPrice() | number : '1.2-2' }
      "
    ></app-order-summary-note>
    <div class="divider"></div>
  </div>
</ng-template>

<ng-template #addOnList>
  <ng-container *ngIf="selectedBundleAddOn.length">
    <ng-container *ngFor="let item of selectedBundleAddOn">
      <app-flex-container
        [title]="'plans.bundleAddOns.' + item.code | translate"
        [value]="item?.evaluate | number : '1.2-2'"
        [currency]="true"
      ></app-flex-container>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="includedAddOn.length">
    <ng-container *ngFor="let item of includedAddOn">
      <app-flex-container
        [title]="'addOn.' + item.code | translate"
        [value]="item?.evaluate | number : '1.2-2'"
        [currency]="true"
      ></app-flex-container>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="selectedAddOn.length">
    <ng-container *ngFor="let item of selectedAddOn">
      <ng-container [ngSwitch]="item.code">
        <ng-container *ngSwitchCase="'Windshield'">
          <app-flex-container
            [title]="'addOn.' + item.code | translate"
            [value]="
              getPrice(item, addOnFormGroup.value.windshield) | number : '1.2-2'
            "
            [currency]="true"
          ></app-flex-container>
        </ng-container>
        <ng-container *ngSwitchCase="'AdditionalDrivers'">
          <app-flex-container
            [title]="'addOn.' + item.code | translate"
            [value]="
              getPrice(item, getDriversFormArray()?.controls.length)
                | number : '1.2-2'
            "
            [currency]="true"
          ></app-flex-container>
        </ng-container>
        <ng-container *ngSwitchCase="'LLTP'">
          <app-flex-container
            [title]="'addOn.' + item.code | translate"
            [value]="
              getPrice(item, addOnFormGroup.value.lltpSeats) | number : '1.2-2'
            "
            [currency]="true"
          ></app-flex-container>
        </ng-container>
        <ng-container *ngSwitchCase="'MERW'">
          <app-flex-container
            [title]="'addOn.' + item.code | translate"
            [value]="getPrice(item) | number : '1.2-2'"
            [currency]="true"
          ></app-flex-container>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <app-flex-container
            [title]="'addOn.' + item.code | translate"
            [value]="getPrice(item) | number : '1.2-2'"
            [currency]="true"
          ></app-flex-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #promoTemplate>
  <ng-container *ngIf="hidePromoCodeOnCampaign">
    <div class="promo-input pt-sm" (click)="$event.stopPropagation()">
      <div class="flex">
        <mat-form-field appearance="outline">
          <input
            matInput
            [placeholder]="'common.enterPromoCode' | translate"
            [(ngModel)]="promoCode"
            [readonly]="promo != null"
            autocomplete="off"
            (click)="$event.stopPropagation()"
            [ngModelOptions]="{ standalone: true }"
          />
          <div class="floating-btn">
            <button
              type="button"
              mat-icon-button
              matSuffix
              (click)="$event.stopPropagation(); clearPromoCode()"
              *ngIf="promoCode"
            >
              <mat-icon class="color-secondary">cancel</mat-icon>
            </button>

            <button
              type="button"
              mat-icon-button
              matSuffix
              *ngIf="promo && promoCode"
            >
              <mat-icon class="color-green">check_circle</mat-icon>
            </button>
          </div>
          <button
            type="button"
            mat-flat-button
            matSuffix
            (click)="$event.stopPropagation(); applyPromoCode()"
            *ngIf="!promo || !promoCode"
          >
            {{ "common.apply" | translate }}
          </button>

          <button
            type="button"
            mat-flat-button
            matSuffix
            (click)="$event.stopPropagation(); applyPromoCode()"
            *ngIf="promo && promoCode"
          >
            {{ "common.applied" | translate }}
          </button>
        </mat-form-field>
      </div>
      <div class="promo-error" *ngIf="promoError">
        {{ "common." + promoError | translate }}
      </div>
      <div class="promo-valid" *ngIf="promo && promoCode">
        Promo code applied.
      </div>
    </div>
    <app-order-summary-note
      [text]="'comparison.orderSummaryNoteDisclaimer' | translate"
    ></app-order-summary-note>
  </ng-container>
</ng-template>

<ng-template #paymentOption>
  <div class="pt-xs" (click)="$event.stopPropagation()">
    <app-section-title
      title="plans.step3Content.paymentOption"
    ></app-section-title>
  </div>
  <app-payment-options
    *ngIf="stepper?.selectedIndex == 3"
    [totalPrice]="getFinalTotal()"
    [totalPriceBeforeCharges]="getFinalTotalBeforeCharges()"
    [bankCharge]="bankInstallmentCharge"
    [paymentFormGroup]="paymentFormGroup"
    [eppChargeList]="eppChargeList"
  ></app-payment-options>
</ng-template>

<ng-template #multiCheckbox let-form="form">
  <div class="multi-checkbox-section" [formGroup]="form">
    <div class="multi-checkbox-section__bg">
      <section className="allow-pointer">
        <mat-checkbox
          formControlName="agreeInfo"
          (click)="$event.stopPropagation()"
          >{{ "common.confirmationOne" | translate }}
        </mat-checkbox>
      </section>

      <section
        *ngIf="selectedQuotation?.code == 'Ikhlas'"
        className="allow-pointer"
      >
        <mat-checkbox
          formControlName="aqadTC"
          (click)="$event.stopPropagation()"
          >{{ "common.confirmationAqad" | translate }}
          <a
            [href]="
              'https://storage.googleapis.com/ps-asset/motor/policy_docs/Aqad.pdf'
            "
            target="blank"
            ><b>Aqad</b>.</a
          >
        </mat-checkbox>
      </section>

      <section formArrayName="doc" className="allow-pointer">
        <ng-container
          *ngFor="let item of getAgreeFormArray()?.controls; let i = index"
        >
          <ng-container [formGroupName]="i">
            <mat-checkbox
              formControlName="checked"
              (click)="$event.stopPropagation()"
              >{{ "common.confirmationTwo" | translate
              }}<a
                [href]="getAgreeFormArray()?.controls[i].value?.url"
                target="blank"
              >
                {{ getAgreeFormArray()?.controls[i].value?.name }}</a
              ><b> {{ selectedQuotation?.name }}</b
              >.
            </mat-checkbox>
          </ng-container>
        </ng-container>
      </section>
    </div>
    <button
      mat-flat-button
      [disabled]="agreementFrmGroup.invalid || paymentFormGroup?.invalid"
      (click)="$event.stopPropagation(); submit()"
      className="allow-pointer"
    >
      {{ "plans.readyToPay" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #psMobileNextBtn>
  <div class="btn-content">
    <div class="btn-content__total-txt">
      {{ "common.totalAmount" | translate }}
    </div>
    <div class="btn-content__price">
      <div class="btn-content__price__txt">
        RM {{ getFinalTotal() | number : "1.2-2" }}
      </div>
      <div
        class="btn-content__price__desc"
        [innerHTML]="getPaymentDurationLabel()"
      ></div>
    </div>
  </div>
</ng-template>

<ng-template #inCollabText>
  <div class="collab-text">
    <div class="incollab-text">
      {{ "plans.inCollab" | translate }}
    </div>
    <img [src]="inCollabImageSrc" alt="logo" class="main-logo" />
    <!-- <img
      src="https://i.ibb.co/j32pWbR/Pacific-Insurance-Logo.png"
      alt="logo"
      class="main-logo"
      *ngIf="!excessData?.length"
    /> -->
    <img
      src="https://storage.googleapis.com/dev-ps-asset/Car_Insurance/pando-logo.png"
      alt="logo"
      class="main-logo"
      *ngIf="excessData?.length"
    />
  </div>
</ng-template>

<!-- ## End Template: all the templates ends here -->
