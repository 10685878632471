import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PlansComponent } from "./screens/plans/plans.component";
import { RenewQuotationComponent } from "./screens/renew-quotation/renew-quotation.component";
import { LandingComponent } from "./screens/landing/landing.component";
import { AffiliatesGuard } from "../guards/affiliates.guard";
import { EndorsementComponent } from "./screens/endorsement/endorsement.component";
import { EndorsementSummaryComponent } from "./screens/endorsement-summary/endorsement-summary.component";
import { EndorsementDetailsComponent } from "./screens/endorsement-details/endorsement-details.component";

const routes: Routes = [
	{
		path: "",
		children: [
			{
				path: "",
				component: LandingComponent,
				canActivate: [AffiliatesGuard],
			},
			{
				path: "quotation/renew/:id",
				component: RenewQuotationComponent,
			},
			{
				path: "quotation/:id",
				component: PlansComponent,
			},
			{
				path: "endorsement",
				component: EndorsementComponent,
			},
			{
				path: "endorsement/details",
				component: EndorsementDetailsComponent,
			},
			{
				path: "endorsement/summary",
				component: EndorsementSummaryComponent,
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class D2cRoutingModule {}
