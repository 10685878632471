import { Component, EventEmitter, Input, OnInit, Output,HostListener } from '@angular/core';

@Component({
  selector: 'app-mobile-bottombar',
  templateUrl: './mobile-bottombar.component.html',
  styleUrls: ['./mobile-bottombar.component.scss'],
})
export class MobileBottombarComponent implements OnInit {

  screenWidth: number;

  @Input() disabledCondition: boolean = false;
  @Input() disabledContactPS: boolean = false;
  @Input() buttonTemplate: any;


  @Output() onSubmit = new EventEmitter();

  constructor() {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = event.target.innerWidth;
  }


  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
  }

  submit() {
    this.onSubmit.emit();
  }
}
