import { Component, EventEmitter, Output } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
// Services
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-went-wrong-dialog",
	templateUrl: "./went-wrong-dialog.component.html",
	styleUrls: ["./went-wrong-dialog.component.scss"],
})
export class WentWrongDialogComponent {
	@Output() retryClicked: EventEmitter<void> = new EventEmitter<void>();
	showSpinner: boolean = false;

	constructor(
		public dialogRef: MatDialogRef<WentWrongDialogComponent>,
		public translate: TranslateService,
	) {}

	closeDialog(): void {
		this.dialogRef.close(true);
	}

	onRetryClick(): void {
		this.retryClicked.emit();
	}
}
