import {Component} from '@angular/core';

@Component({
  selector: 'app-faq-ktmb-dialog',
  templateUrl: './faq-ktmb-dialog.component.html',
  styleUrls: ['./faq-ktmb-dialog.component.scss']
})
export class FaqKtmbDialogComponent {
  faqList: IFaq[] = [
    {
      label: "SOMPO MotorSafe FAQ",
      pdfPath: "https://storage.googleapis.com/ps-asset/motor/policy_docs/SOMPO%20MotorSafe%20FAQ%20(EN).pdf",
    },
    {
      label: "RHBI Motor Saver FAQ",
      pdfPath: "https://storage.googleapis.com/ps-asset/motor/policy_docs/RHBI_Motor%20Saver_FAQ.pdf"
    },
    {
      label: "MSIG EZ-Mile FAQ",
      pdfPath: "https://storage.googleapis.com/ps-asset/motor/policy_docs/EZ-Mile%2BFAQ%2B(ENG).pdf"
    },
  ];

  onDownloadHere(faq: IFaq): void {
    let link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = faq.pdfPath;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}

interface IFaq {
  label: string;
  pdfPath: string;
}
