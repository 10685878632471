import { Component, OnInit, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-flex-container",
  templateUrl: "./flex-container.component.html",
  styleUrls: ["./flex-container.component.scss"],
})
export class FlexContainerComponent implements OnInit {
  @Input() title: string = "";
  @Input() titleBelow: string = "";
  @Input() value: string = "";
  @Input() type: string = "";
  @Input() currency: boolean = false;

  constructor(public translate: TranslateService) {}

  ngOnInit(): void {}
}
