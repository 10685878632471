import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { DecimalPipe } from "@angular/common";
import { shareReplay, Subject, Subscription, takeUntil } from "rxjs";
import clevertap from "clevertap-web-sdk";

// Angular material imports
import { MatStepper } from "@angular/material/stepper";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";

// Services imports
import { MotorDataDataService } from "src/app/share/data-service/motor.data.service";
import { MotorBundleService } from "../../data-service/motor-bundle.service";
import { AlertService } from "src/app/share/service/alert.service";
import { LoadingOverlayService } from "src/app/share/service/loading-overlay.service";
import { FooterMarginService } from "src/app/share/service/footer-margin.service";
import { ValidatorFunction } from "src/app/share/function/validator.function";
import { ScreenSizeService } from "src/app/share/service/screen-size.service";
import { CommonFunction } from "src/app/share/function/common.function";

// Other packages imports
import * as moment from "moment";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { GoogleTagManagerService } from "angular-google-tag-manager";

// Interfaces imports
import {
  D2CQuotationInterface,
  EPPChargeInterface,
  ExcessInterface,
  OtherInsurerInterface,
  ProductAddOnInterface,
  ProductDocumentInterface,
  PromoCodeInterface,
  SelectedExcessInterface,
} from "src/app/share/interface/motor.interface";
import {
  ID_TYPE,
  MARITAL_STATUS,
  GENDER_TYPE,
} from "src/app/share/constants/common.types";

// Static data imports
import {
  floodExpandedData,
  femaleExpandedData,
  familyExpandedData,
} from "../../static/expanded-data";
import { RELATIONSHIP_LIST, STATE_LIST } from "../../static/general-data";
import {
  PAYMENT_OPTION_RAZER,
  PAYMENT_OPTION_SHOPBACK,
  PAYMENT_OPTION_EPP,
  PAYMENT_OPTION_VISA,
  PAYMENT_OPTION_EWALLET,
  PAYMENT_OPTION_FPX,
  PAYMENT_OPTION_FP,
} from "src/app/share/constants/payment-options";
import { HIGHER_EXCESS } from "src/app/share/constants/excess.types";
import { INSURER_FULL_NAME } from "src/app/share/constants/common.info";
import { CAMPAIGN_TYPE } from "src/app/share/constants/campaign";

// Component imports
import { EditQuotationComponent } from "src/app/dashboard/edit-quotation/edit-quotation.component";
import { SubmitSuccessDialogComponent } from "src/app/dashboard/submit-success-dialog/submit-success-dialog.component";
import { BenefitDialogComponent } from "src/app/dashboard/benefit-dialog/benefit-dialog.component";
import { CarVariantDialogComponent } from "src/app/share/component/car-variant-dialog/car-variant-dialog.component";
import { MotorQuotationDataService } from "src/app/share/data-service/motor/motor-quotation.data.service";
import { TimerService } from "src/app/share/service/timer.service";
import { MotorQuotationInterface } from "src/app/share/interface/motor-quotation.interface";
import {
  FULLY_REDEEMED_ERR_MSG,
  FULLY_REDEEMED_MSG,
  HAS_ENDED_MSG,
} from "src/app/share/constants/campaign";
import { DEFAULT_CTA_BANNER } from "src/app/share/constants/banner";

@Component({
  selector: "app-plans",
  templateUrl: "./plans.component.html",
  styleUrls: ["./plans.component.scss"],
  providers: [DecimalPipe],
})
export class PlansComponent implements OnInit, OnDestroy {
  id: string;
  quotationId: string;
  seat: number;
  selectedBundle: any[] = [];
  selectedBundleAddOn: any[] = [];
  selectedAddOn: any[] = [];
  selectedPayment: string = PAYMENT_OPTION_RAZER;
  selectedFullPriceMethod: string = PAYMENT_OPTION_VISA;

  // Insurance placeholder
  additionalInsurerList = [];
  selectedAdditionalInsurer = [];
  otherInsurerList: OtherInsurerInterface[] = [];
  selectedOtherInsurers: string[] = [];

  enableDeliveryAddress: boolean = false;
  isRoadtaxSelected: boolean = false;
  quotationIsExpired: boolean = true;

  isEHailing: boolean = false;
  isFloodChecked: boolean;
  isDriverChecked: boolean;
  showRoadTaxList: boolean = false;
  isIncludeDigitalRoadtax: boolean = false;
  isLoadingBundleData: boolean = false;

  driverAddOnCount: number = 0;
  maxAdditionalDriver: number = 5;
  maxFreeDriver: number = 5;
  productDocumentInterface: ProductDocumentInterface[] = [];
  includedAddOn = [];
  consolidatedAddOn = [];
  popularConsolidatedAddOn = [];
  driversAddOn = [];
  addOnListToDisplay = [];
  numberOfLltpSeats: number[] = [5, 6, 7, 8, 9, 10, 11];

  // Static data
  floodBenefitsSnippets: any = floodExpandedData;
  femaleBenefitsSnippets: any = femaleExpandedData;
  familyBenefitsSnippets: any = familyExpandedData;
  stateList: any = STATE_LIST;
  relationshipList: any = RELATIONSHIP_LIST;
  personalDetailsCardData: any;
  availableSalesCampaign: any;

  paymentOptionRazer: string = PAYMENT_OPTION_RAZER;
  paymentOptionShopback: string = PAYMENT_OPTION_SHOPBACK;
  paymentOptionEPP: string = PAYMENT_OPTION_EPP;

  paymentOptionVisa: string = PAYMENT_OPTION_VISA;
  paymentOptionEwallet: string = PAYMENT_OPTION_EWALLET;
  paymentOptionFpx: string = PAYMENT_OPTION_FPX;
  paymentOptionFullPrice: string = PAYMENT_OPTION_FP;

  idTypeOption = ID_TYPE;
  maritalStatusOption = MARITAL_STATUS;
  genderOption = GENDER_TYPE;
  campaignType = CAMPAIGN_TYPE;
  campaignWithoutPromoCode = [CAMPAIGN_TYPE.roadtaxMalaysianCar];

  // Form group
  agreementFrmGroup: FormGroup;
  quotationFormGroup: FormGroup;
  infoFormGroup: FormGroup;
  addOnFormGroup: FormGroup;
  paymentFormGroup: FormGroup;

  promoCode: string;
  promo: PromoCodeInterface;
  promoError: string;

  // Data
  data: any;
  personalInfo: any;
  selectedQuotation: any;
  quotationList: D2CQuotationInterface[];
  quotationExpiredData: any;
  idType: string;
  ref: string;
  excessData: ExcessInterface[];
  selectedExcess: SelectedExcessInterface;

  // Payment related
  eppChargeList: EPPChargeInterface[] = [];
  bankInstallmentCharge: { 3: number; 6: number; 12: number };

  subsVar: Subscription;
  paymentTypeChangeSub: Subscription;
  paymentChannelSub: Subscription;
  private timerSubscription: Subscription | null = null;
  private timerUnsubscribe$ = new Subject<void>();

  ctaBanner = [];

  @ViewChild("stepper") stepper: MatStepper;

  constructor(
    private ngxSpinnerService: NgxSpinnerService,
    private motorDataDataService: MotorDataDataService,
    private motorQuotationService: MotorQuotationDataService,
    private timerService: TimerService,
    private motorBundleService: MotorBundleService,
    private formBuilder: FormBuilder,
    public translate: TranslateService,
    public screenSizeService: ScreenSizeService,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private router: Router,
    private dialog: MatDialog,
    private _decimalPipe: DecimalPipe,
    private loadingService: LoadingOverlayService,
    private footerMarginService: FooterMarginService,
    private gtmService: GoogleTagManagerService,
  ) {}

  get isPolicystreetProduct() {
    return (
      this.selectedQuotation && this.selectedQuotation.code == "PolicyStreet"
    );
  }

  get eppDuration() {
    if (this.getFinalTotalBeforeCharges() >= 1200) return 12;
    if (this.getFinalTotalBeforeCharges() >= 1000) return 6;
    return 0;
  }

  get hideStepperLabel() {
    return this.stepper?.selectedIndex === 0 ? "hide-stepper" : "";
  }

  get inCollabImageSrc(): string {
    if (this.isPolicystreetProduct) {
      return "https://storage.googleapis.com/dev-ps-asset/Car_Insurance/ps-logo.png";
    } else {
      return this.selectedQuotation?.image || "";
    }
  }

  get hidePromoCodeOnCampaign(): boolean {
    if (this.availableSalesCampaign && this.availableSalesCampaign[0]?.code) {
      // Check if the current availableSalesCampaign[0].code is included in campaignWithoutPromoCode
      // If included, hide promo code
      return !this.campaignWithoutPromoCode.includes(
        this.availableSalesCampaign[0].code,
      );
    }
    // Default return value when there's no valid campaign code
    return true;
  }

  updateCTABanner() {
    // Helper to check if a campaign is active
    const isActive = (code: string) =>
      this.availableSalesCampaign.some((c) => c.code === code);

    // Handle roadtaxSept19 banner
    if (isActive(CAMPAIGN_TYPE.roadtaxSept19)) {
      this.ctaBanner = [
        {
          lang: "en",
          src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/special/12-19-sept/epp-comparison-banner-en.png",
          show: true,
          alt: "",
        },
        {
          lang: "bm",
          src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/special/12-19-sept/epp-comparison-banner-bm.png",
          show: true,
          alt: "",
        },
      ];
    } else {
      this.ctaBanner = [];
    }
  }

  async ngOnInit(): Promise<void> {
    // Listen for window resize events
    this.screenSizeService.notifyResize();
    window.addEventListener("resize", () =>
      this.screenSizeService.notifyResize(),
    );

    this.footerMarginService.updateComponent("ComparisonComponent");
    this.id = this.activatedRoute.snapshot.params.id;
    this.quotationId = this.activatedRoute.snapshot.queryParams.id;
    this.ref = this.activatedRoute.snapshot.queryParams.ref;

    if (!this.id) {
      window.location.href = "/";
    }

    // Check if quotation expired by getQuotationById()
    // if expired reroute to renew-quotation page
    const quotationExpired = await this.checkIfQuotationExpired();

    if (!quotationExpired) {
      if (this.ref == "email") {
        this.gtmService.pushTag({
          event: "link_clicked",
          source: "email",
        });
      }
      this.getPersonalInfo();
      this.getAdditionalInsurer();
      this.getAvailableSalesCampaign();
      this.initQuotationForm();
      this.initAgreementForm();
      this.initPersonalDetailsForm();
      this.initAddOnForm();
      this.initPaymentFormGroup();
      this.pushCleverTapPageView(0);
    }

    this.activatedRoute.queryParams.subscribe((x) => {
      if (x.id && !this.data && !this.selectedQuotation) {
        this.selectQuotation(x.id);
      }
    });
  }

  ngOnDestroy(): void {
    this.footerMarginService.updateComponent(null);
    // Clean up subscriptions and other resources
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    this.timerUnsubscribe$.next();
    this.timerUnsubscribe$.complete();
  }

  onStepChange(e: any) {
    if (e.selectedIndex == 0 || e.selectedIndex == 3)
      this.footerMarginService.updateComponent("ComparisonComponent");
    else this.footerMarginService.updateComponent("PlansComponent");

    if (e.selectedIndex == 0) {
      //Clear route params if returning to insurer page
      this.router.navigate([], {
        queryParams: {
          id: null,
        },
        queryParamsHandling: "merge",
      });
    }

    //Triggered after Personal Details page
    if (e.selectedIndex == 3) {
      this.pushCleverTapPersonalDetails();

      if (this.excessData?.length) this.pushCleverTapExcessSelection();
    }

    this.pushCleverTapPageView(e.selectedIndex);
  }

  private async getBundleData(quotationId: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.motorBundleService
        .getMotorBundleAddOn(this.id, quotationId)
        .subscribe({
          next: (x) => {
            this.isLoadingBundleData = false;
            this.data = x;
            this.data.bundleId = quotationId;

            // manually add isBundleAddOn flag
            let adjustedMotorBundleAddOn = this.data.motorBundleAddOn.map(
              (addOn) => {
                addOn.isBundleAddOn = true;
                return addOn;
              },
            );
            let adjustedMotorAddOn = this.data.motorAddOn.map((addOn) => {
              addOn.isBundleAddOn = false;
              return addOn;
            });

            // join motorAddOn and motorBundleAddOn then sort by sequence
            this.consolidatedAddOn = [
              ...adjustedMotorAddOn,
              ...adjustedMotorBundleAddOn,
            ];

            // set seq = 0 if is included
            this.consolidatedAddOn
              .filter((addOn) => addOn.included == true)
              .map((addOn) => {
                addOn.seq = 0;
                return addOn;
              });

            // add road tax renewal to consolidatedAddOn
            if (!this.isEHailing) {
              let roadTaxRenewalAddOn = {
                category: null,
                code: null,
                condition: null,
                createdDate: null,
                customCode: null,
                description: null,
                evaluate: null,
                explanation: null,
                hidePriceBreakdown: false,
                id: null,
                imageUrl: null,
                included: false,
                includedInBundle: false,
                insuranceType: null,
                isActive: null,
                isConditionFirst: true,
                isPopular: true,
                modifyDate: null,
                motorAddOnId: null,
                motorBundleId: null,
                motorPartnerId: null,
                motorProductId: null,
                rules: null,
                seq: 1,
                isRoadTaxRenewal: true,
              };
              this.consolidatedAddOn.push(roadTaxRenewalAddOn);
            }

            // sort consolidatedAddOn by seq
            this.consolidatedAddOn.sort((a, b) => a.seq - b.seq);

            // get driver add on count
            this.driverAddOnCount = this.consolidatedAddOn.filter(
              (addOn) => addOn.category === "Drivers",
            ).length;

            // populate driversAddOn
            this.driversAddOn = this.consolidatedAddOn.filter(
              (addOn) => addOn.category === "Drivers",
            );

            this.keepFirstDrivers();

            // populate popularConsolidatedAddOn
            this.popularConsolidatedAddOn = this.consolidatedAddOn.filter(
              (addOn) => addOn.isPopular == true,
            );

            // set addOnListToDisplay to display all by default
            this.addOnListToDisplay = this.consolidatedAddOn;

            this.includedAddOn = [
              ...new Set(this.data.motorAddOn.filter((y) => y.included)),
            ];

            this.data.motorAddOn.forEach((y) => {
              if (y.category == "Windshield") {
                this.addOnFormGroup.controls.windshield.setValue(
                  this.getRule(y)?.min || 500,
                );
              }
              if (y.code == "AdditionalDrivers") {
                this.maxAdditionalDriver = this.getRule(y)?.max ?? null;
                this.maxFreeDriver = !y.condition
                  ? this.getRule(y)?.max ?? null
                  : null;
              }
            });
            resolve(true);
          },
        });
    });
  }

  private keepFirstDrivers() {
    // Find the first occurrence of the category "Drivers"
    const firstDriversIndex = this.consolidatedAddOn.findIndex(
      (addOn) => addOn.category === "Drivers",
    );

    // If there's no "Drivers" category do nothing
    if (firstDriversIndex === -1) {
      return;
    }

    // Keep the first "Drivers" and all other categories, excluding other "Drivers"
    this.consolidatedAddOn = [
      ...this.consolidatedAddOn.filter(
        (addOn, index) =>
          addOn.category !== "Drivers" || index === firstDriversIndex,
      ),
    ];
  }

  private async checkIfQuotationExpired(): Promise<boolean> {
    this.loadingService.setLoading(true);
    let quotation;

    try {
      quotation = await this.motorDataDataService
        .getQuotationById(this.id)
        .toPromise();

      if (quotation.hasOwnProperty("expiredDetails")) {
        this.quotationExpiredData = quotation;
        const expiredDetails = this.quotationExpiredData.expiredDetails;
        if (expiredDetails.hasOwnProperty("isResubmitted")) {
          await this.router.navigate(["/quotation/renew/", this.id], {
            state: { quotationDetails: this.quotationExpiredData },
            queryParams: { id: this.quotationId },
          });
          this.loadingService.setLoading(false);
          return true;
        }
      } else {
        this.quotationIsExpired = false;
        this.quotationList = quotation;

        const hasNewQuotation = this.quotationList.some(
          (item) => item.status === "New",
        );

        if (hasNewQuotation) {
          // Start or restart the timer if needed
          this.timerService.startTimer();

          // Ensure only one subscription to the timerExpired$ observable
          if (!this.timerSubscription) {
            this.timerSubscription = this.timerService.timerExpired$
              .pipe(takeUntil(this.timerUnsubscribe$))
              .subscribe(() => {
                // Call the function again after timer expires
                this.checkIfQuotationExpired();
              });
          }
        } else {
          // Stop the timer and clean up subscription if no new quotations
          this.timerService.stopTimer();
          if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
            this.timerSubscription = null;
          }
        }

        this.loadingService.setLoading(false);
        return false;
      }
    } catch (err) {
      console.error("Error occurred:", err);
      this.loadingService.setLoading(false);
      window.location.href = "/";
      return false;
    }

    return true;
  }
  private getExcessList(id: string) {
    this.motorDataDataService.getAccessList(id).subscribe({
      next: (x) => {
        this.excessData = x;
        this.applyExcessDiscount();
      },
    });
  }

  private applyExcessDiscount() {
    // If no excess, show excess
    if (!this.selectedExcess) {
      let defaultExcess = this.excessData?.filter(
        (x) => x.name == HIGHER_EXCESS,
      );
      let defaultPrice;
      let condition = defaultExcess[0]?.premiumDiscountEvaluate?.replace(
        "{bp}",
        this.selectedQuotation.basePremium,
      );
      if (condition && Boolean(eval(condition))) {
        defaultPrice = eval(condition);
      }
      this.selectedExcess = {
        name: defaultExcess[0]?.name,
        price: defaultPrice,
        id: defaultExcess[0]?.id,
      };
    }
  }

  private removeExcessDiscount() {
    if (this.selectedExcess) {
      this.selectedExcess = null;
    }
  }

  changeExcess(data: any) {
    this.selectedExcess = data;
    this.pushCleverTapExcessSelection();
  }

  async selectQuotation(id: string) {
    this.isLoadingBundleData = true;
    this.quotationFormGroup.controls.id.setValue(id);

    this.selectedQuotation = await this.quotationList.find((x) => x.id == id);

    // Remove all selected add on, if insurer selected is different
    if (this.quotationId && this.quotationId != id) {
      this.selectedAddOn = [];
    }
    this.quotationId = id;

    this.getProductDocument();
    if (this.isPolicystreetProduct)
      this.getExcessList(this.selectedQuotation.id);
    else this.removeExcessDiscount();

    //To refactor in the future. If selected quotation is TI, update the validator
    if (this.selectedQuotation?.code == "Ikhlas") {
      this.agreementFrmGroup.controls.aqadTC.setValidators([
        Validators.requiredTrue,
      ]);
    }

    // Run bundle data api only if selectedId is different from previous Id
    if (!this.data || this.data.bundleId !== id) {
      await this.getBundleData(this.selectedQuotation.id);
    } else {
      this.isLoadingBundleData = false;
    }

    if (this.data) {
      this.stepper.next();
      this.router.navigate([], {
        queryParams: {
          id: this.selectedQuotation.id,
        },
      });

      this.pushCleverTapSelectedInsurer();
    } else {
      window.location.href = "/";
    }
  }

  private getPersonalInfo() {
    this.motorDataDataService.getDetailById(this.id).subscribe({
      next: (x) => {
        this.personalInfo = x;
        this.isEHailing = x.eHailingUsed;
        this.getPersonalDetailsCardData();
        this.setIdTypeAndFillForm();
        this.translate.use(this.personalInfo.language);

        //Begin Clevertap analytics
        this.pushCleverTapLogin();
      },
      error: () => {
        window.location.href = "/";
      },
    });
  }

  private getAdditionalInsurer() {
    this.motorDataDataService.getAvailableProduct(this.id).subscribe({
      next: (x: OtherInsurerInterface[]) => {
        this.otherInsurerList = x;
      },
      error: () => {
        // window.location.href = "/";
      },
    });
  }

  private getAvailableSalesCampaign() {
    this.motorDataDataService.getAvailableSalesCampaign(this.id).subscribe({
      next: (x) => {
        this.availableSalesCampaign = x;
        this.updateCTABanner();
      },
      error: () => {
        console.log("Failed to retrieve available campaigns.");
      },
    });
  }

  private getPersonalDetailsCardData() {
    this.personalDetailsCardData = [
      {
        title: this.getNameLabel(),
        value: this.personalInfo?.name,
      },
      {
        title: this.getIdentityNoLabel(),
        value: this.personalInfo?.identityNo,
      },
      {
        title: "common.mobileNo",
        value: this.personalInfo?.mobileNo,
      },
      {
        title: "common.emailAddress",
        value: this.personalInfo?.email,
      },
    ];

    let companyCase = this.personalInfo?.isCompany;
    this.personalDetailsCardData.push(
      {
        title: "common.maritalStatus",
        value: companyCase
          ? "-"
          : this.personalInfo?.maritalStatus == "Single"
          ? "common.Single"
          : this.personalInfo?.maritalStatus == "Married"
          ? "common.Married"
          : "-",
        translateValue: true,
      },
      {
        title: "common.gender",
        value: companyCase
          ? "-"
          : this.personalInfo?.gender == "M"
          ? "common.M"
          : this.personalInfo?.gender == "F"
          ? "common.F"
          : "-",
        translateValue: true,
      },
    );
  }

  setIdTypeAndFillForm(): void {
    switch (true) {
      case this.personalInfo?.isMalaysian &&
        !this.personalInfo?.isCompany &&
        this.personalInfo?.policIdentityNo !== null:
        this.idType = this.idTypeOption.police;
        break;
      case this.personalInfo?.isMalaysian &&
        !this.personalInfo?.isCompany &&
        !this.personalInfo?.policIdentityNo:
        this.idType = this.idTypeOption.malaysian;
        break;
      case this.personalInfo?.isMalaysian && this.personalInfo?.isCompany:
        this.idType = this.idTypeOption.company;
        this.infoFormGroup.controls.name.setValidators([]);
        break;
      case !this.personalInfo?.isMalaysian &&
        !this.personalInfo?.isCompany &&
        this.personalInfo?.nationality !== null:
        this.idType = this.idTypeOption.foreigner;
        break;
      default:
        break;
    }
    this.infoFormGroup.patchValue({
      name: this.personalInfo?.name,
      nationality: this.personalInfo?.nationality,
      identityNo: this.personalInfo?.identityNo,
      policIdentityNo: this.personalInfo?.policIdentityNo,
      mobileNo: this.personalInfo?.mobileNo,
      email: this.personalInfo?.email,
      maritalStatus: this.personalInfo?.maritalStatus,
      gender: this.personalInfo?.gender,
    });
  }

  getNameLabel() {
    if (
      !this.personalInfo?.isCompany &&
      this.personalInfo?.nationality !== null
    )
      return "common.namePerPassport";
    else if (this.personalInfo?.isCompany) return "common.companyName";
    else return "common.fullNamePerICPassport";
  }

  getIdentityNoLabel() {
    if (
      !this.personalInfo?.isCompany &&
      this.personalInfo?.nationality !== null
    )
      return "common.passport";
    else if (this.personalInfo?.isCompany)
      return "common.businessRegistrationNo";
    return "common.icNoLabel";
  }

  get isPaymentProviderShopback() {
    return (
      this.paymentFormGroup?.value?.paymentProvider ==
      this.paymentOptionShopback
    );
  }

  get isPaymentTypeEPP() {
    return this.paymentFormGroup?.value?.paymentType == this.paymentOptionEPP;
  }

  get insurerFullName(): string {
    if (this.selectedQuotation && this.selectedQuotation.code) {
      return INSURER_FULL_NAME[this.selectedQuotation.code];
    }
    return "";
  }

  allAddonChipSelected($event): void {
    if ($event.selected) this.addOnListToDisplay = this.consolidatedAddOn;
  }

  popularAddonChipSelected($event): void {
    if ($event.selected)
      this.addOnListToDisplay = this.popularConsolidatedAddOn;
  }

  // Forms
  private initQuotationForm() {
    this.quotationFormGroup = new FormGroup({
      id: new FormControl(this.quotationId, [Validators.required]),
    });
  }
  private initPersonalDetailsForm() {
    this.infoFormGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      nationality: new FormControl({ value: "", disabled: true }),
      identityNo: new FormControl({ value: "", disabled: true }),
      policIdentityNo: new FormControl({ value: "", disabled: true }),
      mobileNo: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email]),
      maritalStatus: new FormControl({ value: "", disabled: true }),
      gender: new FormControl({ value: "", disabled: true }),

      address1: new FormControl(null, [Validators.required]),
      address2: new FormControl(null, [Validators.required]),
      city: new FormControl(null, [Validators.required]),
      state: new FormControl(null, [Validators.required]),

      deliveryAddress1: new FormControl(null),
      deliveryAddress2: new FormControl(null),
      deliveryCity: new FormControl(null),
      deliveryPostcode: new FormControl(null),
      deliveryState: new FormControl(null),
    });
  }

  private initAgreementForm() {
    this.agreementFrmGroup = new FormGroup({
      agreeInfo: new FormControl(false, [Validators.requiredTrue]),
      aqadTC: new FormControl(false),
      doc: new FormArray([]),
    });
  }

  private initAddOnForm() {
    this.addOnFormGroup = new FormGroup({
      windshield: new FormControl(),
      drivers: new FormArray([]),
      roadTaxPrice: new FormControl(this.personalInfo?.roadTaxPrice),
      isIncludeRoadtax: new FormControl(false),
      isNotBlacklisted: new FormControl(null),
      lltpSeats: new FormControl(this.numberOfLltpSeats[0]),
    });
    this.addOnFormGroup.controls.roadTaxPrice.setValue(
      this.personalInfo?.roadTaxPrice,
    );

    this.addOnFormGroup.controls.isIncludeRoadtax.valueChanges.subscribe(
      (x) => {
        if (x) {
          this.addOnFormGroup.controls.isNotBlacklisted.setValidators([
            Validators.requiredTrue,
          ]);
        } else {
          this.addOnFormGroup.controls.isNotBlacklisted.setValidators([]);
        }
        this.addOnFormGroup.controls.isNotBlacklisted.updateValueAndValidity();
      },
    );
  }

  private initPaymentFormGroup() {
    this.motorDataDataService.getEPPRates().subscribe({
      next: (x: EPPChargeInterface[]) => {
        this.eppChargeList = x;
      },
    });

    this.paymentFormGroup = new FormGroup({
      paymentType: new FormControl(this.paymentOptionFullPrice, [
        Validators.required,
      ]),
      paymentProvider: new FormControl(this.paymentOptionRazer, [
        Validators.required,
      ]),
      paymentChannel: new FormControl("", []),
      ewalletType: new FormControl("", []),
      // For EPP only
      bank: new FormControl("", []),
      installmentMonth: new FormControl(0, []),
      paymentOptionEPPType: new FormControl("", []),
      paymentOptionFPType: new FormControl(this.paymentOptionRazer, [
        Validators.required,
      ]),
    });

    let paymentFormVal = this.paymentFormGroup.controls;
    let paymentChannel = this.paymentFormGroup.controls.paymentChannel;
    let paymentProvider = this.paymentFormGroup.controls.paymentProvider;
    let ewalletType = this.paymentFormGroup.controls.ewalletType;

    // Change EPP rate on bank change
    this.paymentFormGroup.controls.bank.valueChanges.subscribe((x) => {
      if (x) {
        this.getIndividualEppRates(x);
        this.pushCleverTapPaymentOptionSelected();
      }
    });

    // Change Monthly Payment Option for EPP
    this.paymentFormGroup.controls.installmentMonth.valueChanges.subscribe(
      (x) => {
        if (
          x &&
          this.paymentFormGroup.value.paymentType == this.paymentOptionEPP
        ) {
          this.pushCleverTapPaymentOptionSelected();
        }
      },
    );

    // To toggle e-wallet dropdown to be required/not
    this.paymentChannelSub =
      this.paymentFormGroup.controls.paymentChannel.valueChanges.subscribe(
        (x) => {
          if (x == this.paymentOptionEwallet) {
            ewalletType?.setValidators([Validators.required]);
          } else {
            ewalletType?.setValidators([]);
          }
          ewalletType?.updateValueAndValidity();
        },
      );

    // To toggle other payment type child inputs to be required/not
    this.paymentTypeChangeSub =
      this.paymentFormGroup.controls.paymentType.valueChanges.subscribe((x) => {
        if (x == this.paymentOptionFullPrice) {
          paymentChannel.setValidators([]);
          this.paymentFormGroup.patchValue({
            paymentProvider: this.paymentOptionRazer,
          });
          paymentFormVal?.paymentOptionFPType.setValidators([
            Validators.required,
          ]);
        } else {
          paymentChannel.setValidators([]);
        }

        if (x == this.paymentOptionEPP) {
          paymentFormVal?.bank.setValidators([Validators.required]);
          paymentFormVal?.installmentMonth.setValidators([Validators.required]);
          paymentProvider.setValidators([Validators.required]);
          paymentFormVal?.paymentOptionEPPType.setValidators([
            Validators.required,
          ]);
          paymentFormVal?.paymentOptionFPType.setValidators([]);
          paymentFormVal.ewalletType.reset();
          paymentFormVal.paymentChannel.reset();

          this.paymentFormGroup.patchValue({
            paymentOptionFPType: "",
            bank: "",
            installmentMonth: "",
          });
        } else {
          paymentFormVal?.bank.setValidators([]);
          paymentFormVal?.installmentMonth.setValidators([]);
          paymentFormVal?.paymentOptionEPPType.setValidators([]);
          paymentFormVal.bank.reset();
          paymentFormVal.installmentMonth.reset();
          paymentFormVal.paymentOptionEPPType.reset();
        }

        if (x == this.paymentOptionShopback) {
          paymentChannel.setValidators([]);
          paymentFormVal?.bank.setValidators([]);
          paymentFormVal?.installmentMonth.setValidators([]);
          paymentFormVal?.paymentOptionFPType.setValidators([]);

          paymentFormVal.bank.reset();
          paymentFormVal.installmentMonth.reset();
          paymentFormVal.ewalletType.reset();

          this.paymentFormGroup.patchValue({
            paymentProvider: this.paymentOptionShopback,
            paymentChannel: this.paymentOptionShopback,
            paymentOptionFPType: "",
          });
        }
        paymentChannel.updateValueAndValidity();
        paymentFormVal?.bank.updateValueAndValidity();
        paymentFormVal?.installmentMonth.updateValueAndValidity();

        this.pushCleverTapPaymentOptionSelected();
      });
  }
  // End forms

  getEppRate() {
    let bankName = this.paymentFormGroup?.value?.bank;
    let paymentProvider = this.paymentFormGroup?.value?.paymentProvider;
    let installmentPeriod = this.paymentFormGroup?.value?.installmentMonth;

    if (bankName && installmentPeriod && this.eppChargeList?.length) {
      const result = this.eppChargeList?.filter(
        (entry) =>
          entry.bankName == bankName &&
          entry.installmentPeriod == installmentPeriod &&
          entry.provider == paymentProvider,
      );
      return result.length > 0 ? result[0]?.eppRate : null;
    } else {
      return null;
    }
  }

  getIndividualEppRates(x) {
    let bankName = x;
    let paymentProvider = this.paymentFormGroup?.value?.paymentProvider;

    if (bankName && this.eppChargeList?.length) {
      const results: any = this.eppChargeList
        .filter(
          (entry) =>
            entry.bankName == bankName && entry.provider == paymentProvider,
        )
        .reduce((accumulator, entry) => {
          const periods = [3, 6, 12];
          if (periods.includes(entry.installmentPeriod)) {
            accumulator[entry.installmentPeriod] =
              entry.eppRate * (this.getTotalCharge() - this.getTotalPromo());
          }
          return accumulator;
        }, {});

      this.bankInstallmentCharge = results;
    } else {
      this.bankInstallmentCharge = { 3: null, 6: null, 12: null };
    }
  }

  deliveryAddressChecked(e: MatCheckboxChange) {
    this.enableDeliveryAddress = e.checked;
    let validatorList = e.checked ? [Validators.required] : [];

    if (e.checked) {
      this.infoFormGroup.controls.deliveryAddress1.reset();
      this.infoFormGroup.controls.deliveryAddress2.reset();
      this.infoFormGroup.controls.deliveryCity.reset();
      this.infoFormGroup.controls.deliveryPostcode.reset();
      this.infoFormGroup.controls.deliveryState.reset();
    }

    this.infoFormGroup.controls.deliveryAddress1.setValidators(validatorList);
    this.infoFormGroup.controls.deliveryAddress2.setValidators(validatorList);
    this.infoFormGroup.controls.deliveryCity.setValidators(validatorList);
    this.infoFormGroup.controls.deliveryPostcode.setValidators(validatorList);
    this.infoFormGroup.controls.deliveryState.setValidators(validatorList);

    this.infoFormGroup.controls.deliveryAddress1.updateValueAndValidity();
    this.infoFormGroup.controls.deliveryAddress2.updateValueAndValidity();
    this.infoFormGroup.controls.deliveryCity.updateValueAndValidity();
    this.infoFormGroup.controls.deliveryPostcode.updateValueAndValidity();
    this.infoFormGroup.controls.deliveryState.updateValueAndValidity();
  }

  backToStep(index: number) {
    this.stepper.selectedIndex = index;
  }

  // Bundle functions
  addBundle(item: any) {
    this.selectedBundle.push(item);

    // If bundle selected, remove all add on with the selected bundle ID
    this.selectedBundleAddOn = this.selectedBundleAddOn.filter(
      (x) => x.motorBundleId !== item.id && x.code !== "UnlimitedTowing",
    );
  }

  removeBundle(type: any) {
    this.selectedBundle = this.selectedBundle.filter((e: any) => e !== type);
  }

  getIsBundleSelected(id: string) {
    return this.selectedBundle.some((e: any) => e.id == id);
  }

  getIsBundleAddOnSelected(code: string) {
    return this.selectedBundleAddOn.some((e: any) => e.code == code);
  }

  isAnyBundleSelected() {
    return this.selectedBundle.length > 0;
  }

  toggleBundleAddonCheckbox(e: MatCheckboxChange, type?: any) {
    if (e.checked) {
      this.selectedBundleAddOn.push(type);
      this.pushCleverTapSelectAddOn(type.code);
    }
    if (!e.checked) {
      this.selectedBundleAddOn = this.selectedBundleAddOn.filter(
        (x) => x !== type,
      );
    }
  }
  // End Bundle functions

  toggleUnlimitedTowingPlan(display: string) {
    const elements = document.getElementsByClassName("UnlimitedTowing");
    for (let i = 0; i < elements.length; i++) {
      (elements[i] as HTMLElement).style.display = display;
    }
  }

  toggleAddOn(e: MatCheckboxChange, value: any) {
    if (e.checked) {
      if (
        value.code === "MPAPlus" ||
        value.code === "BISP" ||
        value.code === "PA" ||
        value.code === "MERW"
      ) {
        this.toggleUnlimitedTowingPlan("none");
        this.selectedAddOn = this.selectedAddOn.filter(
          (x) => x.category !== "UnlimitedTowing",
        );
      }
      this.selectedAddOn.push(value);
      this.pushCleverTapSelectAddOn(value.code);
    } else {
      if (
        value.code === "MPAPlus" ||
        value.code === "BISP" ||
        value.code === "PA" ||
        value.code === "MERW"
      ) {
        this.toggleUnlimitedTowingPlan("flex");
      }
      let index = this.selectedAddOn.findIndex((x) => x.id == value.id);
      if (index >= 0) {
        this.selectedAddOn.splice(index, 1);
      }
      if (value.category === "Windshield") {
        this.addOnFormGroup.controls.windshield.setValue(
          this.getRule(value)?.min || 500,
        );
      }
    }
    if (value.category == "Drivers") {
      let frmArray = this.getDriversFormArray();
      frmArray.reset();
      frmArray.clear();
      if (e.checked && value.code == "AdditionalDrivers") this.addDriver();
    }
  }

  toggleRoadTax(e: MatCheckboxChange) {
    let value: any = e.source.value as any;
    if (e.checked) {
      this.showRoadTaxList = true;

      this.addOnFormGroup.controls.isIncludeRoadtax.setValue(true);
      this.isIncludeDigitalRoadtax = true;
      this.pushCleverTapSelectAddOn("Road Tax Renewal");
    }
    if (!this.personalInfo.isMalaysian || this.personalInfo.isCompany) {
      this.addOnFormGroup.controls.isIncludeRoadtax.setValue(true);
      this.addOnFormGroup.controls.roadTaxPrice.setValue(
        this.personalInfo.roadTaxPrice,
      );
      this.isIncludeDigitalRoadtax = false;
    }
    if (!e.checked) {
      this.addOnFormGroup.controls.isIncludeRoadtax.setValue(false);
      this.showRoadTaxList = false;
      this.isIncludeDigitalRoadtax = false;
    }
  }

  getPrice(item: ProductAddOnInterface, val?: any) {
    try {
      let evaluate = item.evaluate;
      let condition = item.condition;

      if (val) {
        evaluate = evaluate.replace("{val}", val);

        if (condition) {
          condition = condition.replace("{val}", val);
          if (item.isConditionFirst) {
            let status = Boolean(eval(condition));
            if (status) {
              return eval(evaluate);
            } else {
              return 0;
            }
          }
        }
      }
      return eval(evaluate);
    } catch {
      return 0;
    }
  }

  getAddOnListByCategory(val: string) {
    return this.data.motorAddOn.filter((x) => x.category == val);
  }

  isAddOnSelected(item: ProductAddOnInterface) {
    if (item) {
      return this.selectedAddOn.some((x) => x.id == item.id);
    }
    return null;
  }

  hasSelectedCode(val: string) {
    return this.selectedAddOn.some((x) => x.code == val);
  }

  hasSelectedCategory(val: string) {
    return this.selectedAddOn.some((x) => x.category == val);
  }

  addonLabel(value: number) {
    return "RM " + value.toFixed(2).toLocaleString();
  }

  private getProductDocument() {
    if (!this.selectedQuotation) return;
    this.productDocumentInterface = [];
    let frmArray = this.getAgreeFormArray();
    frmArray.clear();
    this.motorDataDataService
      .getProductDocumentById(
        this.selectedQuotation.productId,
        this.translate.currentLang,
        this.personalInfo?.insuranceType,
      )
      .pipe(shareReplay())
      .subscribe({
        next: (x) => {
          this.productDocumentInterface = x;
          x.forEach((doc) => {
            if (doc.neededUserAgree) {
              let frm = this.formBuilder.group({
                checked: new FormControl(false, [Validators.requiredTrue]),
                name: new FormControl(doc.name),
                url: new FormControl(doc.url),
              });
              frmArray.push(frm);
            }
          });
        },
        error: (err) => {},
      });
  }

  benefitDialog(item) {
    this.dialog.open(BenefitDialogComponent, {
      panelClass: "benefit-dialog",
      data: {
        productId: item.productId,
        code: item.code,
        lang: this.translate.currentLang,
        insuranceType: this.personalInfo.insuranceType,
      },
    });
  }

  addDriver() {
    let frmArray = this.getDriversFormArray();
    let frm = this.formBuilder.group({
      name: new FormControl(null, [Validators.required]),
      identityNo: new FormControl(null, [
        Validators.required,
        ValidatorFunction.nricValidator(),
      ]),
      dob: new FormControl(null, [Validators.required]),
      gender: new FormControl("M", [Validators.required]),
      maritalStatus: new FormControl("Single", [Validators.required]),
      relationship: new FormControl(null, [Validators.required]),
    });
    frmArray.push(frm);
  }

  deleteDriver(i: number) {
    let frmArray = this.getDriversFormArray();
    frmArray.removeAt(i);
  }

  driverRadioChange(e: ProductAddOnInterface, isCheckbox: boolean = false) {
    let index = this.selectedAddOn.findIndex((x) => x.category == e.category);
    if (index >= 0) this.selectedAddOn.splice(index, 1);
    if (!isCheckbox || index == -1) {
      this.selectedAddOn.push(e);

      this.pushCleverTapSelectAddOn(e.code);
    }

    let frmArray = this.getDriversFormArray();
    if (frmArray.length == 0 && e.code == "AdditionalDrivers") this.addDriver();
    else {
      frmArray.reset();
      frmArray.clear();
    }
  }

  getRoadtaxDisplayPrice() {
    // If no promo then use the form price as display
    if (
      this.personalInfo.roadTaxPrice == this.personalInfo.originalRoadTaxPrice
    ) {
      if (this.showRoadTaxList) {
        // if roadtax is selected
        if (
          !this.addOnFormGroup.controls.roadTaxPrice.value ||
          this.isIncludeDigitalRoadtax
        ) {
          // set default roadtax price as roadtaxprice from api
          this.addOnFormGroup.controls.roadTaxPrice.setValue(
            this.personalInfo.roadTaxPrice,
          );
        }
        // if its delivery roadtax price, use form value to get the added delivery price
        return this.addOnFormGroup.controls["roadTaxPrice"].value;
      } else {
        return this.personalInfo?.originalRoadTaxPrice;
      }
      // If there is promo, use the originalRoadTaxPrice as display
    } else {
      return this.personalInfo?.originalRoadTaxPrice;
    }
  }

  radioDigitalRoadTaxChange(e: any, isChecked: boolean = false) {
    if (e.value == "digitalRoadTax") {
      this.isIncludeDigitalRoadtax = true;
      this.addOnFormGroup.controls.roadTaxPrice.setValue(
        this.personalInfo.roadTaxPrice,
      );
    } else {
      this.isIncludeDigitalRoadtax = false;
      this.addOnFormGroup.controls.roadTaxPrice.setValue(
        this.personalInfo.roadTaxPrice + 15,
      );
    }
  }

  getRule(e: ProductAddOnInterface) {
    if (e.rules) {
      return JSON.parse(e.rules);
    }
    return null;
  }

  additionalDriverIdentityNoChange(i: number) {
    let frmArray = this.getDriversFormArray();
    let control = frmArray.controls[i] as FormGroup;

    if (control.controls.identityNo.valid) {
      let value = control.value.identityNo;
      let date = moment(value.substring(0, 6), "YYMMDD");
      if (moment().isBefore(date)) {
        date.subtract(100, "year");
      }
      let lastChar = value.substring(11);
      control.controls.dob.setValue(date.toDate());
      let gender = lastChar % 2 ? "M" : "F";
      control.controls.gender.setValue(gender);
    }
  }

  getDriversFormArray() {
    return this.addOnFormGroup.get("drivers") as FormArray;
  }

  getAgreeFormArray() {
    return this.agreementFrmGroup.get("doc") as FormArray;
  }

  getFormArrayControlError(i, formControlName) {
    return (this.getDriversFormArray().controls[i] as FormGroup).controls[
      formControlName
    ].errors;
  }

  applyPromoCode() {
    if (!this.promoCode) return;
    this.ngxSpinnerService.show();
    this.promoError = null;
    this.promo = null;
    this.motorDataDataService
      .applyPromoCode(this.id, this.promoCode, this.getTotalGrossPremium())
      .subscribe({
        next: (x) => {
          this.promo = x;
          this.ngxSpinnerService.hide();
          this.promoCheck();
          this.pushCleverTapPromoApplied();
        },
        error: (err) => {
          this.ngxSpinnerService.hide();
          this.promoError = err.error.message;
        },
      });
  }

  private promoCheck() {
    if (!this.promo) return;
    if (
      this.promo.customCode == "RoadTaxDelivery" &&
      !this.addOnFormGroup.value.isIncludeRoadtax
    ) {
      this.promo = null;
      this.promoError = "InvalidPromoCode";
    }
  }

  clearPromoCode() {
    this.promoCode = null;
    this.promoError = null;
    this.promo = null;
  }

  paymentTypeChange(paymentType: string) {
    this.selectedPayment = paymentType;
  }

  submit() {
    const infoValue = this.infoFormGroup.value;
    const addOnValue = this.addOnFormGroup.value;

    const bundleIdList = this.selectedBundle.map((x) => x.id);
    const bundleAddOnIdList = this.selectedBundleAddOn.map((x) => x.id);
    const addOnIdList = [...this.selectedAddOn, ...this.includedAddOn].map(
      (x) => x.id,
    );

    let paymentFGroup = this.paymentFormGroup?.value;

    if (paymentFGroup) {
      if (paymentFGroup.paymentChannel === this.paymentOptionEwallet)
        // To change payment channel for e-wallet and EPP
        paymentFGroup.paymentChannel = paymentFGroup.ewalletType;
      else if (paymentFGroup.paymentType === this.paymentOptionEPP)
        paymentFGroup.paymentChannel = paymentFGroup.bank;
      if (paymentFGroup.paymentProvider === this.paymentOptionShopback)
        // To change payment type null for shopback
        paymentFGroup.paymentType = null;
    }

    let values = {
      quotationId: this.quotationId,
      windShield: addOnValue.windshield,
      additionalDriverList: addOnValue.drivers,
      motorBundleIds: bundleIdList,
      motorBundleAddOnIds: bundleAddOnIdList,
      addOnIds: addOnIdList,
      promoCodeId: this.promo?.id || null,
      occupation: null,
      address: infoValue.address1,
      address2: infoValue.address2,
      city: infoValue.city,
      state: infoValue.state,
      deliveryAddress: infoValue.deliveryAddress1 || infoValue.address1,
      deliveryAddress2: infoValue.deliveryAddress2 || infoValue.address2,
      deliveryCity: infoValue.deliveryCity || infoValue.city,
      deliveryPostcode:
        infoValue.deliveryPostcode || this.personalInfo?.postcode,
      deliveryState: infoValue.deliveryState || infoValue.state,
      includeRoadTax: this.showRoadTaxList,
      includeDigitalRoadTax: this.isIncludeDigitalRoadtax,
      name: this.infoFormGroup.get("name").value,
      email: this.infoFormGroup.get("email").value,
      mobileNo: this.infoFormGroup.get("mobileNo").value,
      paymentProvider:
        this.paymentFormGroup?.value?.paymentProvider ||
        this.paymentOptionRazer,
      paymentType: paymentFGroup.paymentType || this.paymentOptionFullPrice,
      paymentChannel: paymentFGroup.paymentChannel || this.paymentOptionVisa,
      seat: addOnValue.lltpSeats,
      motorExcessId: this.selectedExcess?.id,
      ...(this.paymentFormGroup?.value.installmentMonth && {
        installmentMonth: this.paymentFormGroup.value.installmentMonth,
      }),
      salesCampaignList: this.availableSalesCampaign,
    };

    this.motorBundleService.generateBundlePayment(this.id, values).subscribe({
      next: (x: any) => {
        let navigationExtras: NavigationExtras = {
          queryParams: {
            id: x.id,
            partnerCode: "ps",
          },
        };
        this.pushCleverTapPaymentInitiated();
        this.ngxSpinnerService.hide();
        this.router.navigate(["/payment"], navigationExtras);
      },
      error: (err) => {
        this.alertService.openSnackBar(err.error);
        this.ngxSpinnerService.hide();
        if (
          err.error &&
          (err.error.includes(FULLY_REDEEMED_MSG) ||
            err.error.includes(HAS_ENDED_MSG))
        ) {
          setTimeout(() => {
            location.reload();
          }, 3000); // Delay of 3 seconds
        }
      },
    });
  }

  editQuotation() {
    let dialogRef = this.dialog.open(EditQuotationComponent, {
      data: {
        id: this.id,
        request: this.personalInfo,
        quotationList: this.quotationList,
        bundleSite: true,
      },
      panelClass: "edit-quote-dialog",
    });

    dialogRef.afterClosed().subscribe((x) => {
      if (x) {
        this.dialog.open(SubmitSuccessDialogComponent, {
          data: {
            editQuotations: true,
          },
          panelClass: "success-dialog",
        });
      }
    });
  }
  // Get price list
  getTotalBundlePrice() {
    let total = 0;
    this.selectedBundle.forEach((x) => {
      total += parseFloat(x.evaluate);
    });
    return total;
  }

  getTotalBundleAddonPrice() {
    let total = 0;
    this.selectedBundleAddOn.forEach((x) => {
      total += parseFloat(x.evaluate);
    });
    return total;
  }

  getTotalAddOnPrice() {
    let total = 0;

    this.includedAddOn.forEach((x) => {
      total += this.getPrice(x);
    });

    this.selectedAddOn.forEach((x) => {
      if (x.code == "AdditionalDrivers")
        total += this.getPrice(x, this.getDriversFormArray().length);
      else if (x.category == "Windshield")
        total += this.getPrice(x, this.addOnFormGroup.value.windshield);
      else if (x.category == "LLTP")
        total += this.getPrice(x, this.addOnFormGroup.value.lltpSeats);
      else total += this.getPrice(x);
    });
    return total;
  }

  getNcdPrice() {
    if (this.selectedExcess) {
      return (
        (this.selectedQuotation?.basePremium - this.selectedExcess?.price) *
        this.personalInfo?.ncd
      );
    } else {
      return this.selectedQuotation?.basePremium * this.personalInfo?.ncd;
    }
  }

  getTotalBasePremium() {
    if (this.selectedExcess) {
      // Any selected Excess will recalculate Total Base Premium
      return (
        this.selectedQuotation?.basePremium -
        this.getNcdPrice() -
        this.selectedExcess?.price
      );
    } else {
      // If Excess is disabled, Total Base Premium will be the one returned from BE
      return this.selectedQuotation?.totalBasePremium;
    }
  }

  getTotalGrossPremium() {
    if (!this.personalInfo && !this.selectedQuotation) return 0;

    return (
      this.getTotalBasePremium() +
      this.getTotalAddOnPrice() +
      this.getTotalBundlePrice() +
      this.getTotalBundleAddonPrice()
    );
  }

  getServiceTax() {
    if (!this.selectedQuotation) return 0;
    let total = this.getTotalGrossPremium();

    return total * this.selectedQuotation.taxRate;
  }

  getTotalNetPremium() {
    var total =
      Number(this.getTotalGrossPremium().toFixed(2)) +
      Number(this.getServiceTax().toFixed(2)) +
      this.selectedQuotation?.stampDutyPrice;
    total =
      this.selectedQuotation &&
      this.selectedQuotation.code.toLowerCase() === "takaful_malaysia"
        ? CommonFunction.customSTMBRound(total)
        : total;

    return total;
  }

  getPlatformDiscountRate() {
    return (
      this.selectedQuotation?.platformDiscountRate * this.getTotalGrossPremium()
    );
  }

  getTotalPromo() {
    if (!this.promo) return 0;

    let evaluate = this.promo.evaluate;
    evaluate = evaluate.replace(
      "{TotalGrossPremium}",
      this.getTotalGrossPremium().toString(),
    );
    return Number.parseFloat(eval(evaluate));
  }

  getTotalCharge() {
    let total = this.getTotalNetPremium() - this.getPlatformDiscountRate();
    if (this.addOnFormGroup.value?.isIncludeRoadtax) {
      total = total + this.personalInfo.roadTaxPrice;

      // If digital road tax is not selected, add delivery price
      if (!this.isIncludeDigitalRoadtax) {
        total = total + this.personalInfo.roadTaxDeliveryPrice;
      }
    }

    if (this.selectedQuotation?.rebate !== null)
      total = total - this.selectedQuotation?.rebate;
    else total;

    return total;
  }

  getServiceFee() {
    if (!this.selectedQuotation) return 0;

    //Requested by Siew Wai to increase the Service Fee rate by 0.2% if Digital Road Tax is selected. (Removed for now)
    //const digiRoadTaxServiceFeeRateIncrease = 0.002;

    // let serviceFeeDigital =
    // 	(this.selectedQuotation.serviceTaxRate +
    // 		digiRoadTaxServiceFeeRateIncrease) *
    // 	(this.getTotalCharge() - this.getTotalPromo());
    let serviceFee =
      this.selectedQuotation.serviceTaxRate *
      (this.getTotalCharge() - this.getTotalPromo());

    //if (this.isIncludeDigitalRoadtax)
    //return Number(serviceFeeDigital.toFixed(2));
    //else
    return Number(serviceFee.toFixed(2));
  }

  getPremium() {
    // Total without serviceFee
    let totalBeforeCharges = this.getTotalCharge() - this.getTotalPromo();
    return totalBeforeCharges;
  }

  getBankInstalmentCharges() {
    return this.getPremium() * this.getEppRate();
  }

  getFinalTotalBeforeCharges() {
    // Total with serviceFee, without instalmentCharges
    return this.getTotalCharge() - this.getTotalPromo() + this.getServiceFee();
  }

  getFinalTotal() {
    let totalBeforeCharges =
      this.getTotalCharge() - this.getTotalPromo() + this.getServiceFee();

    if (this.isPaymentTypeEPP) {
      return totalBeforeCharges + this.getBankInstalmentCharges();
    } else {
      // If not EPP
      return totalBeforeCharges;
    }
  }
  // End Get price list

  getPaymentDurationLabel() {
    let paymentFGroup = this.paymentFormGroup?.value;
    if (paymentFGroup?.paymentType == this.paymentOptionEPP) {
      if (paymentFGroup?.installmentMonth == 3) {
        return `<span class='bold'>RM ${this._decimalPipe.transform(
          this.getFinalTotal() / 3,
          "1.2-2",
        )}</span>
				${this.translate.instant("common.perMonth")}
				${this.translate.instant("paymentOptions.for3months")}`;
      } else if (paymentFGroup?.installmentMonth == 6) {
        return `<span class='bold'>RM ${this._decimalPipe.transform(
          this.getFinalTotal() / 6,
          "1.2-2",
        )}</span>
				${this.translate.instant("common.perMonth")}
				${this.translate.instant("paymentOptions.for6months")}`;
      } else if (paymentFGroup?.installmentMonth == 12) {
        return `<span class='bold'>RM ${this._decimalPipe.transform(
          this.getFinalTotal() / 12,
          "1.2-2",
        )}</span>
				${this.translate.instant("common.perMonth")}
				${this.translate.instant("paymentOptions.for12months")}`;
      }
    }

    return "";
  }

  getPSComissionPrice() {
    // PS comission, only shown in Allianz quote in order summary section
    let comission = this.getTotalGrossPremium() * (10 / 100);
    return Number(comission.toFixed(2));
  }

  openCarVariantDialog(id: string) {
    let dialogRef = this.dialog.open(CarVariantDialogComponent, {
      panelClass: "edit-car-variant-dialog",
      data: { requestData: this.personalInfo, linkId: this.id },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.data === true) {
        this.selectQuotation(id);
      }
    });
  }

  getBundleBrochure(itemCode: string) {
    let lang = this.translate.currentLang;
    let basePath = "https://storage.googleapis.com/ps-asset/motor/FFF/Bundle/";
    let basePath2 = "_Brochure_";
    let fileFormat = ".pdf";

    return basePath + itemCode + basePath2 + lang + fileFormat;
  }

  // -- CleverTap Analytics Functions
  pushCleverTapLogin() {
    const identity = this.personalInfo.mobileNo;

    clevertap.onUserLogin.push({
      Site: {
        Identity: identity, // String or number (same as phone number) this will be used as Clevertap Identity
      },
    });
  }

  pushCleverTapPageView(pageIndex: number) {
    let currentPage = "";

    switch (pageIndex) {
      case 0:
        currentPage = "Insurers";
        break;

      case 1:
        currentPage = "Add-on";
        break;

      case 2:
        currentPage = "Personal details";
        break;

      case 3:
        currentPage = "Order summary";
        break;
      default:
        break;
    }

    clevertap.event.push("Page Viewed", {
      URL: window.location.href,
      "Page Name": currentPage,
    });
  }

  pushCleverTapSelectedInsurer() {
    clevertap.event.push("Insurer Selected", {
      "Insurer Name": INSURER_FULL_NAME[this.selectedQuotation.code],
    });
  }

  pushCleverTapSelectAddOn(addOnCode: string) {
    clevertap.event.push("Add-on Selected", {
      "Add-on Name": addOnCode,
    });
  }

  pushCleverTapPersonalDetails() {
    clevertap.event.push("Personal Details Filled", {
      City: this.infoFormGroup.value.city,
      State: this.infoFormGroup.value.state,
    });
  }

  pushCleverTapExcessSelection() {
    clevertap.event.push("Excess Fee Selected", {
      "Excess Type": this.selectedExcess.name,
    });
  }

  pushCleverTapPromoApplied() {
    clevertap.event.push("Promo Code Applied", {
      "Promo Code Name": this.promoCode,
    });
  }

  pushCleverTapPaymentOptionSelected() {
    clevertap.event.push("Payment Option Selected", {
      "Payment Option Type": this.paymentFormGroup.value.paymentType,
      "Bank Name": this.paymentFormGroup.value.bank,
      "Monthly Option": this.paymentFormGroup.value.installmentMonth,
    });
  }

  pushCleverTapPaymentInitiated() {
    clevertap.event.push("Payment Initiated");
  }
  // -- CleverTap Analytics Functions

  // -- Other insurer section
  updateSelectedInsurers(id: string) {
    const index = this.selectedOtherInsurers.indexOf(id);
    if (index === -1) {
      this.selectedOtherInsurers.push(id);
    } else {
      this.selectedOtherInsurers.splice(index, 1);
    }
  }

  submitOtherInsurer() {
    let typeOfSum = this.personalInfo.typeOfSum ?? "MV";

    this.motorQuotationService
      .submitExtraProduct(this.id, typeOfSum, this.selectedOtherInsurers)
      .subscribe({
        next: (x) => {
          this.handleSuccessSubmitQuotation({
            preferredChannel: this.personalInfo.preferredChannel,
            email: this.personalInfo.email,
            mobileNo: this.personalInfo.mobileNo,
          });
        },
      });
  }
  // -- Other insurer section

  get sortedQuotationList() {
    return this.quotationList.sort((a, b) => {
      if (a.status === "Rejected" && b.status !== "Rejected") {
        return -1;
      }
      if (a.status !== "Rejected" && b.status === "Rejected") {
        return 1;
      }
      return 0;
    });
  }

  get disableEditQuotationBtn() {
    const availableCount = this.quotationList.filter(
      (x) => x.status == "Available",
    ).length;
    return availableCount == 0;
  }

  private handleSuccessSubmitQuotation(
    customerInput: Partial<MotorQuotationInterface>,
  ): void {
    const dialogRef = this.dialog.open(SubmitSuccessDialogComponent, {
      panelClass: "success-dialog",
      data: { customerInput },
    });

    dialogRef.afterClosed().subscribe(() => location.reload());
  }
}
