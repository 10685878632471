import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MotorRequestInterface} from "../../../share/interface/motor.interface";

@Component({
  selector: 'app-ktmb-car-details',
  templateUrl: './ktmb-car-details.component.html',
  styleUrls: ['./ktmb-car-details.component.scss']
})
export class KtmbCarDetailsComponent {
  @Input() carDetails: MotorRequestInterface;
  @Output() editQuotation = new EventEmitter<void>();

  onEditQuotation(): void {
    this.editQuotation.next();
  }
}
