import { Component, Output, Input,EventEmitter } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-customer-details-card',
  templateUrl: './customer-details-card.component.html',
  styleUrls: ['./customer-details-card.component.scss']
})
export class CustomerDetailsCardComponent {
  @Input() renewPage: any;
  @Input() idType: string;
  @Input() reSubmitted: boolean;
  @Output() editDetailsClicked: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    public translate: TranslateService,
  ) { }
  
  onEditDetailsClick(): void {
    this.editDetailsClicked.emit();
  }
}
