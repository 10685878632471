import { Component, Input, Output, OnInit, ViewChild, EventEmitter, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HIGHER_EXCESS, LOWER_EXCESS, ZERO_EXCESS } from 'src/app/share/constants/excess.types';
import { ExcessInterface, SelectedExcessInterface } from 'src/app/share/interface/motor.interface';
import { SwiperComponent } from 'swiper/angular';

@Component({
  selector: 'app-excess',
  templateUrl: './excess.component.html',
  styleUrls: ['./excess.component.scss']
})
export class ExcessComponent implements OnInit {
  excessAmount: number;
  screenWidth: number;

  @ViewChild("swiper", { static: false }) swiper!: SwiperComponent;


  @Input() data: ExcessInterface[];
  @Input() quotationData: any;
  @Input() selectedExcess: SelectedExcessInterface;

  @Output() onChangeExcess = new EventEmitter();

  mobileData: ExcessInterface[];

  constructor(public translate: TranslateService) {}

  @HostListener("window:resize", ["$event"])
	onResize(event) {
		this.screenWidth = event.target.innerWidth;
	}

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;

    // Change data order for mobile view
    this.mobileData = [...this.data].sort((a, b) => {
      const order = { "Lower Excess": 0, "Higher Excess": 1, "Zero Excess": 2 }; // Define the desired order
      return order[a.name] - order[b.name]; // Sort based on the defined order
    });
  }

  isMaxSaving(data: any) {
    return data?.name == HIGHER_EXCESS;
  }

  isExcessSelected(data: any){
    return this.selectedExcess?.name == data?.name;
  }

  getExcessAmount(data: any): number{
    let condition = data.voluntaryExcessEvaluate?.replace("{si}", this.quotationData?.sumInsured);

    let status = Boolean(eval(condition));
    if (status) {
      return eval(condition);
    } else {
      return 0;
    }
  }

  getDiscountAmount(data: any): number{
    let condition = data.premiumDiscountEvaluate?.replace("{bp}", this.quotationData.basePremium);
    let status = Boolean(eval(condition));
    if (status) {
      return eval(condition);
    } else {
      return 0;
    }
  }

  switchExcess(data: any){
    let newExcess = {
      name: data?.name,
      price: this.getDiscountAmount(data),
      id: data.id,
      showState: true
    }

    this.onChangeExcess.emit(newExcess)

    if(data.name == LOWER_EXCESS){
      this.swiper.swiperRef.slideTo(0);
    } else if (data.name == HIGHER_EXCESS){
      this.swiper.swiperRef.slideTo(1);
    } else if (data.name == ZERO_EXCESS){
      this.swiper.swiperRef.slideTo(2);
    }
  }

  showButton(index: number){
    if(this.swiper?.swiperRef?.activeIndex == index){
      return true;
    } else {
      return false;
    }
  }

}
