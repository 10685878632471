import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {TranslateModule} from "@ngx-translate/core";
import {MaterialModule} from "src/app/material-module";
import {BenefitComponent} from "./benefit.component";
import {MsigBenefitComponent} from './msig-benefit/msig-benefit.component';
import {RhbBenefitComponent} from './rhb-benefit/rhb-benefit.component';
import {SompoBenefitComponent} from './sompo-benefit/sompo-benefit.component';

@NgModule({
  exports: [BenefitComponent, SompoBenefitComponent, MsigBenefitComponent, RhbBenefitComponent],
  declarations: [BenefitComponent, SompoBenefitComponent, MsigBenefitComponent, RhbBenefitComponent],
  imports: [TranslateModule, CommonModule, MaterialModule],
})
export class BenefitModule {
}
