import {SharedComponentModule} from "../share/component/shared-component.module";
import { InsurerDetailsModule } from "./../share/component/insurer-details/insurer-details.module";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "../material-module";
import {KtmbCarDetailsComponent} from "./comparison/ktmb-car-details/ktmb-car-details.component";
import {KtmbInsurerCardComponent} from "./comparison/ktmb-insurer-card/ktmb-insurer-card.component";
import {UploadMileageComponent} from "./comparison/upload-mileage/upload-mileage.component";
import {
  UploadedImageDialogComponent
} from "./comparison/upload-mileage/uploaded-image-dialog/uploaded-image-dialog.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { DashboardRoutingModule } from "./dashboard-routing.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { ComparisonComponent } from "./comparison/comparison.component";
import { CustomPipeModule } from "../share/pipe/custom-pipe.module";
import { EditQuotationComponent } from "./edit-quotation/edit-quotation.component";
import {FaqKtmbDialogComponent} from "./faq-dialog/faq-ktmb-dialog/faq-ktmb-dialog.component";
import { SubmitSuccessDialogComponent } from "./submit-success-dialog/submit-success-dialog.component";
import { NgxMaskModule } from "ngx-mask";
import { NgxMatIntlTelInputModule } from "ngx-mat-intl-tel-input";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { SwiperModule } from "swiper/angular";
import { BeforeExpiredDialogComponent } from "./before-expired-dialog/before-expired-dialog.component";
import { MatSelectInfiniteScrollModule } from "ng-mat-select-infinite-scroll";
import { FaqDialogComponent } from "./faq-dialog/faq-dialog.component";
import { GuidesDialogComponent } from "./guides-dialog/guides-dialog.component";
import { BenefitDialogComponent } from "./benefit-dialog/benefit-dialog.component";
import { CustomerPolicyComponent } from "./customer-policy/customer-policy.component";
import { BenefitModule } from "../share/component/benefit/benefit.module";
import { CarDetailsDialogComponent } from "./car-details-dialog/car-details-dialog.component";
import { CarPlanDialogComponent } from "./car-plan-dialog/car-plan-dialog.component";
import { RenewalReminderDialogComponent } from "./renewal-reminder-dialog/renewal-reminder-dialog.component";
import { RefundRequestComponent } from "./refund-request/refund-request.component";
import { UploadDocumentsComponent } from "./upload-documents/upload-documents.component";
import { ThankyouDialogComponent } from "./thankyou-dialog/thankyou-dialog.component";
import { TopupRequestComponent } from "./topup-request/topup-request.component";
import { ComplainComponent } from "./complain/complain.component";
import { ThankyouPageComponent } from "./thankyou-page/thankyou-page.component";
import { StaticStylesComponent } from "./static-styles/static-styles.component";
import { SampleDialogComponent } from "./upload-documents/sample-dialog/sample-dialog.component";
import { FaqTakafulDialogComponent } from "./faq-dialog/faq-takaful-dialog/faq-takaful-dialog.component";

import { OpenImageViewerDirective } from "../share/directive/open-image-viewer.directive";
import { SectionTitleComponent } from "../d2c/ui/section-title/section-title.component";
import { PaymentOptionsComponent } from "../d2c/ui/payment-options/payment-options.component";
import { D2cModule } from "../d2c/d2c.module";
import { EndorsementPaymentComponent } from './endorsement-payment/endorsement-payment.component';
import { SubmitFailDialogComponent } from './submit-fail-dialog/submit-fail-dialog.component';

@NgModule({
	entryComponents: [
		EditQuotationComponent,
		SubmitSuccessDialogComponent,
		BeforeExpiredDialogComponent,
		RenewalReminderDialogComponent,
	],
	declarations: [
		DashboardComponent,
		ComparisonComponent,
		EditQuotationComponent,
		SubmitSuccessDialogComponent,
		BeforeExpiredDialogComponent,
		FaqDialogComponent,
		GuidesDialogComponent,
		BenefitDialogComponent,
		CustomerPolicyComponent,
		CarDetailsDialogComponent,
		CarPlanDialogComponent,
		RenewalReminderDialogComponent,
		RefundRequestComponent,
		UploadDocumentsComponent,
		ThankyouDialogComponent,
		EndorsementPaymentComponent,
		TopupRequestComponent,
		TopupRequestComponent,
		ComplainComponent,
		ThankyouPageComponent,
		StaticStylesComponent,
		SampleDialogComponent,
		FaqTakafulDialogComponent,
		OpenImageViewerDirective,
    UploadMileageComponent,
    UploadedImageDialogComponent,
    KtmbInsurerCardComponent,
    KtmbCarDetailsComponent,
    FaqKtmbDialogComponent,
    SubmitFailDialogComponent,
	],
  imports: [
    CommonModule,
    MaterialModule,
    DashboardRoutingModule,
    FlexLayoutModule,
    FormsModule,
    SwiperModule,
    ReactiveFormsModule,
    TranslateModule,
    CustomPipeModule,
    NgxMaskModule,
    NgxMatIntlTelInputModule,
    NgxMatSelectSearchModule,
    MatSelectInfiniteScrollModule,
    BenefitModule,
    InsurerDetailsModule,
    D2cModule,
    SharedComponentModule
  ],
})
export class DashboardModule {}
